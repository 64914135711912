import { motion } from "framer-motion"
import styled from "styled-components"

export const CommunityTabContainer = styled(motion.div)`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 99;
    transition: background 1.5s;
    padding: 1rem;
    overflow: scroll;

    .page-content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        overflow: visible;
        gap: 1rem;
        width: 100%;
        height: fit-content;
        z-index: 2;
    }
`

