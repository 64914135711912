import ButtonIcon from "@/components/Widgets/ButtonIcon"
import api from "@/services/api"
import { updateKey } from "@/slices/config"
import { ApiCheckoutResponse, ApiStoreItem } from "@types"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import styled from "styled-components"
import StoreCardItem, {
    FakeEmptyStoreCardItem,
    StoreCardItemProps,
} from "./Store/StoreCardItem"

const CheckoutPageContainer = styled(motion.div)`
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 601;

    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    gap: 1rem;
    h1 {
        font-size: 4rem;
        text-shadow: -2px 2px 2px black;
        text-align: center;
        margin: auto;
    }

    .items-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin: auto;
        align-items: center;
        /* overflow: hidden; */
    }

    .button-area {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        align-items: center;
        margin: auto;
    }

    .button-area button {
        gap: 0.25rem;
        font-size: 1rem;
        span {
            font-size: 2rem;
        }

        &.special {
            background: #11c979;
            box-shadow: 0px 0px 20px 1px #11c9799b;
            transition: transform 0.2s ease-in-out, background 0.5s ease-in-out,
                box-shadow 1s;
            text-shadow: 0px 0px 1px #00000047;
            color: var(--background);
            padding: 0.75rem;
            padding-left: 1.2rem !important;
            span {
                text-shadow: none;
                color: var(--background);
            }
            &:hover {
                transform: scale(1.05);
                background-color: #0ead69;
                box-shadow: 0px 0px 35px 1px #0ead6996;
            }
            &:active {
                transform: scale(0.9) !important;
            }

            &.remove {
                background: #f53636;
                box-shadow: 0px 0px 20px 1px #e01a4f9b;
            }
        }
    }

    > .background {
        position: fixed !important;
        height: 100% !important;
        inset: 0;
        backdrop-filter: blur(50px) grayscale(100%) brightness(0.5);
        background-color: #1e23316b;
        z-index: 1 !important;
    }

    > * {
        z-index: 2;
    }
`

interface ItemData {
    rectPosition: [number, number]
    position: "static" | "absolute"
    hasExited?: boolean
}

let cachedPosition: { [key: string]: [number, number] } = {}

const StoreItemCheckout = ({
    storeItem,
    getItemPosition,
    itemsData,
    index,
    handleItemExited,
}: {
    itemsData: ItemData[]
    storeItem: ApiStoreItem
    getItemPosition: (
        storeItem: ApiStoreItem,
        index: number
    ) => [number, number]
    index: number
    handleItemExited: (index: number) => () => void
}) => {
    const data = itemsData[index]
    const position = getItemPosition(storeItem, index) || [0, 0]
    const shouldExit = Boolean(data.position === "absolute")
    const dancerCardPosition = useSelector(
        (state: IRootState) => state.roomData.storeModalExitPositionDancerCard
    )

    // console.log(position)
    const props: StoreCardItemProps = {
        onClick: () => {},
        storeItem: storeItem,
        shouldExit,
        handleSafeToRemove: handleItemExited(index),
        position,
        exitProps: {
            x: [position[0] + 1000, dancerCardPosition[0] + 1000],
            y: [position[1] + 1000, dancerCardPosition[1] + 1000],
            pointerEvents: "none",
            zIndex: 999 - index,
            inset: -1000,
        },
        transitionProps: {
            type: "spring",
            damping: 50,
            mass: 20,
            stiffness: 80,
            duration: 2,
            opacity: { delay: 1.5, duration: 1 },
        },
    }

    return (
        <>
            {shouldExit && (
                <FakeEmptyStoreCardItem key={`${storeItem._id}-fake`} />
            )}
            <StoreCardItem
                extraProps={
                    shouldExit
                        ? {
                              style: {
                                  position: "absolute",
                                  inset: -1000,
                              },
                          }
                        : {}
                }
                key={`${storeItem._id}`}
                {...props}
            />
        </>
    )
}

const CheckoutCallback = ({ children }: { children: JSX.Element }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { checkoutUUID } = useParams()
    const [result, setResult] = useState<ApiCheckoutResponse>(null)
    const [isExiting, setIsExiting] = useState(false)
    // const [shouldUnmountPage, setShouldUnmountPage] = useState(false)
    const [itemsData, setItemsData] = useState<ItemData[]>(null)
    const shouldUnmountPage = itemsData?.every((d) => d.hasExited)
    const [error, setError] = useState<string>(null)

    const handleCheckout = async () => {
        const response = await api.handleCheckoutCallback(checkoutUUID)

        if (typeof response === "string") {
            return toast.error(response, { autoClose: 60_000 })
        }

        setResult(response.data)
        setItemsData(
            response.data.items.map((item) => {
                cachedPosition[item._id] = [0, 0]
                return {
                    rectPosition: [0, 0],
                    position: "static",
                }
            })
        )

        await api.execCommand("dancer", [
            "enter",
            response.data.items[0].itemId,
        ])
    }

    useEffect(() => {
        handleCheckout()
        dispatch(updateKey({ key: "tab_active", newValue: "dashboard" }))
    }, [])

    const handleExit = async () => {
        setIsExiting(true)
        for (const [index, item] of itemsData.entries()) {
            if (index === 0)
                toast.success(
                    t("checkout_page.order_approved"),
                    {
                        position: "bottom-right",
                        autoClose: 120_000,
                    }
                )
            setTimeout(() => {
                setItemsData((prev) => {
                    const newData = [...prev]
                    newData[index].position = "absolute"
                    return newData
                })
            }, 0.5 * index * 1000)
        }
    }

    const handleItemExited = (index: number) => () => {
        setItemsData((prev) => {
            const newData = [...prev]
            newData[index].hasExited = true
            return newData
        })
    }

    const getItemPosition = (
        storeItem: ApiStoreItem,
        index: number
    ): [number, number] => {
        const element = document.getElementById(
            `store-card-item-${storeItem._id}`
        )
        if (!element) {
            return [50000, 50000]
        }
        const rect = element.getBoundingClientRect()
        const { left, top } = rect
        if (element.style.position === "absolute") {
            return cachedPosition[storeItem._id]
        }
        // console.log([left, top])
        cachedPosition[storeItem._id] = [left, top]
        return [left, top]
    }

    const handleScroll = () => {
        result.items.forEach((item, index) => {
            getItemPosition(item, index)
            // console.log(index)
        })
    }

    useEffect(() => {
        if(!shouldUnmountPage) return
        const url = window.location.href.split("/checkout")[0]
        window.history.replaceState(null, null, url)
    }, [shouldUnmountPage])

    return (
        <>
            <AnimatePresence key={"bro"}>
                {Boolean(result && !shouldUnmountPage) && (
                    <CheckoutPageContainer
                        style={isExiting && { pointerEvents: "none" }}
                        onScroll={handleScroll}
                    >
                        <motion.div
                            animate={{
                                opacity: isExiting ? 0 : 1,
                            }}
                            className="background"
                        />
                        <motion.h1
                            animate={isExiting ? { y: "-20vh" } : {}}
                            transition={{ delay: 0.3 }}
                        >
                            {t("checkout_page.order_approved_title")}
                        </motion.h1>
                        <div className="items-wrapper">
                            {result.items.map((storeItem, index) => (
                                <StoreItemCheckout
                                    getItemPosition={getItemPosition}
                                    handleItemExited={handleItemExited}
                                    index={index}
                                    itemsData={itemsData}
                                    storeItem={storeItem}
                                    key={storeItem._id}
                                />
                            ))}
                        </div>
                        <motion.div
                            animate={{ y: isExiting ? "20vh" : 0 }}
                            className="button-area"
                        >
                            <ButtonIcon
                                icon="help"
                                children={t("checkout_page.help")}
                                onSubmit={() =>
                                    window.open("/support", "_blank")
                                }
                                
                                type={"white"}
                            ></ButtonIcon>
                            <ButtonIcon
                                icon="nightlife"
                                children={t("checkout_page.confirm")}
                                onSubmit={handleExit}
                                wrapperProps={{ className: "special" }}
                            ></ButtonIcon>
                        </motion.div>
                    </CheckoutPageContainer>
                )}
            </AnimatePresence>
            {children}
        </>
    )
}

export default CheckoutCallback
