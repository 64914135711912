import { CommandLoading } from "@/components/Loading"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import { BetaTag } from "@/components/Widgets/Tags"
import useModal from "@/hooks/useModal"
import CreateRoomFinal from "@/pages/CreateRoom/Final"
import CreateRoomForm from "@/pages/CreateRoom/Form"
import CreateRoomRoadMap from "@/pages/CreateRoom/Roadmap"
import PreLoginPage from "@/pages/PreLogin"
import api from "@/services/api"
import { ApiRoom } from "@types"
import { AnimatePresence, motion } from "framer-motion"
import { ReactNode, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import useMeasure from "react-use-measure"
import styled from "styled-components"

const Container = styled(motion.div)`
    .content-wrapper {
        position: relative;
        padding: 1rem;
        overflow: hidden;
    }

    .loading-main {
        inset: 0;
    }

    .content-wrapper p.error {
        color: var(--color-2);
        text-align: center;

        > a {
            color: var(--color-5);
        }
    }

    .beta-tag {
        position: absolute;
        inset: 0.5rem;
        width: fit-content;
        height: fit-content;
    }
`

type PageType = "form" | "roadmap" | "final"

const CreateRoomPage = ({ defaultEventId }: { defaultEventId?: string }) => {
    const [currentPage, setCurrentPage] = useState<PageType>("form")
    const [roomName, setRoomName] = useState<string>(null)
    const [eventId, setEventId] = useState<string>(defaultEventId)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<ReactNode>(null)
    const [ref, size] = useMeasure({})
    const [roomData, setRoomData] = useState<ApiRoom>(null)
    const { t } = useTranslation()
    const user = useSelector((state: IRootState) => state.userData.user)

    const handleSubmit = async (data: string) => {
        setIsLoading(true)
        setError(null)
        const response = await api.sendCreateRoomRequest(data)
        console.log({ response })
        if (!response.success) {
            if (response.code && response.data) {
                setError(
                    <Trans i18nKey={`home.rooms.create_modal.errors.${response.code}`}>
                        <a href={`/request/${response.data.eventId}`} target="_blank"></a>
                    </Trans>,
                )
            } else {
                setError(response.message!)
            }

            setIsLoading(false)
            return
        }
        setEventId(response.data.eventId)
        history.pushState({}, "", `/request/${response.data.eventId}`)

        setCurrentPage("roadmap")
        setRoomName(data)
        setIsLoading(false)
    }

    const shouldRenderLoading = isLoading && currentPage === "form"

    useEffect(() => {
        if (!defaultEventId) return
        setEventId(defaultEventId)
        setError(null)
        setIsLoading(false)
        setCurrentPage("roadmap")
    }, [defaultEventId])

    const handleRoomCreated = (data: ApiRoom) => {
        setRoomData(data)
        setTimeout(() => setCurrentPage("final"), 1000)
    }

    const handleRoomError = (errorCode: "not_found") => {
        setEventId(null)
        setCurrentPage("form")
        setError(t("home.rooms.create_modal.errors." + errorCode))
    }

    return (
        <Container animate={{ height: size.height }}>
            <motion.div className="content-wrapper" ref={ref}>
                <BetaTag className="beta-tag" />
                <AnimatePresence mode="wait">
                    {!user && <PreLoginPage isGeneric={true} />}
                    {Boolean(currentPage === "form" && user) && (
                        <CreateRoomForm onSubmit={handleSubmit} key={"form"}>
                            {error && (
                                <motion.p
                                    className="error"
                                    initial={{ height: 0 }}
                                    animate={{ height: "fit-content" }}
                                    exit={{ height: 0 }}
                                    key={"error"}
                                >
                                    {error}
                                </motion.p>
                            )}
                        </CreateRoomForm>
                    )}
                    {Boolean(currentPage === "roadmap" && user) && (
                        <CreateRoomRoadMap
                            onError={handleRoomError}
                            onSuccess={handleRoomCreated}
                            eventId={eventId}
                            key={"roadmap"}
                        />
                    )}

                    {Boolean(currentPage === "final" && user) && <CreateRoomFinal roomData={roomData} />}
                </AnimatePresence>

                <CommandLoading className="loading-main" customHeight={40} isLoading={shouldRenderLoading} />
            </motion.div>
        </Container>
    )
}

export const CreateRoomButton = ({ large }: { large?: boolean }) => {
    const { setModal } = useModal()
    const { t } = useTranslation(null, { keyPrefix: "home.rooms.create_modal" })
    const largerStyles = { fontSize: "1.25rem", padding: "1rem", gap: ".5rem" }

    return (
        <ButtonIcon
            children={t("create_room_button")}
            type={"white"}
            onSubmit={() => setModal(<CreateRoomPage />, "minimum")}
            icon="person_raised_hand"
            wrapperProps={{
                style: large && largerStyles,
                className: "create-room-button",
            }}
        />
    )
}

export default CreateRoomPage
