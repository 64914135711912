import TabButton from "@/components/TabButton"
import Tooltip from "@/components/Tooltip"
import ShadowButton from "@/components/Widgets/ShadowButton"
import useModal from "@/hooks/useModal"
import api from "@/services/api"
import { ApiTipsResponse } from "@types"
import { ReactNode, useEffect, useState } from "react"
import { toast } from "react-toastify"
import ConfirmationPagePix from "./ConfirmationPagePix"
import ConfirmationPageCard from "./ConfirmationPageCard"

import TipsTable from "./TipsTable"
import { DonatePageContainer, ShadowContainerButton } from "./styles"
import { useTranslation } from "react-i18next"
import ButtonIcon from "@/components/Widgets/ButtonIcon"

const TemplateTooltip = ({ children, src, description }: { children: ReactNode; src: string; description: string }) => (
    <Tooltip
        delay={[0, 100]}
        hideOnClick={false}
        interactive={true}
        content={
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: ".3rem",
                }}
            >
                <p
                    style={{
                        textAlign: "center",
                        background: "var(--background-2)",
                    }}
                >
                    {description}
                </p>
                <img
                    // style={{ width: 72, height: 72 }}
                    src={src}
                ></img>
            </div>
        }
    >
        <div className="underline">{children}</div>
    </Tooltip>
)

const DonatePage = ({ measureRef }: { measureRef?: any }) => {
    const [tips, setTips] = useState<ApiTipsResponse>({
        history: [],
        leaderboard: [],
        dollarPrice: 5
    })
    const [mode, setMode] = useState<"leaderboard" | "history">("leaderboard")
    const [filter, setFilter] = useState<"hide" | "show">("hide")
    const { t } = useTranslation()
    const { setModal } = useModal()

    const fetchTips = () =>
        api
            .fetchTipsList()
            .then((res) => setTips(res.data))
            .catch((e: string) => {
                toast.error(e)
            })

    useEffect(() => {
        fetchTips()
    }, [])

    const handleOptions = (e: any) => {
        setMode(e)
    }

    const handleFilter = (e: any) => {
        setFilter(e === "person" ? "show" : "hide")
        console.log(e)
    }

    return (
        <DonatePageContainer ref={measureRef}>
            <h1>{t("tips.title")}</h1>
            <div className="table-wrapper">
                <div className="filtering-wrapper">
                    <TabButton
                        className={"select-filter"}
                        texts={t("tips.input_1", { returnObjects: true })}
                        layoutId="tab-button-leaderboard"
                        onClick={handleOptions}
                        icons={["leaderboard", "history"]}
                    />
                    <TabButton
                        className={"select-filter"}
                        texts={t("tips.input_2", { returnObjects: true })}
                        layoutId="tab-button-userfilter"
                        onClick={handleFilter}
                        icons={["person_off", "person"]}
                    />
                </div>
                {tips && <TipsTable filter={filter} mode={mode} tips={tips[mode]} dollarPrice={tips.dollarPrice} />}
            </div>
            <div className="section">
                <div className="content">
                    <p>{t("tips.description_1")}</p>

                    <div className="inline-p">
                        {t("tips.description_2")}
                        <TemplateTooltip
                            src="https://f.feridinha.com/sync/badges/supporter_l.gif"
                            children={t("tips.description_3")}
                            description={t("tips.description_3_tooltip")}
                        />
                        {t("tips.description_3_5")}
                        <TemplateTooltip
                            src="https://f.feridinha.com/sync/dancers/AvatarViscoito_A.gif"
                            children={t("tips.description_4")}
                            description={t("tips.description_4_tooltip")}
                        />
                    </div>
                </div>
            </div>
            <div className="buttons-area">
                <ButtonIcon
                    type={"white"}
                    onSubmit={() => setModal(<ConfirmationPageCard />, "minimum")}
                    icon="credit_card"
                    children={t("tips.card_button")}
                />
                <ShadowButton
                    shadowColor="#ffffff34"
                    style={{
                        borderRadius: "var(--border-radius-ss)",
                        padding: 0,
                    }}
                    scale={2}
                    onClick={() => setModal(<ConfirmationPagePix />, "minimum")}
                >
                    <ShadowContainerButton>
                        <span className="material-icon notranslate">payments</span>
                        <p>{t("tips.pix_button")}</p>
                    </ShadowContainerButton>
                </ShadowButton>
            </div>
        </DonatePageContainer>
    )
}

export default DonatePage
