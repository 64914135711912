import { useGridQueries } from "@/hooks/useGridQueries"
import { useSelector } from "react-redux"

const Grid = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    const { queueSmaller, queueHidden, mobileSize } = useGridQueries()
    const queue_in_sidebar = useSelector(
        ({ config }: IRootState) => config.queue_in_sidebar
    )

    let gridClassNames = ["grid-normal"]

    if (queue_in_sidebar) gridClassNames.push("forced-hidden-queue")
    if (queueSmaller) gridClassNames.push("grid-queue-smaller")
    if (queueHidden) gridClassNames.push("grid-queue-hidden")
    if (mobileSize) gridClassNames.push("grid-mobile-size")

    return (
        <div className={`container ${gridClassNames.join(" ")}`}>
            {children}
        </div>
    )
}

export default Grid
