import useModal from "@/hooks/useModal"
import DonatePage from "@/pages/Donate/"
import styled from "styled-components"
import ShadowButton from "../Widgets/ShadowButton"
import { useTranslation } from "react-i18next"

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4067d1;
    padding: 0.5rem 0.75rem;
    padding-left: 0.3rem;
    color: var(--foreground);
    cursor: pointer;
    width: 100%;
    transition: 0.5s;

    span {
        z-index: 2;
        font-size: 1.75rem;
    }

    p {
        font-size: 1rem;
        z-index: 2;
    }

    &.hover {
        background-color: #335ac5;
        * {
            color: #eee;
            fill: #eee;
        }
    }
`

const DonateButton = (props: { borderRadius?: string }) => {
    const { setModal } = useModal()
    const { t } = useTranslation()
    return (
        <ShadowButton
            shadowColor="#6c8bdf"
            style={{
                borderRadius: props.borderRadius || "var(--border-radius-ss)",
            }}
            scale={2}
            onClick={() => setModal(<DonatePage />, "minimum")}
        >
            <Container>
                <span className="material-icon notranslate">attach_money</span>
                <p>{t("widgets.customs.donate_button")}</p>
            </Container>
        </ShadowButton>
    )
}

export default DonateButton
