import Tooltip from "@/components/Tooltip"
import { useSingleton } from "@tippyjs/react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { IHelpTooltips, badges } from "./data"

const colors = [
    "#53b3cb",
    "#0ead69",
    "#5f19dd",
    "#e01a4f",
    "#858ea1",
    "#ffaaa8",
]
const ids = ["cyan", "green", "purple", "red", "normal", "flower"]
// 5f19dd

let currentColor = colors[0]
interface ContainerProps {
    title: string
    description?: string
    id: string
    tooltips: IHelpTooltips
}

export const Container = ({ title, description, id, tooltips }: ContainerProps) => {
    const [source, target] = useSingleton({
        overrides: ["placement"],
    })
    const currentBadges = badges[id]
    const { i18n, t } = useTranslation()

    const currentTooltips = tooltips[i18n.language][id]
    return (
        <div>
            <h2>{title}</h2>
            {description && (
                <>
                    <br />
                    <h3>{description}</h3>
                </>
            )}
            <br />
            <Tooltip
                singleton={source}
                placement="left"
                moveTransition="transform 0.8s cubic-bezier(0.22, 1, 0.36, 1)"
                hideOnClick={false}
                animation="shift-away"
                interactive={true}
                offset={[0, 10]}
                delay={[0, 1000]}
                appendTo={document.body}
            />
            <table>
                <tbody>
                    {currentTooltips.map((_, index) => (
                        <Command
                            currentBadges={currentBadges}
                            currentTooltip={currentTooltips[index]}
                            key={index}
                            singleton={target}
                            t={t}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}

const getWordsColor = (words: string[]) => {
    return words.map((word, i) => {
        if (i === 0) currentColor = colors[0]
        if (ids.includes(word)) {
            currentColor = colors[ids.indexOf(word)]
            return
        }
        return (
            <span key={i} style={{ color: currentColor }}>
                {" "}
                {word}
            </span>
        )
    })
}

interface CommandProps {
    singleton: any
    currentTooltip: {
        tutorial: string
        message: string
        response: string
    }
    currentBadges: string[]
    t: (key: string) => string
}

export const Command = ({
    singleton,
    currentTooltip,
    currentBadges,
    t
}: CommandProps) => {
    const words = currentTooltip.tutorial.split(" ")

    return (
        <tr>
            <td>{getWordsColor(words)}</td>
            <td>
                <Tooltip
                    content={
                        <TooltipChat
                            currentTooltip={currentTooltip}
                            currentBadges={currentBadges}
                            t={t}
                        />
                    }
                    singleton={singleton}
                    placement="left"
                    interactive={true}
                    offset={[0, 10]}
                    interactiveBorder={50}
                    delay={[0, 1000]}
                    // visible={true}
                >
                    <button onClick={(e) => e.stopPropagation()}>
                        {t("help.example")}
                    </button>
                </Tooltip>
            </td>
        </tr>
    )
}

const Badge = ({ id }: { id: string }) => (
    <span className="badge">
        <img
            style={{ height: "1.1rem", width: "1.1rem" }}
            src={`https://f.feridinha.com/sync/badges/${id}`}
        />
    </span>
)

interface TooltipChatProps {
    currentTooltip: {
        tutorial: string
        message: string
        response: string
    }
    currentBadges: string[]
    t: (key: string) => string
}

export const TooltipChat = ({
    currentTooltip,
    currentBadges,
    t
}: TooltipChatProps) => {
    const { message, response } = currentTooltip

    return (
        <div className="tooltip-chat">
            <h4 style={{ textAlign: "center" }}>{message.split(" ")[0]}</h4>
            <div className="message-1">
                <p className="time">
                    {moment(moment.now()).format("HH:mm:ss")}
                </p>
                {currentBadges.map((name, index) => (
                    <Badge id={name} key={index} />
                ))}
                <p className="author">Feridinha:</p>
                <p className="message">{message}</p>
            </div>
            <div className="message-2">
                <p className="time">
                    {moment(moment.now()).add(1, "seconds").format("HH:mm:ss")}
                </p>
                <Badge id="vip.png" />
                <p className="author">Botirinho:</p>
                <p className="message">{response}</p>
                {!response && <p className="time">{t("help.no_response")}</p>}
            </div>
        </div>
    )
}

type BadgeType =
    | "moderator.png"
    | "broadcaster.png"
    | "staff.png"
    | "dj.png"
    | "vip.png"
    | "supporter.png"

interface TooltipMessageProps {
    message: string
    badges: BadgeType[]
    title: string
}

export const TooltipMessage = ({ message, badges, title }: TooltipMessageProps) => {
    return (
        <div className="tooltip-chat">
            <h4 style={{ textAlign: "center" }}>{title}</h4>
            <div className="message-1">
                <p className="time">
                    {moment(moment.now()).format("HH:mm:ss")}
                </p>
                {badges.map((name, index) => (
                    <Badge id={name} key={index} />
                ))}
                <p className="author">Feridinha:</p>
                <p className="message">{message}</p>
            </div>
            <div className="message-1">
                <p className="time">
                    {moment(moment.now()).format("HH:mm:ss")}
                </p>
                {badges.map((name, index) => (
                    <Badge id={name} key={index} />
                ))}
                <p className="author">Feridinha:</p>
                <p className="message">{message}</p>
            </div>
        </div>
    )
}
