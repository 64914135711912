import styled from "styled-components"
import { CommandLoading } from "@/components/Loading"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import { useTranslation } from "react-i18next"
import { ReactNode, useEffect, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import * as socketio from "socket.io-client"
import { ApiRoom, ApiRoomData, ApiRoomRequestEvent } from "@types"
import { fadeInOut } from "@/utils/fadeInOut"

const Container = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    /* width: 100%; */
    height: 100%;
    max-width: 45rem;
    gap: 2rem;
    padding: 1rem;
    h1 {
        font-size: 2rem;
    }
    align-items: center;
`

const RoadmapContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    padding: 0.75rem 1rem;
    background-color: var(--background);
    border-radius: var(--border-radius-m);
    outline: 1px solid #ffffff14;

    .roadmap-item {
        display: flex;
        gap: 0.75rem;
        align-items: center;

        &.success span.point {
            background-color: var(--color-4);
        }

        span.point {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: #ffffff1a;
            height: 1.75rem;
            width: 1.75rem;
            border-radius: var(--border-radius-m);
            color: var(--background-contrast);
            font-size: 1.5rem;
            cursor: pointer;
            box-shadow: 0px 0px 5px 1px #00000065;
        }

        &.error span.point {
            background-color: var(--color-2);
        }

        &.loading span.point {
            background-color: #ffffff11;
        }

        p.error {
            color: var(--color-2);
        }

        p.success {
            color: var(--color-4);
        }
    }

    .loading-box {
        background-color: #ffffff00;
        cursor: wait;
    }

    .v-line {
        position: relative;
        height: 3rem;
        width: 1.75rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 0.1rem;
        .filler {
            position: absolute;
            height: 0;
            background-color: var(--foreground);
            width: 0.25rem;
            border-radius: 0.75rem;
            box-shadow: 0px 0px 5px 1px #00000055;
        }

        &.not-active .filler {
            height: 100%;
            background-color: var(--background);
            opacity: 0.5;
        }

        &.success .filler {
            background-color: var(--color-4);
            box-shadow: 0px 0px 5px 1px #00000055;
        }
    }

    .roadmap-item.not-active {
        p {
            opacity: 0.2;
            color: var(--foreground);
        }

        span.point {
            opacity: 0.2;
            background-color: var(--background-contrast);
            color: var(--foreground);
        }
    }
`

const Step = ({ state, children }: { state: "success" | "loading" | "error" | "pending"; children: ReactNode }) => {
    const isActive = state === "success" || state === "loading" || state == "error"
    let icon: ReactNode = null

    switch (state) {
        case "error":
            icon = "error"
            break
        case "loading":
            icon = (
                <CommandLoading
                    customColor={"var(--foreground)"}
                    customStroke={"var(--foreground)"}
                    isLoading={true}
                    className="loading-box"
                />
            )
            break
        case "success":
            icon = "done"
            break
        case "pending":
            icon = "fiber_manual_record"
    }

    return (
        <div className={`roadmap-item ${isActive ? "" : "not-active"} ${state}`}>
            <AnimatePresence mode="popLayout">
                <motion.span
                    className="point material-icon notranslate"
                    key={state}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: state === "pending" ? 0.2 : 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    {icon}
                </motion.span>
            </AnimatePresence>
            <p className={state}>{children}</p>
        </div>
    )
}

const CreateRoomRoadMap = ({
    eventId,
    onSuccess,
    onError,
}: {
    eventId: string
    onSuccess: (data: ApiRoom) => void
    onError: (errorCode: "not_found") => void
}) => {
    const [currentData, setCurrentData] = useState<ApiRoomRequestEvent>(null)
    const currentStep = currentData ? currentData.step : -1
    const isErrored = currentData ? currentData.isErrored : false

    const { t } = useTranslation()

    console.log({ currentData, currentStep, isErrored })

    const startEvents = () => {
        const io = socketio.connect(process.env.API_PATH, {
            transports: ["websocket"],
            autoConnect: false,
            extraHeaders: {
                "ngrok-skip-browser-warning": "any",
            },
        })

        io.on("room_request", (data: ApiRoomRequestEvent) => {
            console.log("Atualizando", data)
            setCurrentData(() => data)
            if (data.step >= 3) {
                onSuccess(data.room!)
            }
        })

        io.on("room_request_not_found", () => {
            onError("not_found")
        })

        io.connect()

        io.on("connect", () => {
            io.emit("join-event", eventId)
        })
    }

    useEffect(() => {
        setCurrentData(null)
        startEvents()
    }, [eventId])

    const stepsText = t("home.rooms.create_modal.roadmap_steps", { returnObjects: true }) as string[]
    
    return (
        <Container
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
        >
            <h1>{t("home.rooms.create_modal.roadmap_title")}</h1>
            <RoadmapContainer>
                {stepsText.map((_, index) => {
                    let state = index >= currentStep ? "loading" : "success"
                    if (state === "loading" && index > currentStep) state = "pending"
                    const isActive = state === "success"
                    if (index === currentStep && isErrored) state = "error"

                    const shouldShowFiller = index < stepsText.length - 1

                    return (
                        <div key={index}>
                            <Step children={stepsText[index]} state={state as any} />
                            {Boolean(shouldShowFiller && isActive) && (
                                <div className={`v-line success`}>
                                    <motion.div
                                        className="filler"
                                        initial={{ height: 0 }}
                                        animate={{ height: "calc(100% - .2rem)" }}
                                        transition={{ duration: 2 }}
                                    />
                                </div>
                            )}
                            {Boolean(shouldShowFiller && !isActive) && (
                                <div className={`v-line not-active`}>
                                    <div className="filler" />
                                </div>
                            )}
                        </div>
                    )
                })}
            </RoadmapContainer>
            <ButtonIcon
                onSubmit={() => window.open("/support", "_blank")}
                type={"white"}
                wrapperProps={{ style: { paddingRight: ".9rem" } }}
                children={
                    <>
                        <span className="material-icon notranslate">help</span>
                        {t("about.need_help")}
                    </>
                }
            />
        </Container>
    )
}

export default CreateRoomRoadMap
