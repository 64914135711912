import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Box = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    /* min-height: 260px; */
    height: 100%;
    padding: 0.5rem;
    opacity: 0.25;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, 0);
    border-radius: var(--border-radius-s);
    text-align: center;
    height: 100%;
`

export const RenderDisabled = (props: any) => {
    const { t } = useTranslation()
    return (
        <Box {...props}>
            {props.children ||
                t("widgets.render_disabled.config")}
        </Box>
    )
}
export const RenderDisabledOpenGl = (props: any) => {
    const { t } = useTranslation()
    return (
        <RenderDisabled
            style={{
                height: "calc(18.75rem)",
                backgroundColor: "var(--background-light)",
            }}
            {...props}
        >
            {t("widgets.render_disabled.open_gl")}
        </RenderDisabled>
    )
}
export const RenderDisabledHardAcceleration = (props: any) => {
    const { t } = useTranslation()
    return (
        <RenderDisabled
            style={{
                height: "calc(18.75rem)",
                backgroundColor: "var(--background-light)",
            }}
            {...props}
        >
            <p>
                {t("widgets.render_disabled.hardware_acceleration")}
            </p>
        </RenderDisabled>
    )
}
export default RenderDisabled
