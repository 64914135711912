import TwitchLogin from "@/components/Customs/TwitchLogin"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1rem 0.5rem;
    background-color: var(--background);
    padding: 1rem;
    gap: 1rem;
    border-radius: var(--border-radius-s);
`

const PreLoginPage = ({ isGeneric }: { isGeneric?: boolean }) => {
    const { t } = useTranslation()
    return (
        <Container>
            {!isGeneric && (
                <>
                    <h4>{t("pre_login.title_1")}</h4>
                    <p>{t("pre_login.title_2")}</p>
                </>
            )}
            {isGeneric && <h2 style={{ marginBottom: "2rem", textAlign: "center" }}>{t("pre_login.generic")}</h2>}

            <TwitchLogin customProps={{ scale: 1.5 }} isSidebar={true} />
        </Container>
    )
}

export default PreLoginPage
