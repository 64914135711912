import AsyncButton from "@/components/Widgets/AynscButton"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import useModal from "@/hooks/useModal"
import { greenButtonColors } from "@/pages/Donate/ConfirmationPagePix"
import { DonatePageContainer } from "@/pages/Donate/styles"
import PreLoginPage from "@/pages/PreLogin"
import api from "@/services/api"
import { getRoomName, sleep } from "@/utils"
import { FocusEventHandler, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import useMeasure from "react-use-measure"
import styled from "styled-components"

export const ConfirmPageContainer = styled(DonatePageContainer)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem;
    gap: 2rem;

    h1 {
        font-size: 3rem;
    }

    span {
        color: var(--foreground) !important;
    }

    input.editable {
        margin: 0 0.3rem;
        text-decoration: underline;
        border: none;
        background: none;
        /* border-bottom: 1px solid var(--foreground); */
        font-size: 3rem;
        font-weight: 700;
    }

    span.click-to-edit {
        cursor: pointer;
        padding: 0.1rem;
        transition: 0.1s;
        border-radius: 0.25rem;
        :hover {
            background-color: var(--background-light);
        }
    }

    .buttons-wrapper {
        display: flex;
        height: fit-content;
        gap: 1rem;

        button {
            padding: 0.5rem 0.7rem !important;
            color: var(--foreground) !important;
        }
        color: var(--foreground) !important;
    }

    .buttons-grid {
        display: grid;
        grid-template-columns: repeat(2, 8rem);
        grid-template-rows: repeat(3, 3rem);
        /* background-color: red; */
        gap: 0.5rem;

        button {
            font-size: 1.25rem;
            padding: 1rem 1.5rem;
            padding-left: 1.5rem !important;
            outline: 0px solid var(--color-3);
        }

        button.selected {
            /* box-shadow: 0px 0px 1px 2px var(--color-3); */
            outline: 5px solid var(--color-3);
            /* background-color: #abb5cc !important; */
            /* color: var(--foreground) !important; */
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .buttons-area {
        color: var(--foreground);
        /* padding: 0.5rem 1rem !important; */
    }

    .loading-box {
        inset: 0;
    }
`

const amounts = [1, 5, 10, 25, 50, 100]

const ConfirmationPageCard = () => {
    const [amount, setAmount] = useState<string>("5")
    const [error, setError] = useState<string>(null)
    const { t, i18n } = useTranslation(null, { keyPrefix: "tips.card_payment_page" })
    const { setModal } = useModal()
    const ref = useRef<HTMLInputElement>(null)
    const currency = i18n.language === "pt" ? "brl" : "usd"
    const currencyPrefix = currency === "brl" ? "R$" : "$"
    const [measureRef, size] = useMeasure()
    const user = useSelector((state: IRootState) => state.userData.user)

    const handleCreate = async () => {
        const roomName = getRoomName()
        const response = await api.createStripeDonation({
            amount: parseInt(amount),
            roomName: roomName.length > 0 ? roomName : undefined,
        })
        if (typeof response === "string") {
            toast.error(response)
            return setError(response)
        }

        window.location.href = response.data.url
    }

    const setSpanActive = () => {
        if (!ref.current) return
        ref.current.focus()
        const range = document.createRange()
        range.selectNodeContents(ref.current)
        range.collapse(false)
        const sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)
    }

    const handleInputChange = (e: any) => {
        const value = e.target.value as string
        const cleanedValue = value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1")
        if (cleanedValue.length > 5) return
        setAmount(cleanedValue)
    }

    const handleInputKeyDown = (e: any) => {
        if (e.key === "Enter") {
            // e.target.blur()
            e.preventDefault()
        }
    }

    const handleInputBlur: FocusEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value
        if (value.length === 0 || value === "0") setAmount("1")
    }

    if (!user) return <PreLoginPage isGeneric={true} />

    return (
        <ConfirmPageContainer>
            <h1 style={{ textAlign: "center" }}>{t("title")}</h1>
            <h1 style={{ position: "absolute", opacity: 0, pointerEvents: "none" }} ref={measureRef}>
                {amount}
            </h1>
            <h1>
                <span style={{ marginRight: ".25rem" }}>{currencyPrefix}</span>
                <input
                    ref={ref}
                    className="editable"
                    type="text"
                    value={amount}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    style={{ width: size.width }}
                    onBlur={handleInputBlur}
                    maxLength={10000}
                />
                <span onClick={setSpanActive} className="material-icon notranslate click-to-edit">
                    edit
                </span>
            </h1>
            <div className="form">
                <div className="buttons-grid">
                    {amounts.map((value, index) => {
                        const isSelected = value.toString() === amount
                        return (
                            <ButtonIcon
                                wrapperProps={{ className: isSelected ? "selected" : null }}
                                type={"white"}
                                key={index}
                                onSubmit={() => setAmount(value.toString())}
                                children={`${currencyPrefix} ${value}`}
                            />
                        )
                    })}
                </div>
            </div>
            <div className="buttons-wrapper">
                <AsyncButton
                    colors={greenButtonColors}
                    resolver={handleCreate}
                    children={i18n.t("store_page.shopping_cart.pay_with_card")}
                    icon="credit_card"
                />
            </div>
        </ConfirmPageContainer>
    )
}

export default ConfirmationPageCard
