import styled from "styled-components"

export const DonatePageContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 100%;
    background-color: var(--background-contrast);
    padding: 0.5rem;
    padding-top: 1rem;
    border-radius: var(--border-radius-m);
    overflow-y: auto;

    justify-content: space-between;

    .section {
        display: flex;
        flex-direction: column;
        padding: 0 0.5rem;
        width: 100%;
        align-items: center;
        gap: 0.1rem;
        .content {
            display: flex;
            flex-direction: column;
            gap: 0.15rem;
            padding: 0.5rem;
            background-color: var(--background-2);
            border-radius: var(--border-radius-s);
            max-height: 8.5rem;
            overflow-y: auto;
        }

        .content p,
        .content .inline-p {
            border-radius: var(--border-radius-s);
            padding: 0.4rem 0.5rem;
            transition: 0.1s;
        }

        .content p:hover {
            background: var(--background);
        }

        .inline-p {
            display: flex;
            width: 100%;
            gap: .3rem;
            :hover {
                background-color: var(--background) !important;
            }
        }

        .underline {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .select-filter {
        background-color: var(--background-2);
        overflow: hidden;
        align-self: flex-end;
    }

    .table-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 0.5rem;
        gap: 0.3rem;
    }

    .filtering-wrapper {
        display: flex;
        gap: .3rem;
        margin-left: auto;
    }

    .buttons-area {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }
`

export const ShadowContainerButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0ead69;
    padding: 0.5rem 0.75rem;
    padding-left: 0.5rem;
    color: var(--foreground);
    cursor: pointer;
    width: 100%;
    transition: 0.5s;
    span {
        z-index: 2;
        font-size: 1.75rem;
    }

    span.material-icon {
        font-variation-settings: "FILL" 1, "wght" 200, "GRAD" 0, "opsz" 28;
        margin-right: 0.25rem;
    }

    p {
        font-size: 1rem;
        z-index: 2;
    }
`

export const TipsTableContainer = styled.div`
    overflow-y: auto;
    max-height: 20rem;
    min-height: 20rem;

    table {
        background-color: var(--background-2);
        border-radius: var(--border-radius-s);
        width: 100%;

        /* border-spacing: 0.75rem; */

        td {
            text-align: left;
        }

        span.material-icon {
            font-size: 1.5rem;
            font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 0, "opsz" 40;
        }

        span.user {
            vertical-align: middle;
            line-height: 21px;
            font-size: 1rem;
        }

        .badge-wrapper {
            display: inline-block;
            margin-left: 0.25rem;
            padding: 0 0.15rem;
            border-radius: var(--border-radius-ss);
            :hover {
                background-color: var(--background-light);
            }
        }

        span.currency-exchange {
            color: var(--color-5);
        }

        span.user.unknown {
            color: var(--color-2);
            font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 500, "opsz" 48;
            padding-right: 0.05rem;
        }

        span.trophy.first {
            color: #f1c40f;
        }

        span.trophy.second {
            color: #a0b8ec;
        }

        span.trophy.third {
            color: #f18f0f;
        }

        tr.tip-item {
            min-height: 2rem;
            height: 2rem;
        }

        tr td {
            padding: 0 0.5rem;
        }

        tr.header th {
            min-height: 3rem;
            padding: 0.5rem 0;
        }

        tr.tip-item:nth-child(even) {
            background-color: var(--background);
        }
    }
`
