import ContactButton from "@/components/Customs/ContactButton"
import DiscordButton from "@/components/Customs/DiscordButton"
import DonateButton from "@/components/Customs/DonateButton"
import Tooltip from "@/components/Tooltip"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"
import { LegalHyperlinks } from "./Support"
import useModal from "@/hooks/useModal"
import CreateRoomPage from "@/pages/CreateRoom"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
    /* justify-content: space-around; */
    height: 100%;
    color: var(--foreground);
    overflow-y: auto;
    gap: 2rem;
    > * {
        margin: auto;
    }

    .texts {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        p {
            padding: 0.75rem;
            background-color: var(--background-2);
            font-weight: 400;
            border-radius: var(--border-radius-ss);
        }

        a.request {
            color: var(--color-5);
            text-decoration: underline;
            cursor: pointer;
        }
    }

    span.material-icon.role {
        color: var(--background-2);
        border-radius: 0.25rem;
        font-size: 1.5rem;
        font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 18;
        transition: 0.5s ease;
    }

    span.material-icon.role:hover {
        z-index: 9999;
        transform: scale(1.5);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 1);

        font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 28;
    }

    a.username {
        margin-right: 0.4rem;
        margin-bottom: 0.1rem;
        margin-top: 0.1rem;
        align-items: center;
        display: inline-flex;
        gap: 0.25rem;
        vertical-align: middle;
        font-weight: 400;
        text-decoration: none;
        font-size: 0.9rem;

        span {
            display: inline-block;
            vertical-align: middle;
            text-decoration: none !important;
        }
    }

    .button-area {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        > * {
            flex-grow: 1;
            height: 2.75rem;
        }

        
    }

    .legal-notice {
        margin: 0;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: 0.25rem 0.5rem;
        font-size: 0.9rem;
        justify-content: center;
        > * {
            display: flex;
            width: fit-content;
        }

        .help-button {
            flex-grow: 1;

            /* padding: .1rem !important; */
        }
    }
`

const plugDjTweet = "https://twitter.com/plugdj/status/1359210486798114819"

const Link = (props: any) => <a href={props.url} target="_blank" {...props}></a>

const Page = ({ backgroundColor }: { backgroundColor?: string }) => {
    const { t } = useTranslation()
    const { setModal } = useModal()
    return (
        <Container className="about">
            <h1>{t("about.title")}</h1>
            <div className="texts">
                <p style={{ backgroundColor }}>
                    <Trans i18nKey="about.explanation_1">
                        <Link url={plugDjTweet}></Link>
                    </Trans>
                </p>
                <p style={{ backgroundColor }}>
                    <Trans i18nKey={"about.explanation_2"}>
                        <a
                            onClick={() => {
                                setModal(<CreateRoomPage />, "minimum")
                            }}
                            className="request"
                        ></a>
                    </Trans>
                    {/* {t("about.explanation_2")} */}
                </p>

                <p style={{ backgroundColor }}>
                    {t("about.explanation_3")}{" "}
                    <Link
                        className="username"
                        url={"https://discord.com/users/485322789822136330"}
                        style={{ color: "#FFA3EE" }}
                    >
                        <Tooltip content={t("home.backend_frontend_tooltip")} placement="top" offset={[0, 12]}>
                            <span style={{ backgroundColor: "#FFA3EE" }} className="material-icon notranslate role">
                                code
                            </span>
                        </Tooltip>
                        Feridinha
                    </Link>{" "}
                    <Link className="username" url={"//twitch.tv/opeepo"} style={{ color: "gold" }}>
                        <Tooltip content={t("home.arts_tooltip")} placement="top" offset={[0, 12]}>
                            <span style={{ backgroundColor: "gold" }} className="material-icon notranslate role">
                                palette
                            </span>
                        </Tooltip>
                        Opeepo
                    </Link>{" "}
                    <Link className="username" url={"//twitch.tv/obleto"} style={{ color: "#9151FF" }}>
                        <Tooltip content={t("home.design_tooltip")} placement="top" offset={[0, 12]}>
                            <span style={{ backgroundColor: "#9151FF" }} className="material-icon notranslate role">
                                code
                            </span>
                        </Tooltip>
                        BoletoDoSub
                    </Link>
                    <Link className="username" url={"https://twitter.com/xandghost"} style={{ color: "#cfcfcf" }}>
                        <Tooltip content={t("home.arts_tooltip")} placement="top" offset={[0, 12]}>
                            <span style={{ backgroundColor: "#b3b3b3" }} className="material-icon notranslate role">
                                palette
                            </span>
                        </Tooltip>
                        XandGhost
                    </Link>
                    <Link className="username" url={"https://twitter.com/zonianmidian"} style={{ color: "#ff257f" }}>
                        <Tooltip content={t("home.translation_spanish")} placement="top" offset={[0, 12]}>
                            <span
                                style={{
                                    backgroundColor: "#ff257f",
                                    fontSize: "1.25rem",
                                    width: "1.5rem",
                                    height: "1.5rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                className="material-icon notranslate role"
                            >
                                translate
                            </span>
                        </Tooltip>
                        ZonianMidian
                    </Link>
                </p>
            </div>
            <div className="button-area">
                <DonateButton />
                <ContactButton />
                <DiscordButton />
                <ButtonIcon
                    // wrapperProps={{ style: { padding: 0 }}}
                    onSubmit={() => window.open("/support", "_blank")}
                    wrapperProps={{ style: { paddingRight: ".9rem" } }}
                    type={"white"}
                    children={
                        <>
                            <span className="material-icon notranslate">help</span>
                            {t("about.need_help")}
                        </>
                    }
                />
            </div>
            <div className="legal-notice">
                <LegalHyperlinks />
            </div>
        </Container>
    )
}

export default Page
