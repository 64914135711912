import ButtonIcon from "@/components/Widgets/ButtonIcon"
import useModal from "@/hooks/useModal"
import { getRoomName } from "@/utils"
import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"
import ConfigOverlayPage from "../ConfigOverlayPage"
import CommandButton from "./CommandButton"

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.5rem 0.75rem;
    align-items: flex-start;
    background-color: var(--background);
    border-radius: var(--border-radius-s);

    .small {
        font-size: 0.9rem;
    }

    a,
    p {
        display: inline-block;
        margin-bottom: 0.25rem;
    }

    a {
        font-size: 0.9rem;
        color: var(--color-5);
    }
    > * {
        width: 100% !important;
    }

    div button {
        width: 100%;
    }
`

const Overlay = () => {
    const { t } = useTranslation()
    const { setModal } = useModal()

    const handleConfigClose = () => {
        setModal(null, "minimum")
    }

    const handleConfigOpen = () => {
        setModal(<ConfigOverlayPage />, "minimum", {
            style: { maxWidth: "35rem" },
        })
    }

    return (
        <Container>
            <p className="small">{t("dashboard.overlay.text_1")}</p>
            <p className="small">
                <Trans
                    i18nKey={"dashboard.overlay.text_2"}
                    values={{ url: `overlay.feridinha.com/${getRoomName()}` }}
                    tOptions={{ interpolation: { escapeValue: false } }}
                >
                    <a
                        href={`https://overlay.feridinha.com/${getRoomName()}`}
                    ></a>
                </Trans>
            </p>
            <ButtonIcon
                icon="movie"
                onSubmit={() =>
                    window.open("https://f.feridinha.com/d71Ny.mp4", "_blank")
                }
                children={t("dashboard.overlay.tutorial_button")}
                type={1}
            />
            <ButtonIcon
                icon="tune"
                onSubmit={handleConfigOpen}
                children={t("dashboard.overlay.customize")}
                type={1}
            />
            <CommandButton
                command="overlay"
                args={["reload"]}
                icon="sync"
                children={t("dashboard.overlay.reload")}
                type={1}
            />
        </Container>
    )
}

export default Overlay
