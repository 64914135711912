import api from "@/services/api"
import { removeVideoFromPlaylist } from "@/slices/playlists/thunks"
import { ThunkDispatch } from "@reduxjs/toolkit"
import {
    ApiGenericVideo,
    ApiPlaylist,
    ApiPlaylistVideo
} from "@types"
import { motion } from "framer-motion"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import styled from "styled-components"
import { PlaylistVideo } from "./PlaylistVideo"

const Container = styled(motion.div)`
    width: clamp(20rem, 50rem, 70rem);
    height: calc(100% - 1rem);
    display: flex;
    flex-direction: column;
    background-color: var(--background-contrast);
    padding: 1rem;
    border-radius: 0.5rem;
    gap: 1.5rem;
    box-shadow: 0px 0px 25px 1px #00000061;
    h1 {
        text-align: center;
        text-overflow: ellipsis;
        overflow-y: visible;
        min-height: fit-content;
    }

    .videos-list {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        overflow-y: auto;
        overflow-x: hidden;
    }
    z-index: 4;
`

const Wrapper = styled(motion.div)`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    left: 0;
    top: 0;
    height: calc(100%);
    z-index: 101;
`

export const PlaylistCardActive = ({
    className,
    onClick,
    playlist,
    isExtern,
    forcedLayoutId,
    wrapperProps,
}: {
    className?: string
    playlist: ApiPlaylist
    onClick: (playlist: ApiPlaylist) => void
    isExtern?: boolean
    forcedLayoutId?: string
    wrapperProps?: any
}) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()

    const addVideoToQueue = async (video: ApiPlaylistVideo) => {
        await api
            .addVideo(`https://youtu.be/${video.id}`)
            .then(api.onResponse)
            .catch(() => {
                // toast.error("Ocorreu um erro desconhecido")
                return false
            })
    }

    const removeFromPlaylist = async (video: ApiGenericVideo) => {
        await dispatch(removeVideoFromPlaylist({ video, playlist }))
    }

    return (
        <Wrapper {...wrapperProps}>
            <Container
                layoutId={forcedLayoutId || playlist._id}
                onClick={() => onClick(playlist)}
                className={className}
            >
                <h1>{playlist.name}</h1>
                <div className="videos-list">
                    {playlist.videos.map((video, index) => (
                        <PlaylistVideo
                            video={video}
                            index={index}
                            addVideoToQueue={addVideoToQueue}
                            removeVideoFromPlaylist={removeFromPlaylist}
                            playlist={playlist}
                            key={index}
                            isExtern={isExtern}
                        />
                    ))}
                </div>
            </Container>
        </Wrapper>
    )
}
