import useModal from "@/hooks/useModal"
import DonatePage from "@/pages/Donate"
import { ApiTip } from "@types"
import { motion, MotionProps } from "framer-motion"
import { ReactNode, useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useReward } from "react-rewards"
import styled from "styled-components"
import Tooltip from "./Tooltip"

const Container = styled(motion.div)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    span.material-icon {
        font-size: 2rem;
        font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 28;
        color: #ff5b5b;
    }

    #rewardId {
        position: absolute;
    }

    .notification-body {
        /* display: flex; */
    }

    span.tip-username {
        display: inline-block;
        width: fit-content;
        padding: 0.2rem;
        border-radius: var(--border-radius-s);
        transition: 0.3s ease;
    }

    span.tip-username:hover {
        filter: brightness(150%);
        background-color: var(--background);
        transform: scale(1.05);
    }
`

const UsernameWrapper = (props: { color: string; children?: any }) => {
    const { setModal } = useModal()
    const { t } = useTranslation()

    return (
        <Tooltip content={t("tips.notification_tooltip")}>
            <span
                className="tip-username"
                style={{
                    color: props.color,
                    fontWeight: "bold",
                    textDecoration: "underline",
                }}
                onClick={(e) => {
                    e.preventDefault()
                    setModal(<DonatePage />, "minimum")
                }}
            >
                {props.children}
            </span>
        </Tooltip>
    )
}

const heartAnimation: MotionProps = {
    animate: {
        scale: [1, 0.9, 1.2, 1.35, 1.25, 1.2, 1.15, 1.1, 1, 1],
        transition: {
            repeat: Infinity,
            duration: 1,
            repeatType: "loop",
            // ease: "easeInOut"
        },
    },
}

const TipNotification = ({
    tip,
    isHeadless,
    customChildren,
}: {
    tip?: ApiTip
    isHeadless?: boolean
    customChildren?: ReactNode
}) => {
    const rewardConfetti = useReward("rewardId", "confetti", {
        lifetime: 2000,
        decay: 0.975,
        elementCount: 200,
    })

    useEffect(() => {
        if (isHeadless) return
        setTimeout(() => {
            rewardConfetti.reward()
        }, 1000)
    }, [])

    if (isHeadless)
        return (
            <Container>
                <span id="rewardId"></span>
                <motion.span {...heartAnimation} className="material-icon notranslate">
                    favorite
                </motion.span>
                <span className="notification-body">{customChildren}</span>
            </Container>
        )

    const { t } = useTranslation()

    const authorName = tip.author?.name || t("tips.unknown_user")
    const color = tip.author?.color || "#cacacab2"

    return (
        <Container>
            <span id="rewardId"></span>
            <motion.span {...heartAnimation} className="material-icon notranslate">
                favorite
            </motion.span>
            <span className="notification-body">
                <Trans
                    i18nKey={"tips.notification"}
                    values={{ amount: tip.amount, name: authorName, currency: tip.currency === "brl" ? "R$" : "$" }}
                >
                    <UsernameWrapper color={color} />
                    <a></a>
                </Trans>
            </span>
        </Container>
    )
}

export default TipNotification
