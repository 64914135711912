import styled from "styled-components"
import Tooltip from "../Tooltip"
import { useTranslation } from "react-i18next"

const NewTagContainer = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(318deg, #ff80bf, #9580ff);
    background-size: 150% 150%;
    -webkit-animation: BackgroundMove 5s infinite;
    -moz-animation: BackgroundMove 5s infinite;
    animation: BackgroundMove 5s infinite;
    padding: 0.25rem 0.5rem;
    border-radius: var(--border-radius-ss);
    font-size: 0.8rem;


    &.darker {
        background: linear-gradient(318deg, #e94c9a, #9580ff);
    }
`

export const NewTag = ({ className }: { className?: string }) => {
    const { t } = useTranslation()
    return <NewTagContainer className={className}>{t("widgets.new_tag")} </NewTagContainer>
}

const BetaTagContainer = styled(NewTagContainer)`
    background: linear-gradient(30deg, #ff80bf, #e01a4f);
    padding: 0.1rem 0.5rem !important;
    font-size: 0.7rem;
    cursor: pointer;

`

const OwnedTagContainer = styled(NewTagContainer)`
    background: linear-gradient(30deg, #0ead69, #057a76);
    padding: 0.1rem 0.5rem !important;
    font-size: 0.7rem;
    cursor: pointer;
`

export const BetaTag = ({ className, forcedLanguage }: { className?: string; forcedLanguage?: string }) => {
    const { i18n } = useTranslation()
    const t = i18n.getFixedT(forcedLanguage || i18n.language, null, null)
    return (
        <Tooltip content={t("widgets.beta_tag_description")} offset={[0, 10]}>
            <BetaTagContainer className={className}>{t("widgets.beta_tag")}</BetaTagContainer>
        </Tooltip>
    )
}

export const OwnedTag = ({ className }: { className?: string }) => {
    const { t } = useTranslation()
    return <OwnedTagContainer className={className}>{t("widgets.item_owned_tag")}</OwnedTagContainer>
}
