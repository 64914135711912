import { motion, AnimatePresence } from "framer-motion"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { useState, useEffect } from "react"
import useSocket from "@/hooks/useSocket"
import { ApiVideo } from "@types"
import Tooltip from "@/components/Tooltip"
import RangeInput from "@/components/Widgets/RangeInput"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import CommandButton from "./CommandButton"
import { useTranslation } from "react-i18next"

let seekStartedTime: number = null
let intervalId: any = null
const Container = styled(motion.div)`
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    background-color: var(--background-2);
    border-radius: var(--border-radius-s);
    width: 100%;
    align-items: flex-start;

    .line {
        width: 100%;
        height: 1px;
        background: #ffffff14;
        margin: 0.35rem 0;
    }

    .inputs {
        width: 100%;
    }

    .inputs .row {
        display: flex;
        justify-content: center;
        width: 100%;
    }
`

function formatTime(milliseconds: number) {
    const seconds = Math.floor(milliseconds / 1000)
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`
}

const ConfirmToSave = ({
    handleCommandExecuted,
    arg,
}: {
    handleCommandExecuted: (success: boolean) => void
    arg: string
}) => {
    const { t } = useTranslation()
    return (
        <motion.div
            className="inputs"
            initial={{ height: 0, overflow: "hidden" }}
            animate={{ height: "fit-content" }}
            exit={{ height: 0 }}
            transition={{ ease: "easeInOut", duration: 0.5 }}
        >
            <div className="line"></div>
            <div className="row">
                <ButtonIcon
                    onSubmit={() => handleCommandExecuted(false)}
                    icon={"undo"}
                    type={3}
                >
                    {t("undo")}
                </ButtonIcon>
                <CommandButton
                    command="seek"
                    args={[arg]}
                    icon="save"
                    type={3}
                    onComplete={handleCommandExecuted}
                >
                    {t("save")}
                </CommandButton>
            </div>
        </motion.div>
    )
}

const SeekRangeInput = ({
    label,
    handleInputChange,
    userValue,
    difference,
    currentVideo,
}: {
    label: string
    userValue?: number
    difference: number
    currentVideo: ApiVideo
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => (
    <div className="row">
        <label className="time">{label}</label>
        <RangeInput
            max={currentVideo?.duration.toString() || "0"}
            min="0"
            onChange={handleInputChange}
            value={userValue || difference}
        ></RangeInput>
    </div>
)

const SeekQueueWidget = () => {
    const currentVideo = useSelector(
        (state: IRootState) => state.roomData.currentVideo
    )

    const [label, setLabel] = useState("?")
    const [userValue, setUserValue] = useState(null)
    const [arg, setArg] = useState("")
    const [difference, setDifference] = useState(0)
    const { i18n } = useTranslation()
    const { socket } = useSocket()
    const t = i18n.getFixedT(null, null, "dashboard.mod_inputs.seek_widget")

    const handleLabel = () => {
        const targetDate = seekStartedTime || currentVideo.startedTime

        const videoDate = new Date(targetDate)
        const currentDate = new Date()

        const offset = currentDate.getTime() - videoDate.getTime()
        setDifference(offset)
        setLabel(formatTime(offset))
    }

    const handleSeek = (video: ApiVideo) => {
        seekStartedTime = video.startedTime
        setUserValue(null)
    }

    useEffect(() => {
        if (!currentVideo || !socket) return
        seekStartedTime = null
        intervalId = setInterval(handleLabel, 500)
        socket.on("seek", handleSeek)

        return () => {
            seekStartedTime = null
            clearInterval(intervalId)
            socket.removeListener("seek", handleSeek)
        }
    }, [currentVideo, socket])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const raw = parseInt(e.target.value)
        setUserValue(raw)
        const offset = (raw - difference) / 1000
        let sign = "+"
        if (offset < 0) sign = "-"
        setArg(sign + offset.toString().replace("-", ""))
    }

    const handleCommandExecuted = (success: boolean) => {
        if (!success) setUserValue(null)
    }

    return (
        <Tooltip
            content={
                <p>
                    {t("command")}
                    <span style={{ fontSize: ".75rem", opacity: 0.8 }}>
                        %seek {arg}
                    </span>
                </p>
            }
            hideOnClick={false}
            interactive={true}
            appendTo={document.body}
            placement={"left"}
            disabled={!Boolean(currentVideo) || !Boolean(userValue)}
        >
            <Container>
                <p className="title">{t("title")}</p>
                <div className="line"></div>
                {!currentVideo && (
                    <p className="small">{t("no_video_playing")}</p>
                )}
                {currentVideo && (
                    <>
                        <SeekRangeInput
                            currentVideo={currentVideo}
                            difference={difference}
                            handleInputChange={handleInputChange}
                            label={label}
                            userValue={userValue}
                        />

                        <AnimatePresence>
                            {userValue !== null && (
                                <ConfirmToSave
                                    arg={arg}
                                    handleCommandExecuted={
                                        handleCommandExecuted
                                    }
                                />
                            )}
                        </AnimatePresence>
                    </>
                )}
            </Container>
        </Tooltip>
    )
}

export default SeekQueueWidget
