import { motion } from "framer-motion"
import React, { ReactNode, useState } from "react"
import styled from "styled-components"

interface Props {
    shadowColor: string
    scale: number
    children?: JSX.Element
    style?: React.CSSProperties
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    className?: string
    defaultHover?: boolean
    defaultCords?: [number, number]
}

// Obrigado vultr por fazer o melhor hover effect de todos os tempos

const Button = styled.button<Partial<Props>>`
    display: flex;
    position: relative;
    overflow: hidden;
    /* height: 100%; */
    .mouse-gradient {
        position: absolute;
        width: 10rem;
        height: 10rem;
        background: radial-gradient(
            circle closest-side,
            ${(e: any) => e.shadowColor},
            transparent
        );
        top: 0px;
        left: 0px;
        z-index: 0;
    }

    &.sidebar-login {
        padding: 0 !important;
        border-radius: var(--border-radius-ss) !important;

        > div {
            padding: 0.25rem;
        }
    }

    > * {
        cursor: pointer !important;
    }
`

const ShadowButton = (props: Props) => {
    const [isHover, setIsHover] = useState(props.defaultHover || false)
    const [isPointerDown, setPointerDown] = useState(false)
    const [cords, setCords] = useState(props.defaultCords || [0, 0])

    const handleMouseMove = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        const rect = e.currentTarget.getBoundingClientRect()
        const x = e.clientX - rect.left
        const y = e.clientY - rect.top
        setCords([x, y])
    }

    let scale = 0

    if (isHover) scale = props.scale
    if (isPointerDown && isHover) scale = props.scale * 2.5
    if (isPointerDown && !isHover) {
        setPointerDown(false)
        scale = props.scale
    }

    const hover: any = {
        x: `calc(-50% + ${cords[0]}px)`,
        y: `calc(-50% + ${cords[1]}px)`,
        scale,
        transition: {
            ease: "linear",
            duration: 0,
            scale: {
                ease: isHover ? "easeIn" : "linear",
                duration: isHover ? 0.2 : 0.15,
            },
        },
    }

    return (
        <Button
            className={"reset-button " + props.className}
            onMouseMove={handleMouseMove}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            shadowColor={props.shadowColor}
            style={props.style}
            onClick={props.onClick}
            onPointerDown={() => setPointerDown(true)}
            onPointerUp={() => setPointerDown(false)}
        >
            <>
                {React.cloneElement(props.children, {
                    className: isHover ? "hover" : "",
                }) || <p>Hover me!</p>}
                <motion.div
                    className="mouse-gradient"
                    animate={hover}
                    transition={{ ease: "linear" }}
                ></motion.div>
            </>
        </Button>
    )
}

export default ShadowButton

const Wrapper = styled<any>(motion.div)`
    display: flex;
    position: relative;
    overflow: hidden;
    .mouse-gradient,
    .mouse-gradient-2 {
        position: absolute;
        width: 10rem;
        height: 10rem;
        background: radial-gradient(
            circle closest-side,
            ${(e: any) => e.shadowColor},
            transparent
        );
        top: 0px;
        left: 0px;
        z-index: 5;
        pointer-events: none;
    }
`

interface WrapperProps extends Omit<Props, "children"> {
    children: ReactNode
    scale2?: number
    transition2?: any
    scale3?: number
}

export const ShadowWrapper = (props: WrapperProps) => {
    const [isHover, setIsHover] = useState(props.defaultHover || false)
    const [isPointerDown, setPointerDown] = useState(false)
    const [cords, setCords] = useState(props.defaultCords || [0, 0])

    const handleMouseMove = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        const rect = e.currentTarget.getBoundingClientRect()
        const x = e.clientX - rect.left
        const y = e.clientY - rect.top
        setCords([x, y])
    }

    let scale = 0

    if (isHover) scale = props.scale
    if (isPointerDown && isHover) scale = props.scale * 2.5
    if (isPointerDown && !isHover) {
        setPointerDown(false)
        scale = props.scale
    }

    const hover: any = {
        x: `calc(-50% + ${cords[0]}px)`,
        y: `calc(-50% + ${cords[1]}px)`,
        scale,
        transition: {
            ease: "linear",
            duration: 0,
            scale: {
                ease: isHover ? "easeIn" : "linear",
                duration: isHover ? 0.4 : 0.3,
            },
        },
    }

    const hover2 = {
        ...hover,
        scale: props.scale3 || scale,
    }

    return (
        <Wrapper
            className={props.className}
            shadowColor={props.shadowColor}
            style={props.style}
            onClick={props.onClick}
            onMouseMove={handleMouseMove}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <motion.div
                className="mouse-gradient"
                animate={hover}
                transition={{ ease: "linear" }}
            ></motion.div>
            <motion.div
                className="mouse-gradient-2"
                animate={hover2}
                transition={{ ease: "linear" }}
            ></motion.div>
            <motion.div
                className="mouse-gradient-3"
                // animate={{...hover2, background: "red"}}
                transition={{ ease: "linear" }}
            ></motion.div>
            {props.children}
        </Wrapper>
    )
}
