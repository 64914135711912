import ButtonIcon from "@/components/Widgets/ButtonIcon"
import { fadeInOut } from "@/utils/fadeInOut"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import Wave from "react-wavify"
import styled from "styled-components"

const WaveContainer = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 10rem;
    height: fit-content;
    border-radius: var(--border-radius-s);
    overflow: hidden;

    .wave-rotated {
        position: absolute;
        left: 0;
        top: -5px;
        transform: rotateZ(180deg);
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
        width: 30rem;
        min-height: fit-content;

        .title-wrapper {
            display: flex;
            justify-content: center;
            position: relative;
            width: 100%;
            height: 3.5rem;

            > * {
                position: absolute;
            }
        }

        > * {
            position: relative;
        }
    }

    input {
        background-color: #ffffff32;
        backdrop-filter: blur(1px);
        padding: 0.75rem 1rem;
        width: calc(100% - 1rem);
        min-width: 25rem;
        max-width: 15rem;
        border: none;
        border-radius: var(--border-radius-s);
        transition: box-shadow 0.3s;
        ::placeholder {
            color: #ffffff9d;
        }
    }

    input:focus {
        backdrop-filter: blur(5px);
        outline: 1px solid #25252511 !important;
        box-shadow: 0px 0px 15px 5px #6d6d6d11;
    }

    h1 {
        text-align: center;
    }

    h1 span {
        position: relative;
    }

    h1 span::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 4px;
        background: #ffffff6c;
        left: 0;
        bottom: 2px;
        border-radius: 2px;
    }

    button {
        background-color: #ffffff39;
        backdrop-filter: blur(5px);
    }

    button,
    button > span.material-icon {
        transition: 0.3s !important;
    }

    button:hover {
        background-color: var(--foreground);
        color: var(--background);
        pointer-events: all !important;

        > span.material-icon {
            color: var(--background);
        }
    }
`

const TitleBanner = ({
    handleSearch,
    defaultValue,
}: {
    handleSearch: (data: string) => Promise<boolean>
    defaultValue?: string
}) => {
    const ref = useRef(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const [isSearchActive, setSearchActive] = useState(Boolean(defaultValue))
    const { t } = useTranslation()

    const handleSubmit = async (data: string) => {
        const shouldContinueSearching = await handleSearch(data)
        if (!shouldContinueSearching) setSearchActive(false)
    }

    useEffect(() => {
        if (!inputRef?.current) return
        inputRef.current.focus()
    }, [inputRef, isSearchActive])

    return (
        <WaveContainer ref={ref} initial={{ y: -200 }} animate={{ y: 0 }}>
            <div className="content">
                <div className="title-wrapper">
                    <AnimatePresence>
                        {!isSearchActive && (
                            <motion.h1 {...fadeInOut(0, 0.3)} key={"vida"}>
                                <Trans i18nKey={"community_playlists.title"}>
                                    <span></span>
                                </Trans>
                            </motion.h1>
                        )}
                    </AnimatePresence>
                    <AnimatePresence>
                        {isSearchActive && (
                            <motion.input
                                placeholder={t(
                                    "community_playlists.search.placeholder"
                                )}
                                defaultValue={defaultValue}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        const value = ref.current.value
                                        handleSubmit(value)
                                    }
                                }}
                                autoFocus={true}
                                {...fadeInOut(0, 0.3)}
                                ref={ref}
                            />
                        )}
                    </AnimatePresence>
                </div>
                <AnimatePresence mode="popLayout">
                    {!isSearchActive && (
                        <motion.div {...fadeInOut(0, 0.3)} key={1}>
                            <ButtonIcon
                                icon="search"
                                children={t("community_playlists.search.title")}
                                onSubmit={() => setSearchActive((e) => !e)}
                            />
                        </motion.div>
                    )}
                    {isSearchActive && (
                        <motion.div
                            {...fadeInOut(0, 0.3)}
                            style={{ display: "flex", gap: ".5rem" }}
                            key={2}
                        >
                            <ButtonIcon
                                icon="close"
                                children={t("close")}
                                onSubmit={() => {
                                    handleSearch("")
                                    setSearchActive((e) => !e)
                                }}
                            />
                            <ButtonIcon
                                icon="search"
                                children={t(
                                    "community_playlists.search.button_search"
                                )}
                                onSubmit={() => {
                                    handleSubmit(ref.current.value)
                                }}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <Wave className="wave-rotated" fill="url(#gradient)" height={15}>
                <defs>
                    <linearGradient
                        id="gradient"
                        gradientTransform="rotate(90)"
                    >
                        <stop offset={"10%"} stopColor="#9580ff"></stop>
                        <stop offset={"90%"} stopColor="#ff80bf"></stop>
                    </linearGradient>
                </defs>
            </Wave>
        </WaveContainer>
    )
}

export default TitleBanner
