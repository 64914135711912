import Tooltip from "@/components/Tooltip"
import { AsyncButtonProps } from "@/components/Widgets/AynscButton"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import TextInputIcon from "@/components/Widgets/TextInputIcon"
import { adminDeletePlaylist, deletePlaylist, renamePlaylist } from "@/slices/playlists/thunks"
import { getRoomName } from "@/utils"
import { ThunkDispatch } from "@reduxjs/toolkit"
import { ApiCommunityPlaylist, ApiPlaylist } from "@types"
import { MouseEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import styled from "styled-components"

const buttonColors = ["var(--background-2)", "var(--background-light)"]
type PlaylistActionsProps = {
    isVisible: boolean
    setVisible: React.Dispatch<boolean>
    buttonIconType?: AsyncButtonProps["type"]
    isExtern?: boolean
    playlist: ApiCommunityPlaylist | ApiPlaylist
}

const TooltipContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.2rem;

    button {
        justify-content: space-between;
        gap: 0.5rem;
        background-color: var(--background-2);
    }

    .rename-wrapper {
        /* background-color: var(--background-2); */
        /* outline: none !important; */

        button {
            padding: 0.25rem !important;
            background-color: var(--background-light) !important;
        }

        span {
            font-size: 1.5rem;
        }
    }

    button:hover {
        background-color: var(--background);
    }
`

export const PlaylistActionsTooltip = ({
    isVisible,
    setVisible,
    isExtern,
    buttonIconType,
    playlist,
}: PlaylistActionsProps) => {
    const { i18n } = useTranslation()
    const t = i18n.getFixedT(null, null, "playlists_page.tooltip")
    const [shareMessage, setShareMessage] = useState(t("share"))
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const [isRenameActive, setRenameActive] = useState(false)
    const chatter = useSelector((state: IRootState) => state.userData.chatter)

    const onPlaylistDelete = (e: any) => {
        if (isExtern) {
            return
        }
        e.stopPropagation()
        dispatch(deletePlaylist(playlist as ApiPlaylist))
    }

    const onPlaylistDeleteAdmin = (e: any) => {
        e.stopPropagation()
        dispatch(adminDeletePlaylist(playlist._id))
    }

    const onActivateRename = (e: MouseEvent) => {
        e.stopPropagation()
        setRenameActive(!isRenameActive)
    }

    const onPlaylistRename = (text: string) => {
        if (isExtern) return
        setRenameActive(false)
        dispatch(
            renamePlaylist({
                playlist: playlist as ApiPlaylist,
                name: text,
            })
        )
    }

    const openOnYoutube = (e: any) => {
        e.stopPropagation()
        const baseUrl = "https://www.youtube.com/playlist"
        if (!playlist.youtube_id) return toast.error(t("not_synchronized_yet"))
        window.open(`${baseUrl}?list=${playlist.youtube_id}`, "_blank")
    }

    const onPlaylistShare = (e: any) => {
        e.stopPropagation()
        const path = document.location.origin
        navigator.clipboard.writeText(
            `${path}/${getRoomName()}/playlist/${playlist._id}`
        )
        setShareMessage(t("link_copied"))
    }

    return (
        <Tooltip
            placement="bottom-start"
            content={
                <TooltipContainer>
                    {!isExtern && (
                        <>
                            <ButtonIcon
                                onSubmit={() => deletePlaylist}
                                wrapperProps={{ onClick: onPlaylistDelete }}
                                icon="delete_outline"
                                children={t("delete")}
                                type={2}
                            />
                            {isRenameActive ? (
                                <TextInputIcon
                                    onSubmit={(text) => onPlaylistRename(text)}
                                    icon="edit"
                                    resetAfterSubmit={false}
                                    wrapperProps={{
                                        onClick: (e: any) =>
                                            e.stopPropagation(),
                                        className: "rename-wrapper",
                                    }}
                                    inputProps={{ defaultValue: playlist.name }}
                                />
                            ) : (
                                <ButtonIcon
                                    onSubmit={() => {}}
                                    wrapperProps={{
                                        onClick: onActivateRename,
                                    }}
                                    icon="edit"
                                    children={t("rename")}
                                    type={2}
                                />
                            )}
                        </>
                    )}
                    {chatter?.admin && (
                        <ButtonIcon
                            onSubmit={() => {}}
                            wrapperProps={{ onClick: onPlaylistDeleteAdmin }}
                            icon="admin_panel_settings"
                            children={"Admin delete"}
                            type={2}
                        />
                    )}
                    <ButtonIcon
                        onSubmit={() => {}}
                        wrapperProps={{ onClick: onPlaylistShare }}
                        icon="share"
                        children={shareMessage}
                        type={2}
                    />
                    <ButtonIcon
                        onSubmit={() => {}}
                        wrapperProps={{ onClick: openOnYoutube }}
                        icon="youtube_activity"
                        children={t("open_on_youtube")}
                        type={2}
                    />
                </TooltipContainer>
            }
            // visible={isVisible}
            trigger="click"
            inlinePositioning={true}
            interactive={true}
            zIndex={999}
            appendTo={document.body}
            animation={"shift-away"}
            offset={[0, 5]}
        >
            <div style={{ height: "fit-content" }}>
                <ButtonIcon
                    wrapperProps={{
                        className: "playlist-details-button",
                        onClick: (e: any) => {
                            e.stopPropagation()
                            setVisible(!isVisible)
                        },
                    }}
                    onSubmit={() => {}}
                    icon="more_vert"
                    colors={buttonColors}
                    type={buttonIconType}
                />
            </div>
        </Tooltip>
    )
}
