import api from "@/services/api"
import { UpdatePayload } from "@/slices/roomData"
import { handleRejectedThunk } from "@/utils"
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ApiAvatar, ApiChatter, ApiCommands, ApiPremiumAvatarSpecs, ApiStoreItem, ApiUser } from "@types"
import { toast } from "react-toastify"

type LoadingItem = "user" | "chatter" | "playlists"

export interface IUserDataState {
    loadingItems: LoadingItem[]
    isLoading: boolean
    user: ApiUser
    chatter: ApiChatter
    commandsAvailable: ApiCommands[]
    avatarsAvailable: (ApiAvatar["specs"] | ApiPremiumAvatarSpecs)[]
    error: string
    shoppingCart: ApiStoreItem[]
}

const initialState: IUserDataState = {
    loadingItems: [],
    isLoading: false,
    user: null,
    chatter: null,
    error: null,
    commandsAvailable: [],
    avatarsAvailable: [],
    shoppingCart: [],
}

export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
    const res = await api.fetchTokenData()
    return res.data
})

export const fetchChatter = createAsyncThunk("chatter/fetchChatter", async () => {
    const res = await api.fetchChatter()
    if (typeof res === "string") throw new Error(res)
    return res.data
})

export const fetchShoppingCart = createAsyncThunk("shoppingCart/fetch", async () => {
    const res = await api.fetchShoppingCart()
    if (typeof res === "string") throw new Error(res)
    return res.data
})

export const addItemToShoppingCart = createAsyncThunk("shoppingCart/add", async (itemId: string) => {
    const res = await api.addItemToShoppingCart(itemId)
    if (typeof res === "string") throw new Error(res)
    return res.data
})

export const removeItemFromShoppingCart = createAsyncThunk("shoppingCart/remove", async (itemId: string) => {
    const res = await api.removeItemFromShoppingCart(itemId)
    if (typeof res === "string") throw new Error(res)
    return res.data
})

const filter = (array: any[], target: any) => array.filter((item) => item !== target)

const userDataSlice = createSlice({
    name: "userData",
    initialState,
    reducers: {
        updateKey: (state, action: PayloadAction<UpdatePayload<IUserDataState, keyof IUserDataState>>) => {
            const { key, newValue } = action.payload
            return { ...state, [key]: newValue }
        },
    },
    extraReducers: (builder) => {
        // User
        builder.addCase(fetchUser.pending, (state) => {
            state.loadingItems = [...state.loadingItems, "user"]
            state.isLoading = true
        })
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.loadingItems = filter(state.loadingItems, "user")
            state.isLoading = state.loadingItems.length > 0
            state.user = action.payload
            state.shoppingCart = action.payload.shoppingCart
        })
        builder.addCase(fetchUser.rejected, (state) => {
            // toast.error("Erro ao carregar seu usuário")
        })

        // Chatter
        builder.addCase(fetchChatter.pending, (state) => {
            state.loadingItems = [...state.loadingItems, "chatter"]
            state.isLoading = true
        })
        builder.addCase(fetchChatter.fulfilled, (state, action) => {
            state.loadingItems = filter(state.loadingItems, "chatter")
            state.isLoading = state.loadingItems.length > 0
            state.chatter = action.payload.chatter
            state.commandsAvailable = action.payload.commandsAvailable
            state.avatarsAvailable = action.payload.avatarsAvailable
        })

        // ShoppingCart
        builder.addCase(fetchShoppingCart.fulfilled, (state, action) => {
            state.shoppingCart = action.payload
        })
        builder.addCase(addItemToShoppingCart.fulfilled, (state, action) => {
            console.log(action.payload)
            state.shoppingCart = action.payload
        })
        builder.addCase(removeItemFromShoppingCart.fulfilled, (state, action) => {
            state.shoppingCart = action.payload
        })

        builder.addCase(fetchChatter.rejected, handleRejectedThunk)
        builder.addCase(addItemToShoppingCart.rejected, handleRejectedThunk)
        builder.addCase(removeItemFromShoppingCart.rejected, handleRejectedThunk)
    },
})

export const { updateKey } = userDataSlice.actions

const userDataReducer = userDataSlice.reducer
export default userDataReducer
