import { useGridQueries } from "@/hooks/useGridQueries"
import { roomSelect } from "@/utils"
import { TippyProps } from "@tippyjs/react"
import { ApiAvatar } from "@types"
import { Application, BLEND_MODES, Container } from "pixi.js"
import { memo, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import Tooltip from "../Tooltip"
import { getAvatarModifiers, handleMouseLeave } from "./Avatar"
import "./DanceFloor.css"
import { getMixer, getScenarioLights } from "./Scenario"
import background from "./background"
import { handleDanceFloorTooltip } from "./utils"

let observer: any = null
let timeout: ReturnType<typeof setTimeout> = null
let timeoutBg: ReturnType<typeof setTimeout> = null
let timeoutTooltipBg: ReturnType<typeof setTimeout> = null

const DanceFloor = memo(() => {
    const [app, setApp] = useState<Application>(null)
    const avatars = useSelector((state: IRootState) => state.roomData.avatars)
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [width, setWidth] = useState<number>(null)
    const mediaQueries = useGridQueries()
    const danceFloorTooltip = useSelector((state: IRootState) => state.roomData.danceFloorTooltip)
    const [tooltipProps, setTooltipProps] = useState<TippyProps>(null)
    const [isTooltipVisible, setTooltipVisible] = useState(false)

    const [tooltipBgProps, setTooltipBgProps] = useState<TippyProps>({
        content: "",
    })
    const [isBgTooltipVisible, setBgTooltipVisible] = useState(false)
    const [isBgTooltipHover, setBgTooltipHover] = useState(false)
    const [isTooltipHover, setTooltipHover] = useState(false)
    const [isCanvasHover, setCanvasHover] = useState(false)

    const currentVideo = useSelector(roomSelect("currentVideo"))
    const audioDataEvent = useSelector(roomSelect("audioDataEvent"))
    const videoPlayer = useSelector(roomSelect("videoPlayer"))
    const lastSeek = useSelector(roomSelect("lastSeek"))

    const getParentWidth = () => document.getElementById("canvas-wrapper")?.clientWidth || 0

    const size = avatars.length < 4 ? 6 : 4
    const ratio = Math.round(avatars.length / size)
    const widthRatio = width / 100

    useEffect(() => {
        setWidth(getParentWidth())
        const target = document.getElementById("canvas-wrapper")
        if (!target) return
        if (observer) observer?.disconnect()

        observer = new ResizeObserver(() => setWidth(getParentWidth())).observe(target)
        return () => observer?.disconnect()
    }, [])

    const createApp = () => {
        const _app = new Application({
            resizeTo: wrapperRef.current,
            antialias: false,
            autoDensity: true,
            powerPreference: "low-power",
            backgroundColor: 0x0e121c,
        })
        // @ts-ignore
        globalThis.__PIXI_APP__ = _app
        setApp(_app)
        _app.render()

        document.getElementById("dance-floor").appendChild(_app.view as any)
    }

    useEffect(() => {
        if (!wrapperRef.current) return
        if (app) return
        createApp()
    }, [wrapperRef])

    const activateTooltip = () => {
        clearTimeout(timeout)
        setTooltipVisible(true)
        setTooltipHover(true)
    }

    const deactivateTooltip = () => {
        setTooltipHover(false)
        timeout = setTimeout(() => {
            setTooltipVisible(false)
        }, 500)
    }

    const activateTooltipBg = () => {
        clearTimeout(timeoutBg)
        setBgTooltipVisible(true)
    }

    const deactivateTooltipBg = () => {
        clearTimeout(timeoutBg)
        timeoutBg = setTimeout(() => {
            setBgTooltipVisible(false)
        }, 250)
    }

    const renderAvatars = () => {
        const avatarsLayer = new Container()
        avatarsLayer.sortableChildren = true

        let currentAvatar: ApiAvatar = null

        avatars.forEach((avatar, index) => {
            if (avatar.user.id === currentVideo?.user.id) {
                currentAvatar = avatar
                return
            }
            const position = getAvatarModifiers(avatar, index, ratio)

            avatar.animatedSprite.x = position.x
            avatar.animatedSprite.y = position.y
            avatar.animatedSprite.tint = position.tint
            avatar.animatedSprite.scale.set(position.scale, position.scale)
            avatar.animatedSprite.on("mouseleave", handleMouseLeave(avatar.animatedSprite, index))
            avatar.animatedSprite.blendMode = BLEND_MODES.LUMINOSITY
            avatarsLayer.addChild(avatar.animatedSprite)
        })

        const mixer = getMixer(currentAvatar, widthRatio)
        avatarsLayer.addChild(...mixer)
        const lights = getScenarioLights(currentAvatar, width)
        app.stage.addChild(avatarsLayer)
        app.stage.addChild(lights)
    }

    let video = currentVideo
    if (lastSeek?.uuid === currentVideo?.uuid) {
        video = lastSeek
    }

    const handleTooltipHover = (newValue: boolean) => {
        clearTimeout(timeoutTooltipBg)
        setBgTooltipHover(newValue)
        if (newValue === true) {
            timeoutTooltipBg = setTimeout(() => {
                setBgTooltipHover(false)
            }, 500)
        }
    }

    useEffect(() => {
        if (!app || !videoPlayer) return
        app.stage.removeChildren()

        const backgroundItems = background({
            width,
            currentVideo: video,
            audioDataEvent,
            videoPlayer,
            setTooltipBgProps,
            activateTooltipBg,
            deactivateTooltipBg,
            wrapperRef,
            isTooltipVisible,
            setBgTooltipHover: handleTooltipHover,
            isCanvasHover,
        })
        backgroundItems.forEach((item) => app.stage.addChild(item))
        renderAvatars()
    }, [avatars, width, currentVideo, audioDataEvent, videoPlayer, lastSeek, isTooltipVisible, isCanvasHover])

    useEffect(() => {
        handleDanceFloorTooltip({
            danceFloorTooltip,
            wrapperRef,
            deactivateTooltip,
            activateTooltip,
            timeout,
            isTooltipHover,
            setTooltipProps,
            setTooltipVisible,
            isCanvasHover,
            avatars,
        })()
    }, [danceFloorTooltip, isCanvasHover, avatars])

    return (
        <div
            className="dance-floor"
            id="dance-floor"
            onMouseEnter={() => setCanvasHover(true)}
            onMouseLeave={() => setCanvasHover(false)}
        >
            <div ref={wrapperRef} style={{ width: "100%" }} className="canvas-wrapper" id="canvas-wrapper"></div>
            {wrapperRef.current && (
                <Tooltip
                    appendTo={wrapperRef.current}
                    interactive={true}
                    interactiveBorder={160}
                    visible={isTooltipVisible}
                    moveTransition="transform 0.3s cubic-bezier(0, 1, 0.36, 1)"
                    {...tooltipProps}
                />
            )}
            {wrapperRef.current && (
                <Tooltip
                    appendTo={document.body}
                    interactive={false}
                    // interactiveBorder={160}
                    visible={isBgTooltipVisible || isBgTooltipHover}
                    moveTransition="transform 0.3s cubic-bezier(0, 1, 0.36, 1)"
                    className="white-outlined-tooltip"
                    {...tooltipBgProps}
                    maxWidth={"20rem"}
                />
            )}
        </div>
    )
})

export default DanceFloor
