import { AnimatePresence, cubicBezier, motion, MotionStyle } from "framer-motion"
import { useTranslation } from "react-i18next"
import { Bars, Oval } from "react-loading-icons"
import { useSelector } from "react-redux"
import useMeasure from "react-use-measure"
import styled from "styled-components"
import FadeInOut from "./FadeInOut"

const Box = styled(motion.div)`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: var(--background);
    backdrop-filter: blur(15px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    top: 0;
    left: 0;

    .logo-wrapper {
        position: relative;
        padding: 1rem;
        height: 25rem;
        min-height: 15rem;
        aspect-ratio: 1/1;

        img {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            aspect-ratio: 1/1;
        }

        img.main {
            filter: drop-shadow(0px 10px 5px #0000007b) drop-shadow(3px -5px 5px #0000006f);
        }
    }

    .messages {
        background-color: var(--background-light);
        border-radius: var(--border-radius-s);
    }

    .messages-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        padding: 0.5rem 0.5rem;
        min-width: fit-content;
    }
`

function Loading() {
    const loadingItems = useSelector((state: IRootState) => state.roomData.loadingItems)
    const [ref, size] = useMeasure()
    const { t } = useTranslation()

    return (
        <Box
            className="loading-container"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
        >
            <div className="logo-wrapper">
                <motion.img
                    className="ns"
                    animate={{ rotateZ: [0, 360] }}
                    transition={{
                        repeat: Infinity,
                        duration: 3,
                        ease: "linear",
                    }}
                    draggable={false}
                    src="https://c.feridinha.com/LOGOHD_1-compressed.png"
                    alt=""
                />
                <img
                    className="ns main"
                    draggable={false}
                    src="https://c.feridinha.com/LOGOHD_2-compressed.png"
                    alt=""
                />
            </div>
            <motion.div animate={{ height: size.height }} className="messages">
                <div className="messages-wrapper" ref={ref}>
                    <AnimatePresence>
                        {loadingItems.map((id, key) => (
                            <motion.p
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                key={id}
                                transition={{ duration: 0.3 }}
                                layout
                            >
                                {t(`loading.${id}`)}
                            </motion.p>
                        ))}
                    </AnimatePresence>
                </div>
            </motion.div>
        </Box>
    )
}

export default Loading

const LoadingContainer = styled(motion.div)`
    /* opacity: 0 !important; */
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    z-index: 99;
    left: 0;
    top: 0;
    .icon-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 6rem;
        height: 6rem;
        > * {
            position: absolute;
        }
    }

    p.loading-message {
        font-size: 1.5rem;
    }
`

export const SearchLoading = ({
    message,
    background,
    customIcon,
    messageStyle,
    barsHeight,
    styles,
}: {
    message?: string
    background?: boolean
    customIcon?: JSX.Element
    messageStyle?: React.CSSProperties
    barsHeight?: string
    styles?: MotionStyle
}) => {
    return (
        <LoadingContainer
            style={{ background: background && "var(--background)", ...styles }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.5,
                ease: "easeOut",
            }}
        >
            <motion.div className="icon-wrapper">
                <AnimatePresence>
                    {customIcon && (
                        <FadeInOut duration={0.3} key="icon-1">
                            {customIcon}
                        </FadeInOut>
                    )}
                    {!customIcon && (
                        <FadeInOut duration={0.3} key="icon-2">
                            <Bars fill={"var(--foreground)"} height={barsHeight || "8rem"} />
                        </FadeInOut>
                    )}
                </AnimatePresence>
            </motion.div>
            <AnimatePresence>
                {message && (
                    <motion.div
                        animate={{
                            height: Boolean(message) ? "fit-content" : 0,
                            overflow: "hidden",
                            opacity: Boolean(message) ? 1 : 0,
                        }}
                        layout
                    >
                        <motion.p className="loading-message" style={messageStyle}>
                            {message}
                        </motion.p>
                    </motion.div>
                )}
            </AnimatePresence>
        </LoadingContainer>
    )
}

const CommandContainer = styled<any>(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    z-index: 1;
    background-color: ${(props) => props.colors[0]};
`

export const CommandLoading = ({
    isLoading,
    className,
    colors,
    customHeight,
    customColor,
    customStroke
}: {
    isLoading: boolean
    className?: string
    colors?: string[]
    customHeight?: number
    customColor?: string
    customStroke?: string
}) => {
    return (
        <AnimatePresence>
            {isLoading && (
                <CommandContainer
                    className={className}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    colors={colors || ["var(--background-2)"]}
                >
                    <Oval
                        strokeWidth={5}
                        height={customHeight || 22}
                        color={customColor || "var(--foreground)"}
                        stroke={customStroke || "var(--foreground)"}
                    />
                </CommandContainer>
            )}
        </AnimatePresence>
    )
}
