import Tooltip from "@/components/Tooltip"
import UsernameLabel from "@/components/UsernameLabel"
import { useSocket } from "@/hooks/SocketContext/Provider"
import { TippyProps } from "@tippyjs/react"
import { ApiVoteEvent } from "@types"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { followCursor } from "tippy.js"
import CommandButton from "./CommandButton"
import { Trans, useTranslation } from "react-i18next"
import i18next from "i18next"

const Container = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: 0.15rem;
    padding: 0.25rem;
    font-size: 0.9rem;
    background-color: var(--background-2);
    width: 100%;
    max-width: 20rem;
    border-radius: var(--border-radius-s);

    .row {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding-right: 0.2rem;
    }

    .main-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.4rem;
        width: 100%;
        padding: 0.5rem 0;
    }

    .bars-wrapper {
        position: relative;
        display: flex;
        width: 100%;
    }
    .bar-1,
    .bar-2,
    .bar-3 {
        height: 100%;
        height: 0.5rem;
        outline: 2px solid var(--background-contrast);
        :hover {
            outline: 2px solid var(--foreground);
            background-color: var(--foreground);
            z-index: 2;
        }
    }

    .bar-1 {
        background-color: var(--color-4);
        border-top-left-radius: var(--border-radius-ss);
        border-bottom-left-radius: var(--border-radius-ss);
    }
    .bar-2 {
        background-color: var(--color-2);
        border-top-right-radius: var(--border-radius-ss);
        border-bottom-right-radius: var(--border-radius-ss);
        min-width: 1rem;
        z-index: 4;
    }

    .bar-3 {
        position: absolute;
        width: 0.5rem;
        left: calc(50% - 0.5rem);
        background-color: #ffd000;
        z-index: 3;
    }

    .updates-wrapper {
        position: relative;
        display: flex;
        /* justify-content: center; */
        overflow: hidden;
        background: var(--background);
        border-radius: var(--border-radius-ss);
        padding: 0.15rem 0.5rem;
        height: 1.2rem;
        p {
            position: absolute;
            font-size: 0.8rem;
        }
        span {
            margin-right: 0.25rem;
        }
    }
`


const tooltipProps: TippyProps = {
    followCursor: "horizontal",
    offset: [0, 5],
    plugins: [followCursor],
    hideOnClick: false,
}

let timeoutId: any = null

const VoteWidget = () => {
    const voteStatus = useSelector(
        ({ roomData }: IRootState) => roomData.voteStatus
    )
    const currentVideo = useSelector(
        ({ roomData }: IRootState) => roomData.currentVideo
    )
    const [lastEvent, setLastEvent] = useState<ApiVoteEvent>(null)
    const [lastEventMessage, setLastEventMessage] = useState<
        string | JSX.Element
    >("Nenhum voto recente")
    const { socket } = useSocket()
    const { t } = useTranslation()

    const handleVoteEvent = (vote: ApiVoteEvent) => {
        setLastEvent(vote)
        setLastEventMessage(
            <Trans i18nKey={"widgets.vote_widget.user_voted"}>
                <UsernameLabel user={vote.user} as={"span"} />
            </Trans>
        )
        clearTimeout(timeoutId)
        timeoutId = setTimeout(
            () => setLastEventMessage(t("widgets.vote_widget.no_recent_votes")),
            10_000
        )
    }

    const handleVoteReset = () => {
        const skipedMessage = t("widgets.vote_widget.vote_reset")
        if (lastEventMessage !== skipedMessage)
            setLastEventMessage(skipedMessage)

        setTimeout(() => {
            setLastEvent(null)
        }, 500)
    }

    useEffect(() => {
        if (!socket) return
        socket.on("vote-event", handleVoteEvent)
        socket.on("vote-reset", handleVoteReset)
        return () => {
            socket.removeListener("vote-event", handleVoteEvent)
            socket.removeListener("vote-reset", handleVoteReset)
        }
    }, [socket])

    useEffect(() => {
        setLastEventMessage(t("widgets.vote_widget.no_recent_votes"))
    }, [i18next.language])

    useEffect(() => {
        if (!lastEvent) return
        if (lastEvent.for.id !== currentVideo?.id)
            setTimeout(() => setLastEvent(null), 1000)
    }, [currentVideo])

    const stayPercentage = (lastEvent || voteStatus)?.stay_percentage || 100
    const skipPercentage = (lastEvent || voteStatus)?.skip_percentage || 0

    return (
        <Container>
            <div className="row">
                <CommandButton
                    type={4}
                    command="voteSkip"
                    icon="how_to_vote"
                    tooltip={t("widgets.vote_widget.button_vote")}
                    tooltipPlacement="top"
                    tooltipProps={{ hideOnClick: false }}
                />
                <div className="main-wrapper">
                    <p>{t("widgets.vote_widget.title")}</p>
                    <div className="bars-wrapper">
                        <Tooltip
                            content={t("widgets.vote_widget.tooltip_stay", {
                                percentage: stayPercentage.toFixed(2),
                            })}
                            {...tooltipProps}
                        >
                            <motion.div
                                className="bar-1"
                                animate={{
                                    width: `${stayPercentage}%`,
                                }}
                            ></motion.div>
                        </Tooltip>
                        <Tooltip
                            content={t(
                                "widgets.vote_widget.fifty_percentage_info"
                            )}
                            {...tooltipProps}
                        >
                            <div className="bar-3"></div>
                        </Tooltip>

                        <Tooltip
                            content={t("widgets.vote_widget.tooltip_skip", {
                                percentage: skipPercentage.toFixed(2),
                            })}
                            {...tooltipProps}
                        >
                            <motion.div
                                className="bar-2"
                                animate={{
                                    width: `${skipPercentage}%`,
                                }}
                                // transition={{duration: 2}}
                            ></motion.div>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div className="line"></div>
            <div className="updates-wrapper">
                <AnimatePresence>
                    {lastEventMessage && (
                        <motion.p
                            initial={{ y: -20 }}
                            animate={{ y: 0 }}
                            exit={{ y: 50 }}
                            transition={{ duration: 0.3 }}
                            key={lastEvent?.uuid}
                        >
                            {lastEventMessage}
                        </motion.p>
                    )}
                </AnimatePresence>
            </div>
        </Container>
    )
}

export default VoteWidget
