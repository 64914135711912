import Draggable from "react-draggable"
import styled from "styled-components"
import ButtonIcon from "./Widgets/ButtonIcon"
import { useTranslation } from "react-i18next"

const WindowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    background: var(--background-contrast);
    border: 2px solid #ffffff1d;
    width: 27rem;
    height: 35rem;
    max-width: calc(100vw - 1rem);
    top: 0;
    z-index: 999999;
    border-radius: var(--border-radius-m);

    .topbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: var(--background-light);
        padding-right: 0.4rem;
    }

    .topbar-drag {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 100%;
        padding: 0.25rem 0.5rem;
        cursor: all-scroll;
    }

    .window-content {
        height: 100%;
    }

    span.material-icon {
        font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 48 !important;
        /* background: red; */
    }
    overflow: hidden;
`

interface WindowProps {
    children: JSX.Element
    handleClose: () => void
    title?: string
    position?: [number, number]
}

const Window = ({ children, handleClose, title, position }: WindowProps) => {
    const { t } = useTranslation()
    const handleStart = () => {}
    const handleDrag = () => {}
    const handleEnd = () => {}

    let x = 0
    let y = 0
    if (position?.length >= 2) {
        x = position[0]
        y = position[1]
        const height = window.innerHeight
        const width = window.innerWidth

        if (x + 400 > width) x = width - 400
        if (y + 500 > height) y = height - 500
    }

    return (
        <Draggable
            axis="both"
            handle=".topbar-drag"
            defaultPosition={{ x, y }}
            position={null}
            // grid={[25, 25]}
            scale={1}
            onStart={handleStart}
            onDrag={handleDrag}
            onStop={handleEnd}
        >
            <WindowWrapper>
                <div className="topbar">
                    <div className="topbar-drag">
                        <span className="material-icon notranslate">web_asset</span>
                        <p>{title || "título"}</p>
                    </div>
                    <ButtonIcon
                        type={3}
                        onSubmit={(e) => {
                            e.stopPropagation()
                            handleClose()
                        }}
                        icon="close"
                        children={t("close")}
                    />
                </div>
                <div className="window-content">{children}</div>
            </WindowWrapper>
        </Draggable>
    )
}

export default Window
