import Tooltip from "@/components/Tooltip"
import CommandButton from "@/pages/Dashboard/CommandButton"
import { ApiPlaylistVideo, ApiSearchVideo, ApiVideo } from "@types"
import { motion } from "framer-motion"
import prettyMs from "pretty-ms"
import { memo } from "react"
import styled from "styled-components"
import SelectPlaylistTooltip from "./SelectPlaylistTooltip"
import UsernameLabel from "./UsernameLabel"
import { useTranslation } from "react-i18next"

const formatter = Intl.NumberFormat("en", { notation: "compact" })

const VideoDetails = ({
    video,
}: {
    video: ApiVideo | ApiSearchVideo | ApiPlaylistVideo
}) => {
    const isSearchVideo = Boolean((video as ApiSearchVideo).channel)
    const { t } = useTranslation()

    return (
        <>
            <Tooltip
                content={t("search_videos.video_item.duration")}
                placement="bottom"
            >
                <motion.p className="duration text">
                    <span className="material-icon notranslate">schedule</span>
                    {prettyMs(video.duration)}
                </motion.p>
            </Tooltip>
            <Tooltip
                content={t("search_videos.video_item.views")}
                placement="bottom"
            >
                <motion.p className="views text">
                    <span className="material-icon notranslate">visibility</span>
                    {formatter.format(video.views)}
                </motion.p>
            </Tooltip>
            {isSearchVideo && (
                <Tooltip
                    content={t("search_videos.video_item.channel")}
                    placement="bottom"
                >
                    <motion.p className="channel text">
                        <span className="material-icon notranslate remote">tv_gen</span>
                        {(video as ApiSearchVideo).channel}
                    </motion.p>
                </Tooltip>
            )}
        </>
    )
}

export default VideoDetails

const FlexRow = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    span.material-icon {
        color: var(--foreground);
        display: inline-flex;
        align-items: center;
        margin: 0 0.2rem 0 0;
        vertical-align: bottom;
        font-size: 1rem;
        font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 0, "opsz" 20;
    }

    p {
        display: flex;
        align-items: center;
        font-size: 0.9rem;
        opacity: 0.9;
        width: fit-content;
    }
`

export const VideoDetailsFlex = ({
    video,
    className,
}: {
    video: ApiVideo | ApiSearchVideo | ApiPlaylistVideo
    className?: string
}) => {
    return (
        <FlexRow className={className}>
            <VideoDetails video={video} />
        </FlexRow>
    )
}

export const QueueVideoDetails = memo(
    ({
        video,
        index,
        isOwner,
        show_queue_thumbs,
        commandsAvailable,
    }: {
        video: ApiVideo
        index: number
        roomName: string
        isOwner?: boolean
        show_queue_thumbs?: boolean
        commandsAvailable: string[]
    }) => {
        const { t } = useTranslation()
        return (
            <>
                <Tooltip content={t("queue.item.position")} placement="bottom">
                    <div className="column-0">
                        <span className="material-icon notranslate">tag</span>
                        <p className="views text">{index + 1}</p>
                    </div>
                </Tooltip>
                <div className="column-1">
                    <Tooltip
                        content={t("queue.item.open_on_youtube")}
                        placement="bottom"
                    >
                        <a href={`//youtu.be/${video.id}`} target={"_blank"}>
                            {show_queue_thumbs && (
                                <img
                                    src={`https://i.ytimg.com/vi/${video.id}/mqdefault.jpg`}
                                    loading="lazy"
                                ></img>
                            )}
                        </a>
                    </Tooltip>
                </div>
                <div className="column-2">
                    <p className="title text">
                        <span className="material-icon notranslate">play_arrow</span>
                        {video.title.length > 60
                            ? video.title.slice(0, 50) + "..."
                            : video.title}
                    </p>
                    <p className="author text">
                        <span className="material-icon notranslate">headphones</span>
                        <UsernameLabel user={video.user} as="span" />
                    </p>
                    <div className="details">
                        <Tooltip
                            content={t("queue.item.duration")}
                            placement="bottom"
                        >
                            <p className="duration text">
                                <span className="material-icon notranslate">schedule</span>
                                {prettyMs(video.duration)}
                            </p>
                        </Tooltip>
                        <Tooltip
                            content={t("queue.item.views")}
                            placement="bottom"
                        >
                            <p className="views text">
                                <span className="material-icon notranslate">
                                    visibility
                                </span>
                                {formatter.format(video.views)}
                            </p>
                        </Tooltip>
                    </div>
                    <div className="actions-wrapper">
                        <SelectPlaylistTooltip video={video} />
                        {commandsAvailable?.includes("skip") && (
                            <CommandButton
                                icon="skip_next"
                                command="skip"
                                args={[(index + 1).toString()]}
                                tooltip={t("queue.item.skip_button")}
                                tooltipPlacement="auto-end"
                                tooltipDelay={[100, 0]}
                                type={3}
                            ></CommandButton>
                        )}
                        {isOwner && (
                            <CommandButton
                                icon="exit_to_app"
                                command="quit"
                                args={[video.uuid]}
                                tooltip={t("queue.item.quit_button")}
                                tooltipPlacement="auto-end"
                                tooltipDelay={[100, 0]}
                                type={3}
                            ></CommandButton>
                        )}
                    </div>
                </div>
            </>
        )
    }
)
