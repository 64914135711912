export const fadeInOut = (index: number, duration: number = .2) => ({
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: { duration, delay: index * 0.075 },
    },
    exit: {
        opacity: 0,
    },
})

export const fadeInOutButton = (index: number) => ({
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: { duration: 0.4, delay: index * 0.1 },
    },
    exit: {
        opacity: 0,
    },
})

export const fadeInOutItem = (index: number) => ({
    initial: {
        x: -50,
        opacity: 0,
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: index * 0.035,
            opacity: {
                duration: 0.4,
                delay: index * 0.05,
            },
        },
    },
    exit: {
        x: 50,
        opacity: 0,
        transition: { duration: 0.3, delay: 0 },
    },
})
