import Tooltip from "@/components/Tooltip"
import UsernameLabel from "@/components/UsernameLabel"
import { ApiTip } from "@types"
import i18next from "i18next"
import moment from "moment"
import "moment/dist/locale/pt-br"
import { useTranslation } from "react-i18next"
import { TipsTableContainer } from "./styles"

i18next.on("languageChanged", (lng) => {
    if (lng === "pt") return moment.locale("pt-br")
    if (lng === "es") return moment.locale("es")
    moment.locale("en")
})

const UserBadge = () => {
    const { t } = useTranslation()
    return (
        <Tooltip content={t("tips.unknown_user_tooltip")} hideOnClick={false} interactive={true}>
            <div className="badge-wrapper">
                <span className="material-icon notranslate user unknown">gpp_bad</span>
            </div>
        </Tooltip>
    )
}

const CurrencyExchange = ({ dollarPrice }: { dollarPrice: number }) => {
    const { t } = useTranslation()

    return (
        <Tooltip
            content={t("tips.currency_exchange_tooltip", { dollarPrice })}
            hideOnClick={false}
            interactive={true}
        >
            <div className="badge-wrapper">
                <span className="material-icon notranslate user currency-exchange">currency_exchange</span>
            </div>
        </Tooltip>
    )
}

type ItemProps =
    | {
          index: number
          filter: "hide" | "show"
          mode: "leaderboard"
          tip: ApiTip
          dollarPrice: number
      }
    | {
          index: number
          filter: "hide" | "show"
          mode: "history"
          tip: ApiTip
          dollarPrice: number
      }

export const TipItem = ({ tip, index, mode, filter, dollarPrice }: ItemProps) => {
    const now = moment()
    const { t } = useTranslation()
    let difference: string = null
    let logDate = null
    if (mode === "history") {
        logDate = moment(tip.created_at)
        difference = logDate.from(now).replace("há poucos segundos", "agora")
    }

    let position = null

    switch (index) {
        case 1:
            position = <span className="material-icon notranslate trophy first">emoji_events</span>
            break
        case 2:
            position = <span className="material-icon notranslate trophy second">emoji_events</span>
            break
        case 3:
            position = <span className="material-icon notranslate trophy third">emoji_events</span>
            break
        default:
            position = `${index}º`
    }

    const isKnown = Boolean(tip.author)
    const authorName = tip.author?.name || tip.raw_author

    const coinPrefix = tip.currency === "brl" ? "R$ " : "$"

    return (
        <tr className="tip-item">
            {mode === "leaderboard" && <td>{position}</td>}
            {mode === "history" && <td>{difference}</td>}
            {!isKnown && (
                <td style={{ maxWidth: "10rem", overflow: "hidden" }}>
                    {filter === "hide" ? t("tips.unknown_user") : authorName}
                    <UserBadge />
                </td>
            )}
            {isKnown && (
                <td>
                    <UsernameLabel className="known-user" user={tip.author} as="span" />
                </td>
            )}
            <td>
                {coinPrefix} {tip.amount.toString().replace(".", ",")}{" "}
                {tip.isMultiCoin && <CurrencyExchange dollarPrice={dollarPrice} />}
            </td>
        </tr>
    )
}

type TableProps = {
    mode: "leaderboard" | "history"
    tips: ApiTip[] | ApiTip[]
    filter: "hide" | "show"
    dollarPrice: number
}

const TipsTable = ({ tips, mode, filter, dollarPrice }: TableProps) => {
    const { t } = useTranslation()

    return (
        <TipsTableContainer>
            <table>
                <tr className="header">
                    {mode === "leaderboard" && <th>{t("tips.table.position")} </th>}
                    {mode === "history" && <th>{t("tips.table.when")}</th>}
                    <th>{t("tips.table.name")}</th>
                    <th>{t("tips.table.value")}</th>
                </tr>
                <tbody>
                    {tips.map((tip, index) => (
                        <TipItem
                            dollarPrice={dollarPrice}
                            tip={tip as any}
                            key={index}
                            index={index + 1}
                            mode={mode}
                            filter={filter}
                        />
                    ))}
                </tbody>
            </table>
        </TipsTableContainer>
    )
}

export default TipsTable
