import useModal from "@/hooks/useModal"
import CommunityTabPage from "@/pages/CommunityTab"
import { DancerCard } from "@/pages/Dashboard/Card"
import CommandButton from "@/pages/Dashboard/CommandButton"
import UserHeader from "@/pages/Dashboard/UserHeader"
import { getRoomName } from "@/utils"
import { ApiVideo } from "@types"
import { AnimatePresence } from "framer-motion"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import styled from "styled-components"
import QueueItem from "@/components/QueueItem"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import Folder from "@/components/Widgets/Folder"
import Window from "@/components/Window"
import { updateConfigKey } from "@/slices/config"
import { updateRoomKey } from "@/slices/roomData"

interface UserCardProps {}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    overflow-x: hidden;
    padding: 0.5rem 0.5rem;

    .user-header {
        /* margin-bottom: 0 !important; */
    }

    .user {
        display: flex;
        align-items: center;
        background-color: var(--background-2);
        width: 100%;
        padding: 0.5rem 0.5rem;
        gap: 0.5rem;
        p.name {
            font-size: 1.5rem;
            overflow: hidden;
        }

        img.badge {
            height: 2.25rem;
        }
        border-radius: var(--border-radius-s);
        justify-self: flex-start;
        text-overflow: ellipsis;
        min-height: fit-content !important;
    }

    .user span.base {
        width: 0.3rem;
        height: 2rem;
        border-radius: 1rem;
    }

    .user img {
        height: 2rem;
    }

    .actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5rem 0.25rem;
        min-height: fit-content;
        height: fit-content;
        /* margin-top: auto; */
    }

    .actions span.material-icon {
        font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48 !important;
    }

    button span.material-icon {
        font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48 !important;
    }
    .folder {
        background-color: var(--background-2);
        min-height: fit-content;
    }

    .folder-queue {
        max-height: 19rem;
        overflow-y: auto !important;
        overflow-x: clip !important;
    }

    .folder-dancer * {
        z-index: 99;
    }

    .queue {
        width: 100%;
    }

    .folder li {
        background: var(--background-light) !important;
    }
`

interface UserVideo extends ApiVideo {
    originalIndex: number
}

const QueueItems = ({ userVideos }: { userVideos: UserVideo[] }) => {
    const commandsAvailable = useSelector(
        (state: IRootState) => state.userData.commandsAvailable
    )
    const roomName = getRoomName()

    return (
        <div className="queue">
            {userVideos.map((video, index) => {
                return (
                    <QueueItem
                        roomName={roomName}
                        index={video.originalIndex}
                        video={video}
                        key={video.uuid + "-usercard"}
                        commandsAvailable={commandsAvailable}
                        isPrimary={false}
                    />
                )
            })}
        </div>
    )
}

const WindowContent = () => {
    const { userCard } = useModal()
    const queue = useSelector(({ roomData }: IRootState) => roomData.queue)
    const avatars = useSelector(({ roomData }: IRootState) => roomData.avatars)
    const commandsAvailable = useSelector(
        (state: IRootState) => state.userData.commandsAvailable
    )
    const { i18n } = useTranslation()
    const t = i18n.getFixedT(null, null, "user_card")

    const { setModal } = useModal()
    const [isQueueOpen, setIsQueueOpen] = useState(false)
    const [isDancerOpen, setIsDancerOpen] = useState(false)

    if (!userCard) return

    const userVideos: UserVideo[] = queue
        .map((video, index) => ({ ...video, originalIndex: index }))
        .filter((video) => video.user.id === userCard.id)

    const userDancer = avatars.find((a) => a.user.id === userCard.id)
    const dispatch = useDispatch()

    const handleUserLogs = async () => {
        dispatch(
            updateConfigKey({ key: "is_logs_tab_visible", newValue: true })
        )
        dispatch(updateConfigKey({ key: "tab_active", newValue: "logs" }))
        dispatch(
            updateRoomKey({ key: "logsSearchQuery", newValue: userCard.name })
        )
    }

    return (
        <Container>
            <UserHeader
                isUserCard={true}
                className={"user-header"}
                chatter={userCard}
            />
            <div className="actions">
                {commandsAvailable.includes("ban") && (
                    <CommandButton
                        icon="do_not_disturb_on"
                        command="ban"
                        args={[userCard.name]}
                        children={t("ban_button")}
                        type={2}
                    />
                )}
                {commandsAvailable.includes("unban") && (
                    <CommandButton
                        icon="do_not_disturb_off"
                        command="unban"
                        args={[userCard.name]}
                        children={t("unban_button")}
                        type={2}
                    />
                )}
                {commandsAvailable.includes("dj") && (
                    <CommandButton
                        icon="headphones"
                        command="dj"
                        args={["add", userCard.name]}
                        children={t("dj_add_button")}
                        type={2}
                    />
                )}
                {commandsAvailable.includes("dj") && (
                    <CommandButton
                        icon="headset_off"
                        command="dj"
                        args={["remove", userCard.name]}
                        children={t("dj_remove_button")}
                        type={2}
                    />
                )}
            </div>
            <Folder
                isOpen={isQueueOpen}
                title={t("user_videos_in_queue", { count: userVideos.length })}
                onClick={() => setIsQueueOpen(!isQueueOpen)}
                className="folder"
            >
                <QueueItems userVideos={userVideos} />
            </Folder>
            <Folder
                isOpen={isDancerOpen}
                title={t(userDancer ? `user_dancer` : "user_dancer_empty")}
                onClick={() => setIsDancerOpen(!isDancerOpen)}
                className="folder folder-dancer"
            >
                {userDancer && <DancerCard dancer={userDancer} />}
            </Folder>
            <div className="actions">
                <ButtonIcon
                    icon="queue_music"
                    type={2}
                    onSubmit={() =>
                        setModal(
                            <CommunityTabPage preSearch={`${userCard.name}`} />,
                            "main"
                        )
                    }
                    wrapperProps={{
                        style: {
                            position: "relative",
                            overflow: "hidden",
                        },
                    }}
                    children={t("user_playlists")}
                />
                <ButtonIcon
                    icon="message"
                    children={t("user_logs")}
                    type={2}
                    onSubmit={handleUserLogs}
                />
            </div>
        </Container>
    )
}

let timeout: any = null

const UserCardWindow = ({}: UserCardProps) => {
    const { userCard, setUserCard, userCardPosition } = useModal()
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        if (isVisible) return

        timeout = setTimeout(() => {
            setUserCard(null, [0, 0])
        }, 300)

        return () => {
            clearTimeout(timeout)
        }
    }, [isVisible])

    useEffect(() => {
        const newValue = Boolean(userCard)
        if (newValue === isVisible) return
        setIsVisible(newValue)
    }, [userCard])

    return (
        <AnimatePresence>
            {userCard && (
                <div
                    style={{
                        position: "absolute",
                        zIndex: 99999,
                        width: "100vw",
                        height: "100vh",
                        opacity: isVisible ? 1 : 0,
                        transition: ".2s",
                    }}
                >
                    <Window
                        title={`Usercard`}
                        handleClose={() => setIsVisible(false)}
                        position={userCardPosition}
                    >
                        <WindowContent />
                    </Window>
                </div>
            )}
        </AnimatePresence>
    )
}

export default UserCardWindow
