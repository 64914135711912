import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import storage from "@/utils/storage"
import { ButtonType } from "@/pages/QuickActions"
import { LanguageKeys } from "@/i18n"

export type IConfigTabId =
    | "chat"
    | "about"
    | "stats"
    | "dashboard"
    | "logs"
    | "queue"

export interface IConfigState {
    render_avatars: boolean
    render_animations: boolean
    render_twitch_chat: boolean
    show_queue_thumbs: boolean
    autoplay: boolean
    last_viewed_news?: string
    tab_index: number
    hide_title: boolean
    queue_in_sidebar: boolean
    actions_buttons: ButtonType["id"][]
    actions_show_description: boolean
    language: LanguageKeys
    is_logs_tab_visible: boolean
    tab_active: IConfigTabId
}

const localStorageData = storage.get("config")

const defaultValues = {
    render_avatars: true,
    render_animations: true,
    render_twitch_chat: true,
    show_queue_thumbs: true,
    autoplay: false,
    tab_active: "about",
    tab_index: 0,
    last_viewed_news: null,
    hide_title: false,
    queue_in_sidebar: false,
    actions_buttons: ["sync", "add", "playlists", "store", "choose_language"],
    actions_show_description: true,
    language: null,
    is_logs_tab_visible: false,
} as IConfigState

const initialState: IConfigState = {
    ...defaultValues,
    ...(localStorageData || {}),
}
console.info("Local storage data:", {
    initialState,
    isDefault: !Boolean(localStorageData),
})
type BooleanKeys<T> = {
    [K in keyof T]: T[K] extends boolean ? K : never
}[keyof T]

type UpdatePayload<T, K extends keyof T> = {
    key: K
    newValue: T[K]
}

const configSlice = createSlice({
    name: "config",
    initialState,
    reducers: {
        enableAvatars: (state) => {
            state.render_avatars = true
        },
        disableAvatars: (state) => {
            state.render_avatars = false
        },
        enableAnimations: (state) => {
            state.render_animations = true
        },
        disableAnimations: (state) => {
            state.render_animations = false
        },
        enableTwitchChat: (state) => {
            state.render_twitch_chat = true
        },
        disableTwitchChat: (state) => {
            state.render_twitch_chat = false
        },
        setTabIndex: (state, action: PayloadAction<number>) => {
            state.tab_index = action.payload
        },
        updateKey: (
            state,
            action: PayloadAction<
                UpdatePayload<IConfigState, keyof IConfigState>
            >
        ) => {
            const { key, newValue } = action.payload
            return { ...state, [key]: newValue } as IConfigState
        },
    },
})

export const {
    enableAvatars,
    enableAnimations,
    enableTwitchChat,
    disableAnimations,
    disableAvatars,
    disableTwitchChat,
    setTabIndex,
    updateKey: updateConfigKey,
    updateKey,
} = configSlice.actions

export default configSlice.reducer
