import { SearchLoading } from "@/components/Loading"
import TabButton from "@/components/TabButton"
import { useGridQueries } from "@/hooks/useGridQueries"
import api from "@/services/api"
import { fadeInOut } from "@/utils/fadeInOut"
import { ApiResponse, ApiSearchResult, ApiSearchVideo } from "@types"
import { AnimatePresence, motion } from "framer-motion"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import VideoItem from "./VideoItem"
import { PageContainer, VideosContainer } from "./styles"
import { useTranslation } from "react-i18next"

let timeout: ReturnType<typeof setTimeout> = null

const Icon = styled(motion.span)`
    font-size: 6rem;
    font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 100, "opsz" 48;
`

const ErrorIcon = () => <Icon className="material-icon notranslate">error</Icon>

const VideosListPage = () => {
    const [currentSearch, setCurrentSearch] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState(null)
    const [loadingIcon, setLoadingIcon] = useState(null)
    const [isListView, setListView] = useState(false)
    const { mobileSize } = useGridQueries()
    const [startIndex, setStartIndex] = useState(0)
    const ref = useRef<HTMLInputElement>()
    const { t } = useTranslation()

    const resetLoadingMessage = () => {
        setLoadingIcon(null)
        setLoadingMessage(null)
    }

    const handleSearchResult = (response: ApiResponse<ApiSearchResult>) => {
        if (!response.success) return setLoadingMessage(response.message)
        const zeroVideosFound = response.data.length === 0

        if (zeroVideosFound) {
            setIsLoading(true)
            setLoadingMessage(t("search_videos.no_video_found"))
            setLoadingIcon(<ErrorIcon />)
            setCurrentSearch([])
        } else {
            setCurrentSearch(response.data)
            resetLoadingMessage()
            setIsLoading(false)
        }
    }

    const searchVideo = async (title: string) =>
        api.searchVideo(title).then(handleSearchResult)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        clearTimeout(timeout)
        resetLoadingMessage()
        setIsLoading(true)
        setCurrentSearch([])

        timeout = setTimeout(() => {
            searchVideo(e.target.value)
        }, 500)
    }

    const handeTabClick = (element: string | JSX.Element, index: number) => {
        setListView(Boolean(index))
    }

    useEffect(() => {
        if (!ref.current) return
        setTimeout(() => {
            ref.current.focus()
        }, 300)
    }, [ref])

    useEffect(() => {
        if (mobileSize) {
            setStartIndex(1)
            setListView(true)
            return
        }
        setStartIndex(0)
        setListView(false)
    }, [mobileSize])

    return (
        <PageContainer {...fadeInOut(0, 0.5)}>
            <input
                onChange={handleChange}
                placeholder={t("search_videos.input_placeholder")}
                ref={ref}
            />
            <div className="options">
                <TabButton
                    texts={[
                        t("search_videos.view_tabs_1"),
                        t("search_videos.view_tabs_2"),
                    ]}
                    icons={["list", "view_day"]}
                    onClick={handeTabClick}
                    layoutId={"view-type"}
                />
            </div>

            <VideosContainer layout layoutScroll>
                {currentSearch.map((video: ApiSearchVideo, i: number) => (
                    <VideoItem
                        video={video}
                        key={video.id}
                        index={i + 1}
                        isListView={!isListView}
                    />
                ))}
                <AnimatePresence>
                    {isLoading && (
                        <SearchLoading
                            message={loadingMessage}
                            customIcon={loadingIcon}
                            barsHeight={"12rem"}
                        />
                    )}
                </AnimatePresence>
            </VideosContainer>
        </PageContainer>
    )
}

export default VideosListPage
