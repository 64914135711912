import { useEffect } from "react"
import { useMediaQuery } from "usehooks-ts"

export const useGridQueries = () => {
    const queueSmaller = useMediaQuery("screen and (max-width: 1450px)")
    const queueHidden = useMediaQuery("screen and (max-width: 1150px)")
    const mobileSize = useMediaQuery("screen and (max-width: 750px)")

    useEffect(() => {
        const root: HTMLDivElement = document.querySelector(":root")
        if (!mobileSize && !queueHidden)
            return root.style.setProperty("--root-font-size", "medium")

        root.style.setProperty("--root-font-size", "small")
    }, [mobileSize, queueHidden])

    return { queueSmaller, queueHidden, mobileSize }
}
