
export interface IHelpTooltips {
    [key: string]: {
        [key: string]: {
            tutorial: string
            message: string
            response: string
        }[]
    }
}

export const fetchTooltips = async () => {
    const pt = await import("@/translations/help-pt.json")
    const en = await import("@/translations/help-en.json")
    const es = await import("@/translations/help-es.json")

    return { pt, en, es } as unknown as IHelpTooltips
}

export interface IHelpBadge {
    [key: string]: string[]
}

export const badges: IHelpBadge = {
    user: ["sub.png"],
    mod: ["moderator.png"],
}
