import useModal from "@/hooks/useModal"
import ManageQueuePage from "@/pages/ManageQueue"
import { AnimatePresence, motion } from "framer-motion"
import { memo } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { hideAll } from "tippy.js"
import QueueItem from "./QueueItem"
import ButtonIcon from "./Widgets/ButtonIcon"
import { useTranslation } from "react-i18next"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: queue;
    border-radius: var(--border-radius-s);
    width: 100%;
    overflow: hidden;
    overflow-x: hidden;
    background-color: var(--background-2);

    .queue-header {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        /* background: blue; */
        padding: 0.5rem 0.75rem;
    }

    .queue-header .actions {
        display: flex;
        gap: 0.25rem;
    }

    .queue-header .actions button {
        /* padding: .15rem; */
    }

    .queue-header .playing-next {
        color: var(--foreground);
        word-break: break-word;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 1.25rem;
        /* background: red; */
    }

    .queue-header .material-icon {
        font-size: 1.75rem;
        font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 20;
    }
`

const Container = styled(motion.ul)`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    padding: 0.5rem;
    scroll-snap-type: y mandatory;
    gap: 0.25rem;
    li {
        scroll-snap-align: start;
        transition: background-color 0.3s cubic-bezier(0.26, -0.05, 0.06, 1.01);
    }

    li:hover {
        background-color: var(--background-light);
        border-radius: 0.25rem;
    }

    li:nth-child(1) {
        background-color: var(--background-light);
        border-radius: 0.25rem;
    }

    li:last-child {
        margin-bottom: 5rem;
    }

    .queue-header {
        display: flex;
        align-items: center;
        scroll-snap-align: start;
    }
`

const Queue = memo(({ isPrimary }: { isPrimary?: boolean }) => {
    const { t } = useTranslation()
    const queue = useSelector((state: IRootState) => state.roomData.queue)
    const isLoading = useSelector(
        (state: IRootState) => state.roomData.isLoading
    )

    const { setModal } = useModal()
    const { roomName } = useParams()

    const user = useSelector((state: IRootState) => state.userData.user)
    const commandsAvailable = useSelector(
        (state: IRootState) => state.userData.commandsAvailable
    )

    const show_queue_thumbs = useSelector(
        (state: IRootState) => state.config.show_queue_thumbs
    )

    if (isLoading) return null

    let classNames = ["queue"]
    if (!isPrimary) classNames.push("queue-secundary")

    return (
        <Wrapper className={classNames.join(" ")}>
            <div className="queue-header">
                <span className="playing-next">
                    <span className="material-icon notranslate">subscriptions</span>
                    {t("queue.title")}:
                </span>
                {commandsAvailable?.includes("queue") && (
                    <div className="actions">
                        <ButtonIcon
                            icon="settings"
                            onSubmit={() =>
                                setModal(<ManageQueuePage />, "main")
                            }
                            type={3}
                        />
                    </div>
                )}
            </div>
            <Container onScroll={() => hideAll({ duration: 300 })}>
                <AnimatePresence presenceAffectsLayout={true}>
                    {!isLoading &&
                        queue.map((video, i) => (
                            <QueueItem
                                video={video}
                                key={video.uuid}
                                index={i}
                                roomName={roomName}
                                isOwner={video.user.id === user?.id}
                                commandsAvailable={commandsAvailable}
                                show_queue_thumbs={show_queue_thumbs}
                                isPrimary={isPrimary}
                            />
                        ))}
                </AnimatePresence>
            </Container>
        </Wrapper>
    )
})

export default Queue
