import { PayloadAction } from "@reduxjs/toolkit"
import { ApiCommunityPlaylist, ApiPlaylist, ApiPlaylistVideo } from "@types"
import { IPlaylistsState } from "."

const removeVideoFromId = (playlist: ApiPlaylist, video: { id: string }) => {
    return playlist.videos.filter((v) => v.id !== video.id)
}

const addNewPlaylist = (
    state: IPlaylistsState,
    action: PayloadAction<ApiPlaylist>
) => {
    state.playlists = [...state.playlists, action.payload]
}

const removePlaylist = (
    state: IPlaylistsState,
    action: PayloadAction<ApiPlaylist>
) => {
    const targetId = action.payload._id
    const newPlaylists = state.playlists.filter((p) => p._id !== targetId)
    state.playlists = newPlaylists
}

type RemoveAction = {
    playlist: ApiPlaylist
    video: ApiPlaylistVideo
}

const removeVideoFromPlaylist = (
    state: IPlaylistsState,
    action: PayloadAction<RemoveAction>
) => {
    const target = action.payload
    const playlists = state.playlists

    const newPlaylists = playlists.map((p) => {
        if (p._id !== target.playlist._id) return p
        const videos = removeVideoFromId(p, target.video)
        return { ...p, videos }
    })
    state.playlists = newPlaylists
}

const addVideoToPlaylist = (
    state: IPlaylistsState,
    action: PayloadAction<ApiPlaylist>
) => {
    const target = action.payload
    const playlists = state.playlists

    const newPlaylists = playlists.map((playlist) => {
        if (target._id === playlist._id) return target
        return playlist
    })

    state.playlists = newPlaylists
}

const updateCommunityPlaylist = (
    state: IPlaylistsState,
    action: PayloadAction<ApiCommunityPlaylist>
) => {
    const target = action.payload
    console.log("atualizando", target)
    const newPlaylists = state.communityPlaylists.map((playlist) => {
        if (target._id === playlist._id) return target
        return playlist
    })

    state.communityPlaylists = newPlaylists
}

const updateCurrentPlaylist = (
    state: IPlaylistsState,
    action: PayloadAction<ApiPlaylist>
) => {
    const target = action.payload
    state.currentPlaylist = target
}

const getIds = (arr: any[]): string[] => arr.map((i) => i._id)

const updateArray = (arr: any[], target: any) =>
    arr.map((item) => {
        if (item._id === target._id) return target
        return item
    })

const updateLikedPlaylist = (
    state: IPlaylistsState,
    action: PayloadAction<ApiPlaylist>
) => {
    const target = action.payload
    const { likedPlaylists } = state
    state.likedPlaylists = updateArray(likedPlaylists, target)

    // state.currentPlaylist = target
}

const playlistsReducers = {
    addNewPlaylist,
    removePlaylist,
    removeVideoFromPlaylist,
    addVideoToPlaylist,
    updateCommunityPlaylist,
    updateCurrentPlaylist,
    updateLikedPlaylist,
}

export default playlistsReducers
