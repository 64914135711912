import ButtonIcon from "@/components/Widgets/ButtonIcon"
import styled from "styled-components"
import useModal from "../../hooks/useModal"
import ManageQueuePage from "../ManageQueue"
import CommandButton from "./CommandButton"
import ConfigQueueWidget from "./ConfigQueueWidget"
import PauseQueueWidget from "./PauseQueueWidget"
import SeekQueueWidget from "./SeekQueueWidget"
import { useTranslation } from "react-i18next"

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.4rem;
    align-items: flex-start;
    width: 100%;

    .folder-button {
        background-color: var(--background-2);
    }
    .folder-button-add span {
        font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 200, "opsz" 20;
    }

    span.small,
    p.small {
        font-size: 0.75rem;
    }

    &.mods {
        p.title {
            font-size: 1rem;
        }
    }

    &.mods .row {
        position: relative;
        width: 100%;
        display: flex;
        gap: 0.35rem;
        align-items: center;
        flex-direction: row;

        label.time {
            font-size: 0.9rem;
            min-width: 4rem;
        }

        input {
            background-color: var(--background);
        }
    }

    .line {
        width: 100%;
        height: 1px;
        background: #ffffff14;
        margin: 0.35rem 0;
    }
`


const ModInputs = () => {
    const { setModal } = useModal()
    const { t } = useTranslation()

    return (
        <Container className="mods">
            <CommandButton
                icon="skip_next"
                command="skip"
                title={t("dashboard.mod_inputs.button_skip")}
                type={2}
            />
            <CommandButton
                command="skipAll"
                icon="cleaning_services"
                title={t("dashboard.mod_inputs.button_skipAll")}
                type={2}
            />
            <ButtonIcon
                onSubmit={() => setModal(<ManageQueuePage />, "main")}
                icon="settings"
                wrapperProps={{ className: "folder-button" }}
                type={2}
                children={t("dashboard.mod_inputs.button_manage_queue")}
            />
            <PauseQueueWidget />
            <SeekQueueWidget />
            <ConfigQueueWidget />
        </Container>
    )
}

export default ModInputs
