import { FireParticles } from "@/components/FireParticles"
import api from "@/services/api"
import { updateKey } from "@/slices/roomData"
import { fetchShoppingCart, fetchUser } from "@/slices/userData"
import { fadeInOut } from "@/utils/fadeInOut"
import { ThunkDispatch } from "@reduxjs/toolkit"
import { ApiStoreItem } from "@types"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { useDispatch, useSelector } from "react-redux"
import useMeasure, { RectReadOnly } from "react-use-measure"
import ShoppingCart from "./ShoppingCart"
import StoreCardItem from "./StoreCardItem"
import { PageContainer } from "./styles"
import { SearchLoading } from "@/components/Loading"
import store from "@/store"
import { useTranslation } from "react-i18next"

const container = {
    hidden: { opacity: 1 },
    visible: {
        opacity: 1,
        transition: {
            duration: 1,
            delayChildren: 0.1,
            staggerChildren: 0.2,
        },
    },
}

export default function StorePage() {
    const [active, setActive] = useState<ApiStoreItem>(null)
    // const [position, setPosition] = useState<[number, number] | false>(false)
    const [isSafeToRemove, setSafeToRemove] = useState(true)
    const [isLoading, setLoading] = useState(true)
    const [items, setItems] = useState<ApiStoreItem[]>([])
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const userItems = useSelector((state: IRootState) => state.userData.user?.storeItems)
    const storeModalItem = useSelector((state: IRootState) => state.roomData.storeModalItem)
    const positionCard = useSelector((state: IRootState) => state.roomData.storeModalExitPositionCard)
    const positionButton = useSelector((state: IRootState) => state.roomData.storeModalExitPositionButton)
    const storeModalExitTarget = useSelector((state: IRootState) => state.roomData.storeModalExitTarget)
    const shoppingCart = useSelector((state: IRootState) => state.userData.shoppingCart)
    const [size, setSize] = useState<[number, number]>([0, 0])
    const [shouldExit, setShouldExit] = useState(false)
    const { t } = useTranslation()

    const handleClick = (target: ApiStoreItem) => {
        if (active?._id === target._id) {
            dispatch(
                updateKey({
                    key: "storeModalIsExiting",
                    newValue: { value: true, id: Date.now().toString() },
                }),
            )
            return setShouldExit(true)
        }
        setSafeToRemove(false)
        setShouldExit(false)
        setActive(target)
        dispatch(updateKey({ key: "storeModalExitTarget", newValue: "card" }))
    }

    const shouldHidePortal = !active?._id && isSafeToRemove
    const shouldShowPortal = !shouldHidePortal

    useEffect(() => {
        dispatch(updateKey({ key: "storeModalItem", newValue: active }))
        if (!shouldShowPortal) {
            dispatch(updateKey({ key: "storeModalExitTarget", newValue: "card" }))
        }
    }, [active?._id, shouldShowPortal, isSafeToRemove])

    const handleSafeToRemove = () => {
        setSafeToRemove(true)
        setActive(null)
    }

    useEffect(() => {
        setLoading(true)
        api.fetchStoreItems()
            .then((res) => setItems(res.data))
            .then(() => setLoading(false))
        dispatch(fetchShoppingCart())
        dispatch(fetchUser())
    }, [])

    useEffect(() => {
        if (storeModalItem) return
        setShouldExit(true)
    }, [storeModalItem])

    const handleSize = (size: RectReadOnly) => {
        setSize([size.width, size.height])
    }

    const setExitPosition = (position: [number, number]) => {
        dispatch(
            updateKey({
                key: "storeModalExitPositionCard",
                newValue: position,
            }),
        )
    }

    const exitProps =
        storeModalExitTarget === "card"
            ? {
                  opacity: 0,
              }
            : {
                  scale: 0.1,
                  x: positionButton[0] - size[0] / 2,
                  y: positionButton[1] - size[1] / 2,
              }

    const transitionProps =
        storeModalExitTarget === "card"
            ? { opacity: { delay: 0.2, duration: 0.2 } }
            : { duration: 0.4, opacity: { delay: 0.2, duration: 0.3 } }
    const position = storeModalExitTarget === "card" ? positionCard : positionButton

    return (
        <PageContainer {...fadeInOut(1, 0.5)} layoutScroll>
            <div className="canvas-wrapper">
                <h1 style={{ fontSize: "4rem" }}>{t("store_page.title")}</h1>
                <div className="actions">
                    <ShoppingCart currentActive={active} items={shoppingCart} />
                </div>
                <FireParticles />
            </div>
            <motion.div className="items-wrapper" variants={container} initial="hidden" animate="visible">
                {items.map((storeItem, index) => {
                    let isOwned = userItems?.includes(storeItem._id)
                    let isNew = false
                    if (storeItem.new_date_end) {
                        const now = new Date()
                        isNew = now < new Date(storeItem.new_date_end)
                    }
                    if (storeItem.is_discontinued && !isOwned) return null

                    return (
                        <StoreCardItem
                            // id={storeItem._id}
                            key={storeItem._id}
                            onClick={handleClick}
                            isActive={active?._id === storeItem._id && !shouldExit}
                            setPosition={setExitPosition}
                            storeItem={storeItem}
                            transitionProps={{ opacity: { delay: 0, duration: 0 } }}
                            isOwned={isOwned}
                            isNew={isNew}
                        />
                    )
                })}
                {!isSafeToRemove &&
                    createPortal(
                        <StoreCardItem
                            // id={active?._id}
                            isPortal={true}
                            isActive={false}
                            onClick={handleClick}
                            position={position as [number, number]}
                            shouldExit={shouldExit}
                            handleSafeToRemove={handleSafeToRemove}
                            storeItem={active}
                            exitProps={exitProps}
                            setSize={handleSize}
                            transitionProps={transitionProps}
                        />,

                        document.getElementById("store-card-container")!,
                    )}
            </motion.div>
            <AnimatePresence>
                {isLoading && (
                    <SearchLoading
                        styles={{
                            backgroundColor: "var(--background-contrast)",
                        }}
                        background={true}
                        message={t("store_page.loading")}
                    />
                )}
            </AnimatePresence>
        </PageContainer>
    )
}
