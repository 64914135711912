import { motion } from "framer-motion"
import Wave from "react-wavify"
import styled from "styled-components"

const WaveContainer = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 10rem;
    height: fit-content;
    border-radius: var(--border-radius-s);
    overflow: hidden;

    > div:last-child {
        position: absolute;
        left: 0;
        top: -5px;
        transform: rotateZ(180deg);
    }

    h1 span {
        position: relative;
    }

    h1 span::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 4px;
        background: #ffffff6c;
        left: 0;
        bottom: 2px;
        border-radius: 2px;
    }
`

const WaveTitle = ({
    children,
    waveHeight,
}: {
    children: JSX.Element | JSX.Element[]
    waveHeight?: number
}) => (
    <WaveContainer initial={{ y: -200 }} animate={{ y: 0 }}>
        {children}
        <Wave fill="url(#gradient)" height={waveHeight || 15}>
            <defs>
                <linearGradient id="gradient" gradientTransform="rotate(90)">
                    <stop offset={"10%"} stopColor="#9580ff"></stop>
                    <stop offset={"90%"} stopColor="#ff80bf"></stop>
                </linearGradient>
            </defs>
        </Wave>
    </WaveContainer>
)

export default WaveTitle
