import useModal from "@/hooks/useModal"
import CreateRoomPage from "@/pages/CreateRoom"
import Home from "@/pages/Home"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

const RoomRouteComponent = (): any => {
    const { setModal } = useModal()
    const { eventId } = useParams()

    useEffect(() => {
        setModal(<CreateRoomPage defaultEventId={eventId} />, "minimum")
    }, [eventId])

    return <Home />
}

export default RoomRouteComponent
