import { MaterialIcon } from "@/types/material"
import { motion } from "framer-motion"
import { useState, useEffect } from "react"
import styled from "styled-components"

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: fit-content;
    background: var(--background);
    border-radius: var(--border-radius-ss);
    padding: 0.15rem 0.5rem;
    gap: 0.5rem;

    .hover {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        background: var(--foreground);
        z-index: 2;
    }

    .tab {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.1rem;
        font-size: 0.75rem;
        gap: 0.25rem;
        cursor: pointer;
        opacity: 0.5;
        transition: 0.3s;

        &.active {
            opacity: 1;
        }
    }

    span.material-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        position: relative;
        z-index: 4;
        color: var(--foreground);
    }
`

type CallbackFunction = (element: string | JSX.Element, index: number) => void
type Item = JSX.Element | string

const TabButton = ({
    texts,
    icons,
    onClick,
    layoutId,
    className,
    startIndex,
}: {
    texts: string[]
    icons: MaterialIcon[]
    onClick: CallbackFunction
    layoutId: string
    className?: string
    startIndex?: number
}) => {
    const [activeIndex, setActive] = useState<number>(0)

    const handleClick = (item: Item, index: number) => {
        setActive(index)
        onClick(item, index)
    }

    useEffect(() => {
        if (startIndex === undefined) return
        setActive(startIndex)
    }, [startIndex])

    return (
        <Container className={"ns " + className}>
            {icons.map((icon, index) => {
                const active = activeIndex === index
                return (
                    <motion.div
                        className={active ? "tab active" : "tab"}
                        key={index}
                        onClick={() => handleClick(icon, index)}
                        style={{ zIndex: active ? 3 : 0 }}
                    >
                        {active && (
                            <motion.div className="hover" layoutId={layoutId} />
                        )}
                        <span className="material-icon notranslate">{icon}</span>
                        {texts[index]}
                    </motion.div>
                )
            })}
        </Container>
    )
}

export default TabButton
