import Tooltip from "./Tooltip"
import styled from "styled-components"
import { ApiChatter } from "@types"
import { TippyProps } from "@tippyjs/react"
import { useTranslation } from "react-i18next"

const BadgesWrapper = styled.div`
    height: 1.2rem;
`

const BadgeImage = styled.img`
    margin-right: 2px;
    height: 1.2rem;
    image-rendering: initial;
    pointer-events: all !important;
`

function TwitchBadge({
    user,
    tooltipProps,
}: {
    user: ApiChatter
    tooltipProps: TippyProps
}) {
    if (!user) return null
    const { t } = useTranslation()
    const imagePath = "https://f.feridinha.com/sync/badges"
    let badges = []

    if (user.dev || user.admin)
        badges.push({ file: `staff.png`, tooltip: "dev" })

    if (user.vip) badges.push({ file: `vip.png`, tooltip: "vip" })
    else if (user.mod) badges.push({ file: `moderator.png`, tooltip: "mod" })
    else if (user.broadcaster)
        badges.push({ file: `broadcaster.png`, tooltip: "broadcaster" })

    if (user.supporter)
        badges.push({ file: "supporter.gif", tooltip: "supporter" })
    if (user.dj) badges.push({ file: `dj.png`, tooltip: "dj" })
    if (user.supporter) badges.push({ file: `dj.png`, tooltip: "dj" })
    if (user.betaTester)
        badges.push({ file: `beta_user.png`, tooltip: "beta_user" })

    if (user.sevenTvCosmetics?.badge) {
        badges.push({
            file: `https://cdn.7tv.app/badge/${user.sevenTvCosmetics.badge.id}/3x`,
            tooltip: user.sevenTvCosmetics.badge.tooltip,
            isExtern: true,
        })
    }

    return (
        <BadgesWrapper>
            {badges.map((badge, i) => (
                <Tooltip
                    content={
                        <p style={{ fontWeight: "normal" }}>
                            {badge.isExtern
                                ? badge.tooltip
                                : t(`badges.${badge.tooltip}`)}
                        </p>
                    }
                    key={i + badge.file}
                    {...tooltipProps}
                >
                    <BadgeImage
                        draggable={false}
                        src={
                            badge.isExtern
                                ? badge.file
                                : `${imagePath}/${badge.file}`
                        }
                        alt="Badge"
                    />
                </Tooltip>
            ))}
        </BadgesWrapper>
    )
}

export default TwitchBadge
