import { ComponentProps } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import CommandButton from "./CommandButton"

const PauseQueueWidget = () => {
    const whoPausedQueue = useSelector(
        ({ roomData }: IRootState) => roomData.whoPausedQueue
    )

    const isQueuePaused = Boolean(whoPausedQueue)
    const { t, i18n } = useTranslation()
    
    const prefix = "dashboard.mod_inputs.button_pause."
    const shouldShrink = i18n.language === "en" && isQueuePaused

    const props: ComponentProps<typeof CommandButton> = {
        children: t(prefix + (isQueuePaused ? "resume" : "pause")),
        args: isQueuePaused ? ["return"] : ["pause"],
        icon: isQueuePaused ? "play_arrow" : "pause",
        command: "queue",
        buttonProps: {
            style: {
                fontSize: (shouldShrink) ? ".8rem" : null,
                transition: "font-size 0s"
            },
        },

        type: 2,
    }

    return <CommandButton {...props} />
}

export default PauseQueueWidget
