import { handleRejectedThunk } from "@/utils"
import { createSlice } from "@reduxjs/toolkit"
import { ApiCommunityPlaylist, ApiPlaylist } from "@types"
import { toast } from "react-toastify"
import playlistsReducers from "./reducers"
import {
    addVideoToPlaylist,
    adminDeletePlaylist,
    createPlaylist,
    deletePlaylist,
    fetchCommunityPlaylists,
    fetchCurrentPlaylist,
    fetchLikedPlaylists,
    fetchPlaylists,
    likePlaylist,
    removeVideoFromPlaylist,
    renamePlaylist,
} from "./thunks"

export interface IPlaylistsState {
    isLoading: boolean
    playlists: ApiPlaylist[]
    likedPlaylists: ApiPlaylist[]
    communityPlaylists: ApiCommunityPlaylist[]
    currentPlaylist: ApiPlaylist
}

const initialState: IPlaylistsState = {
    isLoading: true,
    playlists: [],
    communityPlaylists: [],
    likedPlaylists: [],
    currentPlaylist: null,
}

const updatePlaylists = (playlists: ApiPlaylist[], target: ApiPlaylist) => {
    const newPlaylists = playlists.map((playlist) => {
        if (playlist._id === target._id) return target
        return playlist
    })
    return newPlaylists
}

const filterPlaylists = (playlists: ApiPlaylist[], target: ApiPlaylist) => {
    const newPlaylists = playlists.filter((p) => p._id !== target._id)
    return newPlaylists
}

const playlistsSlice = createSlice({
    name: "config",
    initialState,
    reducers: playlistsReducers,
    extraReducers: (builder) => {
        builder.addCase(fetchPlaylists.fulfilled, (state, action) => {
            state.playlists = action.payload.data
            state.isLoading = false
        })

        builder.addCase(createPlaylist.fulfilled, (state, action) => {
            state.playlists = [...state.playlists, action.payload.data]
            toast.success(action.payload.message)
        })

        builder.addCase(deletePlaylist.fulfilled, (state, action) => {
            const target = action.payload.data
            state.playlists = filterPlaylists(state.playlists, target)
            toast.success(action.payload.message)
        })

        builder.addCase(renamePlaylist.fulfilled, (state, action) => {
            const target = action.payload.data
            state.playlists = updatePlaylists(state.playlists, target)
            toast.success(action.payload.message)
        })

        builder.addCase(removeVideoFromPlaylist.fulfilled, (state, action) => {
            const target = action.payload.data
            state.playlists = updatePlaylists(state.playlists, target)
            toast.success(action.payload.message)
        })

        builder.addCase(addVideoToPlaylist.fulfilled, (state, action) => {
            const target = action.payload.data
            state.playlists = updatePlaylists(state.playlists, target)
            toast.success(action.payload.message)
        })

        builder.addCase(fetchCurrentPlaylist.fulfilled, (state, action) => {
            const target = action.payload.data
            state.currentPlaylist = target
        })

        builder.addCase(likePlaylist.fulfilled, (state, action) => {
            if (!action.payload.success) {
                toast.error(action.payload.message)
            } else {
                toast.success(action.payload.message)
            }
        })

        builder.addCase(adminDeletePlaylist.fulfilled, (state, action) => {
            toast.warn(action.payload.message)
        })

        builder.addCase(likePlaylist.rejected, (state, action) => {
            toast.error(action.error.message)
        })

        
        builder.addCase(fetchCommunityPlaylists.fulfilled, (state, action) => {
            const target = action.payload.data
            state.communityPlaylists = target
        })
        
        builder.addCase(fetchLikedPlaylists.fulfilled, (state, action) => {
            const target = action.payload.data
            state.likedPlaylists = target
        })
        
        builder.addCase(adminDeletePlaylist.rejected, handleRejectedThunk)
        builder.addCase(fetchLikedPlaylists.rejected, handleRejectedThunk)
        builder.addCase(fetchCommunityPlaylists.rejected, handleRejectedThunk)
        builder.addCase(fetchCurrentPlaylist.rejected, handleRejectedThunk)
        builder.addCase(fetchPlaylists.rejected, handleRejectedThunk)
        builder.addCase(createPlaylist.rejected, handleRejectedThunk)
        builder.addCase(deletePlaylist.rejected, handleRejectedThunk)
        builder.addCase(addVideoToPlaylist.rejected, handleRejectedThunk)
        builder.addCase(removeVideoFromPlaylist.rejected, handleRejectedThunk)
        builder.addCase(renamePlaylist.rejected, handleRejectedThunk)
    },
})

export const {
    updateCommunityPlaylist,
    updateCurrentPlaylist,
    updateLikedPlaylist,
} = playlistsSlice.actions

export default playlistsSlice.reducer
