import styled from "styled-components"
import ShadowButton from "../Widgets/ShadowButton"

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 3px solid #dee6f3 !important;
    /* outline: 3px solid #dee6f3 !important;; */

    padding: calc(0.45rem) 0.75rem;
    padding-left: 0.5rem;
    color: var(--foreground);
    cursor: pointer;
    width: 100%;
    transition: 0.5s;
    gap: 0.25rem;

    span {
        z-index: 2;
        font-size: 1.5rem;
    }

    p {
        font-size: 1rem;
        z-index: 2;
    }

    &.hover {
        background-color: #dee6f3ae;
        * {
            color: var(--background);
            fill: var(--background);
        }
    }
    svg {
        transition: .5s;
    }
    &.hover svg {
        color:  var(--background-contrast);
        fill: var(--background-contrast);
        z-index: 2;
    }
`

const ContactButton = (props: { borderRadius?: string, defaultCords?: [number, number], defaultHover?: boolean }) => {
    return (
        <ShadowButton
            shadowColor="#ecf4ff"
            style={{
                borderRadius: props.borderRadius || "var(--border-radius-ss)",
            }}
            scale={3}
            onClick={() =>
                window.open(
                    "https://twitter.com/feridinhasync",
                    "_blank"
                )
            }
            defaultCords={props.defaultCords}
            defaultHover={props.defaultHover}

        >
            <Container>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23Z"
                    />
                </svg>
                <p>Twitter</p>
            </Container>
        </ShadowButton>
    )
}

export default ContactButton
