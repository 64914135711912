import { setDanceFloorTooltip } from "@/slices/roomData"
import store from "@/store"
import pixiUtils from "@/utils/pixi"
import { ApiAvatar } from "@types"
import {
    AnimatedSprite,
    BLEND_MODES,
    BaseTexture,
    ColorMatrixFilter,
    Container,
    SCALE_MODES,
    Sprite,
    Texture,
    filters,
} from "pixi.js"
import { getAvatarAnimations } from "./Avatar"
import {
    outlineFilter
} from "./filters"
import { getSpecsOutlineColor } from "./utils"

const STATIC_URL = `https://f.feridinha.com/sync`
let assets: { [key: string]: Texture } = {}

const filterL = new filters.ColorMatrixFilter()
filterL.alpha = 1
filterL.blendMode = BLEND_MODES.ADD

const filterR = new filters.ColorMatrixFilter()
filterR.alpha = 1
filterR.blendMode = BLEND_MODES.ADD

let intervalLHasStarted = false
let intervalRHasStarted = false
let currentValueL = 160
let currentValueR = 0

const movinghead = { width: 80, height: 104 }

export const getScenarioTexture = (id: string) => {
    if (assets[id]) return assets[id]
    const base = BaseTexture.from(`${STATIC_URL}/assets/${id}`)
    base.scaleMode = SCALE_MODES.NEAREST
    return (assets[id] = new Texture(base, null, null, null))
}

const handleFilterUpdate =
    (currentValue: number, filter: ColorMatrixFilter) => () => {
        if (currentValue > 360) currentValue = 0
        filter.hue(currentValue, false)

        currentValue += 5
        requestAnimationFrame(handleFilterUpdate(currentValue, filter))
    }

export const getLightR = (widthRatio: number, isOn: boolean) => {
    if (!intervalRHasStarted) {
        handleFilterUpdate(currentValueR, filterR)()
        intervalRHasStarted = true
    }

    const container = new Container()
    container.y = 93
    container.scale.set(-1, -1)
    container.x = widthRatio * 100

    const spriteLight = new Sprite()
    spriteLight.scale.set(2, -2)
    spriteLight.x = movinghead.width - 32
    spriteLight.y = 30
    spriteLight.texture = getScenarioTexture("light3.png")
    spriteLight.blendMode = BLEND_MODES.LUMINOSITY
    spriteLight.alpha = isOn ? 1 : 0

    spriteLight.filters = [filterR]

    const spriteHead = new Sprite()
    spriteHead.scale.set(0.9, 0.9)
    spriteHead.texture = getScenarioTexture("movinghead-3.png")
    spriteHead.alpha = 1

    container.addChild(spriteLight, spriteHead)
    return container
}

export const getLightL = (widthRatio: number, isOn: boolean) => {
    if (!intervalLHasStarted) {
        handleFilterUpdate(currentValueL, filterL)()
        intervalLHasStarted = true
    }

    const container = new Container()
    container.y = 93
    container.scale.set(1, -1)
    container.x = widthRatio * 0

    const spriteLight = new Sprite()
    spriteLight.scale.set(2, -2)
    spriteLight.x = movinghead.width - 32
    spriteLight.y = 30
    spriteLight.texture = getScenarioTexture("light3.png")
    spriteLight.blendMode = BLEND_MODES.LUMINOSITY
    spriteLight.alpha = isOn ? 1 : 0
    spriteLight.filters = [filterL]

    const spriteHead = new Sprite()
    spriteHead.scale.set(0.9, 0.9)
    spriteHead.texture = getScenarioTexture("movinghead-3.png")
    spriteHead.alpha = 1

    container.addChild(spriteLight, spriteHead)
    return container
}

export const getScenarioLights = (currentAvatar: ApiAvatar, width: number) => {
    const container = new Container()
    const widthRatio = width / 100
    if (width > 700) {
        const lightR = getLightR(widthRatio, Boolean(currentAvatar))
        const lightL = getLightL(widthRatio, Boolean(currentAvatar))
        lightL.zIndex = 1000
        lightR.zIndex = 1000
        container.addChild(lightR)
        container.addChild(lightL)
    }
    // else {
    //     const lightM = getLightM(widthRatio)
    //     container.addChild(lightM)
    // }

    return container
}

const spritesheet = pixiUtils.getBaseTexture("assets/mixer.png")
const frames = pixiUtils.getTextures(
    "assets/mixer.png",
    10915,
    75,
    37,
    spritesheet
)

const mixer = new AnimatedSprite(frames)
let animatedSprite: AnimatedSprite = null
let lastDJ: ApiAvatar = null

export const getMixer = (currentDj: ApiAvatar, widthRatio: number) => {
    mixer.scale.set(0.75, 0.75)
    mixer.anchor.set(0.5, 1)
    mixer.x = widthRatio * 50
    mixer.y = 300
    mixer.animationSpeed = 0.3
    mixer.filters = [outlineFilter]
    mixer.zIndex = -1

    if (!currentDj) {
        mixer.stop()
        return [mixer]
    }
    mixer.play()
    mixer.zIndex = 998

    let specs = currentDj.specs.b_side
        ? currentDj.specs.b_side
        : currentDj.specs

    const backAvatar = { ...currentDj, specs }

    const textures = getAvatarAnimations(backAvatar, ["b"])

    if (
        lastDJ?.specs.spritesheet !== currentDj.specs.spritesheet ||
        !animatedSprite
    ) {
        // Só recria a sprite se for diferente do último DJ
        animatedSprite = new AnimatedSprite(textures.animation_b)
    }

    lastDJ = currentDj

    animatedSprite.y = 300 - backAvatar.specs.height
    animatedSprite.x = widthRatio * 50
    animatedSprite.anchor.set(0.5, 0)
    animatedSprite.animationSpeed = 0.15
    animatedSprite.tint = 0xffffff
    animatedSprite.scale.set(1, 1)
    animatedSprite.play()
    animatedSprite.interactive = true
    animatedSprite.zIndex = 999

    animatedSprite.on("mouseenter", (e) => {
        e.target.cursor = "pointer"

        const [filterTarget, color] = getSpecsOutlineColor(currentDj.specs)

        document.documentElement.style.setProperty(
            "--dancefloor-tooltip-color",
            color
        )

        animatedSprite.filters = [filterTarget]
        mixer.filters = [filterTarget]
        let { y, x } = animatedSprite

        store.dispatch(
            setDanceFloorTooltip({
                x,
                y,
                userId: backAvatar.user.id,
                scale: [1, 1],
                avatar: backAvatar,
            })
        )
    })

    animatedSprite.on("mouseleave", () => {
        animatedSprite.filters = null
        mixer.filters = null
        store.dispatch(setDanceFloorTooltip(null))
    })

    animatedSprite.on("mouseout", () => {
        animatedSprite.filters = null
        mixer.filters = null
        store.dispatch(setDanceFloorTooltip(null))
    })

    

    return [mixer, animatedSprite]
}
