import { motion } from "framer-motion"
import styled from "styled-components"

export const VideoItemContainer = styled<any>(motion.div)`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    gap: 0.25rem;
    background-color: var(--background);
    border-radius: var(--border-radius-s);
    min-height: fit-content !important;

    img {
        align-self: center;
        height: auto;
        width: 100%;
        max-width: 25rem;
        min-width: 3.5rem;
        border-radius: var(--border-radius-s);
        aspect-ratio: 16/9;
    }

    .data {
        display: flex;
        height: 100%;
        justify-content: space-between;
        width: 100%;
    }

    .column-2 {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 0.6rem;
    }

    .details {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.25rem 0.5rem;
        .row {
            display: flex;
            opacity: 0.9;
            font-size: 0.9rem;
        }
    }

    span.material-icon {
        color: var(--foreground);
        display: inline-flex;
        align-items: center;
        margin: 0 0.2rem 0 0;
        vertical-align: bottom;
        font-size: 1rem;
        font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 0, "opsz" 20;
    }

    p {
        display: flex;
        align-items: center;
        font-size: 0.9rem;
        padding: 0 0.5rem;
        opacity: 0.9;
    }

    .actions {
        gap: 0.15rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            cursor: pointer;
            border-radius: var(--border-radius-ss);
            background-color: var(--background-light);
            span {
                font-size: 2rem;
                margin: 0;
                font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 0,
                    "opsz" 48;
            }
            transition: 0.15s;
            :hover {
                background-color: var(--background-2);
                transform: scale(1.1);
            }
        }
    }

    .actions button span.star {
        font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 48;
    }

    @media screen and (max-width: 750px) {
    }
`

export const PageContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem;
    gap: 0.5rem;
    overflow-y: auto;
    input {
        padding: 1rem !important;
        background-color: var(--background);
        border: none;
        border-radius: var(--border-radius-s);
        :focus {
            outline: none !important;
        }
        margin: 0;
        ::placeholder {
            color: var(--foreground);
            opacity: 0.6;
        }
    }

    .options {
        display: flex;
        gap: 1rem;
    }
`

export const VideosContainer = styled(motion.div)`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem 0.25rem;
    justify-content: space-around;
    padding: 1rem 0;
    width: 100%;
    gap: 1rem;

    flex-grow: 1;
`
