import TippyBase, { TippyProps } from "@tippyjs/react"
import styled from "styled-components"
import "tippy.js/dist/tippy.css"
import "tippy.js/animations/scale.css"
import "tippy.js/animations/shift-away.css"
import "tippy.js/dist/backdrop.css"
import { inlinePositioning } from "tippy.js"

const Tippy = styled(TippyBase)`
    background: var(--background-contrast) !important;
    border-radius: 0.25rem;
    border: 1px solid rgba(255, 255, 255, 0.06);
    /* box-shadow: 0px 0px 0px 1px #ffffff50; */
    .tippy-arrow {
        color: var(--foreground-hover) !important;
    }

    &.robert-theme {
        background: var(--foreground) !important;
        border: 1px solid rgba(255, 255, 255, 0.144);
        box-shadow: 0px 0px 0px 1px #ffffff50;
    }

    &.subtooltip {
        background: var(--background-contrast) !important;
        border: 1px solid rgba(255, 255, 255, 0.06);
        pointer-events: all;
    }
`

const Tooltip = (props: TippyProps) => {
    return (
        <Tippy
            plugins={[inlinePositioning]}
            animation="shift-away"
            offset={[0, 2]}
            arrow={false}
            zIndex={999_999_999}
            popperOptions={{ modifiers: [{ name: "flip", enabled: true }] }}
            {...props}
        />
    )
}

export default Tooltip
