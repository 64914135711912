import Folder from "@/components/Widgets/Folder"
import { AnimatePresence, motion } from "framer-motion"
import { memo, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import ConfigPage from "../Config"
import Card from "./Card"
import CommandButton from "./CommandButton"
import Inputs from "./Inputs"
import Overlay from "./Overlay"
import UserHeader from "./UserHeader"
import { Container } from "./styles"
import { updateRoomKey } from "@/slices/roomData"
import { useScreen } from "usehooks-ts"

let interval: any = null

const Dashboard = memo(() => {
    const [position, setPosition] = useState<number>(null)
    const [isUserOpen, setUserOpen] = useState(false)
    const [isCardOpen, setCardOpen] = useState(false)
    const [isModOpen, setModOpen] = useState(false)
    const [isOverlayOpen, setOverlayOpen] = useState(false)
    const [isConfigOpen, setConfigOpen] = useState(false)
    const { checkoutUUID } = useParams()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const avatars = useSelector((state: IRootState) => state.roomData.avatars)
    const chatter = useSelector((state: IRootState) => state.userData.chatter)
    const dancerCardPosition = useSelector(
        (state: IRootState) => state.roomData.storeModalExitPositionDancerCard
    )
    const screenSize = useScreen()

    const getUserAvatar = () =>
        avatars.find((avatar) => avatar.user.id === chatter?.id)

    const avatar = getUserAvatar()

    useEffect(() => {
        if (!avatar) return
        if (avatar.config.column === position) return
        setPosition(avatar.config.column)
    }, [avatar])

    if (!chatter) return

    const handleMeasure = () => {
        const element = document.getElementById("dashboard-card-tilt")
        if (!element) return
        const { left, top } = element.getBoundingClientRect()
        if (
            dancerCardPosition[0] === left &&
            dancerCardPosition[1] === top - 32
        )
            return
        dispatch(
            updateRoomKey({
                key: "storeModalExitPositionDancerCard",
                newValue: [left, top - 32],
            })
        )
    }

    useEffect(() => {
        if (!checkoutUUID  || isCardOpen) return
        setCardOpen(true)
        handleMeasure()
        interval = setInterval(handleMeasure, 2000)
        return () => {
            clearInterval(interval)
        }
    }, [checkoutUUID, screenSize])

    const showOverlayFolder =
        chatter.admin || chatter.broadcaster || chatter.mod

    return (
        <Container layout="position">
            <UserHeader chatter={chatter} />
            {showOverlayFolder && (
                <Folder
                    onClick={() => setOverlayOpen((v) => !v)}
                    isOpen={isOverlayOpen}
                    title={t("dashboard.overlay.title")}
                >
                    <Overlay />
                </Folder>
            )}
            <Folder
                onClick={() => setConfigOpen((v) => !v)}
                isOpen={isConfigOpen}
                title={t("widgets.folder.config")}
            >
                <ConfigPage />
            </Folder>
            <Folder
                onClick={() => setUserOpen((v) => !v)}
                isOpen={isUserOpen}
                title={t("dashboard.user_inputs.title")}
            >
                <Inputs.User />
            </Folder>
            {showOverlayFolder && (
                <Folder
                    onClick={() => setModOpen((v) => !v)}
                    isOpen={isModOpen}
                    title={t("dashboard.mod_inputs.title")}
                >
                    <Inputs.Mod />
                </Folder>
            )}

            <Folder
                onClick={() => setCardOpen((v) => !v)}
                isOpen={isCardOpen}
                title={t("dashboard.avatar.title")}
            >
                <motion.div
                    animate={{
                        height: "fit-content",
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                    }}
                    transition={{ duration: 2 }}
                    id={avatar ? undefined : "dashboard-card-tilt"}
                >
                    <AnimatePresence>
                        {avatar && (
                            <Card
                                setPosition={setPosition}
                                dancer={avatar}
                                position={position}
                            />
                        )}
                        {!avatar && (
                            <CommandButton
                                command="dancer"
                                args={["enter"]}
                                icon="nightlife"
                                showNotification={false}
                                type={2}
                            >
                                {t("dashboard.avatar.add")}
                            </CommandButton>
                        )}
                    </AnimatePresence>
                </motion.div>
            </Folder>
        </Container>
    )
})

export default Dashboard
