//@ts-nocheck

import React from "react"
import Particles from "react-particles"
import type { Engine, IOptions } from "tsparticles-engine"
import { loadFirePreset } from "tsparticles-preset-fire"

class ParticlesContainer extends React.PureComponent<any> {
    // this customizes the component tsParticles installation
    async customInit(engine: Engine): Promise<void> {
        // this adds the preset to tsParticles, you can safely use the
        await loadFirePreset(engine)
    }

    render() {
        const options: Partial<IOptions> = {
            preset: "fire",
            fullScreen: {
                enable: false,
            },
            background: {
                // color: "transparent",
                image: null,
            },
            fpsLimit: 60,
            particles: {
                color: {
                    value: "#ffffff", // set the particle color to white
                },
                number: {
                    value: 100,
                },
                opacity: {
                    value: 0.5,
                },
                stroke: {
                    color: "#ffffff",
                },
                size: {
                    value: 3,
                },
            },
            interactivity: {
                events: {
                    onClick: {
                        enable: false,
                        mode: "bubble",
                    },
                },
            },
        }

        return <Particles options={options} init={this.customInit} />
    }
}

export const FireParticles = () => {
    return <ParticlesContainer></ParticlesContainer>
}
