import Tooltip from "@/components/Tooltip"
import AsyncButton from "@/components/Widgets/AynscButton"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import api from "@/services/api"
import { updateKey } from "@/slices/roomData"
import { removeItemFromShoppingCart } from "@/slices/userData"
import { ThunkDispatch } from "@reduxjs/toolkit"
import { TippyProps } from "@tippyjs/react"
import { ApiStoreItem } from "@types"
import { useEffect, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { getHyperlinksFilename } from "../Support"
import { toast } from "react-toastify"
import { motion } from "framer-motion"

const DropdownContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 25rem;
    max-width: 30rem;
    .line {
        width: 100%;
        height: 1px;
        background-color: #ffffff42;
        margin: 0.5rem 0;
    }

    .items {
        display: flex;
        flex-direction: column;
        gap: 0.15rem;
    }

    .shop-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--foreground-hover);
        color: var(--background-2);
        padding: 0.5rem;
        border-radius: 0.25rem;
        button {
            margin-left: auto;
            padding: 0.1rem !important;
            font-size: 0.5rem;
        }
        gap: 1rem;
        p.name {
            flex-grow: 1;
        }
    }

    .checkout {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.5rem;

        p {
            font-size: 1rem;
        }
    }

    h2 {
        padding: 0.2rem 0 0.5rem 0.5rem;
    }

    .buy-notice {
        display: inline-block;
        padding: 0 0.5rem;
        font-size: 0.85rem;
        a {
            color: var(--color-5);
            text-decoration: underline;
            cursor: pointer;
            width: 2rem;
        }

        span {
            color: var(--color-2);
        }

        input {
            margin-right: 0.5rem;
            display: inline-block;
        }
    }
`

const DropdownContent = ({ items }: { items: ApiStoreItem[] }) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const [hasAgreed, setHasAgreed] = useState(true)
    const [triedToPayWithoutAgree, setTriedToPay] = useState(false)
    const { t, i18n } = useTranslation()
    const priceKey = i18n.language === "pt" ? "price_brl" : "price_usd"
    const prices = items.map((price) => price[priceKey])
    let total = prices.length > 0 ? prices.reduce((a, b) => a + b) : 0

    const handlePay = async () => {
        if (!hasAgreed) {
            setTriedToPay((e) => !e)
            return toast.error(
                t("store_page.shopping_cart.purchase_agreement_error"),
                { position: "bottom-center", autoClose: 30_000 }
            )
        }
        setTriedToPay(false)
        const response = await api.getCheckoutSessionUrl()
        if (typeof response === "string") return console.error(response)
        window.location.href = response.data.url
    }


    const handleRemove = async (itemId: string) => {
        await dispatch(removeItemFromShoppingCart(itemId))
    }

    if (items.length === 0)
        return <p>{t("store_page.shopping_cart.empty_cart")}</p>

    const currencyPrefix = i18n.language === "pt" ? "R$" : "$"

    const filenames = getHyperlinksFilename(i18n.language)

    return (
        <DropdownContainer>
            <h2>{t("store_page.shopping_cart.your_cart_title")}</h2>
            <div className="items">
                {items.map((item, index) => {
                    return (
                        <div key={item._id} className="shop-item">
                            <p className="index">#{index + 1}</p>
                            <p className="name">Avatar - {t(item.name)}</p>
                            <p className="price">
                                {currencyPrefix}
                                {item[priceKey]}
                            </p>
                            <AsyncButton
                                icon="delete"
                                resolver={() => handleRemove(item._id)}
                                tooltip={t(
                                    "store_page.shopping_cart.remove_from_cart"
                                )}
                                tooltipProps={{
                                    className: "subtooltip",
                                }}
                                type={4}
                            />
                        </div>
                    )
                })}
            </div>

            <div className="line" />
            <div className="checkout">
                <p className="total">
                    {t("store_page.shopping_cart.total", { total })}
                </p>
                <AsyncButton
                    icon="payments"
                    resolver={handlePay}
                    tooltipProps={{ interactive: true }}
                    type={"white"}
                >
                    {t("store_page.shopping_cart.pay_with_card")}
                </AsyncButton>
            </div>
            <div className="line" />
            <p className="buy-notice">
                <motion.input
                    animate={
                        triedToPayWithoutAgree && {
                            scale: [1, 2, 1, 2, 1, 2, 1],
                            transition: {
                                duration: 2,
                            },
                        }
                    }
                    type="checkbox"
                    defaultChecked={hasAgreed}
                    onChange={(e) => {
                        setHasAgreed(e.target.checked)
                    }}
                />
                <Trans i18nKey={"store_page.shopping_cart.purchase_agreement"}>
                    <span></span>
                    <a target="_blank" href={filenames[0]} />
                    <a target="_blank" href={filenames[1]} />
                    <a target="_blank" href={filenames[2]} />
                </Trans>
            </p>
        </DropdownContainer>
    )
}

const Dropdown = ({
    children,
    content,
    tippyProps,
}: {
    children: JSX.Element
    content: JSX.Element
    tippyProps: TippyProps
}) => {
    return (
        <Tooltip content={content} trigger="click" {...tippyProps}>
            {children}
        </Tooltip>
    )
}

let interval: any = null
let lastPosition: [number, number] = [0, 0]
export default function ShoppingCart({
    items,
    tippyProps,
    currentActive,
}: {
    items: ApiStoreItem[]
    tippyProps?: TippyProps
    currentActive: ApiStoreItem
}) {
    const [isOpen, setOpen] = useState(false)
    const buttonRef = useRef<HTMLDivElement>()
    const dispatch = useDispatch()
    const [listenerActive, setListenerActive] = useState(false)
    const exitTarget = useSelector(
        (state: IRootState) => state.roomData.storeModalExitTarget
    )
    const { t } = useTranslation()

    useEffect(() => {
        if (!buttonRef.current || !listenerActive) return
        interval = setInterval(() => {
            const { top, left, width, height } =
                buttonRef.current.getBoundingClientRect()
            if (lastPosition[0] === left && lastPosition[1] === top) return
            const x = left + width / 2
            const y = top + height / 2

            dispatch(
                updateKey({
                    key: "storeModalExitPositionButton",
                    newValue: [x, y],
                })
            )
            lastPosition = [left, top]
        }, 1000)

        return () => clearInterval(interval)
    }, [buttonRef.current, listenerActive])

    useEffect(() => {
        if (listenerActive) return
        setTimeout(() => {
            setListenerActive(true)
        }, 100)
    }, [listenerActive])

    const isHighlighted = exitTarget === "button"
    const isFocused = isOpen && !currentActive

    return (
        <Dropdown
            tippyProps={{
                placement: "bottom",
                interactive: true,
                appendTo: document.body,
                maxWidth: "fit-content",
                ...tippyProps,
            }}
            content={<DropdownContent items={items} />}
        >
            <div className="shopping-cart">
                <ButtonIcon
                    children={t("store_page.shopping_cart.button")}
                    icon="shopping_cart"
                    onSubmit={() => setOpen(!isOpen)}
                    wrapperProps={{
                        ref: buttonRef,
                        className: `${isHighlighted && "highlighted"} ${
                            isFocused && "focused"
                        }`,
                    }}
                ></ButtonIcon>
            </div>
        </Dropdown>
    )
}
