import { motion } from "framer-motion"
import styled from "styled-components"

export const ViewPlaylistContainer = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: scroll;
    max-height: 35rem;

    .playlist-active-wrapper {
        position: initial !important;
        height: 100% !important;
        > div {
            padding: 1rem 1rem;
            padding-bottom: .25rem;
        }

        > div h1 {
            font-size: 1.5rem;
        }
    }
`

export const TitleAreaContainer = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transform: translateY(-10px);
    gap: 1rem;
    z-index: 1;
`

export const TitleAreaBottom = styled("div")`
    display: flex;
    flex-wrap: wrap;
    z-index: 3;
    gap: .5rem;
    width: 100%;
    align-items: center;

    button {
        background-color: #ffffff39;
    }

    button,
    button > span.material-icon {
        transition: 0.3s !important;
    }

    button:hover,
    * button:hover {
        background-color: var(--foreground) !important;
        color: var(--background);

        > span.material-icon {
            color: var(--background);
        }
    }
`
