import ButtonIcon from "@/components/Widgets/ButtonIcon"
import { ApiCommunityPlaylist, ApiPlaylist } from "@types"
import { useEffect, useRef, useState } from "react"
import CreatePlaylistBox from "./CreatePlaylistBox"
import { PlaylistCard } from "./PlaylistCard"
import { PlaylistCardsContainer } from "./styles"
import useMeasure, { RectReadOnly } from "react-use-measure"
import styled from "styled-components"
import { useElementSize } from "usehooks-ts"

type PlaylistType = ApiPlaylist | ApiCommunityPlaylist
type PlaylistCardListProps<T> = {
    playlists: T[] | null
    isLoading: boolean
    handleCardClick: (playlist: T) => void
    isExtern?: boolean
    onPlaylistUpdate?: (playlist: T) => void
    pageSize: RectReadOnly
}

const ListWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    min-height: fit-content !important;
    height: fit-content !important;
    max-width: 100%;
    align-self: flex-start;
    width: 100%;

    .scroll-to-left,
    .scroll-to-right {
        position: absolute;
        z-index: 2;
        /* top: 0; */
        min-height: 18.5rem;

        background-color: #ffffff1a;
        backdrop-filter: blur(10px);

        span {
            transform: scale(2);
        }
    }

    .scroll-to-right {
        right: 0.5rem;
    }

    .scroll-to-left {
        left: 0.5rem;
    }
`

const PlaylistCardList = <T extends PlaylistType>({
    playlists,
    isLoading,
    handleCardClick,
    isExtern,
    onPlaylistUpdate,
    pageSize,
}: PlaylistCardListProps<T>) => {
    const [currentFocusIndex, setFocusIndex] = useState(0)
    const [focusDirection, setFocusDirection] = useState<"right" | "left">(null)
    const ref = useRef<HTMLDivElement>(null)
    const [wrapperRef, wrapperSize] = useMeasure()
    const [isOverfloying, setOverflowing] = useState(false)

    const addCurrentFocused = () => {
        const targetId = playlists.at(currentFocusIndex)?._id
        if (!targetId) return
        const target = document.getElementById(`${targetId}-playlist-card`)
        target.classList.add("focused")
        target.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
        })
    }

    const removeLastFocused = () => {
        const directionNumber = focusDirection === "right" ? -1 : 1
        const lastTargetId = playlists.at(
            currentFocusIndex + directionNumber
        )?._id

        if (!lastTargetId) return
        const lastTarget = document.getElementById(
            `${lastTargetId}-playlist-card`
        )
        lastTarget.classList.remove("focused")
    }

    useEffect(() => {
        if (!focusDirection) return
        addCurrentFocused()
        removeLastFocused()
    }, [currentFocusIndex])

    function checkOverflow(elem?: HTMLDivElement) {
        if (!elem) return
        const elemWidth = elem.getBoundingClientRect().width
        const scrollWidth = elem.scrollWidth
        setOverflowing(scrollWidth > elemWidth)
    }

    useEffect(() => {
        if (!ref.current) return
        checkOverflow(ref.current)
    }, [ref.current, wrapperSize, playlists])

    return (
        <ListWrapper>
            <PlaylistCardsContainer
                ref={(elem) => {
                    wrapperRef(elem)
                    ref.current = elem
                }}
                layout="position"
            >
                {playlists &&
                    playlists.map((playlist, index) => (
                        <PlaylistCard
                            playlist={playlist}
                            onClick={() => handleCardClick(playlist)}
                            key={playlist._id}
                            index={index}
                            isExtern={isExtern}
                            onPlaylistUpdate={
                                onPlaylistUpdate && onPlaylistUpdate
                            }
                        />
                    ))}

                {Boolean(!isLoading && !isExtern) && <CreatePlaylistBox />}
            </PlaylistCardsContainer>
            {currentFocusIndex > 0 && isOverfloying && (
                <ButtonIcon
                    onSubmit={() => {
                        setFocusDirection("left")
                        setFocusIndex((d) => d - 1)
                    }}
                    icon="arrow_left"
                    wrapperProps={{ className: "scroll-to-left" }}
                />
            )}
            {Boolean(
                currentFocusIndex < playlists.length - 1 && isOverfloying
            ) && (
                <ButtonIcon
                    onSubmit={() => {
                        setFocusDirection("right")
                        setFocusIndex((d) => d + 1)
                    }}
                    icon="arrow_right"
                    wrapperProps={{
                        className: "scroll-to-right",
                    }}
                />
            )}
        </ListWrapper>
    )
}

export default PlaylistCardList
