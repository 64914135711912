import LikePlaylistButton from "@/components/Customs/LikePlaylistButton"
import WaveTitle from "@/components/Customs/WaveTitle"
import UsernameLabel from "@/components/UsernameLabel"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import { updateCurrentPlaylist } from "@/slices/playlists"
import { ApiPlaylist } from "@types"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { PlaylistCardActive } from "../Playlists/PlaylistCardActive"
import {
    TitleAreaBottom,
    TitleAreaContainer,
    ViewPlaylistContainer,
} from "./styles"
import { Trans, useTranslation } from "react-i18next"

type Props = { playlist: ApiPlaylist }
type TitleProps = { playlist: ApiPlaylist; onScroll: () => void }

const TitleArea = ({ playlist, onScroll }: TitleProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const onPlaylistUpdate = (newPlaylist: ApiPlaylist) => {
        dispatch(updateCurrentPlaylist(newPlaylist))
    }

    const openOnYoutube = () => {
        const baseUrl = "https://www.youtube.com/playlist"
        if (!playlist.youtube_id)
            return toast.error(t("playlists_page.tooltip.not_synchronized_yet"))
        window.open(`${baseUrl}?list=${playlist.youtube_id}`, "_blank")
    }

    return (
        <WaveTitle waveHeight={5}>
            <TitleAreaContainer>
                <h1>
                    <Trans i18nKey={"playlists_page.shortcut_title"}>
                        <UsernameLabel
                            as="span"
                            user={{
                                ...playlist.author,
                                color: "var(--foreground)",
                            }}
                        />
                    </Trans>
                </h1>
                <TitleAreaBottom>
                    <ButtonIcon
                        onSubmit={onScroll}
                        icon="subscriptions"
                        children={t("playlist_card.video_size", { count: playlist.videos.length })}
                    />
                    <LikePlaylistButton
                        onPlaylistUpdate={onPlaylistUpdate}
                        playlist={playlist}
                    />
                    <ButtonIcon
                        onSubmit={openOnYoutube}
                        icon="youtube_activity"
                        children={t("playlists_page.tooltip.open_on_youtube")}
                    />
                </TitleAreaBottom>
            </TitleAreaContainer>
        </WaveTitle>
    )
}

const ViewPlaylistPage = ({ playlist }: Props) => {
    const chatter = useSelector((state: IRootState) => state?.userData?.chatter)
    const handleScroll = () => {
        document
            .getElementById("playlist-active-wrapper")
            .scrollIntoView({ behavior: "smooth" })
    }

    return (
        <ViewPlaylistContainer>
            <TitleArea onScroll={handleScroll} playlist={playlist} />
            <PlaylistCardActive
                wrapperProps={{
                    className: "playlist-active-wrapper",
                    id: "playlist-active-wrapper",
                }}
                playlist={playlist}
                onClick={() => {}}
                isExtern={playlist.author.id !== chatter?.id}
            />
        </ViewPlaylistContainer>
    )
}

export default ViewPlaylistPage
