import Wave from "react-wavify"
import { motion } from "framer-motion"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import styled from "styled-components"
import { Trans, useTranslation } from "react-i18next"

const FooterContainer = styled(motion.div)`
    position: relative;
    width: 100%;
    border-radius: 1rem;
    max-width: 55rem;
    overflow: hidden;
    margin-top: auto;
    min-height: 10rem;
    height: fit-content;
    background: var(--background);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;

    button,
    .title {
        position: relative;
        z-index: 999;
        text-align: center;
    }

    button {
        background-color: #ffffff39;
    }

    button,
    button > span.material-icon {
        transition: 0.3s !important;
    }

    button:hover {
        background-color: var(--foreground);
        color: var(--background);

        > span.material-icon {
            color: var(--background);
        }
    }

    > div {
        position: absolute;
        height: 100%;
    }
`

const hidden = {
    opacity: 0,
    transition: {
        delay: 0,
        duration: 1,
    },
    scale: 0.8,
}

const visible = { opacity: 1, scale: 1 }

const Footer = ({
    setCommunityTab,
    isHidden,
}: {
    setCommunityTab: (_: boolean) => void
    isHidden: boolean
}) => {
    const handleOpenPage = () => {
        setCommunityTab(true)
    }
    const { t } = useTranslation()

    return (
        <FooterContainer
            className="footer"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={isHidden ? hidden : visible}
            transition={{ duration: 0.5, delay: 0.2 }}
            layoutId="robert"
        >
            <h2 className="title">{t("community_playlists.banner")}</h2>
            <Wave fill="url(#gradient)">
                <defs>
                    <linearGradient
                        id="gradient"
                        gradientTransform="rotate(90)"
                    >
                        <stop offset={"20%"} stopColor="#ff80bf"></stop>
                        <stop offset={"150%"} stopColor="#9580ff"></stop>
                    </linearGradient>
                </defs>
            </Wave>
            <ButtonIcon
                onSubmit={handleOpenPage}
                icon="explore"
                children={t("community_playlists.open_button")}
            />
        </FooterContainer>
    )
}

export default Footer
