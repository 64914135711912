import { handleAvatarEvents } from "@/components/DanceFloor/Avatar"
import { setAvatars, setLoadingItems } from "@/slices/roomData"
import { fetchChatter } from "@/slices/userData"
import { sleep } from "@/utils"
import { ThunkDispatch } from "@reduxjs/toolkit"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import * as socketio from "socket.io-client"
import { Socket } from "socket.io-client"
import SocketContext from "."
import TokenContext from "../TokenContext"
import {
    handleAudioData,
    handleAudioDataStatus,
    handleCommandEvent,
    handleCurrent,
    handleNotificationInfo,
    handleQueue,
    handleQueueAdd,
    handleQueueRemove,
    handleReady,
    handleResetQueue,
    handleRoomData,
    handleSeek,
    handleSkip,
    handleTip,
    handleUpdateChatterEvent,
} from "./handlers"
import { handleQueueEvent, removeEventListeners } from "./utils"

let isReconneting = false
let reconnectInterval: any = null

const SocketProvider = ({ children }: { children: JSX.Element }) => {
    const [socket, setSocket] = useState<Socket>(null)
    const { token } = useContext(TokenContext)
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const { roomName } = useParams()
    const videoPlayer = useSelector((state: IRootState) => state.roomData.videoPlayer)
    const [playerEventsDone, setPlayerEventsDone] = useState(false)
    const { chatter, user } = useSelector((state: IRootState) => state.userData)

    const registerPlayerEvents = () => {
        if (!socket) return
        if (!videoPlayer) return
        if (playerEventsDone) return

        socket.on("current", handleCurrent(videoPlayer))
        socket.on("seek", handleSeek(videoPlayer))
        socket.on("skip", handleSkip(videoPlayer))
        socket.on("reset-queue", handleResetQueue(videoPlayer))

        return () => {
            removeEventListeners("current", socket)
            removeEventListeners("seek", socket)
            removeEventListeners("skip", socket)
            removeEventListeners("reset-queue", socket)
        }
    }

    const registerGeneralEvents = () => {
        if (!socket) return console.warn("Socket ainda não foi criado")
        console.info("Registrando eventos")

        handleAvatarEvents(socket)

        socket.on("tip", handleTip)

        socket.on("queue", handleQueue)
        socket.on("queue-add", handleQueueAdd)
        socket.on("queue-remove", handleQueueRemove)

        socket.on("room-data", handleRoomData)
        socket.on("audio-data", handleAudioData)
        socket.on("audio-data-status", handleAudioDataStatus)
        socket.on("info", handleNotificationInfo)
        socket.on("command-event", handleCommandEvent)
        socket.on("update-chatter", handleUpdateChatterEvent)
        socket.on("ready", handleReady(socket, roomName))
        socket.on("invalid-room", () => {
            if (isReconneting) return
            window.location.href = "/"
        })
        socket.emit("enter-room", roomName)

        // socket.io.on("reconnect", registerGeneralEvents)
        return () => {
            removeEventListeners("tip", socket)
            removeEventListeners("queue", socket)
            removeEventListeners("queue-add", socket)
            removeEventListeners("queue-remove", socket)
            removeEventListeners("room-data", socket)
            removeEventListeners("audio-data", socket)
            removeEventListeners("audio-data-status", socket)
            removeEventListeners("ready", socket)
            removeEventListeners("invalid-room", socket)
            removeEventListeners("reconnect", socket)
            removeEventListeners("command-event", socket)
            removeEventListeners("update-chatter", socket)
        }
    }

    const handleReconnect = async (socket: Socket) => {
        console.warn("Conexão perdida! Tentando reconectar")
        socket.on("ready", () => {
            isReconneting = false
            clearInterval(reconnectInterval)
            dispatch(setLoadingItems(["reconnected"]))
            setTimeout(() => dispatch(setLoadingItems([])), 2000)
            dispatch(setAvatars([]))
        })

        await sleep(1000)

        reconnectInterval = setInterval(() => socket.emit("enter-room", roomName), 1000)
    }

    const handlePrivateEvents = () => {
        if (!socket) return
        removeEventListeners("queue-event", socket)
        socket.on("queue-event", handleQueueEvent(chatter, user, dispatch))
    }

    useEffect(handlePrivateEvents, [chatter, user, socket])

    const createSocket = () => {
        if (socket) return console.info("Socket já existe, não é preciso re-criar")
        if (!roomName) return
        if (token) dispatch(fetchChatter())
        console.info("Iniciando conexão websocket")

        const io = socketio.connect(process.env.API_PATH, {
            transports: ["websocket"],
            autoConnect: false,
            extraHeaders: {
                "ngrok-skip-browser-warning": "any",
            },
        })

        io.on("disconnect", async () => {
            if (process.env.SERVER !== "dev") {
                dispatch(setLoadingItems(["reconnect_error"]))
                await sleep(3000)
                window.location.reload()
                return
            }
            isReconneting = true
            dispatch(setLoadingItems(["reconnecting"]))
        })
        io.on("connect", () => {
            if (!isReconneting) return
            handleReconnect(io)
        })

        io.connect()
        setSocket(io)
    }

    useEffect(registerGeneralEvents, [socket])
    useEffect(registerPlayerEvents, [videoPlayer, playerEventsDone])
    useEffect(createSocket, [roomName, socket])

    return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>
}

export default SocketProvider
export const useSocket = () => useContext(SocketContext)
