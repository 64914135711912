import BrFlag from "@/assets/flags/br.svg"
import UsFlag from "@/assets/flags/us.svg"
import EsFlag from "@/assets/flags/es.svg"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import useModal from "@/hooks/useModal"
import { LanguageKeys } from "@/i18n"
import { updateKey } from "@/slices/config"
import store from "@/store"
import i18next from "i18next"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { BetaTag } from "@/components/Widgets/Tags"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    max-width: 27rem;
    align-items: center;

    h1 {
        text-align: center;
    }

    .button-area {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        gap: 0.5rem;
        width: 100%;
    }

    .button-area .flag {
        height: 1.75rem;
        border-radius: 0.2rem;
        box-shadow: 0px 2px 1px 2px #00000047;
    }

    .button-area button {
        padding: 1rem 1.5rem;
        background-color: var(--background-2);
        font-size: 1.2rem;
        border-radius: var(--border-radius-s);
        transition: 0.2s;

        :hover {
            background-color: var(--background-light);
        }
    }

    .button-area button {
        position: relative;
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
        align-items: center;
    }

    button.selected {
        box-shadow: 0px 0px 0px 1px var(--foreground);
    }

    button.selected::after {
        content: "";
        position: absolute;
        right: 1.5rem;
        background-color: var(--foreground);
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
    }

    > button {
        width: fit-content;
    }

    p.warning {
        font-size: 0.65rem;
        text-align: left;
        transition: 0.3s;
    }
`
i18next.on("languageChanged", (language: LanguageKeys) => {
    if (!store.getState().config.language) return
    store.dispatch(updateKey({ key: "language", newValue: language }))
})

const ChooseLanguagePage = ({ defaultLang }: { defaultLang?: LanguageKeys }) => {
    const { setModal } = useModal()
    const configLanguage = useSelector((state: IRootState) => state.config.language)

    const [language, setLanguage] = useState(defaultLang || configLanguage)
    const { i18n } = useTranslation()
    const t = i18n.getFixedT(language, null, null)

    const dispatch = useDispatch()

    const handleLanguageChange = () => {
        i18n.changeLanguage(language)
        dispatch(updateKey({ key: "language", newValue: language }))
    }

    return (
        <Container>
            <h1>{t("choose_your_language")}</h1>
            {/* <p>{i18n.language}</p> */}
            <div className="button-area">
                <button
                    className={"reset-button " + (language === "pt" && "selected")}
                    onClick={() => setLanguage("pt")}
                >
                    {/* <BrFlag style={{ height: "1.75rem" }}/> */}
                    <img className="flag" src="http://purecatamphetamine.github.io/country-flag-icons/3x2/BR.svg" />
                    Português
                </button>
                <button
                    className={"reset-button " + (language === "en" && "selected")}
                    onClick={() => setLanguage("en")}
                >
                    <img className="flag" src="http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" />
                    English
                </button>
                <button
                    className={"reset-button " + (language === "es" && "selected")}
                    onClick={() => setLanguage("es")}
                >
                    <img className="flag" src="http://purecatamphetamine.github.io/country-flag-icons/3x2/ES.svg" />
                    Español
                    <BetaTag forcedLanguage={language} />
                </button>
            </div>
            <ButtonIcon
                icon="save"
                children={t("confirm_language")}
                onSubmit={() => {
                    handleLanguageChange()
                    setModal(null, "minimum")
                }}
                type={2}
            ></ButtonIcon>
        </Container>
    )
}

export default ChooseLanguagePage
