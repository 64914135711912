import { MotionConfig } from "framer-motion"
import { Provider, useSelector } from "react-redux"
import { IconProps, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Modal from "./components/Modal"
import UserCardWindow from "./components/UserCardWindow"
import ModalProvider from "./hooks/ModalContext/Provider"
import TokenProvider from "./hooks/TokenContext/Provider"
import Routes from "./pages/Routes"
import store from "./store"
import { MaterialIcon } from "./types/material"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

const icons: any = {
    success: "check",
    error: "close",
    warning: "warning",
    info: "info",
    default: "info",
}

const colors = {
    success: "var(--toastify-color-success)",
    error: "var(--toastify-color-error)",
    warning: "var(--toastify-color-warning)",
    info: "var(--toastify-color-info)",
    default: "var(--toastify-color-info)",
}

const handleIcon = ({ type }: IconProps) => {
    let icon: MaterialIcon = icons[type]
    return (
        <span
            style={{ color: colors[type] }}
            className="material-icon notranslate"
        >
            {icon}
        </span>
    )
}

const Motion = () => {
    const render_animations = useSelector(
        (state: IRootState) => state.config.render_animations
    )
    const { i18n } = useTranslation()

    useEffect(() => {
        const updateLangAttribute = () => {
            const htmlElement = document.documentElement
            htmlElement.setAttribute("lang", i18n.language)
        }

        // Initial update
        updateLangAttribute()

        // Listen for language changes
        i18n.on("languageChanged", updateLangAttribute)

        // Cleanup listener on component unmount
        return () => {
            i18n.off("languageChanged", updateLangAttribute)
        }
    }, [i18n])


    return (
        <MotionConfig
            reducedMotion={render_animations ?? true ? "never" : "always"}
        >
            <Routes />
            <ToastContainer
                theme="dark"
                position="top-center"
                icon={handleIcon}
                className="toast-external-container"
                pauseOnFocusLoss={false}
            />
            <Modal />
            <UserCardWindow />
            <div
                style={{
                    position: "absolute",
                    right: 15,
                    top: 0,
                    pointerEvents: "all",
                    zIndex: 99,
                }}
            ></div>
        </MotionConfig>
    )
}

function App() {
    return (
        <Provider store={store}>
            <TokenProvider>
                <ModalProvider>
                    <Motion />
                </ModalProvider>
            </TokenProvider>
        </Provider>
    )
}

export default App
