import LikePlaylistButton from "@/components/Customs/LikePlaylistButton"
import { SearchLoading } from "@/components/Loading"
import UsernameLabel from "@/components/UsernameLabel"
import { fadeInOut } from "@/utils/fadeInOut"
import { ApiCommunityPlaylist, ApiPlaylist } from "@types"
import { AnimatePresence, motion } from "framer-motion"
import { ComponentProps, useState } from "react"
import { useTranslation } from "react-i18next"
import { PlaylistActionsTooltip } from "../PlaylistActionsTooltip"
import { PlaylistBottomContainer, PlaylistCardContainer } from "./styles"

const getPlaylistThumbIds = (playlist: { videos: Array<{ id: string }> }) =>
    playlist.videos.slice(0, 4).map((video) => video.id)

type PlaylistCardProps = {
    className?: string
    onClick: (playlist: ApiPlaylist | ApiCommunityPlaylist) => void
    index: number
    isLoading?: boolean
    loadingProps?: ComponentProps<typeof SearchLoading>
    onPlaylistUpdate?: (playlist: ApiPlaylist | ApiCommunityPlaylist) => void
    playlist: ApiCommunityPlaylist | ApiPlaylist
    isExtern?: boolean
}

export const PlaylistCard = ({
    className,
    onClick,
    playlist,
    index,
    isExtern,
    isLoading,
    loadingProps,
    onPlaylistUpdate,
}: PlaylistCardProps) => {
    const videosIds = getPlaylistThumbIds(playlist)
    const [isVisible, setVisible] = useState(false)
    const { t } = useTranslation()

    const handlePlaylistClick = () => {
        setVisible(false)
        onClick(playlist)
    }

    const layoutId = isExtern ? `${playlist._id}` : playlist._id

    return (
        <PlaylistCardContainer
            layoutId={layoutId}
            onClick={handlePlaylistClick}
            className={className}
            {...fadeInOut(index + 1, 0.3)}
            id={`${playlist._id}-playlist-card`}
        >
            <AnimatePresence>
                {isLoading && (
                    <SearchLoading background={true} {...loadingProps} />
                )}
            </AnimatePresence>
            <div className={`thumbs thumbs-${videosIds.length}`}>
                {videosIds.map((id, index) => (
                    <motion.img
                        // layoutId={`thumb-${id}-${index}-${playlist._id}`}
                        key={index}
                        src={`https://i.ytimg.com/vi/${id}/mqdefault.jpg`}
                    />
                ))}
            </div>
            <PlaylistBottomContainer>
                <div className="info">
                    <motion.p>{playlist.name}</motion.p>
                </div>
                <div className="extra-info">
                    <p className="size">
                        {t("playlist_card.video_size", {
                            count: playlist.videos.length,
                        })}
                    </p>
                    {isExtern && (
                        <div className="extern">
                            <span className="light">
                                {t("playlist_card.created_by")}
                            </span>
                            <UsernameLabel user={playlist.author} />
                        </div>
                    )}
                </div>
                <div className="actions">
                    {isExtern && (
                        <LikePlaylistButton
                            onPlaylistUpdate={onPlaylistUpdate}
                            playlist={playlist}
                            type={2}
                        />
                    )}
                    <PlaylistActionsTooltip
                        playlist={playlist}
                        isExtern={isExtern}
                        isVisible={isVisible}
                        setVisible={setVisible}
                    />
                </div>
            </PlaylistBottomContainer>
        </PlaylistCardContainer>
    )
}
