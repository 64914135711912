import useModal from "@/hooks/useModal"
import { TippyProps } from "@tippyjs/react"
import { ApiChatter } from "@types"
import { useTranslation } from "react-i18next"
import styled, { CSSProperties } from "styled-components"
import Tooltip from "./Tooltip"
import { getPaintStyles } from "@/utils/7tv"

interface StyleProps {
    bgColor: string
}

const Text = styled.p<StyleProps>`
    position: relative;
    display: inline-block;
    font-weight: 400;
    cursor: pointer;

    label.underline {
        position: absolute;
        left: 0;
        bottom: .05rem;
        width: 100%;
        min-height: 2px;
        max-height: 2px;
        opacity: 0;
        background: ${(props) => props.bgColor};
    }

    :hover label.underline {
        opacity: .9;
    }

    label:hover &.underline {
        opacity: .9;
    }
`


const UsernameLabel = ({
    user,
    className,
    tooltipProps,
    as,
    style,
    propagateClick,
}: {
    user: ApiChatter
    className?: string
    tooltipProps?: TippyProps
    as?: string
    style?: React.CSSProperties
    propagateClick?: boolean
}) => {
    const { setUserCard } = useModal()
    const { t } = useTranslation()

    const handleClick = (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
        setUserCard(user, [e.clientX, e.clientY])
        if (propagateClick) return
        e.stopPropagation()
    }

    let paintStyles: CSSProperties = getPaintStyles(user)

    return (
        <Tooltip content={t("widgets.tooltips.open_usercard")} placement={"bottom"} {...tooltipProps}>
            {/* 
            // @ts-ignore */}
            <Text
                className={"username " + className}
                style={{ ...style, color: user.color, ...paintStyles }}
                onClick={handleClick}
                bgColor={user.color}
                /* 
                // @ts-ignore */
                as={as}
            >
                {user.name}
                <label
                    style={{
                        ...paintStyles,
                        backgroundClip: "initial",
                        backgroundSize: "initial",
                        WebkitTextFillColor: "initial",
                        filter: "initial",
                    }}
                    className="underline"
                />
            </Text>
        </Tooltip>
    )
}

export default UsernameLabel
