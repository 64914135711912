import { utils } from "pixi.js"
import { getGPUTier, TierResult } from "detect-gpu"

const isWebglSupported = utils.isWebGLSupported()

const checkHardwareAccelerate = async (): Promise<boolean> => {
    const gpuTier: TierResult | false = await getGPUTier().catch(() => false)

    if (!gpuTier) return true

    const isBadGpu = gpuTier.tier === 1
    const isFromGoogle = gpuTier.gpu.includes("google,")

    return !(isBadGpu || isFromGoogle)
}

const gpu = {
    isWebglSupported,
    checkHardwareAccelerate,
}

export default gpu
