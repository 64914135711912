import { MaterialIcon } from "@/types/material"
import { motion } from "framer-motion"
import React, { useRef } from "react"
import styled from "styled-components"
import ButtonIcon, { ButtonIconProps } from "./ButtonIcon"

const Box = styled(motion.div)`
    display: flex;
    width: 100%;
    background-color: var(--background);
    padding: 0.2rem 0.25rem 0.2rem 0.75rem;
    gap: 0.3rem;
    input {
        border: none;
        background: none;
        outline: none;
        padding: 0 !important;
        width: 100%;
        ::placeholder {
            color: var(--foreground);
            opacity: 0.6;
        }
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        background-color: var(--background-light);
        border-radius: var(--border-radius-s);
        padding: 0.35rem;
        transition: 0.3s;
        :hover {
            transform: scale(1.1);
        }

        :active {
            transform: scale(0.9);
        }
    }

    border-radius: var(--border-radius-s);
    span {
        font-size: 1.75rem;
        font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 28;
    }
`

interface TextInputIconProps {
    wrapperProps?: React.ComponentProps<typeof Box>
    onSubmit: (value: string) => void
    inputProps?: React.ComponentProps<"input">
    icon: MaterialIcon
    resetAfterSubmit?: boolean
    buttonChildren?: string | JSX.Element
    hideButton?: boolean
    inputRef?: React.MutableRefObject<HTMLInputElement>
    buttonProps?: Partial<ButtonIconProps>
}

const buttonColors = ["var(--background-light)", "var(--background-2)"]

const TextInputIcon = (props: TextInputIconProps) => {
    const ref = props.inputRef || useRef<HTMLInputElement>(null)
    const handleSelect = (e: React.SyntheticEvent<HTMLInputElement, Event>) => {
        // console.log(e.target)
    }

    const shouldReset =
        props.resetAfterSubmit === undefined || props.resetAfterSubmit

    return (
        <Box {...props.wrapperProps}>
            <input
                {...props.inputProps}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        const value = ref.current.value
                        if (shouldReset) {
                            ref.current.value = ""
                        }
                        props.onSubmit(value)
                    }
                }}
                onSelect={(e) => handleSelect(e)}
                ref={ref}
            ></input>
            {!props.hideButton && (
                <ButtonIcon
                    onSubmit={() => {
                        const value = ref.current.value
                        if (shouldReset) {
                            ref.current.value = ""
                        }
                        props.onSubmit(value)
                    }}
                    colors={buttonColors}
                    icon={props.icon}
                    children={props.buttonChildren}
                    {...props.buttonProps}
                />
            )}
        </Box>
    )
}

export default TextInputIcon
