import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import YouTube from "react-youtube"
import styled from "styled-components"

const PageContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    height: 100%;
    background-color: var(--background-contrast);
    padding: 0.5rem;
    border-radius: var(--border-radius-m);
    overflow-y: auto;

    .section {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0 0.5rem;
        width: 100%;
        .content {
            display: flex;
            flex-direction: column;
            gap: 0.1rem;
            padding: 0.25rem 0.5rem;
            background-color: var(--background-2);
            border-radius: var(--border-radius-s);
            /* max-height: 8.5rem; */
            overflow-y: auto;
        }
        .content p {
            border-radius: var(--border-radius-s);
            padding: 0.2rem 0.5rem;
            transition: 0.1s;
        }
        .content p:hover {
            background: var(--background);
        }

        .content p::before {
            content: "⦁";
            margin-right: 0.25rem;
        }
    }
    .buttons-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.25rem 1rem;
    }

    .bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.5rem;
    }

    .bottom small {
        font-size: 0.9rem;
        text-align: center;
    }

    .banner {
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(318deg, #ff80bf, #9580ff);
        background-size: 150% 150%;
        -webkit-animation: BackgroundMove 5s infinite;
        -moz-animation: BackgroundMove 5s infinite;
        animation: BackgroundMove 5s infinite;
        width: 100%;
        min-height: 7rem !important;
        /* height: 7rem !important; */
        padding: 0.5rem;
        border-radius: var(--border-radius-s);

        span.material-icon {
            font-size: 4rem;
            font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 100, "opsz" 48;
        }

        .title {
            position: relative;
            z-index: 9;
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        p.author {
            position: absolute;
            bottom: 0.25rem;
            right: 0.5rem;

            span {
                margin-right: 0.1rem;
            }
        }
    }

    .tooltip-chat .message-1 .message {
        font-size: 0.75rem;
    }

    .player-wrapper {
        aspect-ratio: 1/1;
        width: 100%;

        > div {
            height: 100%;
        }
    }

    .tab-widgets {
        padding: 0.25rem;
        * {
            font-size: 1.5rem;
        }
        background-color: var(--background-2);

        .tab {
            padding: 0.8rem;
            border-radius: 0.15rem;
            &.active {
                background-color: var(--background-contrast);
            }
        }
    }
`

const idsEn = ["SIv_W1fizJI", "NJEOdm9Pdsk", "QGJV0JrtumM"]
const idsPt = ["SIv_W1fizJI", "NJEOdm9Pdsk", "yfZg_ET49L4"]

const NewsPage = () => {
    const { t, i18n } = useTranslation()
    const user = useSelector((root: IRootState) => root.userData?.user)
    const news: string[] = t("devlogs.news", { returnObjects: true })
    const changes: string[] = t("devlogs.changes", { returnObjects: true })
    const [currentIdIndex, setCurrentIdIndex] = useState(idsEn.length - 1)
    const [hasEnded, setHasEnded] = useState(false)
    const trailers = t("devlogs.trailers", { returnObjects: true }) as string[]

    const idsTarget = i18n.language === "pt" ? idsPt : idsEn
    const currentId = idsTarget[currentIdIndex]

    return (
        <PageContainer>
            {/* <ShadowButton
                shadowColor="#ffffff31"
                style={{
                    borderRadius: "var(--border-radius-ss)",
                    width: "100%",
                }}
                scale={2}
                className="banner"
            >
                <>
                    <div className="title">
                        <span className="material-icon notranslate">
                            campaign
                        </span>
                        <h1>{t("devlogs.title")}</h1>
                    </div>
                    <p className="author">
                        <span>-</span>Feridinha
                    </p>
                </>
            </ShadowButton> */}
            {/* <AnimatePresence> */}
            {/* {!hasEnded && ( */}
            <div className="player-wrapper">
                <YouTube
                    opts={{
                        width: "100%",
                        height: "100%",
                        color: "white",
                        playerVars: {
                            autoplay: 1,
                            mute: 0,
                            controls: 1,
                            volume: 50,
                            vq: "hd720",
                        },
                    }}
                    videoId={currentId}
                    onEnd={() => {
                        setHasEnded(true)
                    }}
                />
            </div>
            {/* // )} */}
            {/* </AnimatePresence> */}
        </PageContainer>
    )
}

export default NewsPage
