import { ApiRoom } from "@types"
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import Tooltip from "../../components/Tooltip"
import { useTranslation } from "react-i18next"
import BrFlag from "@/assets/flags/br.svg?url"
import UsFlag from "@/assets/flags/us.svg?url"
import EsFlag from "@/assets/flags/es.svg?url"

const RoomBox = styled(motion.button)`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1.5rem;
    width: fit-content;
    max-width: 100%;
    overflow: hidden;
    background-color: var(--background);
    border-radius: var(--border-radius-l);
    align-items: center;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease;
    :hover {
        transform: scale(1.05);
    }
    :active {
        transform: scale(1);
    }
    img {
        width: 5rem;
        height: auto;
        aspect-ratio: 1/1;
        z-index: 2;
        border-radius: 0.5rem;
    }

    @media only screen and (max-width: 750px) {
        p.name {
            font-size: 1.5rem !important;
        }
    }

    span#room-created-conffeti {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 3;
    }
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .stats {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        opacity: 0.8;
        gap: 0.75rem;
        flex-wrap: wrap;
        /* background-color: blue; */
        border-radius: var(--border-radius-s);
        span {
            display: flex;
            align-items: center;
            gap: 0.2rem;
            background-color: var(--background-light);
            padding: 0.15rem 0.15rem;
            padding-right: 0.5rem;
            border-radius: var(--border-radius-s);
        }

        span.material-icon {
            font-size: 1.4rem;
            font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 18;
        }
    }
    p.name {
        overflow-x: hidden;
        font-size: 2.25rem;
        text-align: start;
        word-break: break-all;
    }

    .flag {
        display: flex;
        width: fit-content;

        border-radius: var(--border-radius-s);
        background-color: var(--background-light);
        /* background-color: red; */
        padding: 0.1rem 0.5rem;
        img {
            width: auto;
            height: 1.75rem;
        }
        transition: 0.3s;
    }
`

const RoomCard = ({
    room,
    index,
    animationDelay,
    className,
    shouldRenderConffeti,
}: {
    room: ApiRoom
    index: number
    animationDelay: number
    className?: string
    shouldRenderConffeti?: boolean
}) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const animation = {
        initial: {
            opacity: 0,
            y: -20,
        },
        whileInView: {
            opacity: 1,
            y: 0,
            transition: {
                delay: index * animationDelay,
            },
        },
        whileTap: {
            scale: 0.98,
            transition: { ease: "easeIn", duration: 0.1 },
        },
    }

    const { profile_image, display_name, stats, name } = room
    const user_count = stats.users
    const queue_size = stats.videos

    let flag: string = null

    switch (room.language) {
        case "en":
            flag = UsFlag
            break
        case "pt":
            flag = BrFlag
            break
        case "es":
            flag = EsFlag
            break
    }

    return (
        <RoomBox className={className} onClick={() => navigate(`/${name}`)} {...animation} viewport={{ once: false }}>
            {shouldRenderConffeti && <span id="room-created-conffeti"></span>}
            <img src={profile_image} alt="room profile image" />
            <Column>
                <p className="name roomcardname">{display_name}</p>
                <div className="stats no-select">
                    <Tooltip
                        content={t("home.user_count_tooltip", {
                            count: user_count,
                        })}
                        placement="bottom"
                    >
                        <span>
                            <span className="material-icon notranslate">person</span>
                            {user_count}
                        </span>
                    </Tooltip>
                    <Tooltip
                        content={t("home.queue_size_tooltip", {
                            count: queue_size,
                        })}
                        placement="bottom"
                    >
                        <span>
                            <span className="material-icon notranslate">subscriptions</span>
                            {queue_size}
                        </span>
                    </Tooltip>
                    <Tooltip content={t(`home.rooms.language.${room.language}`)} placement="bottom">
                        <div className="flag">
                            <img src={flag}></img>
                        </div>
                    </Tooltip>
                </div>
            </Column>
        </RoomBox>
    )
}

export default RoomCard
