import useModal from "@/hooks/useModal"
import PlaylistsPage from "@/pages/Playlists"
import {
    addVideoToPlaylist,
    removeVideoFromPlaylist,
} from "@/slices/playlists/thunks"
import { MaterialIcon } from "@/types/material"
import { getLoginUrl } from "@/utils"
import { ThunkDispatch } from "@reduxjs/toolkit"
import { ApiPlaylist, ApiPlaylistVideo, ApiSearchVideo, ApiVideo } from "@types"
import { AnimatePresence } from "framer-motion"
import { memo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import styled from "styled-components"
import { CommandLoading } from "./Loading"
import Tooltip from "./Tooltip"
import { AsyncButtonProps } from "./Widgets/AynscButton"
import ButtonIcon from "./Widgets/ButtonIcon"
import { Trans, useTranslation } from "react-i18next"

const TooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.15rem;
    padding: 0.4rem 0.25rem;
    padding-top: 0.25rem;
    max-width: 20rem;
    overflow-x: auto;
    .title {
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }

    .playlist {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        padding: 0.5rem;
        background-color: var(--background-2);
        border-radius: var(--border-radius-ss);
        cursor: pointer;
        transition: 0.2s;
        pointer-events: all;
    }

    .playlist p {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .playlist .loading-container {
        background-color: var(--background-2);
    }

    .playlist input {
        pointer-events: none;
    }

    .playlist:hover {
        background-color: var(--background-light);
    }
`

const PlaylistItem = ({
    playlist,
    video,
    onAdd,
    onRemove,
}: {
    playlist: ApiPlaylist
    video: ApiPlaylistVideo | ApiVideo | ApiSearchVideo
    onAdd: (playlist: ApiPlaylist) => Promise<void>
    onRemove: (playlist: ApiPlaylist) => Promise<void>
}) => {
    const [isLoading, setLoading] = useState(false)
    const ids = playlist.videos.map((i) => i.id)
    const isIn = ids.includes(video.id)

    const handleChange = async (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation()
        e.preventDefault()
        if (isLoading) return
        setLoading(true)
        await (isIn ? onRemove(playlist) : onAdd(playlist))
    }

    return (
        <div className="playlist" key={playlist._id} onClick={handleChange}>
            <input defaultChecked={isIn} type={"checkbox"}></input>
            <p>{playlist.name}</p>
            <CommandLoading
                className="loading-container"
                isLoading={isLoading}
            />
        </div>
    )
}

const OpenPlaylistContainer = styled.p`
    span {
        color: var(--color-5);
        text-decoration: underline;
        cursor: pointer;
        transition: 0.2s;
    }

    span:hover {
        color: #5acae6;
    }
`

const ButtonOpenPlaylist = () => {
    const { setModal } = useModal()
    const handleClick = () => {
        setModal(<PlaylistsPage />, "main")
    }
    const { t } = useTranslation()
    return (
        <OpenPlaylistContainer>
            <Trans
                i18nKey={"widgets.select_playlist_tooltip.you_dont_have_any_playlists"}
            >
                <span onClick={handleClick}></span>
            </Trans>
        </OpenPlaylistContainer>
    )
}

const LoginPlaylist = () => {
    return (
        <OpenPlaylistContainer>
            <Trans
                i18nKey={"widgets.select_playlist_tooltip.login"}
            >
                <span onClick={() => window.open(getLoginUrl(), "_self")}></span>
            </Trans>
        </OpenPlaylistContainer>
    )
}

const SelectPlaylistTooltip = ({
    icon,
    video,
    buttonIconType,
}: {
    icon?: MaterialIcon
    video: ApiPlaylistVideo | ApiVideo | ApiSearchVideo
    buttonIconType?: AsyncButtonProps["type"]
}) => {
    const playlists = useSelector(
        (state: IRootState) => state.playlists.playlists
    )
    const user = useSelector(({ userData }: IRootState) => userData.user)

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const { t } = useTranslation()

    const onAdd = async (playlist: ApiPlaylist) => {
        dispatch(addVideoToPlaylist({ video, playlist }))
    }

    const onRemove = async (playlist: ApiPlaylist) => {
        dispatch(removeVideoFromPlaylist({ video, playlist }))
    }

    const Content = () => {
        if (!user) return <LoginPlaylist />

        return (
            <TooltipContainer>
                <p className="title">
                    {t("widgets.select_playlist_tooltip.title")}:
                </p>
                {playlists.length === 0 && <ButtonOpenPlaylist />}
                <AnimatePresence>
                    {playlists.map((playlist) => (
                        <PlaylistItem
                            playlist={playlist}
                            video={video}
                            key={playlist._id}
                            onAdd={onAdd}
                            onRemove={onRemove}
                        />
                    ))}
                </AnimatePresence>
            </TooltipContainer>
        )
    }

    return (
        <Tooltip
            content={<Content />}
            interactive={true}
            appendTo={document.body}
            animation={"shift-away"}
            
            maxWidth={"20rem"}
            placement="auto-end"
            hideOnClick={false}
            interactiveBorder={10}
        >
            <div>
                <ButtonIcon
                    wrapperProps={{
                        className: "playlist-details-button",
                    }}
                    onSubmit={() => {}}
                    icon="playlist_add"
                    type={buttonIconType || 3}
                />
            </div>
        </Tooltip>
    )
}

export default SelectPlaylistTooltip
