import { SearchLoading } from "@/components/Loading"
import TextInputIcon from "@/components/Widgets/TextInputIcon"
import api from "@/services/api"
import { updateKey } from "@/slices/config"
import { setCommandLogs, updateRoomKey } from "@/slices/roomData"
import store from "@/store"
import { getLoginUrl, roomSelect } from "@/utils"
import { ApiCommandEvent } from "@types"
import { AnimatePresence } from "framer-motion"
import "moment/dist/locale/pt-br"
import { memo, useEffect, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import ScrollableFeed from "react-scrollable-feed"
import { toast } from "react-toastify"
import styled from "styled-components"
import LogItem, { LogItemProps } from "./LogItem"
import List from "react-virtualized/dist/es/List"
import AutoSizer from "react-virtualized/dist/es/AutoSizer"
import CellMeasurer from "react-virtualized/dist/es/CellMeasurer"
import { CellMeasurerCache } from "react-virtualized/dist/es/CellMeasurer"

const Container = styled.div`
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: var(--border-radius-ss);
    /* background-color: var(--background); */
    flex-grow: 1;
    padding: 0.5rem 0.25rem;
    gap: 0.5rem;
    /* overflow: scroll; */
    /* flex-grow: 1; */

    > div:first-child {
        background-color: var(--background);
    }

    > div:last-child {
        border-radius: var(--border-radius-s);
    }

    .input-wrapper {
        position: absolute;
        top: 0.25rem;
        z-index: 2;
        background-color: red;
        width: 100%;
        padding: 0.5rem;
    }

    .input-box button {
        background-color: var(--background-light);
        transition: 0.2s;

        :hover {
            background-color: var(--background-2);
        }
    }

    p.login-required {
        margin: 0 0.5rem;

        a {
            text-decoration: underline;
            color: var(--color-5);
            cursor: pointer;
        }
    }

    .row {
        height: fit-content;
        max-height: fit-content;
    }

    .auto-size-wrapper {
        height: 100%;
        width: 100%;
    }
`

const NoUserWrapper = () => {
    return (
        <Container style={{ maxHeight: "6rem" }}>
            <p className="login-required">
                <Trans i18nKey={"logs_page.login_required"}>
                    <a onClick={() => window.open(getLoginUrl(), "_self")}></a>
                </Trans>
            </p>
        </Container>
    )
}

let killItselfTimeout: any = null

const cache = new CellMeasurerCache({
    fixedWidth: true,
    // minHeight: 100,

    // defaultHeight: 100,
})

const LogsPage = memo(() => {
    const commandLogs = useSelector(roomSelect("commandLogs"))
    const isLoading = useSelector(roomSelect("isLoading"))
    const logsSearchQuery = useSelector(roomSelect("logsSearchQuery"))

    const [fetched, setFetched] = useState(Boolean(commandLogs.length > 0))
    const user = useSelector((state: IRootState) => state.userData.user)
    const { i18n, t } = useTranslation()
    const inputRef = useRef<HTMLInputElement>(null)

    const dispatch = useDispatch()
    const tab_active = useSelector(
        (state: IRootState) => state.config.tab_active
    )
    const ref = useRef<List>(null)

    const [searchLogs, setSearchLogs] = useState<ApiCommandEvent[] | null>(null)
    const currentLogs = searchLogs || commandLogs

    const handleSearch = async (value: string) => {
        if (!value) return setSearchLogs(null)
        setFetched(false)
        const search = await api.searchCommandLogs(value)
        setFetched(true)
        if (!search) return toast.error(search.message)
        cache.clearAll()
        setSearchLogs(search.data)
        // ref.current.scrollToRow(search.data.length)
    }

    const handleFetch = async () => {
        api.fetchCommandLogs()
            .then((res) => {
                if (!res) return toast.error("Erro ao carregar logs da sala")
                cache.clearAll()
                dispatch(setCommandLogs(res.data))
                // ref.current.scrollToRow(res.data.length)
            })
            .finally(() => {
                setFetched(true)
                // scrollToBottom()
            })
    }

    useEffect(() => {
        if (fetched || !user || commandLogs.length > 0 || searchLogs) return
        handleFetch()
    }, [isLoading, fetched, commandLogs])

    useEffect(() => {
        if (tab_active === "logs") return
        clearTimeout(killItselfTimeout)

        killItselfTimeout = setTimeout(() => {
            store.dispatch(
                updateKey({ key: "is_logs_tab_visible", newValue: false })
            )
            store.dispatch(
                updateRoomKey({ key: "logsSearchQuery", newValue: null })
            )
        }, 500)

        return () => clearTimeout(killItselfTimeout)
    }, [tab_active])

    useEffect(() => {
        if(!ref.current) return
        setTimeout(() => {
            ref.current.scrollToRow(currentLogs.length - 1)
        }, 200)
    }, [currentLogs, ref])

    useEffect(() => {
        console.log("inputRef", inputRef.current)
        if (!logsSearchQuery || !inputRef.current) return

        inputRef.current.value = logsSearchQuery
        handleSearch(logsSearchQuery)
        dispatch(updateRoomKey({ key: "logsSearchQuery", newValue: null }))
    }, [inputRef.current, logsSearchQuery])
    if (!user) return <NoUserWrapper />
    const languageKey = i18n.language as keyof ApiCommandEvent["response"]

    return (
        <Container>
            <TextInputIcon
                icon="search"
                onSubmit={handleSearch}
                inputProps={{
                    placeholder: t("logs_page.search_placeholder"),
                    onChange: (e) => {
                        if (e.target.value.length === 0) setSearchLogs(null)
                    },
                }}
                wrapperProps={{ className: "input-box" }}
                resetAfterSubmit={false}
                inputRef={inputRef}
            />
            <div className="auto-size-wrapper">
                <AnimatePresence>
                    {!fetched && (
                        <SearchLoading
                            background={true}
                            message={t("logs_page.loading_logs")}
                        />
                    )}
                </AnimatePresence>
                <AutoSizer>
                    {({ width, height }) => (
                        <List
                            width={width}
                            height={height}
                            rowCount={currentLogs.length}
                            rowHeight={cache.rowHeight}
                            ref={ref}
                            
                            rowRenderer={({ index, key, parent, style }) => {
                                const log = currentLogs[index]
                                const parsedData: LogItemProps = {
                                    command: log.command,
                                    createdAt: log.created_at,
                                    platform: log.platform,
                                    response: log.response[languageKey],
                                    user: log.user,
                                    uuid: log.uuid,
                                    responseType: log.response.type,
                                    args: log.args,
                                    index: log.index,
                                }
                                return (
                                    <CellMeasurer
                                        cache={cache}
                                        columnIndex={0}
                                        key={key}
                                        parent={parent}
                                        rowIndex={index}
                                    >
                                        {({ registerChild, measure }) => (
                                            <div
                                                style={style}
                                                className="row"
                                                ref={registerChild}
                                            >
                                                <LogItem
                                                    language={i18n.language}
                                                    t={t}
                                                    key={log.uuid}
                                                    log={parsedData}
                                                />
                                            </div>
                                        )}
                                    </CellMeasurer>
                                )
                            }}
                        />
                    )}
                </AutoSizer>
            </div>
            {/* <ScrollableFeed>
                <AnimatePresence>
                    {!fetched && (
                        <SearchLoading
                            background={true}
                            message={t("logs_page.loading_logs")}
                        />
                    )}
                </AnimatePresence>
                {currentLogs.map((log) => {
                    const parsedData: LogItemProps = {
                        command: log.command,
                        createdAt: log.created_at,
                        platform: log.platform,
                        response: log.response[languageKey],
                        user: log.user,
                        uuid: log.uuid,
                        responseType: log.response.type,
                        args: log.args,
                    }

                    return (
                        <LogItem
                            language={i18n.language}
                            t={t}
                            key={log.uuid}
                            log={parsedData}
                        />
                    )
                })}
            </ScrollableFeed> */}
        </Container>
    )
})

export default LogsPage
