import { motion } from "framer-motion"

const HelpBox = (props: any) => {
    return (
        <motion.div
            key={props.layoutName}
            layoutId={props.layoutName}
            onClick={() => {
                props.setCurrent(props.layoutName)
            }}
            {...props}
        />
    )
}

export default HelpBox
