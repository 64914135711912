import ButtonIcon from "@/components/Widgets/ButtonIcon"
import { CommandLoading } from "@/components/Loading"
import Tooltip from "@/components/Tooltip"
import { MaterialIcon } from "@/types/material"
import { TippyProps } from "@tippyjs/react"
import { ReactNode, useState } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
    position: relative;
    height: fit-content;
    * {
        pointer-events: all !important;
    }
`

export interface AsyncButtonProps {
    icon?: MaterialIcon
    title?: string
    children?: JSX.Element | string
    args?: string[]
    tooltip?: string
    tooltipPlacement?: TippyProps["placement"]
    tooltipDelay?: TippyProps["delay"]
    tooltipProps?: TippyProps
    colors?: string[]
    type?: 1 | 2 | 3 | 4 | "white"
    showNotification?: boolean
    onComplete?: (success: boolean, response?: any) => void
    resolver: () => Promise<any>
    wrapperProps?: any
    loadingClassName?: string
}

const AsyncButton = ({
    icon,
    title,
    children,
    tooltip,
    tooltipPlacement,
    tooltipDelay,
    colors,
    onComplete,
    tooltipProps = {},
    type,
    resolver,
    wrapperProps,
    loadingClassName
}: AsyncButtonProps) => {
    const [isLoading, setLoading] = useState(false)

    let currentColors = colors?.length > 2 && colors

    const handleExec = async () => {
        setLoading(true)
        resolver()
            .then((data: any) => {
                if (onComplete) onComplete(true, data)
            })
            .catch((e: any) => {
                const message = e.response?.data?.message
                onComplete && onComplete(false, message)
            })
            .finally(() => setLoading(false))
    }

    const target = (
        <Wrapper {...wrapperProps}>
            <CommandLoading className={loadingClassName} isLoading={isLoading} colors={currentColors} />
            <ButtonIcon
                onSubmit={(e) => {
                    e.stopPropagation()
                    handleExec()
                }}
                icon={icon}
                colors={currentColors}
                type={type}
            >
                {title || children}
            </ButtonIcon>
        </Wrapper>
    )

    if (tooltip)
        return (
            <Tooltip
                content={tooltip}
                animation={"shift-away"}
                placement={tooltipPlacement || "auto"}
                delay={tooltipDelay}
                {...tooltipProps}
            >
                {target}
            </Tooltip>
        )
    return target
}

export default AsyncButton
