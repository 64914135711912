import api from "@/services/api"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { ApiCommunityPlaylist, ApiPlaylist, ApiResponse } from "@types"
import { t } from "i18next"

const getError = (rest: string) => t("playlists_page.errors." + rest)

export const fetchPlaylists = createAsyncThunk(
    "playlist/fetchPlaylists",
    async (): Promise<ApiResponse<ApiPlaylist[]>> => {
        const res = await api.fetchPlaylists()
        if (!res || typeof res === "string") {
            // @ts-ignore
            throw new Error(res || getError("owned_fetch"))
        }

        return res as ApiResponse<ApiPlaylist[]>
    }
)

export const createPlaylist = createAsyncThunk(
    "playlist/createPlaylist",
    async (name: string): Promise<ApiResponse<ApiPlaylist>> => {
        const res = await api.createPlaylist(name)
        // @ts-ignore
        if (!res || typeof res === "string") throw new Error(res)
        return res
    }
)

export const deletePlaylist = createAsyncThunk(
    "playlist/deletePlaylist",
    async (playlist: ApiPlaylist): Promise<ApiResponse<ApiPlaylist>> => {
        const res = await api.deletePlaylist(playlist._id)
        if (!res || typeof res === "string")
            // @ts-ignore
            throw new Error(res || getError("delete"))
        return res
    }
)

export const renamePlaylist = createAsyncThunk(
    "playlist/renamePlaylist",
    async ({
        playlist,
        name
    }: {
        playlist: ApiPlaylist
        name: string
    }): Promise<ApiResponse<ApiPlaylist>> => {
        const res = await api.renamePlaylist(playlist._id, name)
        if (!res || typeof res === "string")
            // @ts-ignore
            throw new Error(res || getError("delete"))
        return res
    }
)

type Args = {
    playlist: ApiPlaylist
    video: {
        id: string
    }
}

export const removeVideoFromPlaylist = createAsyncThunk(
    "playlist/removeVideoFromPlaylist",
    async (args: Args): Promise<ApiResponse<ApiPlaylist>> => {
        const { video, playlist } = args
        const url = `https://youtu.be/${video.id}`
        const res = await api.removeFromPlaylist(playlist._id, url)

        if (!res || typeof res === "string")
            // @ts-ignore
            throw new Error(res || getError("remove"))
        return res
    }
)

export const addVideoToPlaylist = createAsyncThunk(
    "playlist/addVideoToPlaylist",
    async (args: Args): Promise<ApiResponse<ApiPlaylist>> => {
        const { video, playlist } = args
        const url = `https://youtu.be/${video.id}`
        const res = await api.addToPlaylist(playlist._id, url)
        if (!res || typeof res === "string")
            // @ts-ignore
            throw new Error(res || getError("add"))
        return res
    }
)

export const fetchCurrentPlaylist = createAsyncThunk(
    "playlist/fetchCurrentPlaylist",
    async (_id: string): Promise<ApiResponse<ApiPlaylist>> => {
        const res = await api.fetchPlaylist(_id)
        if (!res || typeof res === "string")
            throw new Error(
                // @ts-ignore
                res || getError("url_fetch")
            )
        return res
    }
)

export const likePlaylist = createAsyncThunk(
    "playlist/likeCurrentPlaylist",
    async (_id: string): Promise<ApiResponse<ApiPlaylist>> => {
        const res = await api.likePlaylist(_id)
        if (!res || typeof res === "string")
            // @ts-ignore
            throw new Error(res || getError("like"))

        return res
    }
)

export const fetchCommunityPlaylists = createAsyncThunk(
    "playlist/fetchCommunityPlaylists",
    async (): Promise<ApiResponse<ApiCommunityPlaylist[]>> => {
        const res = await api.fetchCommunityPlaylists()
        if (!res || typeof res === "string")
            throw new Error(
                // @ts-ignore
                res || getError("community_fetch")
            )
        return res
    }
)

export const fetchLikedPlaylists = createAsyncThunk(
    "playlist/fetchLikedPlaylists",
    async (): Promise<ApiResponse<ApiPlaylist[]>> => {
        const res = await api.fetchLikedPlaylists()
        if (!res || typeof res === "string")
            // @ts-ignore
            throw new Error(res || getError("liked_fetch"))
        return res
    }
)

export const adminDeletePlaylist = createAsyncThunk(
    "playlist/adminDelete",
    async (id: string): Promise<ApiResponse<ApiPlaylist>> => {
        const res = await api.adminDelete(id)
        if (!res || typeof res === "string")
            // @ts-ignore
            throw new Error(res || getError("delete"))
        return res
    }
)
