import { useGridQueries } from "@/hooks/useGridQueries"
import { useSelector } from "react-redux"
import About from "../pages/About"
import Dashboard from "../pages/Dashboard"
import Stats from "../pages/Stats"
import TwitchLogin from "./Customs/TwitchLogin"
import Queue from "./Queue"
import TwitchChat from "./TwitchChat"
import TabWidget from "./Widgets/TabWidget"
import PreLoginPage from "@/pages/PreLogin"
import { useTranslation } from "react-i18next"
import LogsPage from "@/pages/Logs"
import { IConfigTabId } from "@/slices/config"

const Sidebar = () => {
    const chatter = useSelector((state: IRootState) => state.userData.chatter)
    // const chatter = null
    const queue_in_sidebar = useSelector(
        (state: IRootState) => state.config.queue_in_sidebar
    )
    const is_logs_tab_visible = useSelector(
        (state: IRootState) => state.config.is_logs_tab_visible
    )
    const { queueHidden } = useGridQueries()

    const shouldRenderQueue = queueHidden || queue_in_sidebar
    const { i18n } = useTranslation()
    const t = i18n.getFixedT(null, null, "widgets.sidebar")

    let ids: IConfigTabId[] = ["chat", "about", "stats", "dashboard"]

    let tabs = [
        <TwitchChat />,
        <About backgroundColor={"var(--background-contrast)"} />,
        <Stats />,
        Boolean(chatter) ? <Dashboard /> : <PreLoginPage />,
    ]

    let names = [
        t("chat"),
        t("about"),
        t("status"),
        Boolean(chatter) ? (
            t("dashboard")
        ) : (
            <TwitchLogin className="sidebar-login" />
        ),
    ]

    if (is_logs_tab_visible) {
        tabs.push(<LogsPage />)
        names.push("Logs")
        ids.push("logs")
    }

    if (shouldRenderQueue) {
        tabs.push(<Queue />)
        names.push(t("queue"))
        ids.push("queue")
    }

    return <TabWidget ids={ids} tabs={tabs} names={names} />
}

export default Sidebar
