import "@/App.css"
import Controls from "@/components/Controls"
import DanceFloor from "@/components/DanceFloor"
import Loading from "@/components/Loading"
import ModalMain from "@/components/ModalMain"
import Queue from "@/components/Queue"
import {
    RenderDisabled,
    RenderDisabledHardAcceleration,
    RenderDisabledOpenGl,
} from "@/components/RenderDisabled"
import Sidebar from "@/components/Sidebar"
import VideoPlayer from "@/components/VideoPlayer"
import { useGridQueries } from "@/hooks/useGridQueries"
import gpu from "@/utils/gpu"
import { AnimatePresence, MotionProps, motion } from "framer-motion"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import useModal from "../../hooks/useModal"
import Grid from "./Grid"
import "./Room.css"
import { fadeInOut } from "@/utils/fadeInOut"
import StoreModalWrapper from "../Store/StoreModalWrapper"

const Container = styled.main`
    position: relative;
    display: flex;
    flex-direction: column;
    grid-area: main;
    overflow-y: auto;
    border-radius: var(--border-radius-s);
`

const isWebglSupported = gpu.isWebglSupported

const Room = () => {
    const { mainModal } = useModal()
    const isLoading = useSelector(
        (state: IRootState) => state.roomData.isLoading
    )
    const [errorMessage, setErrorMessage] = useState(null)
    const render_avatars = useSelector(
        (state: IRootState) => state.config.render_avatars
    )
    const queue_in_sidebar = useSelector(
        (state: IRootState) => state.config.queue_in_sidebar
    )

    useEffect(() => {
        if (!isWebglSupported)
            setErrorMessage(
                <RenderDisabledOpenGl
                    className="dance-floor"
                    id="dance-floor"
                />
            )

        const checkGpu = async () => {
            const isBadGPU = await gpu.checkHardwareAccelerate()
            if (!isBadGPU) {
                setErrorMessage(
                    <RenderDisabledHardAcceleration
                        className="dance-floor"
                        id="dance-floor"
                    />
                )
            }
        }
        checkGpu()
    }, [])

    const { queueHidden } = useGridQueries()
    const shouldHideQueue = queueHidden || queue_in_sidebar

    return (
        <div className="App" id="app">
            <Grid>
                <AnimatePresence>{isLoading && <Loading />}</AnimatePresence>

                <Container
                    style={{ overflowY: mainModal ? "hidden" : "auto" }}
                    className="main"
                >
                    <AnimatePresence>
                        {mainModal && <ModalMain>{mainModal}</ModalMain>}
                    </AnimatePresence>
                    <VideoPlayer />
                    <Controls />
                </Container>

                {!errorMessage && render_avatars && <DanceFloor />}
                {!render_avatars && (
                    <RenderDisabled
                        className="dance-floor"
                        id="dance-floor"
                        style={{ height: "300px" }}
                    />
                )}
                {errorMessage && errorMessage}

                {!shouldHideQueue && <Queue isPrimary={true} />}
                <Sidebar />
            </Grid>
            <StoreModalWrapper />
        </div>
    )
}

export default Room
