import { SearchLoading } from "@/components/Loading"
import useModal from "@/hooks/useModal"
import { updateLikedPlaylist } from "@/slices/playlists"
import { fetchLikedPlaylists, fetchPlaylists } from "@/slices/playlists/thunks"
import { ThunkDispatch } from "@reduxjs/toolkit"
import { ApiPlaylist } from "@types"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuidv4 } from "uuid"
import { CommunityTabPageAnimation } from "../CommunityTab"
import CreatePlaylistBox from "./CreatePlaylistBox"
import Footer from "./Footer"
import { PlaylistCard } from "./PlaylistCard"
import { PlaylistCardActive } from "./PlaylistCardActive"
import { PageContainer, PlaylistCardsContainer } from "./styles"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import PlaylistCardList from "./PlaylistCardList"
import useMeasure from "react-use-measure"

const PlaylistsPage = () => {
    const { t } = useTranslation()
    const [current, setCurrent] = useState<ApiPlaylist>(null)
    const playlists = useSelector(
        ({ playlists }: IRootState) => playlists.playlists
    )
    const likedPlaylists = useSelector(
        ({ playlists }: IRootState) => playlists.likedPlaylists
    )

    const isLoading = false
    const [isCommunityTabOpen, setCommunityTab] = useState(false)
    const { addListener, removeListener } = useModal()
    const [currentListener, setCurrentListener] = useState(null)
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const [ref, pageSize] = useMeasure()
    

    useEffect(() => {
        if (!current) return
        const currentId = current._id
        const newCurrent = playlists.find((p) => p._id === currentId)
        setCurrent(newCurrent)
    }, [playlists])

    const onModalClose = (data: any | false, type: string) => {
        if (type !== "main") return true
        if (current || data) {
            setCurrent(null)
            return false
        }
        return true
    }

    useEffect(() => {
        removeListener(currentListener)
        if (!current) return
        const uuid = uuidv4()
        setCurrentListener(uuid)
        addListener(uuid, onModalClose)
    }, [current])

    const handlePlaylistLiked = (target: ApiPlaylist) =>
        dispatch(updateLikedPlaylist(target))

    useEffect(() => {
        dispatch(fetchPlaylists())
        dispatch(fetchLikedPlaylists())
    }, [])


    return (
        <PageContainer transition={{ duration: 1, ease: "linear" }}>
            <motion.h1
                animate={{ y: current ? -50 : 0 }}
                transition={{ delay: 0.15 }}
            >
                {t("playlists_page.title")}
            </motion.h1>
            <p className="label">{t("playlists_page.owned_playlists")}</p>
            <PlaylistCardList
                isLoading={isLoading}
                playlists={playlists}
                handleCardClick={(playlist) => setCurrent(playlist)}
                pageSize={pageSize}

            />
            <p className="label">{t("playlists_page.liked_playlists")}</p>
            <PlaylistCardList
                isLoading={isLoading}
                playlists={likedPlaylists}
                handleCardClick={(playlist) => setCurrent(playlist)}
                isExtern={true}
                onPlaylistUpdate={handlePlaylistLiked}
                pageSize={pageSize}
            />
            <AnimatePresence>
                {isLoading && (
                    <SearchLoading
                        key={"loading"}
                        message={t("playlists_page.loading")}
                    />
                )}

                {current && (
                    <PlaylistCardActive
                        className="active-playlist"
                        onClick={() => setCurrent(null)}
                        playlist={current}
                        key={current._id}
                    />
                )}
            </AnimatePresence>
            <Footer
                isHidden={Boolean(current || isLoading || isCommunityTabOpen)}
                setCommunityTab={setCommunityTab}
            />

            {isCommunityTabOpen && <CommunityTabPageAnimation />}
        </PageContainer>
    )
}

export default PlaylistsPage
