import CommunitPlaylistsButton from "@/components/Customs/CommunityPlaylistsButton"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import useModal from "../../hooks/useModal"
import Help from "../Help"
import NewsPage from "../News"
import PlaylistsPage from "../Playlists"
import VideosListPage from "../SearchVideos"
import StorePage from "../Store"
import CommandButton from "./CommandButton"
import ModInputs from "./ModInputs"
import VoteWidget from "./VoteWidget"
import { useDispatch } from "react-redux"
import { updateKey } from "@/slices/config"
import StoreButton from "@/components/Customs/StoreButton"

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.4rem;
    align-items: flex-start;
    width: 100%;

    .folder-button {
        background-color: var(--background-2);
    }
    .folder-button-add span {
        font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 200, "opsz" 20;
    }

    span.small,
    p.small {
        font-size: 0.75rem;
    }

    &.mods {
        p.title {
            font-size: 1rem;
        }

        .range-wrapper {
            display: flex;
            flex-direction: column;
            padding: 0.5rem 0.5rem 0.4rem 0.75rem;
            background-color: var(--background-2);
            border-radius: var(--border-radius-s);
            width: 100%;
            gap: 0.35rem;
            align-items: flex-start;
        }
    }

    &.mods .row {
        position: relative;
        width: 100%;
        display: flex;
        gap: 0.35rem;
        align-items: center;
        flex-direction: row;

        label.time {
            font-size: 0.9rem;
            min-width: 4rem;
        }

        input {
            background-color: var(--background);
        }
    }

    .line {
        width: 100%;
        height: 1px;
        background: #ffffff14;
    }
`

const InputsUser = () => {
    const { setModal } = useModal()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    return (
        <Container>
            <ButtonIcon
                wrapperProps={{ className: "folder-button folder-button-add" }}
                onSubmit={() => setModal(<VideosListPage />, "main")}
                icon="add"
                type={2}
            >
                {t("dashboard.user_inputs.button_add")}
            </ButtonIcon>
            <CommandButton
                command="quit"
                icon="logout"
                title={t("dashboard.user_inputs.button_quit")}
                type={2}
            />
            <ButtonIcon
                onSubmit={() => setModal(<PlaylistsPage />, "main")}
                icon="video_library"
                wrapperProps={{ className: "folder-button" }}
                type={2}
            >
                {t("dashboard.user_inputs.button_playlists")}
            </ButtonIcon>
            <CommunitPlaylistsButton />
            <VoteWidget />
            <ButtonIcon
                onSubmit={() => setModal(<Help />, "main")}
                icon="help"
                children={t("dashboard.user_inputs.button_help")}
                type={2}
            />
            <ButtonIcon
                onSubmit={() => setModal(<NewsPage />, "minimum")}
                icon="description"
                children={t("dashboard.user_inputs.button_devlogs")}
                type={2}
            />
            <StoreButton />
            <ButtonIcon
                onSubmit={() => {
                    dispatch(
                        updateKey({
                            key: "is_logs_tab_visible",
                            newValue: true,
                        })
                    )
                    dispatch(
                        updateKey({
                            key: "tab_active",
                            newValue: "logs",
                        })
                    )
                }}
                icon="chat"
                children={t("dashboard.user_inputs.button_logs")}
                type={2}
                key={"logs"}
            />
        </Container>
    )
}

const InputsMod = ModInputs

export default { User: InputsUser, Mod: InputsMod }
