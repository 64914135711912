import TipNotification from "@/components/TipNotification"
import { VideoPlayerApi } from "@/components/VideoPlayer"
import {
    addCommandLog,
    addQueueVideo,
    removeQueueVideo,
    setAudioDataEvent,
    setAudioDataStatus,
    setAvatars,
    setConfig,
    setCurrentVideo,
    setLastSeek,
    setOverlayConfig,
    setQueue,
    setQueuePaused,
    setVotes,
} from "@/slices/roomData"
import store from "@/store"
import { sleep } from "@/utils"
import {
    ApiAudioDataEvent,
    ApiAudioDataStatus,
    ApiCommandEvent,
    ApiRoomData,
    ApiTip,
    ApiUpdateChatterQuery,
    ApiVideo,
} from "@types"
import { toast } from "react-toastify"
import { Socket } from "socket.io-client"
import { updateKey as updateUserDataKey } from "@/slices/userData"

const dispatch = store.dispatch

// queue
export const handleQueue = (data: ApiVideo[]) => {
    dispatch(setQueue(data))
}

// queue-add
export const handleQueueAdd = (data: ApiVideo) => {
    dispatch(addQueueVideo(data))
}

// queue-remove
export const handleQueueRemove = (data: string) => {
    dispatch(removeQueueVideo(data))
}

// audio-data
export const handleAudioData = (data: ApiAudioDataEvent) => {
    dispatch(setAudioDataEvent(data))
    if (!data) return
    dispatch(setAudioDataStatus({ for: data.for, status: "done" }))
}

// audio-data-status
export const handleAudioDataStatus = (data: ApiAudioDataStatus) => {
    dispatch(setAudioDataStatus(data))
}

// room-data
export const handleRoomData = (data: ApiRoomData) => {
    dispatch(setVotes(data.votes))
    dispatch(setQueuePaused(data.queueStatus))
    dispatch(setConfig(data.config))
    dispatch(setOverlayConfig(data.overlay_config))
    // dispatch(setAudioDataEvent(data.audioData))
    handleAudioData(data.audioData)
}

// command-event
export const handleCommandEvent = (data: ApiCommandEvent) => {
    dispatch(addCommandLog(data))
}

// info
export const handleNotificationInfo = (data: string) => {
    toast.info(data)
}

export const handleReady = (socket: Socket, roomName: string) => () => {
    console.info("Ready recebido, enviando eventos iniciais")
    socket.emit("get-queue", roomName)
    socket.emit("get-avatars", roomName)
    socket.emit("get-current", roomName)
    socket.emit("get-room-data", roomName)
    document.title = "Sync - " + roomName
}

export const handleTip = (tip: ApiTip) => {
    toast.info(<TipNotification tip={tip} />, {
        position: "bottom-center",
        autoClose: 120000,
        icon: false,
        progressStyle: { background: "#ff5b5b" },
        pauseOnFocusLoss: false,
    })
}

// current
export const handleCurrent = (videoPlayer: VideoPlayerApi) => (data: ApiVideo) => {
    dispatch(setCurrentVideo(data))
    videoPlayer.setVideo(data)
}

// seek
export const handleSeek = (videoPlayer: VideoPlayerApi) => async (data: ApiVideo) => {
    videoPlayer.seekTo(data)
    await sleep(300)
    dispatch(setLastSeek(data))
}

// skip
export const handleSkip = (videoPlayer: VideoPlayerApi) => async () => {
    dispatch(setCurrentVideo(null))
    videoPlayer.skipCurrent()
}

// reset-queue
export const handleResetQueue = (videoPlayer: VideoPlayerApi) => async (data: ApiVideo) => {
    dispatch(setCurrentVideo(null))
    videoPlayer.skipCurrent()
    dispatch(setQueue([]))
}

export const handleVideoPlayerEvents = (socket: Socket, videoPlayer: VideoPlayerApi) => {
    socket.on("current", (data: ApiVideo) => {
        dispatch(setCurrentVideo(data))
        videoPlayer.setVideo(data)
    })
    socket.on("seek", async (video: ApiVideo) => {
        videoPlayer.seekTo(video)
        await sleep(300)
        dispatch(setLastSeek(video))
    })
    socket.on("skip", () => {
        dispatch(setCurrentVideo(null))
        videoPlayer.skipCurrent()
    })
    socket.on("reset-queue", () => {
        dispatch(setCurrentVideo(null))
        videoPlayer.skipCurrent()
        dispatch(setQueue([]))
    })
}

export const handleUpdateChatterEvent = (twitchId: string, query: ApiUpdateChatterQuery) => {
    const state = store.getState()
    const isSelfUpdate = state.userData.user?.id === twitchId
    if (isSelfUpdate) {
        const me = state.userData.chatter
        store.dispatch(updateUserDataKey({ key: "chatter", newValue: { ...me, ...query } }))
    }

    const { queue, avatars, currentVideo } = state.roomData

    const newQueue = queue.map((video) => {
        if (video.user.id !== twitchId) return video
        return { ...video, user: { ...video.user, ...query } }
    })

    const newAvatars = avatars.map((avatar) => {
        if (avatar.user.id !== twitchId) return avatar
        return { ...avatar, user: { ...avatar.user, ...query } }
    })

    const newCurrentVideo = (
        currentVideo
            ? {
                  ...currentVideo,
                  user: { ...currentVideo.user, ...query },
              }
            : null
    ) as ApiVideo

    dispatch(setQueue(newQueue))
    dispatch(setAvatars(newAvatars))
    console.log({ newAvatars })
    dispatch(setCurrentVideo(newCurrentVideo))
}
