import TipNotification from "@/components/TipNotification"
import i18next from "i18next"
import { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { toast } from "react-toastify"

const DonateCallback = ({ children, isRoom }: { children: JSX.Element; isRoom?: boolean }) => {
    const [hasBeenNotified, setNotified] = useState(false)
    useEffect(() => {
        if (hasBeenNotified) return
        setNotified(true)
        const paths = window.location.href.split("/")
        const isError = paths.includes("error")

        setTimeout(() => {
            let location = window.location.pathname.split("donation/")[0]
            if (location.at(-1) === "/") location = location.slice(0, location.length - 1)
            window.history.replaceState(null, null, location)
        }, 1500)

        if (isError) {
            toast.error(
                <Trans i18nKey={"tips.card_payment_page.tooltip_error"}>
                    <a href="/support"></a>
                </Trans>,
                { autoClose: 120000, closeOnClick: false },
            )
            return
        }

        toast.info(
            <TipNotification isHeadless={true} customChildren={i18next.t("tips.card_payment_page.tooltip_success")} />,
            {
                position: "top-center",
                autoClose: 120000,
                icon: false,
                progressStyle: { background: "#ff5b5b" },
                pauseOnFocusLoss: false,
                className: "donation-tip",
            },
        )
    }, [])

    return children
}

export default DonateCallback
