import { motion } from "framer-motion"
import React, { useEffect, useRef } from "react"
import VanillaTilt from "vanilla-tilt"

interface Props extends React.PropsWithoutRef<any> {
    options: {
        reverse?: boolean
        max?: number
        startX?: number
        startY?: number
        perspective?: number
        scale?: number
        speed?: number
        transition?: boolean
        axis?: any
        reset?: boolean
        "reset-to-start"?: boolean
        easing?: string
        glare?: boolean
        "max-glare"?: number
        "glare-prerender"?: boolean
        "mouse-event-element"?: any
        gyroscope?: boolean
        gyroscopeMinAngleX?: number
        gyroscopeMaxAngleX?: number
        gyroscopeMinAngleY?: number
        gyroscopeMaxAngleY?: number
    }
}

function Tilt(props: Props) {
    const { options, ...rest } = props
    const tilt = useRef(null)

    useEffect(() => {
        VanillaTilt.init(tilt.current, options)
    }, [options])

    return <motion.div ref={tilt} {...rest} />
}

export default Tilt
