import { motion, MotionValue, useTransform } from "framer-motion"
import { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import YouTube from "react-youtube"
import styled, { css } from "styled-components"
import ChooseLanguagePage from "../ChooseLanguage"
import useModal from "@/hooks/useModal"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import { CommandLoading } from "@/components/Loading"
import { CreateRoomButton } from "@/pages/CreateRoom"

const Container = styled(motion.div)`
    position: relative;
    @-moz-document url-prefix() {
        scroll-snap-type: y mandatory;
    }
    height: 100%;
    gap: 1rem;
    overflow: auto !important;

    scroll-behavior: smooth;
    h1 {
        font-family: "Space Grotesk", sans-serif;
    }

    section {
        scroll-snap-align: start;
        height: fit-content;
    }

    a.logo {
        position: absolute;
        top: 1rem;
        left: 2rem;
        font-size: 3rem;
        text-decoration: none;
        font-family: "Space Grotesk", sans-serif;
        font-weight: 800;
        color: var(--foreground);
        z-index: 5;
    }

    #presentation {
        overflow: hidden;
        position: relative;
        display: flex;
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;

        h1 {
            position: absolute;
            /* text-transform: uppercase; */
            height: fit-content;
            width: 19ch;
            text-align: left;
            font-size: 3rem;
            left: 20%;
            font-family: "Space Grotesk", sans-serif;
            font-weight: 900;
            color: var(--foreground);
            z-index: 4;
            span {
                position: relative;
                font-family: "Space Grotesk", sans-serif;
                font-weight: 900;
                z-index: 4;
                ::before {
                    position: absolute;
                    content: "";
                    height: 6px;
                    background-color: var(--foreground-hover);
                    width: 100%;
                    bottom: 1px;
                    border-radius: 2px;
                    z-index: -1;
                }
            }
        }

        button.scroll {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            cursor: pointer;
            bottom: 1rem;
            padding: 0.15rem 0.3rem;
            border: none;
            background-color: transparent;
            border-radius: var(--border-radius-l);
            transition: 0.3s;
            z-index: 6;
            span {
                font-size: 3.5rem;
                color: var(--foreground);
                transition: 0.3s;
            }

            :hover {
                background-color: var(--foreground);
                span {
                    color: var(--background);
                }
            }
        }
    }

    #rooms {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 50vh;
        padding: 2rem;
        gap: 3rem;
        background-color: var(--background-2);
        align-items: center;
    }

    #rooms .list {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem;
        height: 100%;
        width: 100%;
    }

    #rooms h1 {
        font-size: 3.5rem;
        font-family: "Space Grotesk", sans-serif;
        font-weight: 800;
        color: var(--foreground);
    }

    #rooms .order-by {
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        background-color: var(--background);
        padding: 0.5rem;
        border-radius: var(--border-radius-s);
        font-size: .9rem;
    }

    #rooms .title-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;
    }

    @media (max-width: 900px) {
        #presentation h1 {
            position: initial;
            padding: 1.5rem;
            max-width: 100%;
        }
    }

    #footer {
        min-height: fit-content;
        .about {
            min-height: 50vh;
            height: fit-content;
        }

        h1 {
            font-size: 3rem;
        }
    }

    @media only screen and (max-width: 750px) {
        h1 {
            font-size: 2.5rem !important;
        }
        #rooms h1 {
            font-size: 1.75rem !important;
        }

        #rooms .order-by {
            position: static;
            font-size: .9rem;
        }

        #footer h1 {
            font-size: 1.75rem !important;
        }
    }

    .page-view {
        position: absolute;
        display: flex;
        justify-content: center;
        aspect-ratio: 16/9;
        height: 50%;
        left: 40%;
        z-index: 3;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
        }
        .player-wrapper {
            /* position: relative; */
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            height: 60%;
            aspect-ratio: 16.4/9;
            border-radius: 0.24rem;
            backdrop-filter: blur(15px);
            background-color: rgb(30, 35, 49, 0.5);
        }

        .player-wrapper > div {
            position: absolute;
            width: 100%;
            height: 100%;
            > iframe {
                width: 100%;
                height: 100%;
            }
        }

        .player-wrapper .play {
            /* position: static; */

            color: rgb(95, 104, 124, 0.25);
            font-size: 7rem;
            font-family: "Material Symbols Rounded";
            font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 0, "opsz" 50;
            cursor: pointer;
            width: fit-content;
            height: fit-content;
        }
    }
`

export const ScrollButton = () => (
    <motion.button
        className="scroll"
        onClick={() => document.getElementById("rooms").scrollIntoView()}
        animate={{ y: [-40, 0, -40], opacity: 1 }}
        whileHover={{
            y: -20,
            transition: { duration: 0.1 },
        }}
        exit={{
            opacity: 0,
            transition: { duration: 0.1, repeat: 0 },
        }}
        transition={{ repeat: Infinity }}
    >
        <span className="material-icon notranslate scroll-icon">arrow_downward</span>
    </motion.button>
)

export const SyncImage = ({
    scrollYProgress,
    setIgnorePointerEvents,
}: {
    scrollYProgress: MotionValue<number>
    setIgnorePointerEvents: (d: boolean) => void
}) => {
    const x = useTransform(scrollYProgress, [0, 2], ["0%", "-27%"])
    const y = useTransform(scrollYProgress, [0, 2], ["0%", "120%"])
    const scale = useTransform(scrollYProgress, [0, 100], [1, 20])
    const [count, setCount] = useState(0)

    return (
        <motion.div
            className="page-view no-select"
            initial={{
                x: 1000,
                skewX: "0deg",
                skewY: "0deg",
            }}
            animate={{
                x: 0,
                skewX: "-17deg",
                skewY: "10deg",
            }}
            style={{ translateY: y, scale, translateX: x }}
            transition={{
                ease: "easeOut",
                duration: 1,
            }}
        >
            <motion.img
                // src="https://f.feridinha.com/sync/assets/sync.svg"
                src="https://f.feridinha.com/sync/assets/sync-new.svg"
            ></motion.img>
            <div className="player-wrapper">
                {count >= 4 && (
                    <YouTube
                        videoId="d1YBv2mWll0"
                        opts={{
                            playerVars: { autoplay: 1, autohide: 0, mute: 0 },
                        }}
                        onReady={(e: YT.PlayerEvent | YT.OnStateChangeEvent) => {
                            e.target.setVolume(20)
                        }}
                        onPlay={() => setIgnorePointerEvents(true)}
                        onPause={() => setIgnorePointerEvents(false)}
                        loading="lazy"
                    ></YouTube>
                )}
                {count < 4 && (
                    <motion.div
                        className="material-icon notranslate play"
                        onClick={() => setCount((d) => d + 1)}
                        whileHover={{ scale: 1.15 + count * 0.4 }}
                        whileTap={{ scale: 0.9 + count * 0.4 }}
                        animate={{ rotateZ: count * 120 }}
                    >
                        play_arrow
                    </motion.div>
                )}
            </div>
        </motion.div>
    )
}

export const SyncTitle = (props: { ignorePointsEvents: boolean }) => {
    return (
        <motion.h1
            style={props.ignorePointsEvents && { pointerEvents: "none" }}
            initial={{ opacity: 0, y: -100 }}
            whileInView={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{
                type: "easeIn",
                duration: 1,
            }}
        >
            <Trans i18nKey="home.syncingVideos">
                <span></span>
                <span></span>
            </Trans>
        </motion.h1>
    )
}

const NavbarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    position: absolute !important;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 2;
    width: fit-content;
`

export const NavbarArea = () => {
    const { t } = useTranslation()
    const { setModal } = useModal()

    return (
        <NavbarWrapper>
            <ButtonIcon
                onSubmit={() =>
                    setModal(<ChooseLanguagePage />, "minimum", {
                        style: { maxWidth: "27rem" },
                        hideCloseButton: true,
                    })
                }
                icon="translate"
                children={t("quick_actions.choose_language_short")}
                // type={3}
                type={"white"}
                key={"choose_language"}
            />
            <CreateRoomButton />
        </NavbarWrapper>
    )
}

const LoadRoomsErrorContainer = styled(motion.div)`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    /* background-color: red; */
    span {
        max-width: fit-content;
        text-align: center;
        font-size: 2rem;
    }

    span.main-icon {
        font-size: 4rem;
    }

    .loading {
        font-size: 5rem;
    }
`

export const LoadRooms = ({ onReload, isLoading }: { onReload: () => void; isLoading: boolean }) => {
    const { t } = useTranslation()

    return (
        <LoadRoomsErrorContainer>
            <span className="material-icon notranslate main-icon">error</span>
            <h3>{t("home.error.title")}</h3>
            <ButtonIcon type={4} onSubmit={onReload} icon="restart_alt" children={t("home.error.try_again")} />
            <CommandLoading customHeight={50} className="loading" isLoading={isLoading}></CommandLoading>
        </LoadRoomsErrorContainer>
    )
}

export default Container
