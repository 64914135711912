import { useSocket } from "@/hooks/SocketContext/Provider"
import { useGridQueries } from "@/hooks/useGridQueries"
import useModal from "@/hooks/useModal"
import ChooseLanguagePage from "@/pages/ChooseLanguage"
import NewsPage from "@/pages/News"
import { ButtonType, processButtons } from "@/pages/QuickActions"
import { TippyProps } from "@tippyjs/react"
import { ApiVideo } from "@types"
import { memo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import TooltipBase from "../Tooltip"
import TwitchBadge from "../TwitchBadge"
import UsernameLabel from "../UsernameLabel"
import ButtonIcon from "../Widgets/ButtonIcon"
import "./Controls.css"
import StorePage from "@/pages/Store"
import StoreButton from "@/components/Customs/StoreButton"

const Tooltip = (props: TippyProps) => {
    return <TooltipBase placement={"bottom"} offset={[0, 6]} {...props} />
}

const selectVideo = (state: IRootState) => {
    if (!state.roomData.currentVideo) return null
    return {
        ...state.roomData.currentVideo,
        time: undefined,
    } as ApiVideo
}

const Controls = memo(
    ({ forcedButtons, forcedHideText }: { forcedButtons?: ButtonType["id"][]; forcedHideText?: boolean }) => {
        const video = useSelector(selectVideo)
        const hide_title = useSelector((state: IRootState) => state.config.hide_title)
        const { chatter } = useSelector((state: IRootState) => state.userData)
        const { setModal } = useModal()
        const { socket } = useSocket()
        const { mobileSize } = useGridQueries()

        const quickActionsButtons = processButtons(forcedButtons)
        const { t } = useTranslation()

        return (
            <div className="controls">
                {!hide_title && !forcedHideText && (
                    <span className="title">
                        {video && (
                            <Tooltip content={t("controls.playing_now")}>
                                <span className="material-icon notranslate">play_arrow</span>
                            </Tooltip>
                        )}

                        {video ? video.title : ""}
                    </span>
                )}

                <div className="column">
                    {!forcedHideText && (
                        <span className="video-author">
                            {video && (
                                <Tooltip content={t("controls.added_by")}>
                                    <span className="material-icon notranslate">album</span>
                                </Tooltip>
                            )}
                            <TwitchBadge
                                tooltipProps={{
                                    placement: "bottom",
                                    interactive: true,
                                }}
                                user={video?.user}
                            />
                            {video?.user && <UsernameLabel user={video.user} tooltipProps={{ placement: "bottom" }} />}
                        </span>
                    )}
                    <div className="buttons">
                        {!chatter && (
                            <>
                                <ButtonIcon
                                    onSubmit={() => socket.emit("get-current")}
                                    icon="sync"
                                    children={"Sync"}
                                    type={1}
                                />
                                <ButtonIcon
                                    onSubmit={() => setModal(<NewsPage />, "minimum")}
                                    icon="description"
                                    children={"Devlogs"}
                                    type={1}
                                />
                                <ButtonIcon
                                    onSubmit={() =>
                                        setModal(<ChooseLanguagePage />, "minimum", {
                                            style: { maxWidth: "27rem" },
                                            hideCloseButton: true,
                                        })
                                    }
                                    icon="translate"
                                    children={t("quick_actions.choose_language_short")}
                                    type={1}
                                    key={"choose_language"}
                                />
                                <StoreButton forceText={true} forcedText={t("store_page.button")} key={"store-button"} />
                            </>
                        )}
                        {chatter && quickActionsButtons}

                        {mobileSize && (
                            <ButtonIcon
                                onSubmit={() => {
                                    const elem = document.documentElement
                                    if (elem.requestFullscreen) {
                                        elem.requestFullscreen().catch(() => {})
                                    }
                                    if (document.fullscreenEnabled) return document.exitFullscreen().catch(() => {})
                                }}
                                icon="open_in_full"
                                children={"Fullscreen"}
                                type={1}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    },
)

export default Controls
