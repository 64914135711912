import {
    ApiCheckoutResponse,
    ApiCommandEvent,
    ApiCommunityPlaylist,
    ApiPlaylist,
    ApiResponse,
    ApiRoom,
    ApiRoomMeResponse,
    ApiRoomWithScore,
    ApiSearchResult,
    ApiStoreItem,
    ApiTipsResponse,
    ApiUser,
} from "@types"
import axios from "axios"
import { toast } from "react-toastify"
import storage from "../utils/storage"
import i18next from "i18next"
import store from "@/store"

const token = storage.get("sync_token")
const apiBase = axios.create({
    baseURL: process.env.API_PATH,
    headers: { "ngrok-skip-browser-warning": "any", Authorization: token },
    params: {
        lng: "en",
    },
})

const getRoom = () => window.location.pathname.split("/")[1]

export interface CommonResponse {
    success: boolean
    message: string
}

type ErrorMessage = string

type ReturnType<T> = Promise<ApiResponse<T>>

const getErrorMessage = (e: any): string => {
    const responseData = e.response?.data || "Ocorreu um erro desconhecido"
    switch (responseData?.code) {
        case "TokenExpiredError":
            storage.remove("sync_token")
            toast.error(responseData)
            return
    }

    return responseData?.message
}

const fetchRooms = async (): ReturnType<ApiRoomWithScore[]> =>
    apiBase
        .get("/room/list", null)
        .then((r) => r.data)
        .catch(getErrorMessage)

const fetchTokenData = async (): ReturnType<ApiUser> =>
    apiBase
        .post("/auth/validate", null)
        .then((r) => r.data)
        .catch(getErrorMessage)

const fetchChatter = async (): ReturnType<ApiRoomMeResponse> =>
    apiBase
        .get(`/room/me?room=${getRoom()}`)
        .then((r) => r.data)
        .catch(getErrorMessage)

const addVideo = async (url: string): Promise<CommonResponse> =>
    apiBase
        .post(`/videos/add?room=${getRoom()}`, { url })
        .then((r) => r.data)
        .catch(getErrorMessage)

const searchVideo = async (title: string): ReturnType<ApiSearchResult> => {
    return apiBase
        .post(`/videos/search?room=${getRoom()}`, { title })
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const execCommand = async (command: string, args: string[] = []): ReturnType<undefined> => {
    return apiBase
        .post(`/command/${command}?room=${getRoom()}`, { args })
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const fetchPlaylists = async (): ReturnType<ApiPlaylist[] | string> => {
    return apiBase
        .get(`/playlist/mine`)
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const createPlaylist = async (name: string): ReturnType<ApiPlaylist> => {
    return apiBase
        .post(`/playlist/create`, { name })
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const deletePlaylist = async (_id: string): ReturnType<ApiPlaylist> => {
    return apiBase
        .post(`/playlist/${_id}/delete`, null)
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const renamePlaylist = async (_id: string, name: string): ReturnType<ApiPlaylist> => {
    return apiBase
        .post(`/playlist/${_id}/rename`, { name })
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const addToPlaylist = async (playlistID: string, url: string): ReturnType<ApiPlaylist> => {
    return apiBase
        .post(`/playlist/${playlistID}/add`, { url })
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const removeFromPlaylist = async (playlistID: string, url: string): ReturnType<ApiPlaylist> => {
    return apiBase
        .post(`/playlist/${playlistID}/remove`, { url })
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const fetchCommunityPlaylists = async (): ReturnType<ApiCommunityPlaylist[]> => {
    return apiBase
        .get(`/playlist/community`)
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const fetchLikedPlaylists = async (): ReturnType<ApiPlaylist[]> => {
    return apiBase
        .get(`/playlist/liked`)
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const fetchPlaylist = async (playlistID: string): ReturnType<ApiPlaylist> => {
    return apiBase
        .get(`/playlist/${playlistID}`)
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const likePlaylist = async (playlistID: string): ReturnType<ApiPlaylist> => {
    return apiBase
        .post(`/playlist/${playlistID}/like`)
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const fetchCommandLogs = async (): ReturnType<ApiCommandEvent[]> =>
    apiBase
        .get(`/room/command-logs?room=${getRoom()}`)
        .then((r) => r.data)
        .catch(getErrorMessage)

const fetchTipsList = async (): ReturnType<ApiTipsResponse> =>
    apiBase
        .get(`/tips/list`)
        .then((r) => r.data)
        .catch(getErrorMessage)

const searchPlaylists = async (query: string): ReturnType<ApiPlaylist[]> =>
    apiBase
        .post(`/playlist/search`, { query })
        .then((r) => r.data)
        .catch(getErrorMessage)

const onResponse = (res: CommonResponse | ApiResponse<ApiPlaylist>) => {
    if (res.success) {
        toast.success(res.message)
    } else {
        toast.error(res.message)
    }
}

const fetchAdminData = async (): ReturnType<ApiCommandEvent[]> =>
    apiBase
        .get(`/room/admin`)
        .then((r) => r.data)
        .catch(getErrorMessage)

const saveUserLanguage = async (language: ApiUser["config"]["language"]) => {
    const response = (await execCommand("language", [language])) as any
    if (!response.success) {
        return toast.error(response.message)
    }
    toast.success(response.message)
}

const fetchStoreItems = async (): ReturnType<ApiStoreItem[]> => {
    return apiBase
        .get("/store/items")
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const fetchShoppingCart = async (): ReturnType<ApiStoreItem[]> => {
    return apiBase
        .get("/store/shoppingCart")
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const addItemToShoppingCart = async (itemId: string): ReturnType<ApiStoreItem[]> => {
    return apiBase
        .post("/store/shoppingCart/add", { id: itemId })
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const removeItemFromShoppingCart = async (itemId: string): ReturnType<ApiStoreItem[]> => {
    return apiBase
        .post("/store/shoppingCart/remove", { id: itemId })
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const getSpotifyRedirectUrl = async (): ReturnType<string> => {
    return apiBase
        .post(`/spotify/redirect?room=${getRoom()}`, null, {
            withCredentials: true,
        })
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const searchCommandLogs = async (query: string): ReturnType<ApiCommandEvent[]> => {
    return apiBase
        .post(`/room/command-logs/search?room=${getRoom()}`, { query })
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const getCheckoutSessionUrl = async (): ReturnType<{ url: string }> => {
    return apiBase
        .post(`/store/shoppingCart/checkout?room=${getRoom()}`)
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const handleCheckoutCallback = async (uuid: string): ReturnType<ApiCheckoutResponse> => {
    return apiBase
        .post(`/store/callback/${uuid}`)
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const adminDelete = async (playlistID: string): ReturnType<ApiPlaylist> => {
    return apiBase
        .post(`/playlist/${playlistID}/__adminDelete?room=${getRoom()}`)
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const redeemFormandos = async (): ReturnType<null> => {
    return apiBase
        .post(`/store/formandos?room=${getRoom()}`)
        .then((r) => r.data)
        .catch((e) => e.response?.data)
}

const sendCreateRoomRequest = async (roomName: string): ReturnType<{ eventId: string }> => {
    return apiBase
        .post(`/room/create`, { roomName })
        .then((r) => r.data)
        .catch((e) => e.response?.data)
}

const createStripeDonation = async ({
    amount,
    roomName,
}: {
    amount: number
    roomName?: string
}): Promise<ApiResponse<{ url: string }> | string> => {
    return apiBase
        .post(`/tips/stripe`, { roomName, amount })
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const updateSevenTvCache = (): ReturnType<null> => {
    return apiBase
        .post(`/room/me/update`)
        .then((r) => r.data)
        .catch(getErrorMessage)
}

const api = {
    fetchTokenData,
    addVideo,
    searchVideo,
    fetchChatter,
    fetchRooms,
    execCommand,
    fetchPlaylists,
    createPlaylist,
    deletePlaylist,
    addToPlaylist,
    removeFromPlaylist,
    onResponse,
    fetchCommunityPlaylists,
    fetchPlaylist,
    fetchCommandLogs,
    likePlaylist,
    fetchAdminData,
    fetchLikedPlaylists,
    fetchTipsList,
    searchPlaylists,
    fetchStoreItems,
    addItemToShoppingCart,
    removeItemFromShoppingCart,
    fetchShoppingCart,
    getSpotifyRedirectUrl,
    renamePlaylist,
    searchCommandLogs,
    handleCheckoutCallback,
    getCheckoutSessionUrl,
    adminDelete,
    redeemFormandos,
    sendCreateRoomRequest,
    createStripeDonation,
    updateSevenTvCache,
}

export default api

i18next.on("languageChanged", async (data) => {
    apiBase.defaults.params.lng = data
    const state = store.getState()
    const user = state?.userData?.user

    if (!user || !token) return console.log("Não tem, retornando")
    saveUserLanguage(data as any)
})
