import ButtonIcon from "@/components/Widgets/ButtonIcon"
import { SearchLoading } from "@/components/Loading"
import SelectPlaylistTooltip from "@/components/SelectPlaylistTooltip"
import Tooltip from "@/components/Tooltip"
import { VideoDetailsFlex } from "@/components/VideoDetails"
import api from "@/services/api"
import { MaterialIcon } from "@/types/material"
import { fadeInOut } from "@/utils/fadeInOut"
import { ApiSearchVideo } from "@types"
import { AnimatePresence, motion, MotionProps } from "framer-motion"
import { MouseEventHandler, useState } from "react"
import { toast } from "react-toastify"
import { VideoItemContainer } from "./styles"
import { useTranslation } from "react-i18next"

const VideoData = ({
    video,
    handleAddVideoQueue,
    t,
}: {
    video: ApiSearchVideo
    handleAddVideoQueue: MouseEventHandler<HTMLButtonElement>
    t: (key: string) => string
}) => {
    return (
        <motion.div className="data" layout="position">
            <div className="column-2">
                <motion.p layout="position">{video.title}</motion.p>
                <VideoDetailsFlex className="details" video={video} />
            </div>
            <div className="column-1 actions">
                <Tooltip
                    content={t("search_videos.video_item.add_to_queue")}
                    animation="shift-away"
                    placement="auto-start"
                >
                    <div>
                        <ButtonIcon
                            icon="add"
                            type={1}
                            onSubmit={handleAddVideoQueue}
                        />
                    </div>
                </Tooltip>
                <SelectPlaylistTooltip
                    buttonIconType={1}
                    video={video}
                    icon="add"
                />
            </div>
        </motion.div>
    )
}

const VideoItem = ({
    video,
    index,
    isListView,
}: {
    video: ApiSearchVideo
    index: number
    isListView: boolean
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState(null)
    const [loadingIcon, setLoadingIcon] = useState<MaterialIcon>(null)
    const thumbnail = `https://i.ytimg.com/vi/${video.id}/mqdefault.jpg`
    const motionProps: MotionProps = fadeInOut(index * 1.2)
    const { t } = useTranslation()

    const handleAddVideoQueue = () => {
        setIsLoading(true)
        api.addVideo(`https://youtu.be/${video.id}`)
            .then((res) => {
                if (res.success) {
                    setLoadingIcon("check_circle")
                    setLoadingMessage(res.message)
                    // toast.success(res.message)
                } else {
                    setLoadingIcon("error")
                    setLoadingMessage(res.message)
                }
                setTimeout(() => setIsLoading(false), 4000)
            })
            .catch(() => {
                setLoadingIcon("error")
                setLoadingMessage(t("search_videos.video_item.unknown_error"))
                setIsLoading(false)
            })
    }

    const style = isListView
        ? {
              flexDirection: "row",
              maxHeight: "10rem",
              width: "100%",
          }
        : {
              flexDirection: "column",
              width: "25rem",
          }

    const imgStyle = isListView
        ? {
              maxHeight: "100%",
              width: "auto",
          }
        : null

    const loadingComponent = loadingIcon && (
        <motion.span
            className="material-icon notranslate"
            style={{ fontSize: "5rem" }}
            layout
        >
            {loadingIcon}
        </motion.span>
    )

    return (
        <VideoItemContainer
            {...motionProps}
            layout
            style={style}
            isListView={isListView}
        >
            <motion.img
                layout
                style={imgStyle}
                src={thumbnail}
                loading="lazy"
            />
            <AnimatePresence>
                {isLoading && (
                    <SearchLoading
                        customIcon={loadingComponent}
                        message={loadingMessage}
                        messageStyle={{ fontSize: "1rem", textAlign: "center" }}
                        background={true}
                        styles={{ gap: ".25rem" }}
                        barsHeight="6rem"
                    />
                )}
            </AnimatePresence>
            <VideoData
                video={video}
                handleAddVideoQueue={handleAddVideoQueue}
                t={t}
            />
        </VideoItemContainer>
    )
}

export default VideoItem
