import { IConfigTabId, setTabIndex, updateKey } from "@/slices/config"
import { motion } from "framer-motion"
import React, { cloneElement, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import TwitchLogin from "../Customs/TwitchLogin"

const TabWidgetStyle = styled(motion.div)`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--background-2);
    grid-area: sidebar;
    height: 100%;
    border-radius: var(--border-radius-s);
    .head {
        display: flex;
        gap: 0.25rem;
        padding: 0.25rem;
        box-sizing: border-box;
        flex-wrap: wrap;
    }

    .head button {
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 0.5rem;
        color: var(--foreground-hover);
        background-color: var(--background-2);
        border-radius: calc(var(--border-radius-s) / 2);
        cursor: pointer;
        font-size: 0.9rem;
        :hover {
            background-color: var(--background-2);
        }

        &.active {
            color: var(--foreground);
            background-color: var(--background);
        }
    }

    > .content {
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;
        bottom: 0;
    }

    .content .content-wrapper {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        bottom: 0 !important;
        /* left: 0; */
        > div {
            height: 100%;
            min-width: 100%;
        }
    }
`

const TabWidget = ({
    names,
    tabs,
    ids,
}: {
    names: Array<string | JSX.Element>
    tabs: JSX.Element[]
    ids: IConfigTabId[]
}) => {
    const dispatch = useDispatch()
    const tab_index = useSelector((state: IRootState) => state.config.tab_index)
    const tab_active = useSelector((state: IRootState) => state.config.tab_active)

    const setIndex = (index: number) => dispatch(setTabIndex(index))
    const setTabId = (id: IConfigTabId) =>
        dispatch(updateKey({ key: "tab_active", newValue: id }))

    useEffect(() => {
        const index = ids.findIndex((id) => id === tab_active)
        setIndex(index)
    }, [tab_active])


    return (
        <TabWidgetStyle>
            <div className="head">
                {names.map((name, i) => {
                    if (typeof name !== "string") {
                        return cloneElement(name, { key: i })
                    }
                    return (
                        <button
                            key={i}
                            onClick={() => setTabId(ids[i])}
                            className={tab_index === i ? "active" : ""}
                        >
                            {name}
                        </button>
                    )
                })}
            </div>
            <div className="content">
                <motion.div
                    className="content-wrapper"
                    animate={{ right: `calc(${tab_index} * 100%)` }}
                    transition={{ ease: "easeInOut", duration: 0.25 }}
                >
                    {tabs.map((tab, index) => {
                        return (
                            <div style={{ position: "relative" }} key={index}>
                                {tab}
                            </div>
                        )
                    })}
                </motion.div>
            </div>
        </TabWidgetStyle>
    )
}

export default TabWidget
