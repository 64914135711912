import ButtonIcon from "@/components/Widgets/ButtonIcon"
import { BetaTag, NewTag, OwnedTag } from "@/components/Widgets/Tags"
import { cache } from "@/pages/Store/cache"
import { ApiStoreItem } from "@types"
import axios from "axios"
import { Buffer } from "buffer"
import {
    AnimationDefinition,
    MotionProps,
    Spring,
    motion,
    useAnimate,
    useMotionValue,
    useSpring,
    useTransform,
} from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import useMeasure, { RectReadOnly } from "react-use-measure"
import styled from "styled-components"
import { useHover } from "usehooks-ts"

const Wrapper = styled(motion.div)`
    height: fit-content;
    padding: 1rem;
    width: fit-content;
    /* width: 13rem; */
    /* transform: rotateY(30deg) rotateX(30deg); */
    /* transform-style: preserve-3d; */
`

const Container = styled(motion.div)`
    position: relative;
    height: fit-content;
    min-height: fit-content;
    min-width: 14rem;
    cursor: pointer;

    .face {
        position: relative;
        overflow: hidden;
        border-radius: 1.4rem;
        outline: 3px solid #ffffff16;
        outline-offset: -1px;
    }

    .face .foreground-image,
    .face .background-image {
        position: absolute;
        inset: 0;
        width: 100%;
        height: fit-content;
    }

    .face .wrapper-image {
        width: 100%;
        height: fit-content;
    }

    img {
        height: 100% !important;
        image-rendering: pixelated;
        aspect-ratio: 160/226;
        user-select: none;
    }

    .gradient-foreground,
    .gradient-highlights,
    .gradient-background {
        position: absolute;
        width: 10rem;
        height: 10rem;
        background: radial-gradient(circle closest-side, #ffffff52, transparent);
        top: 0;
        left: 0;
        z-index: 5;
        pointer-events: none;

        /* transform: translate(-50%, -50%); */
    }

    .wrapper-image {
        opacity: 0;
    }

    .background-image {
        z-index: 1;
    }

    .gradient-highlights {
        z-index: 2;
        background: radial-gradient(circle closest-side, #ffffff83, transparent);
        /* mix-blend-mode: lighten; */
    }

    .gradient-foreground {
        z-index: 5;
        width: 20rem;
        height: 20rem;
        /* opacity: .5; */
    }

    .foreground-image {
        z-index: 3;
    }

    .background {
        position: absolute;
        width: 100%;
        /* height: 100%; */
        background-color: #070b15;
        inset: 0;
        transform: translateZ(-20px);
        border-radius: 1.4rem;
        perspective: 1000;
        overflow: hidden;
        outline: 3px solid #ffffff16;
        outline-offset: -1px;
        /* z-index: 0; */
    }

    .gradient-background {
        background: radial-gradient(circle closest-side, #ffffff78, #ffffff2d, transparent);
    }

    .tags-area {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        /* right: .5rem; */
        bottom: 0.5rem;
        display: flex;
        gap: 0.5rem;
        z-index: 100;
        height: 1rem;
        /* aspect-ratio: 6/1; */
        transform: translateZ(50px);
    }
`

export const FakeEmptyStoreCardItem = (props: any) => {
    return (
        <Wrapper style={{ visibility: "hidden" }} {...(props as any)}>
            <Container>
                <div className="face">
                    <img
                        className="wrapper-image"
                        src="https://f.feridinha.com/sync/cards/papai_noel/transparente.webp"
                    ></img>
                </div>
            </Container>
        </Wrapper>
    )
}



async function getImageBase64(
    url: string,
    itemId: ApiStoreItem["itemId"],
    filename: "background_image" | "foreground_image",
) {
    if (cache[itemId] && cache[itemId][filename]) return cache[itemId][filename]

    return axios
        .get(url, {
            responseType: "arraybuffer",
        })
        .then((response) => {
            const buffer = Buffer.from(response.data, "binary").toString("base64")
            const base64 = `data:image/png;base64,${buffer}`
            cache[itemId] = {
                ...cache[itemId],
                [filename]: base64,
            }
            return base64
        })
}

const springOptions: Spring = { type: "spring" }

type Cords = {
    x: number
    y: number
}

export interface StoreCardItemProps {
    onClick: (item: ApiStoreItem, e: React.MouseEvent) => void
    isActive?: boolean
    isPortal?: boolean
    position?: [number, number]
    handleSafeToRemove?: () => void
    shouldExit?: boolean
    setPosition?: (arg: [number, number]) => void
    storeItem: ApiStoreItem
    exitProps?: MotionProps["exit"]
    setSize?: (size: RectReadOnly) => void
    transitionProps?: MotionProps["transition"]
    extraProps?: any
    isOwned?: boolean
    isNew?: boolean
}

export default function StoreCardItem({
    onClick,
    isActive,
    isPortal,
    position,
    handleSafeToRemove,
    shouldExit,
    setPosition,
    storeItem,
    exitProps,
    setSize,
    transitionProps,
    extraProps,
    isNew,
    isOwned,
}: StoreCardItemProps) {
    const [glare, setGlare] = useState<Cords>({ x: 0, y: 0 })
    const tiltX = useMotionValue(0)
    const tiltY = useMotionValue(0)
    const springTiltX = useSpring(tiltX, springOptions)
    const springTiltY = useSpring(tiltY, springOptions)
    const ref = useRef()
    const isHover = useHover(ref)
    const [wrapperRef, wrapperSize] = useMeasure()
    const [animateRef, animate] = useAnimate()
    const storeModalSuicide = useSelector((state: IRootState) => state.roomData.storeModalSuicide)
    const [suicide, setSuicide] = useState<[number, number, number]>(storeModalSuicide)

    useEffect(() => {
        if (
            suicide[0] === storeModalSuicide[0] &&
            suicide[1] === storeModalSuicide[1] &&
            suicide[2] === storeModalSuicide[2]
        )
            return
        setSuicide(storeModalSuicide)
    }, [storeModalSuicide])

    const combinedRef = (node: any) => {
        wrapperRef(node)
        // @ts-ignore
        animateRef.current = node
    }

    const updatePosition = (rect: DOMRect) => {
        if (isPortal) return
        const { left, top } = rect
        setPosition([left, top])
    }

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        const rect = e.currentTarget.getBoundingClientRect()
        const x = e.clientX - rect.left
        const y = e.clientY - rect.top
        const scaleX = e.currentTarget.offsetWidth / rect.width
        const scaleY = e.currentTarget.offsetHeight / rect.height
        const fixedX = (e.clientX - rect.left) * scaleX
        const fixedY = (e.clientY - rect.top) * scaleY

        const { width, height } = rect
        const xPct = x / width - 0.5
        const yPct = y / height - 0.5

        setGlare({ x: fixedX, y: fixedY })
        tiltX.set(xPct)
        tiltY.set(yPct)
        // updatePosition(rect)
    }

    const handleExitAnimation = async () => {
        await animate(
            animateRef.current,
            {
                scale: 1,
                x: position[0] - 16,
                y: position[1] - 16,
                opacity: 0,
                pointerEvents: "none",
                ...(exitProps as any),
            },
            {
                duration: 0.2,
                opacity: { delay: 0.1 },
                ...(transitionProps as any),
            },
        )

        handleSafeToRemove()
    }

    useEffect(() => {
        if (!shouldExit) return
        handleExitAnimation()
    }, [shouldExit])

    useEffect(() => {
        if (!isPortal) return
        setSize(wrapperSize)
    }, [wrapperSize])

    const handleCache = async () => {
        const id = `store-card-item-${storeItem._id}-foreground-image` + (isPortal && "-portal")
        const foregroundImage = document.getElementById(id) as HTMLImageElement
        const backgroundImage = document.getElementById(id.replace("foreground", "background")) as HTMLImageElement

        const foregroundResponse = await getImageBase64(
            storeItem.foreground_image,
            storeItem.itemId,
            "foreground_image",
        )
        const backgroundResponse = await getImageBase64(
            storeItem.background_image,
            storeItem.itemId,
            "background_image",
        )

        foregroundImage.src = foregroundResponse
        backgroundImage.src = backgroundResponse
    }

    useEffect(() => {
        if (!storeItem) return
        const id = `store-card-item-${storeItem._id}-foreground-image` + (isPortal && "-portal")
        const foregroundImage = document.getElementById(id) as HTMLImageElement
        if (!foregroundImage || foregroundImage.src) return
        handleCache()
    }, [storeItem])

    const hoverAnimation: AnimationDefinition = {
        x: `calc(-50% + ${glare.x}px)`,
        y: `calc(-50% + ${glare.y}px)`,
        scale: isHover ? 2 : 0,
        opacity: isHover ? 1 : 0,
        transition: {
            ease: null,
            duration: 0,
            scale: {
                ease: isHover ? "easeIn" : "linear",
                duration: isHover ? 0.2 : 0.15,
            },
            opacity: {
                ease: isHover ? "easeIn" : "linear",
                duration: isHover ? 0 : 0.2,
            },
        },
    }

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        onClick(storeItem, e)
        const rect = e.currentTarget.getBoundingClientRect()
        updatePosition(rect)
    }

    const handleMouseLeave = () => {
        tiltX.set(0)
        tiltY.set(0)
    }

    const rotateX = useTransform(springTiltX, [0.5, -0.5], ["17.5deg", "-17.5deg"])
    const rotateY = useTransform(springTiltY, [0.5, -0.5], ["-17.5deg", "17.5deg"])

    const rem = parseFloat(getComputedStyle(document.documentElement).fontSize)

    let visible: MotionProps["animate"] = {}
    if (isPortal && !isActive)
        visible = {
            ...animate,
            pointerEvents: "all",
            x: storeModalSuicide[0],
            y: storeModalSuicide[1],
            scale: storeModalSuicide[2],
        }
    if (isActive && !isPortal)
        visible = {
            opacity: 0,
            transition: { duration: 0 },
            pointerEvents: "none",
        }

    let className = ""
    if (isPortal) className += "card-portal"
    if (isActive) className += " active-dude"

    return (
        <Wrapper
            ref={combinedRef}
            onMouseLeave={handleMouseLeave}
            initial={
                isPortal && {
                    x: position[0],
                    y: position[1],
                }
            }
            animate={!shouldExit && visible}
            className={className}
            id={`store-card-item-${storeItem._id}`}
            {...extraProps}
        >
            <Container
                ref={ref}
                style={{
                    rotateX,
                    rotateY,
                    transformStyle: "preserve-3d",
                }}
                onMouseMove={handleMouseMove}
                onClick={handleClick}
            >
                <div className="face">
                    <motion.div
                        animate={{
                            ...hoverAnimation,
                            opacity: storeItem ? (hoverAnimation.opacity as number) / 4 : 0,
                            transition: {
                                ...hoverAnimation.transition,
                                scale: {
                                    duration: 0,
                                },
                            },
                        }}
                        className="gradient-foreground"
                    ></motion.div>
                    <img
                        className="foreground-image"
                        id={`store-card-item-${storeItem._id}-foreground-image` + (isPortal && "-portal")}
                    ></img>
                    <motion.div animate={hoverAnimation} className="gradient-highlights"></motion.div>
                    <img
                        className="background-image"
                        id={`store-card-item-${storeItem._id}-background-image` + (isPortal && "-portal")}
                    ></img>
                    <img
                        className="wrapper-image"
                        src={storeItem.background_image}
                        style={{ opacity: storeItem ? 0 : 1 }}
                    ></img>
                    {!isPortal && (
                        <div className="tags-area">
                            {Boolean(!isOwned && isNew) && <NewTag className="darker" />}
                            {isOwned && <OwnedTag/>}
                            
                            {/* <BetaTag className="tag" /> */}
                        </div>
                    )}
                </div>
                <div className="background">
                    <motion.div
                        animate={{
                            ...hoverAnimation,
                            opacity: (hoverAnimation.opacity as number) / 4,
                            transition: {
                                ...hoverAnimation.transition,
                                scale: {
                                    duration: 0,
                                },
                            },
                        }}
                        className="gradient-background"
                    ></motion.div>
                </div>
            </Container>
        </Wrapper>
    )
}
