import { ApiChatter } from "@types"
import { CSSProperties } from "styled-components"

const getColor = (stop: ApiChatter["sevenTvCosmetics"]["paint"]["stops"][0]) =>
    "#" + (stop.color >>> 0).toString(16).padStart(8, "0")


export const getPaintStyles = (user: ApiChatter) => {
    let paintStyles: CSSProperties = {}
    const hasPaint = Boolean(user.sevenTvCosmetics?.paint)
    if (hasPaint) {
        const paint = user.sevenTvCosmetics.paint
        const hasStops = paint.stops && paint.stops.length > 0

        paintStyles = {
            ...paintStyles,
            backgroundClip: "text",
            backgroundSize: "cover",
            WebkitTextFillColor: "transparent",
        }

        if (paint.function === "LINEAR_GRADIENT" && hasStops) {
            const gradientStops = paint.stops.map((stop) => {
                return `${getColor(stop)} ${stop.at * 100}%`
            })
            const gradientDirection = `${paint.angle}deg`
            const gradient = paint.repeat
                ? `repeating-linear-gradient(${gradientDirection}, ${gradientStops.join(", ")})`
                : `linear-gradient(${gradientDirection}, ${gradientStops.join(", ")})`
            paintStyles.backgroundImage = gradient
        } else if (paint.function === "RADIAL_GRADIENT" && hasStops) {
            const gradientStops = paint.stops.map((stop) => `${getColor(stop)} ${stop.at * 100}%`)

            const gradient = `radial-gradient(circle, ${gradientStops.join(", ")})`
            paintStyles.backgroundImage = gradient
        } else if (paint.function === "URL" && paint.image_url) {
            paintStyles.backgroundImage = `url('${paint.image_url}')`
        }

        if (paint.shadows && paint.shadows.length > 0) {
            const dropShadows = paint.shadows.map((shadow) => {
                const colorString = "#" + (shadow.color >>> 0).toString(16).padStart(8, "0")
                return `drop-shadow(${colorString} ${shadow.x_offset}px ${shadow.y_offset}px ${shadow.radius}px)`
            })
            paintStyles.filter = dropShadows.join(" ")
        } else {
            paintStyles.filter = ""
        }
    }
    return paintStyles
}
