import { fetchLikedPlaylists, fetchPlaylists } from "@/slices/playlists/thunks"
import { fetchUser } from "@/slices/userData"
import { ThunkDispatch } from "@reduxjs/toolkit"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import TokenContext from "."
import useStorage from "../../utils/useStorage"

const TokenProvider = ({ children }: { children: JSX.Element }) => {
    const [token, setToken, resetToken] = useStorage<string>("sync_token")
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()

    useEffect(() => {
        if(!token) return
        dispatch(fetchUser())
        dispatch(fetchPlaylists())
        dispatch(fetchLikedPlaylists())
    }, [token])

    return (
        <TokenContext.Provider value={{ token, setToken, resetToken }}>
            {children}
        </TokenContext.Provider>
    )
}

export default TokenProvider
