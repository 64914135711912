import { ConfirmSave } from "@/components/Customs/ConfirmSave"
import { SearchLoading } from "@/components/Loading"
import api from "@/services/api"
import { getRoomName, roomSelect } from "@/utils"
import { fadeInOut } from "@/utils/fadeInOut"
import { ApiRoomData } from "@types"
import { AnimatePresence, motion } from "framer-motion"
import { ChangeEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import styled from "styled-components"
import CommandButton from "./Dashboard/CommandButton"

const PageContainer = styled(motion.div)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 1rem; */
    width: 100%;
    height: 100%;
    background-color: var(--background-contrast);
    padding: 1rem;
    border-radius: var(--border-radius-m);

    .preview-wrapper {
        border-top: 1px solid #ffffff2d;
        padding-top: 0.5rem;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: 0.5rem;
        h3 {
            display: flex;
            align-items: center;
            gap: 1rem;
            justify-content: space-between;
            width: 100%;
        }
        margin-bottom: 0.5rem;
    }

    iframe {
        border: none;
        width: 30rem;
        max-width: 100%;
        height: calc(30rem / 3);
        border-radius: 1rem;
        /* aspect-ratio: 600/800; */
    }

    .options-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        background-color: var(--background-2);
        width: 100%;
        /* max-width: 20rem; */
        padding: 0.5rem 0.5rem;
        border-radius: 0.25rem;
        margin: 1rem 0;
    }

    .options-wrapper .option {
        display: flex;
        gap: 0.5rem;
        padding: 0 0.25rem;
        border-bottom: 1px solid #ffffff2d;

        input[type="number"] {
            width: 5ch;
        }

        input[type="checkbox"] {
            width: 1rem;
        }
    }
`

const ConfigOverlayPage = () => {
    const overlayConfig = useSelector(roomSelect("overlay_config"))
    const { t } = useTranslation()
    const currentVideo = useSelector(roomSelect("currentVideo"))
    const [isLoading, setIsLoading] = useState(false)

    const [currentChanges, setCurrentChanges] = useState(overlayConfig)

    const shouldSave =
        JSON.stringify(overlayConfig) !== JSON.stringify(currentChanges)

    const handleOptionEvent =
        (id: keyof ApiRoomData["overlay_config"], invertValue?: boolean) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            if (typeof currentChanges[id] === "boolean") {
                setCurrentChanges({
                    ...currentChanges,
                    [id]: !currentChanges[id],
                })
            } else {
                setCurrentChanges({
                    ...currentChanges,
                    [id]: e.target.value,
                })
            }
        }

    const handleNumberEvent = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = e.target.value
        if (isNaN(Number(value))) return
        setCurrentChanges({
            ...currentChanges,
            title_speed_ratio: parseFloat(value),
        })
    }

    const saveOverlayConfig = async () => {
        setIsLoading(true)
        const args = Object.keys(currentChanges)
            .map((key: keyof typeof currentChanges) => [
                key,
                currentChanges[key].toString(),
            ])
            .flat(1)
        const response = await api.execCommand("overlay", args)

        if (!response?.success) {
            toast.error(response as unknown as string)
            return
        }

        setIsLoading(false)
        toast.success(response.message)
    }
    const handleUndo = () => setCurrentChanges(overlayConfig)

    useEffect(() => {
        if (!shouldSave) return
        setCurrentChanges(overlayConfig)
    }, [overlayConfig])

    return (
        <PageContainer {...fadeInOut(0)}>
            <h2>{t("config_overlay_page.overlay_settings")}</h2>
            <div className="options-wrapper">
                <div className="option">
                    <input
                        type="checkbox"
                        defaultChecked={currentChanges.is_thumbnail_enabled}
                        onChange={handleOptionEvent(
                            "is_thumbnail_enabled",
                            true
                        )}
                    />
                    <p>{t("config_overlay_page.show_thumbnail")}</p>
                </div>
                <div className="option">
                    <input
                        type="checkbox"
                        defaultChecked={currentChanges.is_progress_bar_enabled}
                        onChange={handleOptionEvent(
                            "is_progress_bar_enabled",
                            true
                        )}
                    />
                    <p>{t("config_overlay_page.show_progress_bar")}</p>
                </div>
                <div className="option">
                    <input
                        type="checkbox"
                        defaultChecked={currentChanges.is_room_data_enabled}
                        onChange={handleOptionEvent(
                            "is_room_data_enabled",
                            true
                        )}
                    />
                    <p>{t("config_overlay_page.show_room_info")}</p>
                </div>
                <div className="option">
                    <select
                        onChange={handleNumberEvent}
                        value={currentChanges.title_speed_ratio}
                    >
                        <option value={0.5}>
                            {t(
                                "config_overlay_page.title_speed_options.very_slow"
                            )}
                        </option>
                        <option value={0.6}>
                            {t("config_overlay_page.title_speed_options.slower")}
                        </option>
                        <option value={0.8}>
                            {t("config_overlay_page.title_speed_options.slow")}
                        </option>
                        <option value={1}>
                            {t(
                                "config_overlay_page.title_speed_options.normal"
                            )}
                        </option>
                        <option value={1.25}>
                            {t("config_overlay_page.title_speed_options.fast")}
                        </option>
                        <option value={1.5}>
                            {t(
                                "config_overlay_page.title_speed_options.very_fast"
                            )}
                        </option>
                        <option value={2}>
                            {t(
                                "config_overlay_page.title_speed_options.light_speed"
                            )}
                        </option>
                    </select>
                    <p>{t("config_overlay_page.title_speed")}</p>
                </div>
                <div className="option">
                    <input
                        type="checkbox"
                        defaultChecked={currentChanges.is_header_enabled}
                        onChange={handleOptionEvent("is_header_enabled", true)}
                    />
                    <p>{t("config_overlay_page.show_header")}</p>
                </div>
                <div className="option">
                    <input
                        type="checkbox"
                        defaultChecked={currentChanges.auto_hide}
                        onChange={handleOptionEvent("auto_hide", true)}
                    />
                    <p>{t("config_overlay_page.auto_hide_overlay")}</p>
                </div>
                <div className="option">
                    <input
                        type="checkbox"
                        defaultChecked={currentChanges.is_hidden}
                        onChange={handleOptionEvent("is_hidden", false)}
                    />
                    <p>{t("config_overlay_page.disable_overlay")}</p>
                </div>
            </div>
            <div className="preview-wrapper">
                <h3>
                    {t("config_overlay_page.real_time_preview")}
                    {!currentVideo && (
                        <CommandButton
                            command="add"
                            args={["rick", "roll"]}
                            icon="add"
                            children={t("config_overlay_page.add_test_video")}
                            type={1}
                        />
                    )}
                    {currentVideo && (
                        <CommandButton
                            icon="skip_next"
                            command="skip"
                            args={[]}
                            title={t("dashboard.mod_inputs.button_skip")}
                            type={1}
                        ></CommandButton>
                    )}
                </h3>
                <iframe
                    src={`${process.env.OVERLAY_URL}/${getRoomName()}?preview`}
                ></iframe>
            </div>
            <AnimatePresence>
                {shouldSave && (
                    <ConfirmSave
                        onUndo={handleUndo}
                        onSave={saveOverlayConfig}
                        buttonProps={{ type: 1 }}
                    />
                )}
            </AnimatePresence>
            <AnimatePresence>
                {isLoading && (
                    <SearchLoading
                        styles={{
                            alignSelf: "center",
                            margin: "auto",
                            background: "var(--background-2)",
                        }}
                        background={true}
                        barsHeight="50"
                    />
                )}
            </AnimatePresence>
        </PageContainer>
    )
}

export default ConfigOverlayPage
