import FloatingBubbles from "@/components/FloatingBubbles"
import About from "@/pages/About"
import RoomsSection from "@/pages/Home/RoomsSection"
import { AnimatePresence, useScroll } from "framer-motion"
import { useRef, useState } from "react"
import Container, { NavbarArea, ScrollButton, SyncImage, SyncTitle } from "./styles"

function Home() {
    const [scrollButton, setScrollButton] = useState(true)
    const ref = useRef(null)
    const { scrollYProgress } = useScroll({ container: ref })
    const [ignorePointerEvents, setIgnorePointerEvents] = useState(false)

    return (
        <Container ref={ref} style={{ overflow: "auto" }} onScroll={() => setScrollButton(false)}>
            <a className="logo" href="/">
                f.sync
            </a>
            <section id="presentation">
                <SyncTitle ignorePointsEvents={ignorePointerEvents} />
                <SyncImage setIgnorePointerEvents={setIgnorePointerEvents} scrollYProgress={scrollYProgress} />
                <AnimatePresence mode="sync">{scrollButton && <ScrollButton />}</AnimatePresence>
                <FloatingBubbles />
                <NavbarArea />
            </section>
            <section id="rooms">
                <RoomsSection />
            </section>
            <section id="footer">
                <About />
            </section>
        </Container>
    )
}

export default Home
