import TwitchBadge from "@/components/TwitchBadge"
import UsernameLabel from "@/components/UsernameLabel"
import Folder from "@/components/Widgets/Folder"
import { ApiAvatar, ApiChatter } from "@types"
import prettyMs from "pretty-ms"
import { memo, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import ConfigPage from "./Config"
import { useTranslation } from "react-i18next"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    padding: 1rem;
    padding-right: 0.5rem;
    gap: 0.5rem;
    color: var(--foreground);
    overflow-y: scroll !important;
    .stats {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        border-radius: var(--border-radius-ss);
        background-color: var(--background);
        padding: 0.5rem;
    }

    .stats p {
        display: flex;
        justify-content: space-between;
        font-weight: 400;
    }

    .users {
        display: flex;
        flex-direction: column;
        gap: 0.15rem;
        border-radius: var(--border-radius-ss);
        background-color: var(--background);
        flex-grow: 1;
        padding: 0.5rem;
        overflow-y: auto;
        min-height: 6.5rem;
    }

    h3:first-child {
        margin-top: 0;
    }

    h3 {
        margin-top: 0.5rem;
    }
`

const UserBox = styled.div`
    display: flex;
    gap: 0.05rem;
    padding: 0.25rem;
    border-radius: var(--border-radius-ss);
    align-items: center;

    :hover {
        background-color: var(--background-light);
    }
`

const User = ({ avatar }: { avatar: ApiAvatar }) => {
    const user = avatar.user as ApiChatter

    return (
        <UserBox>
            <TwitchBadge tooltipProps={{ placement: "bottom" }} user={user} />
            <UsernameLabel user={user} />
        </UserBox>
    )
}

const Page = memo(() => {
    const user = useSelector((state: IRootState) => state.userData.user)
    const avatars = useSelector((state: IRootState) => state.roomData.avatars)
    const queue = useSelector((state: IRootState) => state.roomData.queue)
    const [isConfigOpen, setConfigOpen] = useState(false)
    const { roomName } = useParams()
    const { t } = useTranslation()

    const queueDurationMs =
        queue.length > 0
            ? queue.reduce((total, item) => (total += item.duration), 0)
            : 0

    return (
        <Container>
            <h3>{t("stats.title")}: {roomName}</h3>
            <div className="stats">
                <p>
                    {t("stats.dancers_total")}: <span>{avatars.length}</span>
                </p>
                <p>
                    {t("stats.queue_total")}: <span>{queue.length}</span>
                </p>
                <p>
                    {t("stats.queue_length")}:
                    <span>{prettyMs(queueDurationMs)}</span>
                </p>
            </div>
            <h3>{t("stats.dancers_total")}:</h3>
            <div className="users">
                {avatars.map((avatar) => (
                    <User key={avatar.uuid} avatar={avatar} />
                ))}
            </div>
            {!user && (
                <Folder
                    isOpen={isConfigOpen}
                    onClick={() => setConfigOpen(!isConfigOpen)}
                    title={t("widgets.folder.config")}
                >
                    <ConfigPage />
                </Folder>
            )}
        </Container>
    )
})

export default Page
