import { TFunction, i18n } from "i18next"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 1rem;
    margin: auto;
    height: 100%;

    h1 {
        font-size: 4rem;
    }

    a {
        color: var(--color-5);
        text-decoration: underline;
    }

    .items {
        display: flex;
        flex-direction: column;
        background-color: var(--background-contrast);
        padding: 1rem;
        border-radius: 0.25rem;

        p {
            display: flex;
            gap: 6rem;
            width: 100%;
        }

        p a {
            margin-left: auto;
            cursor: pointer;
        }
    }

    > p {
        font-size: 1.25rem;
    }

    .footer {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 1rem;

        a {
            margin: 0 0.5rem;
        }

        span {
            background-color: var(--background-contrast);
            padding: 0.25rem 1rem;
        }

        span.hidden {
            opacity: 0.5;
            margin: 0 !important;
        }
    }
`

export const getHyperlinksFilename = (language: string) =>
    language === "pt"
        ? ["/terms_of_service_pt.html", "/privacy_policy_pt.html", "/sales_pt.html"]
        : ["/terms_of_service.html", "/privacy_policy.html", "/sales.html"]

export const getLegalHyperlinks = ({ t, i18n }: { t: TFunction; i18n: i18n }) => {
    const links = getHyperlinksFilename(i18n.language)

    const data = [
        <a className="legal-hyperlink" href={links[0]} target="_blank">
            {t("legal_label.terms_of_service")}
        </a>,
        <a className="legal-hyperlink" href={links[1]} target="_blank">
            {t("legal_label.privacy_policy")}
        </a>,
        <a className="legal-hyperlink" href={links[2]} target="_blank">
            {t("legal_label.sales_conditions")}
        </a>,
    ]

    return data
}

export const LegalHyperlinks = () => {
    const { t, i18n } = useTranslation()

    const data = getLegalHyperlinks({ t, i18n })

    return (
        <>
            {data[0]}
            {data[1]}
            {data[2]}
        </>
    )
}

const SupportPage = () => {
    const { t } = useTranslation()
    const date = new Date((import.meta as any).env.BUILD_DATE * 1000)

    return (
        <Container>
            <h1>{t("support_page.title")}</h1>
            <p>{t("support_page.description")}</p>
            <div className="items">
                <p>
                    Twitch{": "}
                    <a href="https://twitch.tv/feridinha" target="_blank">
                        @Feridinha
                    </a>
                </p>
                <p>
                    Discord{": "}
                    <a href="https://discord.com/channels/@me/485322789822136330" target="_blank">
                        @Feridinha
                    </a>
                </p>
                <p>
                    {t("support_page.twitter_label.personal")}
                    {": "}
                    <a href="https://twitter.com/feridinhadev" target="_blank">
                        @FeridinhaDev
                    </a>
                </p>
                <p>
                    {t("support_page.twitter_label.project")}
                    <a href="https://twitter.com/feridinhasync" target="_blank">
                        @FeridinhaSync
                    </a>
                </p>
                <p>
                    Email{": "}
                    <a href="mailto:contato@feridinha.com" target="_blank">
                        contato@feridinha.com
                    </a>
                </p>
                <p>
                    Github
                    <a href="https://github.com/feridinha" target="_blank">
                        @Feridinha
                    </a>
                </p>
            </div>
            <p className="footer">
                <span className="hidden">
                    @FeridinhaSync - {date.toLocaleString("default", { month: "long", year: "numeric" })} -{" "}
                    {(import.meta as any).env.BRANCH}#{(import.meta as any).env.COMMIT_HASH}
                </span>
                <span>
                    <LegalHyperlinks />
                </span>
            </p>
        </Container>
    )
}

export default SupportPage
