import { useParams } from "react-router-dom"
import styled from "styled-components"
import TwitchIcon from "@/assets/twitch.svg"
import ShadowButton from "../Widgets/ShadowButton"

const Container = styled.div<any>`
    position: relative;
    display: flex;
    gap: 0.35rem;
    align-items: center;
    justify-content: center;
    background-color: #9147ff;
    padding: 0.25rem 0.5rem;
    box-shadow: rgba(144, 72, 249, 0.175) 0px 0px 25px 5px;
    color: var(--foreground);
    cursor: pointer;
    width: 100%;
    transition: 0.5s;
    padding: 0.5rem 0.75rem;


    p,
    svg {
        z-index: 2;
        font-size: 1.2rem;
    }

    svg {
        height: 1.75rem;
        width: 1.75rem;
        transition: ease transform 1s;
        transition-delay: 0s;
    }

    &.hover {
        background-color: #772ce8;
        * {
            color: #eee;
            fill: #eee;
        }
        svg {
            transform: rotateZ(-180deg);
            transition-delay: 1s;
        }
    }
`

const TwitchLogin = (props: {
    borderRadius?: string
    className?: string
    forceRoom?: string
    isSidebar?: boolean
    forcedText?: string
    customProps?: any
}) => {
    const { roomName } = useParams()
    return (
        <ShadowButton
            shadowColor="#b687fd75"
            style={{
                borderRadius: props.borderRadius || "var(--border-radius-ss)",
            }}
            scale={2}
            onClick={() =>
                window.open(
                    `${process.env.API_PATH}/auth/redirect?room=${
                        props.forceRoom || roomName
                    }`,
                    "_self"
                )
            }
            className={props.className}
            {...props.customProps}
        >
            <Container isSidebar={props.isSidebar}>
                <TwitchIcon />
                <p>{props.forcedText || "Login"}</p>
            </Container>
        </ShadowButton>
    )
}

export default TwitchLogin
