import { motion } from "framer-motion"
import { MouseEventHandler } from "react"
import styled from "styled-components"

const FolderBox = styled(motion.div)`
    align-self: flex-start;
    
    height: fit-content;
    width: 100%;
    background-color: var(--background);
    background-color: var(--background-contrast);
    border-radius: var(--border-radius-s);
    padding: 0.25rem;
    /* border: 1px solid rgba(255, 255, 255, 0.090); */
    .folder-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center !important;
        width: 100%;

        padding: 0.25rem 0.25rem;
        border-radius: var(--border-radius-s);
        padding-left: 0.75rem;
    }

    .folder-header p {
        font-size: 0.925rem;
    }

    .folder-header span {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .folder-header button.icon {
        transition: 0.2s;
        border-radius: var(--border-radius-s);
        :hover {
            background: var(--background-2);
        }
    }

    .folder-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    border-start-start-radius: var(--border-radius-s);
    border-start-end-radius: var(--border-radius-s);
`

const Folder = ({
    isOpen,
    children,
    onClick,
    title,
    className,
}: {
    isOpen?: boolean
    children?: JSX.Element
    onClick?: MouseEventHandler<HTMLButtonElement>
    title?: string
    className?: string
}) => {
    return (
        <FolderBox className={className}>
            <button className="folder-header reset-button" onClick={onClick}>
                <p>{title}</p>
                <motion.span
                    className="material-icon notranslate"
                    animate={{ rotateZ: isOpen ? 0 : "-90deg" }}
                    style={{ rotateZ: isOpen ? 0 : "-90deg" }}
                    transition={{ ease: "easeIn" }}
                >
                    expand_more
                </motion.span>
            </button>
            <motion.div
                className="folder-content"
                animate={{
                    height: isOpen ? "fit-content" : 0,
                    overflow: isOpen ? "visible" : "hidden",
                    margin: isOpen ? ".25rem 0rem" : "0rem 0rem",
                }}
                style={{
                    height: isOpen ? "fit-content" : 0,
                }}
                transition={{
                    duration: 0.4,
                    ease: "easeInOut",
                    overflow: { duration: 0, delay: isOpen ? 0.4 : 0 },
                }}
            >
                {children}
            </motion.div>
        </FolderBox>
    )
}

export default Folder
