import Controls from "@/components/Controls"
import CommunitPlaylistsButton from "@/components/Customs/CommunityPlaylistsButton"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import useModal from "@/hooks/useModal"
import useSocket from "@/hooks/useSocket"
import { updateKey } from "@/slices/config"
import { motion } from "framer-motion"
import { ReactNode, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ConfigCheckbox } from "../Config"
import CommandButton from "../Dashboard/CommandButton"
import Help from "../Help"
import PlaylistsPage from "../Playlists"
import VideosListPage from "../SearchVideos"
import Kanban, { KanbanProps } from "./Kanban"
import { QuickActionsPageContainer } from "./styles"
import ChooseLanguagePage from "../ChooseLanguage"
import { useTranslation } from "react-i18next"
import StorePage from "../Store"
import StoreButton from "@/components/Customs/StoreButton"

export type ButtonType = {
    text: string
    id: "add" | "playlists" | "skip" | "community_playlists" | "help" | "sync" | "choose_language" | "store"
}

export type ColumnKeys = "droppable-enabled" | "droppable-disabled"
export type ColumnValues = {
    values: ButtonType[]
    set: (d: ButtonType[]) => void
    title: string
}
export type ColumnType = {
    [key in ColumnKeys]: ColumnValues
}

const defaultButtons: { [key in ButtonType["id"]]: ButtonType } = {
    add: { text: "Adicionar vídeo", id: "add" },
    playlists: { text: "Suas playlists", id: "playlists" },
    skip: { text: "Pular vídeo atual", id: "skip" },
    community_playlists: {
        text: "Playlists da comunidade",
        id: "community_playlists",
    },
    help: {
        text: "Página de ajuda",
        id: "help",
    },
    sync: {
        text: "Re-sincronizar vídeo",
        id: "sync",
    },
    choose_language: {
        text: "Escolher idioma",
        id: "choose_language",
    },
    store: {
        text: "Lojinha",
        id: "store",
    },
}

const QuickActionsPage = () => {
    const [buttons, setButtons] = useState<ButtonType[]>([])
    const [disabledButtons, setDisabledButtons] = useState<ButtonType[]>([])
    const [draggingId, setDragging] = useState<string>(null)
    const actions_buttons = useSelector((state: IRootState) => state.config.actions_buttons)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleSave = () => {
        dispatch(
            updateKey({
                key: "actions_buttons",
                newValue: buttons.map((b) => b.id),
            }),
        )
    }

    const handleUndoChanges = () => {
        handleInitButtons()
    }

    const handleInitButtons = () => {
        let includes: ButtonType[] = actions_buttons.map((id) => defaultButtons[id])
        let notIncluded: ButtonType[] = []

        Object.values(defaultButtons).forEach((button) => {
            if (actions_buttons.includes(button.id)) {
                return
            }
            notIncluded.push(button)
        })

        setButtons([...new Set(includes)])
        setDisabledButtons([...new Set(notIncluded)])
    }

    const shouldSave = JSON.stringify(buttons.map((b) => b.id)) !== JSON.stringify(actions_buttons)

    useEffect(() => {
        if (!actions_buttons) return setButtons([])
        handleInitButtons()
    }, [actions_buttons])

    const kanbanProps: KanbanProps = {
        buttons,
        disabledButtons,
        setButtons,
        setDisabledButtons,
        setDragging,
    }

    return (
        <QuickActionsPageContainer layout>
            <h2>{t("quick_actions.title")}</h2>

            <Kanban {...kanbanProps} />
            <p style={{display: "flex", gap: ".5rem", alignItems: "center", padding: "0 .5rem"}}>
                <ConfigCheckbox id="actions_show_description" /> {t("quick_actions.show_button_descriptions")}
            </p>
            <div className="controls-preview">
                <p>{t("quick_actions.preview")}</p>
                <Controls forcedHideText={true} forcedButtons={buttons.map((d) => d.id)} />
            </div>
            <motion.div
                // className="buttons-wrapper"
                animate={{
                    opacity: shouldSave ? 1 : 0,
                    pointerEvents: shouldSave ? "all" : "none",
                    height: shouldSave ? "fit-content" : 0,
                    overflow: "hidden",
                }}
                transition={{
                    duration: 0.3,
                    opacity: {
                        delay: 0.3,
                    },
                }}
            >
                <div className="buttons-wrapper">
                    <ButtonIcon
                        onSubmit={handleUndoChanges}
                        type={4}
                        icon="undo"
                        children={t("quick_actions.undo_button")}
                    />
                    <ButtonIcon onSubmit={handleSave} type={4} icon="save" children={t("quick_actions.save_button")} />
                </div>
            </motion.div>
        </QuickActionsPageContainer>
    )
}

export const processButtons = (forcedButtons?: ButtonType["id"][]) => {
    const { setModal } = useModal()
    const config = useSelector((state: IRootState) => state.config)
    const show_description = config.actions_show_description
    const ids = forcedButtons || config.actions_buttons
    const { socket } = useSocket()
    const { t } = useTranslation()

    const texts: any = show_description
        ? {
              add: t("dashboard.user_inputs.button_add"),
              skip: t("dashboard.mod_inputs.button_skip"),
              playlists: t("dashboard.user_inputs.button_playlists"),
              help: t("dashboard.user_inputs.button_help"),
              config: t("quick_actions.button_quick_actions"),
              sync: t("quick_actions.sync_current_video"),
              choose_language: t("quick_actions.choose_language_short"),
              store: t("store_page.button"),
          }
        : {
              community_playlists: "   ",
          }
    let buttons: ReactNode[] = []
    let buttonsByKey: any = {
        add: (
            <ButtonIcon
                icon="add"
                children={texts["add"]}
                onSubmit={() => setModal(<VideosListPage />, "main")}
                type={1}
                key={"add"}
            />
        ),
        community_playlists: (
            <CommunitPlaylistsButton
                text={texts["community_playlists"]}
                styles={{ padding: ".55rem .75rem" }}
                key={"community_playlists"}
            />
        ),
        skip: <CommandButton icon="skip_next" children={texts["skip"]} command="skip" type={1} key={"skip"} />,
        playlists: (
            <ButtonIcon
                icon="video_library"
                children={texts["playlists"]}
                onSubmit={() => setModal(<PlaylistsPage />, "main")}
                type={1}
                key={"playlists"}
            />
        ),
        help: (
            <ButtonIcon
                onSubmit={() => setModal(<Help />, "main")}
                icon="help"
                children={texts["help"]}
                type={1}
                key={"help"}
            />
        ),
        sync: (
            <ButtonIcon
                onSubmit={() => socket.emit("get-current")}
                icon="sync"
                children={texts["sync"]}
                type={1}
                key={"sync"}
            />
        ),
        choose_language: (
            <ButtonIcon
                onSubmit={() =>
                    setModal(<ChooseLanguagePage />, "minimum", {
                        style: { maxWidth: "27rem" },
                        hideCloseButton: true,
                    })
                }
                icon="translate"
                children={texts["choose_language"]}
                type={1}
                key={"choose_language"}
            />
        ),
        store: (
            <StoreButton
                textless={!Boolean(texts["store"])}
                forceText={true}
                forcedText={texts["store"]}
                key={"store-button"}
            />
        ),
    }

    ids.forEach((id) => buttons.push(buttonsByKey[id]))

    buttons.push(
        <ButtonIcon
            onSubmit={() => setModal(<QuickActionsPage />, "minimum")}
            icon="tune"
            children={texts["config"]}
            type={1}
            key={"config"}
        />,
    )
    return buttons
}

export default QuickActionsPage
