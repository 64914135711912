import { toTitle } from "@/utils"
import { ApiPlaylistVideo } from "@types"
import { MouseEvent, useRef } from "react"
import styled from "styled-components"
import Tooltip from "../Tooltip"
import ButtonIcon from "../Widgets/ButtonIcon"
import { toast } from "react-toastify"
// @ts-ignore
import SpotifyLogo from "@/assets/logos/spotify.png?url"
// @ts-ignore
import DeezerLogo from "@/assets/logos/deezer.png?url"
// @ts-ignore
import YoutubeLogo from "@/assets/logos/youtube.png?url"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.25rem;
    padding: 0.1rem 0.4rem;
    padding-bottom: .4rem;

    div.link-container {
        position: relative;
        display: flex;
        align-items: center;
        border-radius: .2rem;

        padding: 0.25rem 0.5rem;
        background-color: var(--background-2);
        p {
            margin-right: auto;
        }
        button {
            background-color: none !important;
            padding: 0.1rem !important;
            margin-left: 0.4rem;
        }

        img {
            position: absolute;
            left: 0.4rem;
            top: 50%;
            transform: translate(0%, -50%);
            height: 16px;
            margin-right: 0.25rem;

            &.spotify {
                height: 22px;
            }

            &.deezer {
                height: 22px;
            }
        }

        .logo-wrapper {
            width: 32px;
        }
    }

    p.title {
        margin-bottom: 0.4rem;
    }
`

type WhiteListItem = "deezer" | "youtube" | "spotify" | string

const whiteList: WhiteListItem[] = [
    // "amazon",
    "deezer",
    "youtube",
    "spotify",
    // "soundcloud",
]

const open = (url: string) => window.open(url, "_blank")
const copy = (url: string) => navigator.clipboard.writeText(url)

const handlers: { [key in WhiteListItem]: (id: string) => string } = {
    spotify: (id) => {
        return `https://open.spotify.com/track/${id}`
    },
    youtube: (id) => {
        return `https://www.youtube.com/watch?v=${id}`
    },
    deezer: (id) => {
        return `https://www.deezer.com/track/${id}`
    },
    others: (youtube_id: string) => {
        return `https://song.link/y/${youtube_id}`
    },
}

const logos: { [key in WhiteListItem]: string } = {
    spotify: SpotifyLogo,
    youtube: YoutubeLogo,
    deezer: DeezerLogo,
    others: "",
}

const Content = ({ songIds }: { songIds: ApiPlaylistVideo["songIds"] }) => {
    return (
        <Container>
            <p className="title">Links de outras plataformas</p>
            {Object.entries(songIds).map(([key, value]) => {
                if (!whiteList.includes(key as WhiteListItem)) return null
                return (
                    <div className="link-container" key={key}>
                        <img className={key} src={logos[key as WhiteListItem]}></img>
                        <div className="logo-wrapper"></div>
                        <p className="service-title">{toTitle(key)}</p>
                        <ButtonIcon
                            onSubmit={(e) => {
                                e.stopPropagation()
                                const link =
                                    handlers[key as WhiteListItem](value)
                                open(link)
                            }}
                            icon="open_in_new"
                            type={"white"}
                        />
                        <ButtonIcon
                            onSubmit={(e) => {
                                e.stopPropagation()
                                const link =
                                    handlers[key as WhiteListItem](value)
                                copy(link)
                                toast.success("Copiado")
                            }}
                            icon="content_copy"
                            type={"white"}
                        />
                    </div>
                )
            })}
            {Object.values(songIds).length > 1 && (
                <div className="link-container">
                    <div className="logo-wrapper"></div>
                    <p className="service-title">Outros</p>
                    <ButtonIcon
                        onSubmit={(e) => {
                            e.stopPropagation()
                            const link = handlers.others(songIds.youtube)
                            open(link)
                        }}
                        icon="open_in_new"
                        type={"white"}
                    />
                    <ButtonIcon
                        onSubmit={(e) => {
                            e.stopPropagation()
                            const link = handlers.others(songIds.youtube)
                            copy(link)
                            toast.success("Copiado")
                        }}
                        icon="content_copy"
                        type={"white"}
                    />
                </div>
            )}
        </Container>
    )
}
export default function SongLinksTooltip({
    video,
}: {
    video: ApiPlaylistVideo
}) {
    const ref = useRef()

    const onClick = (e: MouseEvent) => {
        e.stopPropagation()
        e.preventDefault()
    }

    const songIds = { ...video.songIds, youtube: video.id }

    return (
        <Tooltip
            maxWidth={"30rem"}
            interactive={true}
            content={<Content songIds={songIds} />}
            appendTo={document.body}
        >
            <div onClick={onClick} ref={ref}>
                <ButtonIcon
                    onSubmit={onClick}
                    icon="link"
                    type={1}
                    wrapperProps={{ id: "song-id-button" }}
                />
            </div>
        </Tooltip>
    )
}
