import QuickActionsItem from "./QuickActionsItem"
import type { ColumnKeys, ColumnType, ColumnValues, ButtonType } from "."
import { DragDropContext, DragStart, DropResult, Droppable } from "react-beautiful-dnd"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"

export interface KanbanProps {
    buttons: ButtonType[]
    setButtons: (d: ButtonType[]) => void
    disabledButtons: ButtonType[]
    setDisabledButtons: (d: ButtonType[]) => void
    setDragging: (d: string) => void
}

const Kanban = ({ buttons, setButtons, disabledButtons, setDisabledButtons, setDragging }: KanbanProps) => {
    const { t } = useTranslation()
    const columns: ColumnType = {
        "droppable-enabled": {
            values: buttons,
            set: setButtons,
            title: t("quick_actions.enabled_buttons"),
        },
        "droppable-disabled": {
            values: disabledButtons,
            set: setDisabledButtons,
            title: t("quick_actions.disabled_buttons"),
        },
    }

    const handleDragOnSameColumn = (result: DropResult) => {
        const { source, destination } = result
        const column = columns[source.droppableId as ColumnKeys]
        const copiedItems = [...column.values]
        const [removed] = copiedItems.splice(source.index, 1)
        copiedItems.splice(destination.index, 0, removed)
        column.set(copiedItems)
    }

    const handleDragDifferentColumns = (result: DropResult) => {
        const { source, destination } = result
        const target = result.draggableId

        const sourceColumn = columns[source.droppableId as ColumnKeys]
        const destinationColumn = columns[destination.droppableId as ColumnKeys]

        let sourceValues = Array.from(sourceColumn.values)
        let destinationValues = Array.from(destinationColumn.values)

        const [newItem] = sourceValues.splice(source.index, 1)
        destinationValues.splice(destination.index, 0, newItem)

        sourceValues = sourceValues.filter((item) => item.id !== target)

        sourceColumn.set(sourceValues)
        destinationColumn.set(destinationValues)
    }

    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) return
            if (result.destination.droppableId === result.source.droppableId) return handleDragOnSameColumn(result)
            handleDragDifferentColumns(result)
        },
        [buttons, disabledButtons]
    )

    const onDragStart = (e: DragStart) => {
        setDragging(e.draggableId)
    }
    return (
        <div className="columns-container">
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                {Object.entries(columns).map(([key, columnValue]) => (
                    <div className={key} key={key}>
                        <h4>{columnValue.title}</h4>
                        <div className="line"></div>
                        <Droppable droppableId={key}>
                            {(provided) => (
                                <div className="droppable" ref={provided.innerRef} {...provided.droppableProps}>
                                    {columnValue.values.map((button, index) => (
                                        <QuickActionsItem button={button} index={index} key={button.id} />
                                    ))}
                                    {columnValue.values.length === 0 && (
                                        <p
                                            style={{
                                                textAlign: "center",
                                                opacity: 0.5,
                                            }}
                                        >
                                            {t("quick_actions.empty")}
                                        </p>
                                    )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                ))}
            </DragDropContext>
        </div>
    )
}

export default Kanban
