import { useCallback, useState } from "react"
import storage from "./storage"

type Params<Type> = [Type, (newValue: Type) => void, () => void]

export default function useStorage<CustomType>(
    key: string
): Params<CustomType> {
    const [state, setState] = useState<CustomType>(() => storage.get(key))

    const set = useCallback(
        (newValue: CustomType) => {
            storage.set(key, newValue)
            setState(newValue)
        },
        [key]
    )

    const remove = useCallback(() => {
        storage.remove(key)
        setState(undefined)
    }, [key])

    return [state, set, remove]
}
