import { motion } from "framer-motion"
import styled from "styled-components"

export const PageContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1rem 0.5rem;
    gap: 1rem;
    overflow-y: auto;
    overflow-x: clip;

    h1 {
        z-index: 2;
        text-align: center;
    }

    .shadow-container {
        width: 100%;
        height: 100%;
    }

    .items-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
        grid-gap: 1rem;
        /* display: flex;
        flex-wrap: wrap;
        gap: 1.5rem 0.5rem; */
        /* justify-content: space-between; */
        min-width: 100%;
        height: 100%;
        flex-grow: 1;
        padding: 0 0.75rem;

        > div {
            margin: auto;
        }
    }

    .canvas-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 9rem;
        max-height: 9rem;
        width: 100%;
        background: linear-gradient(318deg, #ff5555, #ff79c6);
        background-size: 150% 150%;
        -webkit-animation: BackgroundMove 5s infinite;
        -moz-animation: BackgroundMove 5s infinite;
        animation: BackgroundMove 5s infinite;
        border-radius: var(--border-radius-m);
        box-shadow: 0px 0px 5px 2px #00000040;

        canvas {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
        }

        h1 {
            position: relative;
            font-size: 3rem;
            color: var(--foreground);
            text-shadow: 0px 0px 5px #00000052;
            padding: 0.5rem 1rem;
            z-index: 1;
        }


        .shopping-cart {
            position: absolute;
            bottom: 0.5rem;
            right: 0.5rem;
            z-index: 2;
            button {
                background-color: #ffffff37;
                backdrop-filter: blur(5px);

                :hover {
                    background-color: var(--foreground);

                    &,
                    span {
                        color: var(--background);
                    }
                }
            }

            button.highlighted {
                background-color: var(--foreground);
                &,
                span {
                    color: var(--background);
                }
                transform: scale(1.25);
            }

            button.focused {
                background-color: var(--foreground);
                &,
                span {
                    color: var(--background);
                }
            }
            /* color: var(--background); */
        }
    }
`

export const StoreItem = styled(motion.div)`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    min-height: fit-content;
    width: 13rem;
    transform-style: preserve-3d;
    --border-radius-card: 1.5rem;

    img {
        z-index: 1;
        image-rendering: pixelated;
        width: 100%;
        border-radius: var(--border-radius-card);
    }
    p.title {
        font-size: 1rem;
    }

    .content-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        cursor: pointer;
        z-index: 0;
        border-radius: var(--border-radius-card) !important;
    }

    img.background {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
    }

    img.layer-1 {
        position: absolute;
        top: 0rem;
        z-index: 1;
    }

    img.layer-2 {
        position: absolute;
        top: 0rem;
        z-index: 4;
        /* transform: translateZ(15px); */
    }
    .mouse-gradient-3,
    .mouse-gradient-2,
    .mouse-gradient {
        transition: opacity 0.5s;
    }

    .mouse-gradient {
        z-index: 2;
        /* mix-blend-mode: lighten; */
        opacity: 0;
    }

    .mouse-gradient-2 {
        z-index: 5;
        opacity: 0;
    }

    img.wrapper {
        opacity: 0;
    }

    :hover,
    &.card-portal {
        .mouse-gradient {
            opacity: 0.5;
        }

        .mouse-gradient-2 {
            opacity: 0.125;
        }
    }

    .shadow {
        position: absolute;
        content: "";
        /* background-color: red; */
        inset: 0;
        width: 80%;
        height: 80%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) translateZ(-50px);
        box-shadow: 0px 0px 50px 20px #000;
    }

    &.card-portal .shadow {
        width: 90%;
        height: 90%;
    }

    ::after {
        position: absolute;
        content: "";
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: #070b15;
        transform: translateZ(-20px);
        border-radius: var(--border-radius-card);
    }

    ::before {
        position: absolute;
        content: "";
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: #1356ff;
        transform: translateZ(-19px);
        border-radius: var(--border-radius-card);
    }
`
