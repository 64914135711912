import ModSvg from "@/assets/mod.svg"
import UserSvg from "@/assets/user.svg"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"
import "./Help.css"
import HelpBox from "./HelpBox"
import Mod from "./Mod"
import User from "./User"
import useModal from "@/hooks/useModal"
import { v4 } from "uuid"
import { Trans, useTranslation } from "react-i18next"
import { IHelpTooltips, fetchTooltips } from "@/pages/Help/data"
import { CommandLoading } from "@/components/Loading"
// .tint
function Help() {
    const [currentCard, setCurrentCard] = useState<"user" | "mod">(null)
    const { addListener, removeListener } = useModal()
    const [currentListener, setCurrentListener] = useState<string>(null)
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(true)
    const [tooltips, setTooltips] = useState<IHelpTooltips>(null)

    const handleFetchTooltips = async () => {
        const response = await fetchTooltips()
        setTooltips(response)
        setIsLoading(false)
    }

    useEffect(() => {
        handleFetchTooltips()
    }, [])

    const onModalClose = (data: any | false, type: string) => {
        if (type !== "main") {
            return true
        }

        if (currentCard || data) {
            setCurrentCard(null)
            return false
        }

        return true
    }

    useEffect(() => {
        removeListener(currentListener)
        if (!currentCard) return
        const uuid = v4()
        setCurrentListener(uuid)
        addListener(uuid, onModalClose)
    }, [currentCard])

    return (
        <div className="help-container" id="help-container">
            <h1>{t("help.title")}</h1>
            <CommandLoading isLoading={isLoading} customHeight={50} />
            <motion.div
                className="cards"
                id="cards-wrapper"
                transition={{ duration: 1, ease: "linear" }}
                layout="position"
            >
                <HelpBox className="card user" key={1} layoutName={"user"} setCurrent={setCurrentCard}>
                    <UserSvg />
                    {t("help.user")}
                </HelpBox>
                <HelpBox className="card mod" key={2} layoutName={"mod"} setCurrent={setCurrentCard}>
                    <ModSvg />
                    {t("help.mod")}
                </HelpBox>

                <AnimatePresence>
                    {currentCard && (
                        <HelpBox
                            className="card card-active"
                            id="card-active"
                            layoutId={currentCard}
                            index={4}
                            onClick={() => setCurrentCard(null)}
                            style={{ overflow: "auto" }}
                        >
                            {currentCard === "user" ? <User tooltips={tooltips} /> : <Mod tooltips={tooltips} />}
                        </HelpBox>
                    )}
                </AnimatePresence>
            </motion.div>
            <div className="notice">
                <p>
                    <Trans i18nKey={"help.bottom_notice"}>
                        <br></br>
                    </Trans>
                </p>
            </div>
        </div>
    )
}

export default Help
