import ContactButton from "@/components/Customs/ContactButton"
import DiscordButton from "@/components/Customs/DiscordButton"
import DonateButton from "@/components/Customs/DonateButton"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import RoomCard from "@/pages/Home/RoomCard"
import { LegalHyperlinks } from "@/pages/Support"
import { ApiRoom } from "@types"
import { motion } from "framer-motion"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useReward } from "react-rewards"
import styled from "styled-components"

const Container = styled(motion.div)`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    /* gap: 2rem; */
    > * {
        margin: 1rem 0;
    }
    width: 100%;
    h1 {
        font-size: 3rem;
        text-align: center;
    }

    .button-area {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
        flex-wrap: wrap;
        justify-content: center;

        > * {
            min-height: 2.5rem !important;
        }

        button:last-child {
            min-height: 2.75rem !important;
        }
    }

    .legal-notice {
        display: flex;
        gap: 1rem;
        margin: 0;
        margin-top: .5rem;
        flex-wrap: wrap;
    }

    .room-card {
        background-color: var(--background);
        outline: 1px solid #ffffff22;

        .stats * {
            background-color: var(--background-light);
        }

        .stats > * {
            outline: 1px solid #ffffff22;
        }
    }

    .line {
        width: 100%;
        height: 1px;
        background-color: #ffffff22;
        margin: 0.5rem;
    }
`

const CreateRoomFinal = ({ roomData }: { roomData: ApiRoom }) => {
    const { t } = useTranslation()
    const rewardConfetti = useReward("room-created-conffeti", "confetti", {
        lifetime: 2000,
        decay: 0.975,
        elementCount: 200,
    })

    useEffect(() => {
        setTimeout(() => {
            rewardConfetti.reward()
        }, 1000)
    }, [])

    return (
        <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, ease: "easeInOut" }}>
            <h1>{t("home.rooms.create_modal.room_created_title")}</h1>
            <RoomCard shouldRenderConffeti={true} className="room-card" animationDelay={0} index={0} room={roomData} />
            <div className="line" />
            <div className="button-area">
                <DonateButton />
                <ContactButton />
                <DiscordButton />
                <ButtonIcon
                    // wrapperProps={{ style: { padding: 0 }}}
                    onSubmit={() => window.open("/support", "_blank")}
                    wrapperProps={{ style: { paddingRight: ".9rem" } }}
                    type={"white"}
                    children={
                        <>
                            <span className="material-icon notranslate">help</span>
                            {t("about.need_help")}
                        </>
                    }
                />
            </div>
            <div className="line"/>
            <div className="legal-notice">
                <LegalHyperlinks />
            </div>
        </Container>
    )
}

export default CreateRoomFinal
