import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import "./i18n"
import * as Sentry from "@sentry/browser"

const root = ReactDOM.createRoot(document.getElementById("root"))

const repo = (import.meta as any).env

Sentry.init({
    dsn: "https://f3b1679324394bc781e9d03d99780879@glitchtip.feridinha.com/1",
    enabled: true,
    release: "release-1",
    
})
// HEEEEEEEEEEELLLLPDjkh

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
)
