import {
    addAvatar,
    removeAvatar,
    setAvatars,
    setDanceFloorTooltip,
    updateAvatar,
} from "@/slices/roomData"
import store from "@/store"
import { AvatarPixi } from "@/types/pixi"
import pixiUtils from "@/utils/pixi"
import { ApiAvatar } from "@types"
import {
    AnimatedSprite,
    Container,
    FederatedPointerEvent,
    Resource,
    Texture,
} from "pixi.js"
import { Socket } from "socket.io-client"
import { getSpecsOutlineColor } from "./utils"

interface Animations extends ApiAvatar {
    animation_a?: Texture<Resource>[]
    animation_b?: Texture<Resource>[]
}

type Side = "a" | "b"

export const getAvatarAnimations = (avatar: ApiAvatar, sides: Side[]) => {
    const id_a = avatar.specs.spritesheet
    const baseTexture_a = pixiUtils.getBaseTexture(`dancers/${id_a}`)
    let result: Animations = { ...avatar }

    if (sides.includes("a")) {
        const frames_a = pixiUtils.getTextures(
            `dancers/${id_a}`,
            avatar.specs.width,
            avatar.specs.height,
            avatar.specs.steps,
            baseTexture_a
        )
        result["animation_a"] = frames_a
    }

    if (sides.includes("b")) {
        const id_b = id_a.replace("_A.", "_B.")
        const baseTexture_b = pixiUtils.getBaseTexture(`dancers/${id_b}`)
        const frames_b = pixiUtils.getTextures(
            `dancers/${id_b}`,
            avatar.specs.width,
            avatar.specs.height,
            avatar.specs.steps,
            baseTexture_b
        )
        result["animation_b"] = frames_b
    }

    // MUDEI OS FRAMES_B PARA FRAMES_A
    return result
}

const getHeightModifiers = (index: number, ratio: number) => {
    let heightMod: number = 0
    let scale: number = 1
    let tint: number = 0xffffff

    if (index < ratio) {
        heightMod = -65
        scale = 0.6
        tint = 0x555555
    } else if (index < ratio * 2) {
        heightMod = -65
        scale = 0.7
        tint = 0x858585
    } else if (index < ratio * 3) {
        heightMod = -40
        scale = 0.8
        tint = 0xd1d1d1
    } else if (index < ratio * 4) {
        heightMod = 5
        scale = 0.9
        tint = 0xf1f1f1
    }

    return { heightMod, scale, tint }
}

export const getAvatarModifiers = (
    avatar: ApiAvatar,
    index: number,
    ratio: number
) => {
    const canvas = document.getElementById("canvas-wrapper")
    const width = canvas?.clientWidth || 0
    const height = canvas?.clientHeight || 0

    const widthRatio = width / 100
    const y = height - avatar.specs.height
    const x = widthRatio * avatar.config.column

    const modifiers = getHeightModifiers(index, ratio)

    return {
        x,
        y: y + modifiers.heightMod,
        scale: modifiers.scale,
        tint: modifiers.tint,
    }
}

export const handleMouseEnter =
    (data: ApiAvatar, animatedSprite: AnimatedSprite | Container) =>
    (e: FederatedPointerEvent) => {
        e.target.cursor = "pointer"
        const [filter, color] = getSpecsOutlineColor(data.specs)
        document.documentElement.style.setProperty(
            "--dancefloor-tooltip-color",
            color
        )
        // animatedSprite.zIndex = 999
        animatedSprite.filters = [filter]
        let { y, x, scale } = animatedSprite
        const safeScale = [scale.x, scale.y]
        animatedSprite.zIndex = 1000
        store.dispatch(
            setDanceFloorTooltip({
                x,
                y,
                userId: data.user.id,
                scale: safeScale,
                avatar: { ...data },
            })
        )
    }
export const handleMouseLeave =
    (animatedSprite: AnimatedSprite, originalIndex: number) => () => {
        animatedSprite.filters = null
        store.dispatch(setDanceFloorTooltip(null))
        animatedSprite.zIndex = originalIndex
    }

export const createPixiAvatar = (data: ApiAvatar): AvatarPixi => {
    const avatar = getAvatarAnimations(data, ["a"])
    let animatedSprite = new AnimatedSprite(avatar.animation_a)
    animatedSprite.animationSpeed = 0.15
    animatedSprite.anchor.set(0.5, 0)
    animatedSprite.gotoAndPlay(0)
    animatedSprite.interactive = true

    animatedSprite.on("mouseenter", handleMouseEnter(data, animatedSprite))
    // animatedSprite.on("mouseleave", handleMouseLeave(animatedSprite, null))

    animatedSprite.play()
    return { ...avatar, animatedSprite }
}

const createPixiAvatars = (avatars: ApiAvatar[]) => {
    return avatars.map(createPixiAvatar)
}

export const handleAvatarEvents = (socket: Socket) => {
    socket.on("avatars", (avatars: ApiAvatar[]) => {
        const pixiAvatars = createPixiAvatars(avatars)
        store.dispatch(setAvatars(pixiAvatars))
    })

    socket.on("new-avatar", (avatar: ApiAvatar) => {
        const pixiAvatar = createPixiAvatar(avatar)
        store.dispatch(addAvatar(pixiAvatar))
    })

    socket.on("remove-avatar", (avatar: ApiAvatar) => {
        store.dispatch(removeAvatar(avatar))
    })

    socket.on("update-avatar", (avatar: ApiAvatar) => {
        const pixiAvatar = createPixiAvatar(avatar)
        store.dispatch(updateAvatar(pixiAvatar))
    })
}
