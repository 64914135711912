import { memo } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import RenderDisabled from "./RenderDisabled"

const TwitchChat = memo(() => {
    const render_twitch_chat = useSelector(
        (state: IRootState) => state.config.render_twitch_chat
    )
    const isLoading = useSelector(
        (state: IRootState) => state.roomData.isLoading
    )
    const { roomName } = useParams()
    // const roomName = "asdasd"

    if (isLoading) return

    if (!render_twitch_chat) return <RenderDisabled />

    return (
        <div
            className="twitch-wrapper"
            style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                // height: "100%",
                overflow: "hidden",
                width: "100%",
                borderRadius: "var(--border-radius-s)",
            }}
        >
            <iframe
                style={{
                    top: "-50px",
                    height: "100%",
                    border: "none",
                    width: "100%",
                }}
                id="twitch-chat-embed"
                src={`https://www.twitch.tv/embed/${roomName}/chat?parent=${
                    process.env.TWITCH_PARENT || "localhost"
                }&darkpopout`}
            ></iframe>
        </div>
    )
})

export default TwitchChat
