import { likePlaylist } from "@/slices/playlists/thunks"
import { ThunkDispatch } from "@reduxjs/toolkit"
import { ApiCommunityPlaylist, ApiPlaylist, ApiResponse } from "@types"
import { useDispatch, useSelector } from "react-redux"
import AsyncButton from "../Widgets/AynscButton"
import { useTranslation } from "react-i18next"

const LikePlaylistButton = ({
    playlist,
    onPlaylistUpdate,
    type,
}: {
    playlist: ApiPlaylist | ApiCommunityPlaylist
    onPlaylistUpdate: (newPlaylist: ApiPlaylist | ApiCommunityPlaylist) => void
    type?: 1 | 2 | 3
}) => {
    const user = useSelector(({ userData }: IRootState) => userData.user)
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const { t } = useTranslation()

    const isLiked = Boolean(
        playlist.likes.find((like) => like.author === user._id)
    )

    const onClick = async () => {
        const data = await dispatch(likePlaylist(playlist._id))
        const response = data.payload as ApiResponse<
            ApiPlaylist | ApiCommunityPlaylist
        >
        if (response.success) {
            onPlaylistUpdate(response.data)
        }
    }

    return (
        <AsyncButton
            icon={"thumb_up_alt"}
            children={playlist.likes.length.toString()}
            type={type}
            resolver={onClick}
            tooltip={t(isLiked ? "widgets.like_playlist_button.dislike" : "widgets.like_playlist_button.like")}
            tooltipPlacement={"bottom"}
            tooltipProps={{ hideOnClick: false }}
            wrapperProps={{ className: isLiked ? "liked-playlist-button" : null }}
        />
    )
}

export default LikePlaylistButton
