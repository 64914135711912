import ButtonIcon from "@/components/Widgets/ButtonIcon"
import { CommandLoading } from "@/components/Loading"
import Tooltip from "@/components/Tooltip"
import api from "@/services/api"
import { MaterialIcon } from "@/types/material"
import { TippyProps } from "@tippyjs/react"
import { ApiCommands } from "@types"
import { useState } from "react"
import { toast } from "react-toastify"
import styled from "styled-components"

interface CommandButtonProps {
    icon: MaterialIcon
    command: ApiCommands
    title?: string
    children?: string
    args?: string[]
    tooltip?: string
    tooltipPlacement?: TippyProps["placement"]
    tooltipDelay?: TippyProps["delay"]
    tooltipProps?: TippyProps
    colors?: string[]
    type?: 1 | 2 | 3 | 4
    showNotification?: boolean
    onComplete?: (success: boolean) => void
    wrapperProps?: any,
    buttonProps?: any
}

const Wrapper = styled.div`
    position: relative;
`

const CommandButton = ({
    icon,
    command,
    args,
    title,
    children,
    tooltip,
    tooltipPlacement,
    tooltipDelay,
    colors,
    onComplete,
    showNotification = true,
    tooltipProps = {},
    type,
    wrapperProps, 
    buttonProps
}: CommandButtonProps) => {
    const [isLoading, setLoading] = useState(false)

    let currentColors = colors?.length > 2 && colors

    const handleExec = async () => {
        setLoading(true)
        await api
            .execCommand(command, args)
            .then((data) => {
                if (showNotification) {
                    data.success
                        ? toast.success(data.message)
                        : toast.error(data.message)
                }
                if (onComplete) onComplete(data.success)
            })
            .catch((e) => {
                const message = e.response?.data?.message
                const reason = message ? `: ${message}` : ""
                toast.error(`Erro ao executar comando %${command}${reason}`)
                onComplete && onComplete(false)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const target = (
        <Wrapper {...wrapperProps}>
            <CommandLoading
                isLoading={isLoading}
                colors={currentColors}
            />
            <ButtonIcon
                onSubmit={() => handleExec()}
                icon={icon}
                wrapperProps={{ className: "folder-button", ...buttonProps }}
                colors={currentColors}
                type={type}
            >
                {title || children}
            </ButtonIcon>
        </Wrapper>
    )
    
    if (tooltip)
        return (
            <Tooltip
                content={tooltip}
                animation={"shift-away"}
                placement={tooltipPlacement || "auto"}
                delay={tooltipDelay}
                {...tooltipProps}
            >
                {target}
            </Tooltip>
        )
    return target
}

export default CommandButton
