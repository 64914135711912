import { useTranslation } from "react-i18next"
import styled from "styled-components"
import ButtonIcon from "../Widgets/ButtonIcon"
import useModal from "@/hooks/useModal"
import StorePage from "@/pages/Store"

const Container = styled.button`
    position: relative;
    overflow: hidden;
    padding: 0.4rem 0.5rem 0.4rem 0.75rem;
    max-width: fit-content;
    width: fit-content;
    height: fit-content;
    background-position: 150% 150%;
    font-size: 0.9rem;
    span {
        font-size: 1.5rem;
        margin-left: 0.25rem;
    }

    z-index: 1;
    border-radius: var(--border-radius-ss);
    transition: transform 0.3s;

    &.textless {
        padding: 0.4rem 0.5rem;
        span {
            margin-left: 0;
        }
    }

    ::before {
        content: "";
        position: absolute;
        inset: 0;
        width: 200%;
        height: 200%;
        z-index: -1;
        background: linear-gradient(318deg, #ff5555 30%, #ff79c6);
        transition: transform 0.6s;
    }


    :active {
        transform: scale(0.9);
    }

    &:hover::before {
        transform: translateX(-40%) translateY(-40%);
    }
`

const StoreButton = ({
    textless,
    forcedText,
    forceText,
}: {
    textless?: boolean
    forcedText?: string
    forceText?: boolean
}) => {
    const { t } = useTranslation()
    const { setModal } = useModal()
    return (
        <Container
            onClick={() => setModal(<StorePage />, "main")}
            className={
                "reset-button flex-button store-button" + (textless ? " textless" : "")
            }
        >
            {Boolean(!textless && !forceText) && t("store_page.button")}
            {Boolean(forceText) && forcedText}
            <span className="material-icon notranslate">store</span>

            {/* <ButtonIcon
                onSubmit={() => setModal(<StorePage />, "main")}
                icon={iconless ? null : "store"}
                children={t("store_page.button")}
                type={1}
                key={"store"}
                wrapperProps={"close-button"}
            /> */}
        </Container>
    )
}

export default StoreButton
