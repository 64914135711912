import useDraggableInPortal from "@/hooks/useDraggableInPortal"
import { Draggable, DraggableProvided } from "react-beautiful-dnd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    background-color: var(--background-2);
    align-items: center;
    padding: 0.3rem 0.5rem;
    font-size: 0.9rem;
    border-radius: var(--border-radius-s);

    .drag-handler {
        display: flex;
        align-self: center;
        justify-content: center;
        border-radius: var(--border-radius-s);
        margin-right: 0.75rem;
        span {
            font-size: 1.8rem;
        }
    }
`

const QuickActionsItem = ({
    index,
    button,
}: {
    index: number
    button: {
        text: string
        id: string
    }
}) => {
    const renderDraggable = useDraggableInPortal()
    const { t } = useTranslation()

    return (
        <Draggable draggableId={button.id} index={index}>
            {renderDraggable((props: DraggableProvided) => {
                return (
                    <Container ref={props.innerRef} {...props.draggableProps}>
                        <div
                            className="drag-handler"
                            {...props.dragHandleProps}
                        >
                            <span className="material-icon notranslate">
                                drag_indicator
                            </span>
                        </div>
                        {t("quick_actions.descriptions." + button.id)}
                    </Container>
                )
            })}
        </Draggable>
    )
}

export default QuickActionsItem
