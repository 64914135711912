import { ApiChatter } from "@types"
import { createContext } from "react"

type ModalContentType = JSX.Element | false
type ModalType = "main" | "minimum"
type PositionType = [number, number]
type ListenerCallback = (data: ModalContentType, type: ModalType) => boolean
type ListenerType = {
    [key: string]: ListenerCallback
}


export interface IModalContext {
    modal: JSX.Element | false
    mainModal: JSX.Element | false
    userCard: ApiChatter | false
    userCardPosition: [number, number]
    wrapperProps: any
    setModal: (data: ModalContentType, type: ModalType, wrapperProps?: any) => void
    setUserCard: (user: ApiChatter, position: [number, number]) => void
    listeners: ListenerType
    addListener: (id: string, target: ListenerCallback) => void
    removeListener: (id: string) => void
}

const ModalContext = createContext<IModalContext>({
    mainModal: null,
    modal: null,
    userCard: null,
    userCardPosition: [0, 0],
    setModal: () => {},
    setUserCard: () => {},
    listeners: null,
    addListener: null,
    removeListener: () => {},
    wrapperProps: null
})

export default ModalContext
