import { Routes, Route } from "react-router-dom"
import Room from "@/pages/Room"
import Home from "@/pages/Home"
import Help from "@/pages/Help"
import About from "@/pages/About"
import AuthCallback from "@/pages/AuthCallback"
import SocketProvider from "../hooks/SocketContext/Provider"
import UrlShortcut from "@/pages/UrlShortcut"
import StorePage from "./Store"
import CheckoutCallback from "./CheckoutCallback"
import SupportPage from "./Support"
import * as path from "path"
import Formandos from "@/pages/Formandos"
import RoomRouteComponent from "@/pages/CreateRoom/RouteComponent"
import DonateCallback from "@/pages/Donate/DonateCallback"

const Routes_ = () => {
    return (
        <Routes>
            <Route
                path="/:roomName"
                element={
                    <SocketProvider>
                        <Room />
                    </SocketProvider>
                }
            />
            <Route
                path="/:roomName/checkout/:checkoutUUID"
                element={
                    <CheckoutCallback>
                        <SocketProvider key={"robe robe"}>
                            <Room />
                        </SocketProvider>
                    </CheckoutCallback>
                }
            />
            <Route
                path="/:roomName/donation/:status"
                element={
                    <DonateCallback isRoom={true}>
                        <SocketProvider key={"robe robe"}>
                            <Room />
                        </SocketProvider>
                    </DonateCallback>
                }
            />
            <Route
                path="/donation/:status"
                element={
                    <DonateCallback>
                        <Home />
                    </DonateCallback>
                }
            />
            <Route
                path="/:roomName/playlist/:playlistID"
                element={
                    <UrlShortcut.Playlist>
                        <SocketProvider>
                            <Room />
                        </SocketProvider>
                    </UrlShortcut.Playlist>
                }
            />

            <Route path="/help" element={<Help />} />
            <Route path="/about" element={<About />} />
            <Route path="/" element={<Home />} />
            <Route path="/auth/callback" element={<AuthCallback />} />
            <Route path="/store" element={<StorePage />}></Route>
            <Route path="/support" element={<SupportPage />}></Route>
            {/* <Route path="/formandos" caseSensitive={false} element={<Formandos />}></Route> */}
            <Route path="/request/:eventId" element={<RoomRouteComponent />}></Route>
        </Routes>
    )
}

export default Routes_
