import configReducer, { IConfigState } from "@/slices/config"
import { configureStore } from "@reduxjs/toolkit"
import { IPlaylistsState } from "./slices/playlists"
import roomDataReducer, { IRoomDataState } from "./slices/roomData"
import userDataReducer, { IUserDataState } from "./slices/userData"
import storage from "./utils/storage"
import playlistsReducers from "./slices/playlists"


declare global {
    export interface IRootState {
        config: IConfigState
        userData: IUserDataState
        roomData: IRoomDataState
        playlists: IPlaylistsState
    }
}

const store = configureStore({
    reducer: {
        config: configReducer,
        userData: userDataReducer,
        roomData: roomDataReducer,
        playlists: playlistsReducers,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }),
    devTools: false,
})

store.subscribe(() => {
    const state = store.getState()
    const config = state.config
    const userData = state.userData
    // const roomData = state.roomData

    storage.set("config", config)
    storage.set("userData", userData)
    // storage.set("roomData", roomData)
})

export default store