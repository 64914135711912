import { motion } from "framer-motion"
import styled from "styled-components"

export const Container = styled(motion.div)`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
    gap: 0.5rem;
    height: 100%;
    overflow-y: auto;

    .js-tilt-glare {
        opacity: 0.25;
        z-index: 3;
    }
`