import useModal from "@/hooks/useModal"
import { motion } from "framer-motion"
import { memo, MouseEventHandler } from "react"
import "./Modal.css"
import { useTranslation } from "react-i18next"

const dropIn = {
    initial: {
        y: "100%",
        opacity: 0,
    },
    animate: {
        y: 0,
        opacity: 1,
    },
    exit: {
        y: "-100%",
        opacity: 0,
    },
}

const ModalMain = memo(({ children }: { children: JSX.Element }) => {
    const { setModal } = useModal()
    const { t } = useTranslation()

    return (
        <motion.div
            className="modal"
            id="modal"
            {...dropIn}
            onClick={(e) => e.stopPropagation()}
            transition={{ duration: 0.3, ease: "easeInOut" }}
        >
            <button
                onClick={() => setModal(false, "main")}
                className="close-modal"
            >
                <span className="material-icon notranslate">close</span>
                <span>{t("close")}</span>
            </button>
            <div style={{ width: "100%", height: "100%" }} id="gerson">
                {children}
            </div>
        </motion.div>
    )
})

export default ModalMain
