import "react-i18next"
import enLang from "@/translations/en.json"
import ptLang from "@/translations/pt.json"
import esLang from "@/translations/es.json"
import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import storage from "./utils/storage"

// .use(LanguageDetector)

i18next.use(initReactI18next).init({
    fallbackLng: "en",

    debug: true,
    resources: {
        pt: {
            translation: ptLang,
        },
        en: { translation: enLang },
        es: { translation: esLang },
    },
    lng: storage.get("config")?.language as string,
    detection: {},
})

declare module "react-i18next" {
    // and extend them!
    interface Resources {
        translation: typeof ptLang
    }
}

export type LanguageKeys = "pt" | "en" | "es"
