import { CommandLoading } from "@/components/Loading"
import Tooltip from "@/components/Tooltip"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import RangeInput from "@/components/Widgets/RangeInput"
import Tilt from "@/components/Widgets/Tilt"
import api from "@/services/api"
import { useSingleton } from "@tippyjs/react"
import { ApiAvatar, ApiPremiumAvatarSpecs, ApiResponseMessage } from "@types"
import { AxiosError } from "axios"
import { AnimatePresence, motion } from "framer-motion"
import React, { memo, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import styled from "styled-components"
import CommandButton from "./CommandButton"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import useMeasure from "react-use-measure"
import { updateRoomKey } from "@/slices/roomData"
import StorePage from "../Store"
import useModal from "@/hooks/useModal"
import StoreButton from "@/components/Customs/StoreButton"

const assetsPath = `https://f.feridinha.com/sync`

const DancerContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    transform-style: preserve-3d;
    width: 100%;
    pointer-events: all;

    img {
        position: absolute;
        /* bottom: 5rem; */
        image-rendering: pixelated !important;
        transform: translateZ(7.5rem);
        z-index: 4 !important;
        filter: drop-shadow(2px 2px 0 black) drop-shadow(-2px -2px 0 black);
        /* pointer-events: all; */
    }

    .floor {
        position: absolute;
        background-image: linear-gradient(
                45deg,
                var(--background-light) 25%,
                transparent 25%
            ),
            linear-gradient(45deg, transparent 75%, var(--background-light) 75%),
            linear-gradient(45deg, transparent 75%, var(--background-light) 75%),
            linear-gradient(
                45deg,
                var(--background-light) 25%,
                var(--background-contrast) 25%
            );
        background-size: 2rem 2rem;
        background-position: 0 0, 0 0, -1rem -1rem, 1rem 1rem;
        width: 10rem;
        box-shadow: 0px 0px 5px 1px black;
        bottom: -3.15rem;
        aspect-ratio: 1/1;
        transform: scale3d(1, 2, 1.2) rotateX(90deg) translateY(5rem);
        overflow: visible;
        border-radius: var(--border-radius-m);
        ::after {
            filter: blur(20px);
            opacity: 1;
            position: absolute;
            background-color: black;
            content: "";
            margin: 2.5rem;
            width: 5rem;
            height: 5rem;
        }
    }
`

const Box = styled(Tilt)`
    position: relative;
    transform-style: preserve-3d;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 18rem;
    border-radius: var(--border-radius-m);
    padding: 0.5rem;
    background-color: var(--background);
    box-shadow: 0px 0px 10px 3px rgb(0, 0, 0, 0);
    transition: box-shadow 0.2s ease;
    pointer-events: none;

    .dancer-container {
        padding: 2rem 0;
    }

    :hover {
        box-shadow: 0px 0px 15px 3px black;
    }

    .gap-h {
        height: 2.3rem;
        width: 0rem !important;
        pointer-events: all;
    }

    .gap {
        height: 0.2rem;
        pointer-events: all;
    }

    &.page-active {
        transform: none !important;
        transform-style: none !important;
        transition: 1s;
    }
`

const ControlsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius-m);
    overflow: hidden;

    .buttons-wrapper {
        display: flex;
        justify-content: space-around;
        width: 100%;

        button {
            flex-grow: 1;
            align-items: center;
            padding: 0.4rem 0.25rem;
            font-size: 0.9rem;
            background-color: var(--background-light);
            border: none;
            cursor: pointer;
            transition: 0.2s;
            border-radius: 0.15rem;
        }

        button:hover {
            background-color: var(--background-2);
        }

        > div {
            pointer-events: all;
        }

        > div:first-child button span {
            font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
        }
    }

    .position-wrapper {
        position: relative;
        width: 100%;
        display: flex;
        gap: 0.75rem;
        align-items: center;
        flex-direction: row;
        padding: 0.4rem 0.5rem 0.4rem 0.75rem;
        background-color: var(--background-light);
        border-top-left-radius: var(--border-radius-m);
        border-top-right-radius: var(--border-radius-m);
        pointer-events: all;
        label {
            font-size: 0.9rem;
            line-height: 24px;
        }

        .card-range-loading {
            left: 0;
            border-top-left-radius: var(--border-radius-m);
            border-top-right-radius: var(--border-radius-m);
        }
    }
`

interface CardProps {
    dancer: ApiAvatar
    setPosition: (position: number) => void
    position: number
}

let timeout: ReturnType<typeof setTimeout> = null
const getGif = (file: string) => file.replace(".png", ".gif")

export const DancerCardPreview = ({
    dancer,
    isHidden,
}: {
    dancer: ApiAvatar
    isHidden?: boolean
}) => {
    const height = dancer.specs.height
    return (
        <DancerContainer
            className="dancer-container"
            style={{ opacity: isHidden ? 0 : 1 }}
        >
            <motion.img
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                draggable={false}
                className="no-select"
                src={`${assetsPath}/dancers/${getGif(
                    dancer.specs.spritesheet
                )}`}
            ></motion.img>
            <motion.div
                className="img-box"
                animate={{
                    height,
                    minHeight: height,
                    width: "2px",
                }}
                style={{ height: "auto" }}
            />
            <div className="floor"></div>
        </DancerContainer>
    )
}

export const DancerCard = ({
    dancer,
    children,
    showPreview = true,
    isSelectVisible,
}: {
    dancer: ApiAvatar
    children?: JSX.Element | JSX.Element[]
    showPreview?: boolean
    isSelectVisible?: boolean
}) => {
    const visibleClass = isSelectVisible ? " page-active" : ""
    return (
        <Box
            id="dashboard-card-tilt"
            className={"dancer ns no-select dancer-card" + visibleClass}
            options={{
                scale: 1.05,
                speed: 1000,
                gyroscope: false,
            }}
        >
            <DancerCardPreview dancer={dancer} isHidden={!showPreview} />
            {children}
        </Box>
    )
}

const SelectPageContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: 0;
    gap: 0.5rem;
    padding: 0.5rem;
    pointer-events: all !important;
    top: 0;
    left: 0;
    z-index: 9 !important;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--background-2);
    border-radius: inherit;

    .commands {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: 0.2rem;
        overflow-y: auto;
        > * {
            flex-grow: 1;
        }
        /* overflow: hidden; */
    }

    .commands-scroll {
        position: absolute;
        bottom: 0rem;
        width: 100%;
        z-index: 3;
        text-align: center;
        padding: 1rem 0.5rem;
        backdrop-filter: blur(5px);
        background: linear-gradient(
            to bottom,
            #080808ac,
            #080808cf,
            #080808
        ); /* W3C */
    }

    button {
        pointer-events: all;
        width: 100%;
    }

    button.close-button {
        width: fit-content;
        pointer-events: all !important;
    }

    .line {
        background-color: #ffffff1a !important;
        width: 100%;
        height: 1px;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
    }
`

const buttonColors = [
    "var(--background-contrast) !important",
    "var(--foreground) !important",
    // "var(--background) !important",
]

const premiumButtonColors = [
    "linear-gradient(318deg, #ff80bf, #9580ff)",
    "linear-gradient(318deg, #ff80bf, #9580ff)",
    "var(--foreground) !important",
]

const SelectAvatarPage = ({
    onClose,
    t,
}: {
    onClose: () => void
    t: (key: string) => string
}) => {
    const [hasScrolled, setScrolled] = useState(false)
    const ref = useRef<HTMLDivElement>(null)
    const getUrl = (specs: ApiAvatar["specs"]) => {
        const gif = specs.spritesheet.replace(".png", ".gif")
        return `${assetsPath}/dancers/${gif}`
    }

    const [source, target] = useSingleton({
        overrides: ["placement"],
    })

    const avatarsAvailable = useSelector(
        (state: IRootState) => state.userData.avatarsAvailable
    )

    const isOverflowing =
        ref.current?.scrollHeight - 3 > ref.current?.clientHeight

    const exclusiveAvatars = avatarsAvailable.filter(
        (item) => (item as any).id
    ) as ApiPremiumAvatarSpecs[]
    const normalAvatars = avatarsAvailable.filter(
        (item) => !(item as any).id
    ) as ApiAvatar["specs"][]
    const { setModal } = useModal()

    return (
        <SelectPageContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
        >
            <div className="header">
                <ButtonIcon
                    icon="close"
                    onSubmit={onClose}
                    wrapperProps={{
                        className: "close-button",
                    }}
                    type={4}
                />
                <p>{t("dashboard.avatar.choose_title")}</p>
                <StoreButton textless={true} />
            </div>
            <div className="line"></div>
            <Tooltip
                singleton={source}
                moveTransition="transform 0.8s cubic-bezier(0.22, 1, 0.36, 1)"
                animation="shift-away"
                offset={[0, 10]}
                delay={[100, 300]}
                appendTo={document.body}
                placement="left"
            />
            <div
                className="commands"
                ref={ref}
                onScroll={(e) => {
                    const scrollY  = e.currentTarget.scrollTop
                    if(scrollY < 3) return setScrolled(false)
                    setScrolled(true)
                }}
            >
                <motion.div
                    animate={{
                        opacity: isOverflowing && !hasScrolled ? 1 : 0,
                        pointerEvents: isOverflowing && !hasScrolled ? "auto" : "none",
                    }}
                    className="commands-scroll"
                    children={t("dashboard.avatar.scroll")}
                />
                {exclusiveAvatars.map((avatarSpecs, index) => (
                    <CommandButton
                        command="dancer"
                        args={["change", avatarSpecs.id]}
                        children={t(`store_items.${avatarSpecs.id}.name`)}
                        icon={"paid"}
                        tooltip={"_"}
                        colors={premiumButtonColors}
                        tooltipProps={{
                            content: (
                                <img
                                    draggable={false}
                                    className="no-select"
                                    src={getUrl(avatarSpecs)}
                                ></img>
                            ),
                            singleton: target,
                        }}
                        key={avatarSpecs.spritesheet}
                    />
                ))}
                {normalAvatars.map((avatarSpecs, index) => (
                    <CommandButton
                        command="dancer"
                        args={["change", (index + 1).toString()]}
                        children={`${index + 1}`}
                        icon={"tune"}
                        tooltip={"_"}
                        type={3}
                        tooltipProps={{
                            content: (
                                <img
                                    draggable={false}
                                    className="no-select"
                                    src={getUrl(avatarSpecs)}
                                ></img>
                            ),
                            singleton: target,
                        }}
                        key={avatarSpecs.spritesheet}
                    />
                ))}
            </div>
        </SelectPageContainer>
    )
}

const DancerCardWithControls = memo(
    ({ dancer, setPosition, position }: CardProps) => {
        const [isLoading, setRangeLoading] = useState(false)
        const [isSelectVisible, setSelect] = useState(false)
        const { i18n, t } = useTranslation()

        const saveDancersPosition = async (value: string) => {
            setRangeLoading(true)
            await api
                .execCommand("dancer", ["move", value])
                .catch((e: AxiosError<ApiResponseMessage>) => {
                    const message = e.response?.data?.message
                    const reason = message ? `: ${message}` : ""
                    toast.error(`${reason}`)
                })
                .finally(() => {
                    setRangeLoading(false)
                })
        }

        const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const newPosition = parseInt(e.target.value)
            setPosition(newPosition)
            clearTimeout(timeout)
            timeout = setTimeout(() => saveDancersPosition(e.target.value), 250)
        }

        // if (!dancer) return

        return (
            <DancerCard
                dancer={dancer}
                showPreview={!isSelectVisible}
                isSelectVisible={isSelectVisible}
            >
                <AnimatePresence>
                    {isSelectVisible && (
                        <SelectAvatarPage
                            t={t}
                            onClose={() => setSelect(false)}
                        />
                    )}
                </AnimatePresence>
                <ControlsWrapper>
                    <div className="position-wrapper">
                        <label htmlFor="position">
                            {t("dashboard.avatar.position")}
                        </label>
                        <RangeInput
                            onChange={handleRangeChange}
                            value={position || dancer.config.column}
                            min="0"
                            max="100"
                        ></RangeInput>
                        <CommandLoading
                            isLoading={isLoading}
                            className="card-range-loading"
                        />
                    </div>
                    <div className="gap"></div>
                    <div className="buttons-wrapper">
                        <CommandButton
                            command="dancer"
                            showNotification={false}
                            args={["change"]}
                            icon="casino"
                            children={t("dashboard.avatar.change")}
                        />
                        <div className="gap-h"></div>
                        <div onClick={() => setSelect(!isSelectVisible)}>
                            <ButtonIcon
                                icon="colorize"
                                children={t("dashboard.avatar.choose")}
                                onSubmit={() => setSelect(!isSelectVisible)}
                            />
                        </div>
                        <div className="gap-h"></div>
                        <CommandButton
                            command="dancer"
                            showNotification={false}
                            args={["quit"]}
                            icon="exit_to_app"
                            children={t("dashboard.avatar.quit")}
                        />
                    </div>
                </ControlsWrapper>
            </DancerCard>
        )
    }
)

export default DancerCardWithControls
