import ButtonIcon from "@/components/Widgets/ButtonIcon"
import { SearchLoading } from "@/components/Loading"
import SelectPlaylistTooltip from "@/components/SelectPlaylistTooltip"
import Tooltip from "@/components/Tooltip"
import { VideoDetailsFlex } from "@/components/VideoDetails"
import { fadeInOut, fadeInOutButton, fadeInOutItem } from "@/utils/fadeInOut"
import { ApiPlaylist, ApiPlaylistVideo } from "@types"
import { AnimatePresence, motion } from "framer-motion"
import { useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import SongLinksTooltip from "@/components/Customs/SongLinksTooltip"

const Container = styled(motion.div)`
    position: relative;
    display: flex;
    background-color: var(--background);
    border-radius: var(--border-radius-s);
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1.25rem;
    gap: 1rem;
    height: fit-content !important;

    .middle-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
        width: 100%;
        gap: 0.5rem;
        .video-details {
            gap: 0.1rem 1rem;
            opacity: 0.6;
        }
    }

    p.title {
        text-align: left;
    }

    img {
        height: 3.5rem;
        aspect-ratio: 16/9;
        border-radius: 0.25rem;
    }

    .actions {
        gap: 0.25rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    @media screen and (max-width: 750px) {
        .actions {
            flex-direction: column;
        }

        & img {
            height: 3.5rem;
        }
    }
`

type Callback = (video: ApiPlaylistVideo) => Promise<unknown>

export const PlaylistVideo = ({
    video,
    index,
    addVideoToQueue,
    removeVideoFromPlaylist,
    playlist,
    isExtern,
}: {
    video: ApiPlaylistVideo
    index: number
    addVideoToQueue: Callback
    removeVideoFromPlaylist: Callback
    playlist: ApiPlaylist
    isExtern?: boolean
}) => {
    const [isLoading, setIsLoading] = useState(false)

    const handleClick = (target: Callback) => (e: any) => {
        e.stopPropagation()
        setIsLoading(true)
        target(video).finally(() => setIsLoading(false))
    }

    const animateProps =
        index < 15 ? fadeInOut(index * 1, 0.5) : fadeInOut(0, 0)
    const animatePropsItem =
        index < 15 ? fadeInOutItem(index * 1.1) : fadeInOutItem(0)
    const animatePropsImage =
        index < 15 ? fadeInOutItem(index) : fadeInOutItem(0)
    const { t } = useTranslation()

    return (
        <Container {...animateProps}>
            <AnimatePresence>
                {isLoading && (
                    <SearchLoading background={true} barsHeight={"4rem"} />
                )}
            </AnimatePresence>
            <motion.img
                {...animatePropsImage}
                src={`https://i.ytimg.com/vi/${video.id}/mqdefault.jpg`}
            />
            <motion.div {...animatePropsItem} className="middle-container">
                <p className="title">{video.title}</p>
            </motion.div>
            <motion.div className="actions" {...animatePropsImage}>
                <Tooltip
                    content={t("playlist_card.add_to_queue")}
                    animation="shift-away"
                    offset={[0, 5]}
                >
                    <div>
                        <ButtonIcon
                            onSubmit={handleClick(addVideoToQueue)}
                            icon="add"
                            type={1}
                        />
                    </div>
                </Tooltip>
                {video.songIds && <SongLinksTooltip video={video} />}
                {!isExtern && (
                    <Tooltip
                        content={t("playlist_card.remove_from_playlist")}
                        animation="shift-away"
                        offset={[0, 5]}
                    >
                        <div>
                            <ButtonIcon
                                onSubmit={handleClick(removeVideoFromPlaylist)}
                                icon="playlist_remove"
                                type={1}
                            />
                        </div>
                    </Tooltip>
                )}
                {isExtern && (
                    <SelectPlaylistTooltip buttonIconType={1} video={video} />
                )}
            </motion.div>
        </Container>
    )
}
