import React from "react"
import styled from "styled-components"

const Input = styled.input`
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 1rem;
    background: var(--background-2);
    outline: none;
    transition: background 0.2s;
    border-radius: 1.25rem;

    &:hover > input {
        background: red !important;
    }

    ::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 1rem;
        height: 1rem;
        border: none;
        border-radius: 1.25rem;
        background: var(--foreground);
        cursor: pointer;
    }

    ::-moz-range-thumb {
        width: 1rem;
        height: 1rem;
        border: none;
        border-radius: 0.5rem;
        background: var(--foreground);
        cursor: pointer;
    }
`

const RangeInput = ({
    onChange,
    min,
    max,
    value,
}: {
    onChange: (x: React.ChangeEvent<HTMLInputElement>) => void
    min: string
    max: string
    value: number
}) => {
    return (
        <Input
            type="range"
            onChange={onChange}
            min={min}
            max={max}
            value={value}
        ></Input>
    )
}

export default RangeInput
