import ButtonIcon from "@/components/Widgets/ButtonIcon"
import { ApiVideo } from "@types"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import {
    DragDropContext,
    DragStart,
    DropResult,
    Droppable,
} from "react-beautiful-dnd"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import CommandButton from "../Dashboard/CommandButton"
import DraggableWrapper from "./DraggableWrapper"
import { useTranslation } from "react-i18next"

const PageContainer = styled(motion.div)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
    padding-bottom: 2rem;
    gap: 0.5rem;

    .manage-queue {
        position: relative !important;
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
        width: 100%;
        background-color: #191c28a7;
        border-radius: var(--border-radius-s);
        max-width: 35rem;
        overflow: auto;
    }

    .buttons-wrapper {
        display: flex;
        gap: 0.5rem;
        transition: 0.3s;
    }

    .buttons-wrapper > div {
        overflow: hidden;
    }

    .buttons-wrapper > div > div {
        background-color: var(--background) !important;
    }
`

let timeout: any = null

interface CustomVideo extends ApiVideo {
    originalIndex: number
}

const ManageQueuePage = () => {
    const [currentDragging, setDragging] = useState<string>(null)
    const [queueCopy, setQueueCopy] = useState<CustomVideo[]>([])
    const queue = useSelector((state: IRootState) => state.roomData.queue)
    const commandsAvailable = useSelector(
        (state: IRootState) => state.userData.commandsAvailable
    )
    const show_queue_thumbs = useSelector(
        (state: IRootState) => state.config.show_queue_thumbs
    )
    const { roomName } = useParams()
    const { t } = useTranslation()

    const shouldSave =
        queue.map((i) => i.uuid).join("") !==
        queueCopy.map((i) => i.uuid).join("")

    const handleOnDragEnd = (result: DropResult) => {
        if (!result.destination) return
        setDragging(null)
        const items = Array.from(queueCopy)

        const [newItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, newItem)
        setQueueCopy(items)
    }

    const onDragStart = (e: DragStart) => {
        setDragging(e.draggableId)
    }

    const queueWithIndexes = () =>
        queue.map((item, index) => ({ ...item, originalIndex: index }))

    useEffect(() => {
        if (!queue) return
        setQueueCopy(queueWithIndexes())
    }, [queue])

    const onReset = () => {
        setQueueCopy(queueWithIndexes())
    }
    return (
        <PageContainer layout>
            <h1>{t("manage_queue.title")}</h1>
            <p>{t("manage_queue.description")}</p>

            <div
                className="buttons-wrapper"
                style={{ opacity: shouldSave ? 1 : 0 }}
            >
                <div>
                    <ButtonIcon icon="undo" onSubmit={onReset} type={3}>
                        {t("undo")}
                    </ButtonIcon>
                </div>
                <CommandButton
                    command="queue"
                    args={["reorder", queueCopy.map((i) => i.uuid).join(",")]}
                    icon="save"
                    type={3}
                >
                    {t("save")}
                </CommandButton>
            </div>
            <DragDropContext
                onDragStart={onDragStart}
                onDragEnd={handleOnDragEnd}
            >
                <Droppable droppableId={"queue-manage"}>
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="manage-queue"
                        >
                            {queueCopy.map((video, index) => (
                                <DraggableWrapper
                                    key={video.uuid}
                                    video={video}
                                    originalIndex={video.originalIndex}
                                    index={index}
                                    roomName={roomName}
                                    currentDragging={currentDragging}
                                    commandsAvailable={commandsAvailable}
                                    show_queue_thumbs={show_queue_thumbs}
                                />
                            ))}
                            {provided.placeholder}
                            {queue.length === 0 && (
                                <p style={{ textAlign: "center" }}>
                                    {t("manage_queue.empty_queue")}
                                </p>
                            )}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </PageContainer>
    )
}

export default ManageQueuePage
