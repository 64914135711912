import styled from "styled-components"
import UsernameLabel from "@/components/UsernameLabel"
import { ApiCommandEvent } from "@types"
import moment from "moment"
import "moment/dist/locale/pt-br"
import Tooltip from "@/components/Tooltip"
import { memo, useEffect } from "react"
import TwitchBadge from "@/components/TwitchBadge"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import { useEffectOnce } from "usehooks-ts"

moment.locale("pt-br")

const Li = styled.li`
    position: relative;
    display: flex;
    align-items: center;
    height: min-content;
    overflow: hidden;
    background-color: var(--background-contrast);
    margin-bottom: 0.125rem;
    border-radius: 0.25rem;
    .content {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        transition: 0.1s;
        padding: 0.75rem 0.5rem;
        width: 100%;
        flex-grow: 0;
        height: fit-content !important;
        > div {
            z-index: 2;
        }
        /* max-height: 10rem; */
    }

    img.badge {
        height: 1rem;
        /* background: red; */
    }

    :hover {
        background-color: var(--background);
    }
    .header {
        display: inline-block;
        gap: 0.25rem;
        margin-bottom: 0.5rem;
    }

    .response {
        display: inline-block;
        /* display: flex;
        align-items: flex-end; */
        /* flex-wrap: wrap; */
        vertical-align: top;
        font-size: 0.9rem;
        gap: 0.3rem;
    }

    .message {
        display: inline-block;

        gap: 0.2rem;
        vertical-align: top;
        > * {
            margin-bottom: auto;
        }
    }

    span.material-icon {
        position: absolute;
        right: 0.2rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2rem !important;
        z-index: 3;
        background: none !important;
    }

    span.tag {
        display: inline-block;
        font-size: 0.8rem;
        background-color: var(--background-light);
        padding: 0.1rem 0.25rem;
        border-radius: 0.2rem;
        --color: var(--foreground);
        max-width: 9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: fit-content;
        width: fit-content;
        cursor: pointer;
        margin: 0 0.1rem;
        vertical-align: top;
        &.hide {
            display: none;
        }

        &.success {
            --color: #0ead69;
        }

        &.error {
            --color: #e01a4f;
        }

        &.twitch {
            --color: #a470f3;
        }

        &.site {
            --color: #53b3cb;
        }

        &.info {
            --color: #53b3cb;
        }

        &.command {
            --color: var(--foreground);
        }

        color: var(--color);

        :hover {
            box-shadow: 0px 0px 0px 2px var(--color);
            z-index: 9;
        }
    }

    /* &::before {
        position: absolute;
        content: "";
        opacity: .5;
        left: .75rem;
        width: 100%;
        height: 1px;
        background-color: #ffffff21;
        bottom: 1px;
    } */

    p {
        font-size: 0.9rem;
    }

    .user-wrapper {
        display: inline-block;
        vertical-align: top;
        > div {
            display: inline-block;
        }

        p {
            vertical-align: top;
        }

        width: fit-content;
        margin-right: 0.25rem;
    }
`

const keys = ["twitch", "info", "success", "error", "site"]

const Tag = ({
    className,
    children,
    customTooltip,
}: {
    className?: string
    children?: any
    customTooltip?: string
}) => {
    const { t } = useTranslation()
    let tooltip = customTooltip
    if (keys.includes(className)) tooltip = t(`logs_page.tags.${className}`)

    if (!tooltip)
        return (
            <span className={"tag " + className}>{children || className}</span>
        )

    return (
        <Tooltip
            content={tooltip || ""}
            offset={[0, 5]}
            interactive={true}
            appendTo={document.body}
            delay={[150, 0]}
        >
            <span className={"tag " + className}>{children || className}</span>
        </Tooltip>
    )
}

export interface LogItemProps {
    user: ApiCommandEvent["user"]
    createdAt: ApiCommandEvent["created_at"]
    platform: ApiCommandEvent["platform"]
    response: string
    uuid: string
    command: string
    responseType: ApiCommandEvent["response"]["type"]
    args?: string[]
    index: number
}

const LogItem = ({
    log,
    t,
    language,
}: {
    log: LogItemProps
    t: TFunction
    language: string
}) => {
    const { response } = log

    const now = moment().locale(language).utcOffset(-3)
    const logDate = moment(log.createdAt).locale(language).utcOffset(-3)
    const formatted = logDate.locale(language).format("YYYY-MM-DD HH:mm:ss")
    const difference = logDate.locale(language).from(now)

    let user = { ...log.user, name: `${log.user.name}:` }
    // measure()

    return (
        <Li id={log.uuid}>
            <div className="content">
                <div className="header">
                    <Tag
                        customTooltip={t("logs_page.last_executed", {
                            formatted,
                        })}
                    >
                        {difference}
                    </Tag>
                    <Tag className={log.platform} />
                    <Tag className={"index"} children={`#${log.index.toString()}`} />
                </div>
                <div className="message">
                    <div className="user-wrapper">
                        <TwitchBadge
                            tooltipProps={{ placement: "bottom" }}
                            user={user}
                        />
                        <UsernameLabel user={user} />
                    </div>
                    <Tag className={"%" + log.command} />
                    {log.args?.map((arg, i) => (
                        <Tag className={arg} key={i} />
                    ))}
                </div>
                <div className="response">
                    {response && (
                        <p>
                            <span style={{ color: "var(--foreground-hover)" }}>
                                Response:
                            </span>{" "}
                            {response}
                        </p>
                    )}
                    {!response && (
                        <p style={{ color: "var(--foreground-hover)" }}>
                            [Sem resposta]
                        </p>
                    )}
                </div>
            </div>
        </Li>
    )
}

export default LogItem
