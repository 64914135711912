import { createContext } from "react"

export interface ITokenContext {
    token: string
    setToken: (newValue: string) => void
    resetToken: () => void
}

const TokenContext = createContext<ITokenContext>({
    token: null,
    setToken: () => {},
    resetToken: () => {}
})

export default TokenContext
