import { ConfirmSave } from "@/components/Customs/ConfirmSave"
import { SearchLoading } from "@/components/Loading"
import Tooltip from "@/components/Tooltip"
import ButtonIcon, { ButtonIconProps } from "@/components/Widgets/ButtonIcon"
import api from "@/services/api"
import { ApiResponse, ApiRoomData } from "@types"
import { AnimatePresence, motion } from "framer-motion"
import type { ChangeEvent } from "react"
import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import styled from "styled-components"

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 0.5rem;
    gap: 0.2rem;
    border-radius: var(--border-radius-ss);
    background-color: var(--background-2);
    overflow: hidden;

    span.small,
    p.small {
        font-size: 0.75rem;
    }

    p {
        font-size: 0.85rem;
    }

    .number-input {
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        font-size: 0.9rem;
        width: 100%;

        input {
            height: fit-content;
            width: 3rem;
            margin-left: auto;
        }

        select {
            margin-left: auto;
        }
    }

    .line {
        width: 100%;
        height: 1px;
        background: #ffffff14;
        margin: 0.1rem 0;
    }

    .info-tooltip {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-right: 0.25rem;
        span {
            font-size: 1.2rem;
            font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 400, "opsz" 0;
        }
        cursor: pointer;
    }
`



const ConfigQueueWidget = () => {
    const roomConfig = useSelector((state: IRootState) => state.roomData.config)

    const [shouldSave, setShouldSave] = useState(false)
    const [currentValues, setCurrentValues] =
        useState<ApiRoomData["config"]>(null)
    const [isLoading, setLoading] = useState(false)

    const { t } = useTranslation()

    const handleChange =
        (key: keyof ApiRoomData["config"], isString: boolean = false) =>
        (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            setShouldSave(true)

            setCurrentValues({
                ...currentValues,
                [key]: isString ? e.target.value : parseInt(e.target.value),
            })
        }

    const handleUndo = () => {
        setShouldSave(false)
        setCurrentValues(roomConfig)
    }

    const handleSave = async () => {
        setLoading(true)
        let errorCount = 0
        const handleResponse = (d: ApiResponse<undefined>) => {
            if (d?.success) return
            errorCount += 1
            if (d.message) toast.error(d.message)
        }
        await api
            .execCommand("room", [
                "config",
                "videos_per_user_limit",
                currentValues.videos_per_user_limit.toString(),
            ])
            .then(handleResponse)

        await api
            .execCommand("room", [
                "config",
                "queue_limit",
                currentValues.queue_limit.toString(),
            ])
            .then(handleResponse)

        await api
            .execCommand("room", ["config", "language", currentValues.language])
            .then(handleResponse)

        errorCount > 0
            ? null
            : toast.success(t("widgets.config_queue.save_success"))

        setLoading(false)
        if (errorCount > 0) return
        setShouldSave(false)
    }

    useEffect(() => {
        if (!roomConfig) return
        setCurrentValues(roomConfig)
    }, [roomConfig])

    if (!currentValues) return

    return (
        <Container>
            <div className="number-input">
                <p>
                    <Trans
                        i18nKey={"widgets.config_queue.videos_per_user_limit"}
                    >
                        <strong></strong>:
                    </Trans>
                </p>
                <input
                    type="number"
                    size={2}
                    onChange={handleChange("videos_per_user_limit")}
                    value={currentValues.videos_per_user_limit}
                />
            </div>
            <div className="line"></div>
            <div className="number-input">
                <p>
                    <Trans i18nKey={"widgets.config_queue.queue_limit"}>
                        <strong></strong>:
                    </Trans>
                </p>
                <input
                    type="number"
                    size={3}
                    onChange={handleChange("queue_limit")}
                    value={currentValues.queue_limit}
                />
            </div>
            <div className="line"></div>
            <div className="number-input">
                <Tooltip
                    content={t("widgets.config_queue.choose_language_tooltip")}
                    hideOnClick={false}
                >
                    <div className="info-tooltip">
                        <span className="material-icon notranslate tooltip-icon">
                            help
                        </span>
                    </div>
                </Tooltip>
                <p>{t("widgets.config_queue.choose_language")}</p>
                <select
                    id="language"
                    onChange={handleChange("language", true)}
                    value={currentValues.language}
                >
                    <option value="en">
                        {t("widgets.config_queue.choose_language_options.en")}
                    </option>
                    <option value="pt">
                        {t("widgets.config_queue.choose_language_options.pt")}
                    </option>
                    <option value="es">
                        {t("widgets.config_queue.choose_language_options.es")}
                    </option>
                </select>
            </div>
            <AnimatePresence>
                {shouldSave && (
                    <ConfirmSave onUndo={handleUndo} onSave={handleSave} />
                )}
            </AnimatePresence>
            <AnimatePresence>
                {isLoading && (
                    <SearchLoading
                        styles={{
                            alignSelf: "center",
                            margin: "auto",
                            background: "var(--background-2)",
                        }}
                        background={true}
                        barsHeight="50"
                    />
                )}
            </AnimatePresence>
        </Container>
    )
}

export default ConfigQueueWidget
