import useModal from "@/hooks/useModal"
import { fetchCurrentPlaylist } from "@/slices/playlists/thunks"
import { ThunkDispatch } from "@reduxjs/toolkit"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import ViewPlaylistPage from "../ViewPlaylist"

const Playlist = ({ children }: { children: JSX.Element }) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const isPlaylistsLoading = useSelector(
        ({ playlists }: IRootState) => playlists.isLoading
    )

    const isRoomLoading = useSelector(
        ({ roomData }: IRootState) => roomData.isLoading
    )

    const { setModal, removeListener, addListener } = useModal()
    const { playlistID } = useParams()

    const areLoading = isPlaylistsLoading || isRoomLoading
    const currentPlaylist = useSelector(
        ({ playlists }: IRootState) => playlists.currentPlaylist
    )
    const [currentListener, setCurrentListener] = useState(null)

    const onModalClose = (data: any | false, type: string) => {
        if (data) return true
        const location = window.location.pathname.split("/playlist/")[0]
        window.history.replaceState(null, null, location)
        return true
    }

    useEffect(() => {
        removeListener(currentListener)
        if (!currentPlaylist) return
        const uuid = uuidv4()
        setCurrentListener(uuid)
        addListener(uuid, onModalClose)
    }, [currentPlaylist])

    const handleFetch = async () => {
        dispatch(fetchCurrentPlaylist(playlistID))
    }

    useEffect(() => {
        if (!currentPlaylist) return
        setModal(<ViewPlaylistPage playlist={currentPlaylist} />, "minimum")
    }, [currentPlaylist])

    useEffect(() => {
        if (areLoading) return
        handleFetch()
    }, [areLoading])

    return children
}

const Store = () => {
    
}

const UrlShortcut = {
    Playlist,
}

export default UrlShortcut
