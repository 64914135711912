import QueueItem from "@/components/QueueItem"
import useDraggableInPortal from "@/hooks/useDraggableInPortal"
import { ApiVideo } from "@types"
import { memo } from "react"
import { Draggable, DraggableProvided } from "react-beautiful-dnd"
import styled from "styled-components"

const DragContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: var(--background-contrast);
    margin-bottom: 0.25rem;
    border-radius: var(--border-radius-s);
    padding: 0.25rem 0;
    padding-left: 0.25rem;

    .drag-handler {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        border-radius: var(--border-radius-ss);
        transition: 0.1s;
        > * {
            height: 100% !important;
        }
    }

    .drag-handler {
        background-color: var(--background-2);
    }

    li {
        padding: 1rem;
        padding-left: 0.5rem;
        overflow: hidden;
    }

    &.dragging .drag-handler {
        background-color: var(--background-light);
    }
`

const DraggableWrapper = memo(
    ({
        video,
        index,
        roomName,
        currentDragging,
        originalIndex,
        commandsAvailable,
        show_queue_thumbs,
    }: {
        video: ApiVideo
        index: number
        originalIndex: number
        roomName: string
        currentDragging: string
        commandsAvailable: string[]
        show_queue_thumbs: boolean
    }) => {
        const draggableId = `${video.uuid}-queue-video`
        const isBeenDragged = currentDragging === draggableId
        const className = isBeenDragged
            ? "draggable-element dragging"
            : "draggable-element"

        const renderDraggable = useDraggableInPortal()

        return (
            <Draggable
                index={index}
                key={draggableId}
                draggableId={draggableId}
            >
                {renderDraggable((props: DraggableProvided) => (
                    <DragContainer
                        className={className}
                        ref={props.innerRef}
                        {...props.draggableProps}
                    >
                        <div
                            className="drag-handler"
                            {...props.dragHandleProps}
                        >
                            <span className="material-icon notranslate">
                                drag_indicator
                            </span>
                        </div>
                        <QueueItem
                            roomName={roomName}
                            video={video}
                            index={originalIndex}
                            isDragging={true}
                            commandsAvailable={commandsAvailable}
                            show_queue_thumbs={show_queue_thumbs}
                        />
                    </DragContainer>
                ))}
            </Draggable>
        )
    }
)

export default DraggableWrapper
