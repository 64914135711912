import { useTranslation } from "react-i18next"
import { Container } from "./Base"
import { IHelpBadge, IHelpTooltips } from "@/pages/Help/data"

const User = ({ tooltips }: { tooltips: IHelpTooltips }) => {
    const { t } = useTranslation()
    return (
        <Container
            tooltips={tooltips}
            id={"mod"}
            description={t("help.title_mod_notice")}
            title={t("help.title_mod")}
        ></Container>
    )
}

export default User
