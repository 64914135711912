import { TippyProps } from "@tippyjs/react"
import TwitchBadge from "../TwitchBadge"
import UsernameLabel from "../UsernameLabel"
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import { ApiAvatar } from "@types"
import { outlineFilter, outlineFilterPurple, outlineFilterRed, outlineFilterWhite } from "./filters"
import { OutlineFilter } from "@pixi/filter-outline"
import { AvatarPixi } from "@/types/pixi"
import { ReactNode, useMemo } from "react"

interface PropsTooltip {
    danceFloorTooltip: IRootState["roomData"]["danceFloorTooltip"]
    wrapperRef: any
    setTooltipVisible: (d: boolean) => void
    isTooltipHover: boolean
    timeout: any
    setTooltipProps: (d: TippyProps) => void
    deactivateTooltip: () => void
    activateTooltip: () => void
    isCanvasHover: boolean
    avatars: AvatarPixi[]
}

export const getSpecsOutlineColor = (specs: ApiAvatar["specs"]): [OutlineFilter, string] => {
    if (specs.id) {
        if (["viscoitinha", "ferida"].includes(specs.id)) {
            return [outlineFilterPurple, "#aa61ff"]
        } else {
            return [outlineFilterRed, "#ff5555"]
        }
    } else {
        return [outlineFilterWhite, "#dee6f3"]
    }
}

export const handleDanceFloorTooltip =
    ({
        danceFloorTooltip,
        wrapperRef,
        deactivateTooltip,
        setTooltipVisible,
        isTooltipHover,
        timeout,
        setTooltipProps,
        activateTooltip,
        isCanvasHover,
        avatars,
    }: PropsTooltip) =>
    (): void => {
        if (!danceFloorTooltip || !wrapperRef) {
            deactivateTooltip()
            return null
        }

        if (!isCanvasHover) return null
        clearTimeout(timeout)
        setTooltipVisible(true)
        if (isTooltipHover) return null
        let { x, y, scale } = danceFloorTooltip
        const rect = wrapperRef.current.getBoundingClientRect()

        x += rect.x
        y += rect.y - 35
        let scaleClassName = ``

        if (scale?.length > 0) scaleClassName = `scale-${scale[0].toString().replace(".", "dot")}`
        else scaleClassName = `scale-1`

        const isSupporterAvatar = danceFloorTooltip?.avatar.specs.spritesheet === "AvatarViscoito_A.png"

        const target = avatars.find((avatar) => avatar.user.id === danceFloorTooltip.userId)
        const user = target?.user

        if (!target) return null

        setTooltipProps({
            content: (
                <div className="tooltip-user-label">
                    {isSupporterAvatar && i18next.t("dance_floor.avatar.supporters_exclusive")}
                    <div
                        className="tooltip-user-label-row"
                        onMouseEnter={activateTooltip}
                        onMouseLeave={deactivateTooltip}
                    >
                        <TwitchBadge tooltipProps={{ offset: [0, 10] }} user={user} />
                        <UsernameLabel user={user} tooltipProps={{ placement: "top", offset: [0, 10] }} />
                    </div>
                </div>
            ),
            offset: [x, isSupporterAvatar ? y - 20 : y],
            className: `white-outlined-tooltip ${scaleClassName}`,
            // className: danceFloorTooltip
        })
    }
