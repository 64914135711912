import FeelsBadMan from "@/assets/feelsbadman.svg?url"
import RainTime from "@/assets/raintime.webp"
import { useSocket } from "@/hooks/SocketContext/Provider"
import { setVideoPlayerReady, updateVideoPlayerApi } from "@/slices/roomData"
import { ApiVideo } from "@types"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import YouTube from "react-youtube"
import "./VideoPlayer.css"
import { useTranslation } from "react-i18next"
import canAutoplay from "can-autoplay"

export interface VideoPlayerApi {
    isReady?: boolean
    setVideo: (video: ApiVideo) => void
    skipCurrent: () => void
    seekTo: (video: ApiVideo) => void
    unMute: () => void
    getCurrentTime: () => number
}

const generateVideoPlayerApi = (ref: YT.Player): VideoPlayerApi => {
    return {
        setVideo: (video: ApiVideo) => {
            if (!ref.loadVideoById) return
            ref.loadVideoById(
                video ? video.id : "",
                video?.time / 1000,
                "large"
            )
        },
        skipCurrent: function () {
            this.setVideo()
        },
        seekTo: function (video: ApiVideo) {
            ref.seekTo(video.time / 1000, true)
        },
        unMute: function () {
            ref.unMute()
            ref.setVolume(25)
        },
        getCurrentTime: function () {
            return ref.getCurrentTime()
        },
    }
}

let isAutoplayed = false
let intervalId: ReturnType<typeof setInterval> = null

function VideoPlayer() {
    const [isPlaying, setIsPlaying] = useState(false)

    const queue = useSelector((state: IRootState) => state.roomData.queue)
    const videoPlayer = useSelector(
        (state: IRootState) => state.roomData.videoPlayer
    )

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const autoplay = useSelector((state: IRootState) => state.config.autoplay)
    const { socket } = useSocket()

    const onPlayerReady = () => socket.emit("get-current")

    const handlePlayerEvent = (e: YT.PlayerEvent | YT.OnStateChangeEvent) => {
        if (videoPlayer) return
        const newPlayer = generateVideoPlayerApi(e.target)
        dispatch(updateVideoPlayerApi(newPlayer))
    }

    const _ready = (event: YT.PlayerEvent) => {
        handlePlayerEvent(event)
        onPlayerReady()
    }

    const _stateChanged = (event: YT.OnStateChangeEvent) => {
        if (event.data === 2) {
            console.info("Youtube player ready")
            onPlayerReady()
        } else if (event.data === -1 && queue.length < 1) {
            setIsPlaying(false)
        } else {
            setIsPlaying(true)
        }
    }

    const _handleWindowClicked = () => {
        if (!autoplay) return
        if (isAutoplayed) return
        isAutoplayed = true
        videoPlayer.unMute()
    }

    useEffect(() => {
        if (!videoPlayer) return console.warn("Iframe do youtube não existe")
        window.addEventListener("click", _handleWindowClicked)
        return () => {
            window.removeEventListener("click", _handleWindowClicked)
        }
    }, [videoPlayer])

    useEffect(() => {
        if (!videoPlayer) return
        if (isAutoplayed) return
        
        canAutoplay.video({ muted: false }).then(({ result }) => {
            if (!result) return
            videoPlayer.unMute()
            isAutoplayed = true
        })
    }, [videoPlayer])

    const opts = {
        width: "100%",
        height: "100%",
        color: "white",
        playerVars: {
            autoplay: 1,
            mute: 1,
        },
        allow: "autoplay",
        autoplay
    }

    return (
        <div className="video-player">
            <div
                className="no-video-playing ns"
                style={{
                    opacity: isPlaying ? 0 : 0.999,
                    zIndex: isPlaying ? -1 : 1,
                }}
            >
                <div className="images">
                    <img
                        draggable={false}
                        src={FeelsBadMan}
                        alt="FeelsBadMan"
                        className="background"
                    />
                    <img
                        className="overlay"
                        draggable={false}
                        src={RainTime}
                        alt=""
                    />
                </div>
                {t("video_player.no_video_playing")}
            </div>
            <YouTube
                opts={opts}
                onReady={_ready}
                onStateChange={_stateChanged}
                onPlay={() => dispatch(setVideoPlayerReady())}
                style={{
                    opacity: 1,
                    transition: "0.1s",
                    width: "100% !important",
                }}
                
            />
        </div>
    )
}

export default VideoPlayer
