import { createContext } from "react"
import { Socket } from "socket.io-client"

interface ISocket {
    socket: Socket,
    // setSocket: (newValue: Socket) => void
}

const SocketContext = createContext<ISocket>({
    socket: null,
    // setSocket: () => {}
})
export default SocketContext
