import UsernameLabel from "@/components/UsernameLabel"
import { setQueuePaused } from "@/slices/roomData"
import { ThunkDispatch } from "@reduxjs/toolkit"
import { ApiChatter, ApiQueueEvent, ApiUser } from "@types"
import { Trans } from "react-i18next"
import { toast } from "react-toastify"
import { Socket } from "socket.io-client"

const handleNotification = (
    event: ApiQueueEvent,
    currentUser: ApiUser | ApiChatter,
    actionName: string
) => {
    if (event.by.id === currentUser?.id) return
    const notification = (
        <div>
            <Trans i18nKey={`events.${actionName}`}>
                <UsernameLabel user={event.by} />
            </Trans>
        </div>
    )
    toast.info(notification)
}

const handlePause = (
    event: ApiQueueEvent,
    currentUser: ApiUser | ApiChatter,
    dispatch: ThunkDispatch<any, any, any>
) => {
    if (event.type !== "pause") return
    dispatch(setQueuePaused(event))
    const actionName = event.isPaused ? "queue_paused" : "queue_resumed"

    handleNotification(event, currentUser, actionName)
}

export const handleQueueEvent =
    (
        chatter: ApiChatter | null,
        user: ApiChatter | null,
        dispatch: ThunkDispatch<any, any, any>
    ) =>
    (event: ApiQueueEvent) => {
        const currentUser = user || chatter
        if (event.type === "reorder") {
            handleNotification(event, currentUser, "queue_reordered")
        } else {
            handlePause(event, currentUser, dispatch)
        }
    }

export const removeEventListeners = (eventName: string, socket: Socket) => {
    const listeners = socket.listeners(eventName)
    listeners.forEach((listener) => socket.removeListener(eventName, listener))
}
