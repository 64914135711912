import useModal from "@/hooks/useModal"
import CommunityTabPage from "@/pages/CommunityTab"
import Wave from "react-wavify"
import styled from "styled-components"
import ButtonIcon from "../Widgets/ButtonIcon"
import { useTranslation } from "react-i18next"

const Container = styled.div`
    .wave-button-rotated {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .inner-shadow {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        box-shadow: inset 0px 1px 6px 1px #8d8d8d34;
    }

    p {
        position: relative;
        z-index: 3;
        text-shadow: 0px 0px 2px #00000076;
    }
`

const CommunitPlaylistsButton = ({
    styles,
    text,
}: {
    styles?: any
    text?: string
}) => {
    const { setModal } = useModal()
    const { t } = useTranslation()
    return (
        <ButtonIcon
            onSubmit={() => setModal(<CommunityTabPage />, "main")}
            wrapperProps={{
                className: "folder-button button-iconless",
                style: {
                    position: "relative",
                    padding: ".7rem",
                    overflow: "hidden",
                    ...styles,
                },
            }}
            type={2}
        >
            <Container>
                <Wave
                    className="wave-button-rotated"
                    fill="url(#gradient-2)"
                    height={10}
                    amplitude={7}
                    accentHeight={1}
                >
                    <defs>
                        <linearGradient
                            id="gradient-2"
                            gradientTransform="rotate(90)"
                        >
                            <stop offset={"10%"} stopColor="#9580ff"></stop>
                            <stop offset={"90%"} stopColor="#ff80bf"></stop>
                        </linearGradient>
                    </defs>
                </Wave>
                <p> {text || t("widgets.community_playlists_button")} </p>
                <div className="inner-shadow"></div>
            </Container>
        </ButtonIcon>
    )
}

export default CommunitPlaylistsButton
