import ButtonIcon from "@/components/Widgets/ButtonIcon"
import TextInputIcon from "@/components/Widgets/TextInputIcon"
import { LegalHyperlinks } from "@/pages/Support"
import { motion } from "framer-motion"
import { ReactNode, useRef } from "react"
import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"

const FormContainer = styled(motion.div)`
    display: flex;
    /* gap: 2rem; */
    > * {
        margin: auto;
    }

    height: 20rem;
    /* justify-content: space-evenly; */
    flex-direction: column;
    /* padding: 1rem; */

    align-items: center;

    .input-wrapper {
        background-color: var(--background-2);
        outline: 1px solid #5f687c61;

        input {
            padding: 1rem !important;
        }
    }

    h1 {
        font-size: 2.5rem;
    }

    p.error {
        margin: 0rem;
    }

    .animated {
        background: linear-gradient(318deg, #ff80bf, #9580ff);
        background-size: 150% 150%;
        -webkit-animation: BackgroundMove 5s infinite;
        -moz-animation: BackgroundMove 5s infinite;
        animation: BackgroundMove 5s infinite;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .legal-links {
        display: flex;
        gap: 1rem;
        margin: 0 !important;
        flex-wrap: wrap;
        opacity: 0.75;
        * {
            color: var(--color-5) !important;
        }
    }
`

const CreateRoomForm = ({ onSubmit, children }: { onSubmit: (roomName: string) => void; children: ReactNode }) => {
    const ref = useRef<HTMLInputElement>(null)
    const { t } = useTranslation(null, { keyPrefix: "home.rooms.create_modal" })

    return (
        <FormContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
        >
            <h1>
                <Trans i18nKey={"home.rooms.create_modal.title"}>
                    <span className="animated"></span>
                </Trans>
            </h1>
            <TextInputIcon
                icon="help"
                onSubmit={onSubmit}
                resetAfterSubmit={false}
                inputProps={{ placeholder: t("input_placeholder") }}
                hideButton={true}
                wrapperProps={{ className: "input-wrapper" }}
                inputRef={ref}
            />
            {/* <p className="notice">You must be a moderator</p> */}
            <ButtonIcon
                type={"white"}
                onSubmit={() => onSubmit(ref.current.value)}
                icon="send"
                children={t("send_request_button")}
            />
            {children}
            {!children && (
                <div className="legal-links">
                    <LegalHyperlinks />
                </div>
            )}
        </FormContainer>
    )
}

export default CreateRoomForm
