import { SearchLoading } from "@/components/Loading"
import useModal from "@/hooks/useModal"
import api from "@/services/api"
import { updateCommunityPlaylist } from "@/slices/playlists"
import { fetchCommunityPlaylists } from "@/slices/playlists/thunks"
import { ThunkDispatch } from "@reduxjs/toolkit"
import { ApiCommunityPlaylist, ApiPlaylist } from "@types"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { v4 as uuidv4 } from "uuid"
import { PlaylistCard } from "../Playlists/PlaylistCard"
import { PlaylistCardActive } from "../Playlists/PlaylistCardActive"
import TitleBanner from "./TitleBanner"
import { CommunityTabContainer } from "./styles"

export const CommunityTabPageAnimation = () => {
    const { setModal } = useModal()
    const handleAnimationComplete = () => {
        setModal(<CommunityTabPage />, "main")
    }
    return (
        <CommunityTabContainer
            layoutId="robert"
            onLayoutAnimationComplete={handleAnimationComplete}
            style={{ background: "var(--background-contrast)" }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
        ></CommunityTabContainer>
    )
}

const CommunityTabPage = ({ preSearch }: { preSearch?: string }) => {
    const playlists = useSelector(
        (state: IRootState) => state.playlists.communityPlaylists
    )
    const [current, setCurrent] = useState<ApiPlaylist>(null)
    const [currentLoading, setCurrentLoading] = useState<string>(null)
    const { removeListener, addListener } = useModal()
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const [currentListener, setCurrentListener] = useState<string>(null)
    const scrollRef = useRef(null)
    const [search, setSearch] = useState<ApiPlaylist[]>()
    const [isLoading, setIsLoading] = useState(true)
    const [searchCount, setSearchCount] = useState(0)
    const { t } = useTranslation()

    const handleCurrentClose = () => {
        setCurrent(null)
    }

    const handleFetch = async () => {
        const result = fetchCommunityPlaylists()
        await dispatch(result)
        setIsLoading(false)
    }

    const onModalClose = (data: any | false, type: string) => {
        if (current || data) {
            handleCurrentClose()
            return false
        }

        return true
    }

    useEffect(() => {
        removeListener(currentListener)
        if (!current) return
        const uuid = uuidv4()
        setCurrentListener(uuid)
        addListener(uuid, onModalClose)
    }, [current])

    useEffect(() => {
        handleFetch()
    }, [])

    const handleClick = async (playlist: ApiCommunityPlaylist) => {
        if (currentLoading)
            return toast.info(t("community_playlists.wait_until_load"))
        setCurrentLoading(playlist._id)
        api.fetchPlaylist(playlist._id)
            .then((response) => setCurrent(response.data))
            .catch(() => toast.error(t("community_playlists.error_loading")))
            .finally(() => setCurrentLoading(null))
    }

    const onPlaylistUpdate = (newPlaylist: ApiCommunityPlaylist) => {
        dispatch(updateCommunityPlaylist(newPlaylist))
    }

    const handleSearch = async (query: string): Promise<boolean> => {
        if (!query || query.length === 0) {
            setSearch(null)
            return false
        }
        setSearchCount((e) => (e += 1))
        setIsLoading(true)
        const response = await api.searchPlaylists(query)
        setSearch(response.data)
        setIsLoading(false)
        return true
    }

    useEffect(() => {
        if (searchCount !== 0) return
        if (!preSearch) return
        handleSearch(preSearch)
    }, [searchCount, preSearch])

    const target = search || playlists

    return (
        <CommunityTabContainer
            style={{
                background: playlists
                    ? "var(--background-2)"
                    : "var(--background-contrast)",
                position: playlists ? "initial" : "absolute",
            }}
        >
            <AnimatePresence>
                {isLoading && (
                    <SearchLoading
                        styles={{ background: "var(--background-contrast)" }}
                        background={true}
                        message={t("community_playlists.loading")}
                    />
                )}
            </AnimatePresence>
            {playlists && (
                <TitleBanner
                    defaultValue={preSearch}
                    handleSearch={handleSearch}
                />
            )}
            <motion.div
                id="robert-here"
                className="page-content"
                ref={scrollRef}
            >
                {target !== null && target?.length === 0 && (
                    <p>{t("community_playlists.no_playlists_found")}</p>
                )}
                {!isLoading &&
                    target.map((p, i) => (
                        <PlaylistCard
                            playlist={p}
                            index={i}
                            onClick={handleClick}
                            key={p._id + i}
                            isExtern={true}
                            isLoading={p._id === currentLoading}
                            onPlaylistUpdate={onPlaylistUpdate}
                        />
                    ))}
                <AnimatePresence>
                    {current && (
                        <PlaylistCardActive
                            onClick={handleCurrentClose}
                            playlist={current}
                            isExtern={true}
                        />
                    )}
                </AnimatePresence>
            </motion.div>
        </CommunityTabContainer>
    )
}

export default CommunityTabPage
