import ButtonIcon from "@/components/Widgets/ButtonIcon"
import TextInputIcon from "@/components/Widgets/TextInputIcon"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { Form } from "./styles"
import styled from "styled-components"

const PageImportContainer = styled(Form)`
    justify-content: space-evenly;

    button {
        
    }
`

const PageImport = ({ setPage }: { setPage: (d: ReactNode) => void }) => {
    const { t } = useTranslation()

    const handleImportPlaylist = (url: string) => {}
    return (
        <PageImportContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ zIndex: 2 }}
        >
            <ButtonIcon
                onSubmit={(e) => {
                    e.stopPropagation()
                    setPage(null)
                }}
                icon="close"
                wrapperProps={{
                    className: "close-button",
                }}
            />
            <h3>{t("playlists_page.create_new.import")}</h3>
            <TextInputIcon
                onSubmit={handleImportPlaylist}
                inputProps={{
                    placeholder: t(
                        "playlists_page.create_new.import_placeholder"
                    ),
                    autoFocus: true,
                }}
                wrapperProps={{
                    className: "input-wrapper",
                    style: { padding: ".75rem .5rem" },
                }}
                icon="archive"
                hideButton={true}
            />
            <ButtonIcon
                onSubmit={() => {}}
                icon="archive"
                children={t("playlists_page.create_new.import_confirm")}
                type={1}
                
            />
        </PageImportContainer>
    )
}

export const Page1 = ({
    setPage,
    handleCreatePlaylist,
}: {
    setPage: (d: ReactNode) => void
    handleCreatePlaylist: (name: string) => void
}) => {
    const { t } = useTranslation()

    return (
        <Form
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <ButtonIcon
                onSubmit={(e) => {
                    e.stopPropagation()
                    setPage(null)
                }}
                icon="close"
                wrapperProps={{
                    className: "close-button",
                }}
            />
            <TextInputIcon
                onSubmit={handleCreatePlaylist}
                inputProps={{
                    placeholder: t(
                        "playlists_page.create_new.input_placeholder"
                    ),
                    autoFocus: true,
                }}
                wrapperProps={{
                    className: "input-wrapper",
                }}
                icon="create"
            />
        </Form>
    )
}
