import { MaterialIcon } from "@/types/material"
import { MouseEventHandler } from "react"
import styled from "styled-components"

const Button = styled.button<any>`
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem 0.5rem 0.4rem 0.75rem;
    gap: 0.25rem;
    font-size: 0.9rem;
    line-height: 0.9rem;
    transition: 0.2s;
    border-radius: 0.25rem;
    cursor: pointer;
    background: ${(props) => props.colors[0]};
    color: ${(props) => props.colors[2] || "var(--foreground)"};

    span {
        font-size: 1.5rem;
        font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 28;
        color: ${(props) => props.colors[2] || "var(--foreground)"};
        transition: 0.2s;
    }

    :active {
        transform: scale(0.9);
    }

    :hover {
        background: ${(props) => props.colors[1]};
        color: ${(props) => props.colors[3]};
        span {
            color: ${(props) => props.colors[3] || "var(--foreground)"};
        }
        /* color: #0f1118; */
    }
`

export interface ButtonIconProps {
    onSubmit: MouseEventHandler
    icon?: MaterialIcon
    children?: JSX.Element | string
    wrapperProps?: any
    colors?: string[]
    type?: 1 | 2 | 3 | 4 | "white"
}

const ButtonIcon = (props: ButtonIconProps) => {
    let typeColors = null
    switch (props?.type) {
        case 1:
            typeColors = [
                "var(--background-light) !important",
                "var(--background-2) !important",
            ]
            break
        case 2:
            typeColors = [
                "var(--background-2) !important",
                "var(--background-light) !important",
            ]
            break
        case 3:
            typeColors = [
                "var(--background) !important",
                "var(--background-contrast) !important",
            ]
            break
        case 4:
            typeColors = [
                "var(--background-contrast) !important",
                "#0f1118 !important",
            ]
            break
        case "white":
            typeColors = [
                "var(--foreground) !important",
                "#d2dae7 !important",
                "var(--background) !important",
                "var(--background-contrast) !important",
            ]
            break
    }

    return (
        <Button
            colors={
                typeColors ||
                props?.colors || [
                    "var(--background)",
                    "var(--background-light)",
                ]
            }
            onClick={props.onSubmit}
            style={{ paddingLeft: props.children ? ".75rem" : ".5rem" }}
            {...props.wrapperProps}
        >
            {props.children}
            {props.icon && (
                <span className="material-icon notranslate">
                    {props.icon || "add"}
                </span>
            )}
        </Button>
    )
}

export default ButtonIcon
