import { roomSelect } from "@/utils"
import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { BetaTag } from "../Widgets/Tags"
import { Trans, useTranslation } from "react-i18next"

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0.1rem 0.25rem;

    strong {
        color: #28d4cf;

        &.error,
        &.time_limit_min,
        &.time_limit_max {
            color: var(--color-2);
        }

        &.no_video_playing {
            color: var(--foreground-hover);
        }

        &.done {
            color: var(--color-4);
        }

        &.processing_started {
            color: var(--color-7);
        }
    }

    p {
        font-size: 0.8rem;
        margin: 0 0.25rem;
    }

    .line {
        width: 100%;
        height: 1px;
        background: #ffffff14;
        margin: 0.35rem 0;
    }

    .beta-tag {
        position: absolute;
        top: 0.25rem;
        left: 0.45rem;
    }
`


const LightTooltip = ({
    setBgTooltipHover,
}: {
    setBgTooltipHover: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const audioDataStatus = useSelector(roomSelect("audioDataStatus"))
    const currentVideo = useSelector(roomSelect("currentVideo"))
    const { t } = useTranslation()

    let statusDescription = null
    let status = "no_video_playing"

    if (
        audioDataStatus &&
        currentVideo &&
        audioDataStatus.for.id === currentVideo.id
    ) {
        status = audioDataStatus.status
        // statusDescription = descriptions[status]
    }

    statusDescription = t(`dance_floor.dynamic_lights.status.${status}`)
    document.documentElement.style.setProperty(
        "--dancefloor-tooltip-color",
        "var(--foreground)"
    )
    return (
        <Container
            onMouseEnter={() => setBgTooltipHover(true)}
            onMouseLeave={() => setBgTooltipHover(false)}
        >
            {/* <div className="beta-tag">
                <BetaTag />
            </div> */}
            <h3 style={{ textAlign: "center", marginBottom: ".35rem" }}>
                {t("dance_floor.dynamic_lights.title")}
            </h3>
            <p>{t("dance_floor.dynamic_lights.description_1")}</p>
            <div className="line"></div>
            <p>
                <Trans i18nKey={"dance_floor.dynamic_lights.description_2"}>
                    <strong></strong>
                </Trans>
            </p>
            <div className="line"></div>
            <p>
                {t("dance_floor.dynamic_lights.description_3")}
                <strong className={`${status}`}>{statusDescription}</strong>
            </p>
        </Container>
    )
}

export default LightTooltip
