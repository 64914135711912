import ButtonIcon from "@/components/Widgets/ButtonIcon"
import { closeErroredStoreItem, updateKey, updateRoomKey } from "@/slices/roomData"
import { addItemToShoppingCart, removeItemFromShoppingCart } from "@/slices/userData"
import { ThunkDispatch } from "@reduxjs/toolkit"
import { ApiStoreItem } from "@types"
import { AnimatePresence, MotionProps, motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import useMeasure from "react-use-measure"

import store from "@/store"
import { toast } from "react-toastify"
import styled from "styled-components"
import { FakeEmptyStoreCardItem } from "./StoreCardItem"
import { useScreen } from "usehooks-ts"
import { getLoginUrl } from "@/utils"

const Container = styled.div`
    position: fixed;
    display: flex;
    z-index: 600;
    inset: 0;
    width: 100%;
    height: 100%;
    overflow: auto !important;

    &.visible::before {
        opacity: 1;
        pointer-events: all;
    }

    &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        content: "";
        transition: 0.3s;
        z-index: -1;
        backdrop-filter: blur(50px) grayscale(100%) brightness(0.5);
        background-color: #1e23316b;
    }

    #store-card-container {
        height: fit-content;
        width: fit-content;
    }

    .card-portal {
        position: fixed;
        inset: 0;
    }

    #tsparticles {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 900;
    }
`

const ContentWrapper = styled(motion.div)`
    /* position: absolute; */
    /* width: 100%; */
    /* height: 100%; */
    inset: 0;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    gap: 2rem;
    /* overflow-y: auto;
    overflow-x: clip; */

    .top-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        text-align: center;
        pointer-events: all;

        p {
            font-size: 1.25rem;
        }
    }

    h1 {
        font-size: 4rem;
        text-shadow: -2px 2px 2px black;
    }

    .button-area {
        display: flex;
        gap: 1rem;
        align-items: center;
        pointer-events: all;
    }

    .button-area button {
        gap: 0.25rem;
        font-size: 1rem;
        span {
            font-size: 2rem;
        }

        &.buy-button {
            background: #9580ff;
            box-shadow: 0px 0px 20px 1px #9580ff9b;
            transition: transform 0.2s ease-in-out, background 0.5s ease-in-out, box-shadow 1s;
            text-shadow: 0px 0px 1px #00000047;
            color: var(--background);
            padding: 0.75rem;
            padding-left: 0.8rem !important;
            span {
                text-shadow: none;
                color: var(--background);
            }
            &:hover {
                transform: scale(1.05);
                background-color: #c680ff;
                box-shadow: 0px 0px 35px 1px #c680ff96;
            }
            &:active {
                transform: scale(0.9) !important;
            }

            &.remove {
                background: #f53636;
                box-shadow: 0px 0px 20px 1px #e01a3b9b;
            }

            &.owned {
                background: #0ead69;
                box-shadow: 0px 0px 20px 1px #0ead699b;
            }
        }
    }

    @media (max-width: 900px) {
        h1 {
            font-size: 3rem;
            text-shadow: -2px 2px 2px black;
        }
    }
`

const animationTopToBottom: MotionProps = {
    initial: {
        opacity: 0,
        y: -50,
    },
    animate: {
        opacity: 1,
        y: 0,
    },
    exit: {
        opacity: 0,
        y: -50,
    },
}

const animationBottomToTop: MotionProps = {
    initial: {
        opacity: 0,
        y: 50,
    },
    animate: {
        opacity: 1,
        y: 0,
    },
    exit: {
        opacity: 0,
        y: 50,
    },
}

let lastEventId: string = null

const StoreModalWrapper = () => {
    const storeModalItem = useSelector((state: IRootState) => state.roomData.storeModalItem)
    const shoppingCart = useSelector((state: IRootState) => state.userData.shoppingCart)
    const { t } = useTranslation()
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const [cachedItem, setCachedItem] = useState<ApiStoreItem>(null)
    const ref = useRef<HTMLDivElement>()
    const [topRef, topSize] = useMeasure()
    const screen = useScreen()
    const rem = parseFloat(getComputedStyle(document.documentElement).fontSize)
    const userInventory = useSelector((state: IRootState) => state.userData.user?.storeItems || [])
    const chatter = useSelector((state: IRootState) => state.userData.chatter)

    const handleClose = () => {
        dispatch(updateKey({ key: "storeModalItem", newValue: null }))
    }

    const addToCart = async () => {
        if (!chatter) {
            toast.error(
                <p>
                    <Trans i18nKey={"store_page.login_required"}>
                        <a
                            style={{ textDecoration: "underline", color: "var(--color-5)", fontSize: "1rem" }}
                            onClick={() => window.open(getLoginUrl(), "_self")}
                        />
                    </Trans>
                </p>,
                { autoClose: 60_000 },
            )
            return
        }
        dispatch(updateKey({ key: "storeModalExitTarget", newValue: "button" }))
        const result = await dispatch(addItemToShoppingCart(storeModalItem._id))
        if (result.meta.requestStatus === "fulfilled") return handleClose()
        else if (result.meta.requestStatus === "rejected") dispatch(closeErroredStoreItem())
    }

    const removeFromCart = async () => {
        await dispatch(removeItemFromShoppingCart(storeModalItem._id))
    }

    useEffect(() => {
        if (storeModalItem) setCachedItem(storeModalItem)
        else
            setTimeout(() => {
                setCachedItem(null)
            }, 500)
    }, [storeModalItem])

    const handleCalc = () => {
        const rect = ref.current.getBoundingClientRect()
        const target = document.getElementById(`store-card-item-${storeModalItem?._id}`)
        if (!target) return
        const targetRect = target?.getBoundingClientRect()

        const scale = rect.height / targetRect?.height
        console.log({ scale })
        store.dispatch(
            updateRoomKey({
                key: "storeModalSuicide",
                newValue: [rect.left, rect.top * scale, scale],
            }),
        )
    }

    useEffect(() => {
        if (!ref.current) return
        handleCalc()
    }, [ref.current, topSize, screen])

    const isItemInCart = cachedItem && shoppingCart.map((item) => item._id).includes(cachedItem._id)

    useEffect(() => {
        const subscribe = store.subscribe(() => {
            const state = store.getState()
            const event = state.roomData.storeModalIsExiting
            if (!event || !event.id) return
            if (event.id === lastEventId) return
            lastEventId = event.id
            handleClose()
        })
        return () => subscribe()
    }, [])

    const userAlreadyOwnIt = userInventory?.includes(storeModalItem?._id)

    const removeFromCartLabel = !userAlreadyOwnIt && isItemInCart

    let buttonLabel = null
    let handleSubmit = null
    let className = "buy-button"

    if (userAlreadyOwnIt) {
        className += " owned"
        buttonLabel = t("store_page.shopping_cart.already_owned")
        handleSubmit = () =>
            toast.info(t("store_page.shopping_cart.already_owned_notification"), {
                autoClose: 60_000,
            })
    } else if (removeFromCartLabel) {
        className += " remove"
        buttonLabel = t("store_page.shopping_cart.remove_from_cart")
        handleSubmit = removeFromCart
    } else {
        buttonLabel = t("store_page.shopping_cart.add_to_cart", {
            price_brl: cachedItem?.price_brl,
            price_usd: cachedItem?.price_usd,
        })
        handleSubmit = addToCart
    }

    return (
        <Container
            style={{ pointerEvents: "none" }}
            className={storeModalItem && "visible " + storeModalItem._id}
            onScroll={() => handleCalc()}
        >
            <ContentWrapper className="content-wrapper">
                <AnimatePresence>
                    {storeModalItem && (
                        <motion.div ref={topRef} className="top-area" {...animationTopToBottom}>
                            <h1 key={"title"}>{t(storeModalItem.name)}</h1>
                            <p key={"description"}>{t(storeModalItem.description)}</p>
                        </motion.div>
                    )}
                </AnimatePresence>

                <div ref={ref} id="store-card-container" style={{ flexGrow: 1 }}>
                    <FakeEmptyStoreCardItem
                        style={{
                            minWidth: `100%`,
                            height: "100%",
                            position: "static",
                            minHeight: "fit-content",
                            opacity: 0,
                            visibility: "hidden",
                        }}
                    />
                </div>
                <AnimatePresence>
                    {storeModalItem && (
                        <motion.div className="button-area" {...animationBottomToTop}>
                            <ButtonIcon
                                icon="close"
                                children={t("close")}
                                onSubmit={handleClose}
                                type={"white"}
                            ></ButtonIcon>
                            <ButtonIcon
                                icon="shopping_basket"
                                children={buttonLabel}
                                onSubmit={handleSubmit}
                                wrapperProps={{
                                    className,
                                }}
                            ></ButtonIcon>
                        </motion.div>
                    )}
                </AnimatePresence>
            </ContentWrapper>
        </Container>
    )
}

export default StoreModalWrapper
