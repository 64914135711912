import { VideoPlayerApi } from "@/components/VideoPlayer"
import { AvatarPixi } from "@/types/pixi"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
    ApiAudioDataEvent,
    ApiAudioDataStatus,
    ApiAvatar,
    ApiChatter,
    ApiCommandEvent,
    ApiOverlayData,
    ApiQueueEvent,
    ApiRoomData,
    ApiStoreItem,
    ApiVideo,
    ApiVoteStatus,
} from "@types"

export type LoadingItem =
    | "user"
    | "chatter"
    | "queue"
    | "player"
    | "dance-floor"
    | "video"
    | "votes"
    | "reconnecting"
    | "reconnected"
    | "reconnect_error"

export interface IRoomDataState {
    loadingItems: LoadingItem[]
    isLoading: boolean
    voteStatus: ApiVoteStatus
    avatars: AvatarPixi[]
    queue: ApiVideo[]
    commandLogs: ApiCommandEvent[]
    currentVideo: ApiVideo
    error: string
    videoPlayer: VideoPlayerApi
    whoPausedQueue: ApiChatter
    config: ApiRoomData["config"]
    overlay_config: ApiOverlayData
    danceFloorTooltip: {
        userId: string
        avatar: ApiAvatar
        x: number
        y: number
        scale: number[]
    }
    audioDataEvent?: ApiAudioDataEvent
    lastSeek: ApiVideo
    audioDataStatus: ApiAudioDataStatus
    language?: "pt" | "en" | "es"
    storeModalItem: ApiStoreItem
    storeModalExitPositionCard: [number, number]
    storeModalExitPositionButton: [number, number]
    storeModalExitPositionDancerCard: [number, number]
    storeModalExitTarget: "card" | "button" | "dashboard-card"
    logsSearchQuery: string | null
    storeModalSuicide: [number, number, number]
    storeModalIsExiting?: {value?: boolean, id?: string}
}

const initialState: IRoomDataState = {
    loadingItems: ["queue", "player", "dance-floor", "video", "votes"],
    isLoading: true,
    avatars: [],
    queue: [],
    commandLogs: [],
    voteStatus: null,
    currentVideo: null,
    error: null,
    videoPlayer: null,
    whoPausedQueue: null,
    config: null,
    overlay_config: null,
    danceFloorTooltip: null,
    audioDataEvent: null,
    lastSeek: null,
    audioDataStatus: null,
    storeModalItem: null,
    storeModalExitPositionCard: [0, 0],
    storeModalExitPositionButton: [0, 0],
    storeModalExitPositionDancerCard: [0, 0],
    storeModalExitTarget: "card",
    storeModalSuicide: [0, 0, 1],
    logsSearchQuery: null,
    storeModalIsExiting: null
}

const handleLoading = (state: any, item: LoadingItem): any => {
    const items = state.loadingItems.filter((i: any) => i !== item)

    return { ...state, isLoading: items.length > 0, loadingItems: items }
}

export type UpdatePayload<T, K extends keyof T> = {
    key: K
    newValue: T[K]
}

const userDataSlice = createSlice({
    name: "roomData",
    initialState,
    reducers: {
        setAvatars: (state, action: PayloadAction<AvatarPixi[]>) => {
            const avatars = action.payload
            return handleLoading({ ...state, avatars }, "dance-floor")
        },

        addAvatar: (state, action: PayloadAction<AvatarPixi>) => {
            const avatar = action.payload
            const avatars = [...state.avatars, avatar]
            state.avatars = avatars as any
        },

        removeAvatar: (
            state,
            action: PayloadAction<AvatarPixi | ApiAvatar>
        ) => {
            const avatars = state.avatars.filter(
                (item) => item.uuid !== action.payload.uuid
            )
            state.avatars = avatars
        },

        updateAvatar: (state, action: PayloadAction<AvatarPixi>) => {
            const newAvatar = action.payload
            const othersAvatars = state.avatars.filter(
                (avatar) => avatar.uuid !== newAvatar.uuid
            )
            state.avatars = [...othersAvatars, newAvatar] as any
        },

        addQueueVideo: (state, action: PayloadAction<ApiVideo>) => {
            state.queue = [...state.queue, action.payload]
        },

        removeQueueVideo: (state, action: PayloadAction<string>) => {
            state.queue = state.queue.filter((v) => v.uuid !== action.payload)
        },

        setQueue: (state, action: PayloadAction<ApiVideo[]>) => {
            return handleLoading({ ...state, queue: action.payload }, "queue")
        },

        setVotes: (state, action: PayloadAction<ApiVoteStatus>) => {
            return handleLoading(
                { ...state, voteStatus: action.payload },
                "votes"
            )
        },

        setCurrentVideo: (state, action: PayloadAction<ApiVideo>) => {
            return handleLoading(
                { ...state, currentVideo: action.payload },
                "video"
            )
        },
        updateVideoPlayerApi: (
            state,
            action: PayloadAction<VideoPlayerApi>
        ) => {
            return handleLoading(
                { ...state, videoPlayer: action.payload },
                "player"
            )
        },
        addCommandLog: (state, action: PayloadAction<ApiCommandEvent>) => {
            if (state.commandLogs.length >= 100) state.commandLogs.shift()
            state.commandLogs.push(action.payload)
        },
        setCommandLogs: (state, action: PayloadAction<ApiCommandEvent[]>) => {
            state.commandLogs = action.payload
        },
        setQueuePaused: (state, action: PayloadAction<ApiQueueEvent>) => {
            if (action.payload.type !== "pause") return

            if (action.payload.isPaused) {
                state.whoPausedQueue = action.payload.by
            } else {
                state.whoPausedQueue = null
            }
        },
        setConfig: (state, action: PayloadAction<ApiRoomData["config"]>) => {
            state.config = action.payload
        },
        setDanceFloorTooltip: (
            state,
            action: PayloadAction<IRoomDataState["danceFloorTooltip"]>
        ) => {
            state.danceFloorTooltip = action.payload
        },
        setAudioDataEvent: (
            state,
            action: PayloadAction<IRoomDataState["audioDataEvent"]>
        ) => {
            state.audioDataEvent = action.payload
        },
        setVideoPlayerReady: (state) => {
            state.videoPlayer.isReady = true
        },
        setLastSeek: (
            state,
            action: PayloadAction<IRoomDataState["lastSeek"]>
        ) => {
            state.lastSeek = action.payload
        },
        setAudioDataStatus: (
            state,
            action: PayloadAction<IRoomDataState["audioDataStatus"]>
        ) => {
            state.audioDataStatus = action.payload
        },
        setLoadingItems: (
            state,
            action: PayloadAction<IRoomDataState["loadingItems"]>
        ) => {
            state.loadingItems = action.payload
            state.isLoading = state.loadingItems.length > 0
        },
        setOverlayConfig: (
            state,
            action: PayloadAction<IRoomDataState["overlay_config"]>
        ) => {
            state.overlay_config = action.payload
        },
        closeErroredStoreItem: (
            state,
            action: PayloadAction<IRoomDataState["overlay_config"]>
        ) => {
            state.storeModalExitTarget = "card"
            state.storeModalItem = null
        },
        updateKey: (
            state,
            action: PayloadAction<
                UpdatePayload<IRoomDataState, keyof IRoomDataState>
            >
        ) => {
            const { key, newValue } = action.payload
            return { ...state, [key]: newValue }
        },
    },
})

export const {
    addAvatar,
    removeAvatar,
    setAvatars,
    setCurrentVideo,
    setQueue,
    updateAvatar,
    updateVideoPlayerApi,
    setVotes,
    addCommandLog,
    setCommandLogs,
    setQueuePaused,
    addQueueVideo,
    removeQueueVideo,
    setConfig,
    setDanceFloorTooltip,
    setAudioDataEvent,
    setVideoPlayerReady,
    setLastSeek,
    setAudioDataStatus,
    setLoadingItems,
    updateKey,
    setOverlayConfig,
    updateKey: updateRoomKey,
    closeErroredStoreItem,
} = userDataSlice.actions

const roomDataReducer = userDataSlice.reducer
export default roomDataReducer
