import styled from "styled-components"
import { DonatePageContainer } from "./styles"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import useModal from "@/hooks/useModal"
import DonatePage from "."
import { Trans, useTranslation } from "react-i18next"

export const ConfirmPageContainer = styled(DonatePageContainer)`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 2rem;

    h3 {
        font-weight: 500;
    }

    span {
        color: var(--color-2);
    }

    .buttons-wrapper {
        display: flex;
        gap: 1rem;

        button {
            padding: 0.7rem 0.7rem !important;
            color: var(--foreground) !important;
        }
        color: var(--foreground) !important;
    }
`

export const redButtonColors = ["#ce1848", "#b81641", "#a7133a"]
export const greenButtonColors = ["#0c975b", "#0b8d55", "#0b8550"]

const ConfirmPage = ({ measureRef }: { measureRef?: any }) => {
    const { setModal } = useModal()
    const { t } = useTranslation()

    return (
        <ConfirmPageContainer ref={measureRef}>
            <h3>
                <Trans i18nKey={"tips.confirm_page.title"}><span></span></Trans>
            </h3>
            <div className="buttons-wrapper">
                <ButtonIcon
                    onSubmit={() => setModal(<DonatePage />, "minimum")}
                    colors={redButtonColors}
                >
                    {t("tips.confirm_page.go_back")}
                </ButtonIcon>
                <ButtonIcon
                    onSubmit={() =>
                        window.open("//tipa.ai/feridinha", "_blank").focus()
                    }
                    colors={greenButtonColors}
                >
                    {t("tips.confirm_page.confirm")}
                </ButtonIcon>
            </div>
        </ConfirmPageContainer>
    )
}

export default ConfirmPage
