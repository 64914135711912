import { IHelpTooltips } from "@/pages/Help/data"
import { useTranslation } from "react-i18next"
import { Container } from "./Base"

const User = ({ tooltips }: { tooltips: IHelpTooltips }) => {
    const { t } = useTranslation()
    return <Container tooltips={tooltips} id={"user"} title={t("help.title_user")}></Container>
}

export default User
