import Tooltip from "@/components/Tooltip"
import ButtonIcon from "@/components/Widgets/ButtonIcon"
import { TippyProps } from "@tippyjs/react"
import { motion } from "framer-motion"
import { ReactNode, useState } from "react"
import styled, { CSSProperties } from "styled-components"
import { Instance, Props } from "tippy.js"

const Wrapper = styled.div`
    display: flex;
    gap: 0.25rem;
    flex-direction: column;

    button {
        border: none;
        background: var(--background-2);
        padding: 0.5rem;
        cursor: pointer;
        transition: 0.2s;
        border-radius: .2rem;
        :hover {
            background-color: var(--background-light);
            transform: scale(1.05);
        }
    }
`

const ItemBox = styled.button`
    display: flex;
    padding: 0 0.25rem;
    font-size: 0.9rem;
    background-color: var(--foreground);
    border: none;
    font-weight: 500;
    border-radius: var(--border-radius-ss);
    cursor: pointer;
    transition: 0.1s;
    align-items: center;

    &:hover {
        background-color: #ced4dd;
    }

    &,
    span {
        color: var(--background);
        padding-left: 0rem;
    }

    padding-right: 0.5rem;

    span {
        display: block;
        font-size: 1.75rem;
    }
`

const DropDown = <T extends { [key: string]: string }>({
    children,
    tippyProps,
    customWrapper,
    items,
    onChange,
    value,
    style,
}: {
    children?: JSX.Element
    tippyProps?: TippyProps
    customWrapper?: any
    items: T
    onChange: (e: keyof T) => void
    value: string
    style?: CSSProperties
}) => {
    const [isExpanded, setExpanded] = useState(false)
    const [ref, setRef] = useState<Instance<Props>>()
    const ContentWrapper = customWrapper || Wrapper

    return (
        <Tooltip
            trigger="click"
            placement="bottom"
            content={
                <ContentWrapper>
                    {Object.entries(items).map(([key, value], index) => (
                        <button
                            key={key + index}
                            onClick={() => {
                                onChange(key)
                                ref?.hide()
                            }}
                        >
                            {value}
                        </button>
                    ))}
                </ContentWrapper>
            }
            interactive={true}
            interactiveBorder={20}
            onShow={(e) => {
                setRef(e)
                setExpanded(true)
            }}
            onHide={(e) => {
                setRef(e)
                setExpanded(false)
            }}
        >
            <ItemBox style={style}>
                <motion.span
                    className="material-icon notranslate"
                    animate={{ rotate: isExpanded ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                >
                    {"expand_more"}
                </motion.span>
                {
                    //@ts-ignore
                    items[value]
                }
            </ItemBox>
        </Tooltip>
    )
}

export default DropDown
