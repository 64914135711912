import { createPlaylist } from "@/slices/playlists/thunks"
import { ThunkDispatch } from "@reduxjs/toolkit"
import { AnimatePresence, MotionProps } from "framer-motion"
import { ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Page1 } from "./pages"
import { Container } from "./styles"

const getAnimate = ({
    isActive,
    isHover,
    isInputActive,
}: {
    isActive: boolean
    isHover: boolean
    isInputActive: boolean
}): MotionProps["animate"] => {
    if (isInputActive) return {}
    if (isActive) return { scale: 0.9 }
    if (isHover) return { scale: 1.05 }
    return {}
}

const CreatePlaylistBox = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const [currentPage, setPage] = useState<ReactNode>(null)
    const isInputActive = Boolean(currentPage)
    const { t } = useTranslation()
    const [isActive, setActive] = useState(false)
    const [isHover, setHover] = useState(false)

    const handleCreatePlaylist = (name: string) => {
        dispatch(createPlaylist(name))
    }

    const animate = getAnimate({ isActive, isHover, isInputActive })

    return (
        <Container
            layout="preserve-aspect"
            className={
                (isInputActive ? "disabled" : "enabled") +
                (isActive ? " active" : "")
            }
            onClick={() => {
                if (currentPage) return
                setPage(
                    <Page1
                        handleCreatePlaylist={handleCreatePlaylist}
                        setPage={setPage}
                        key={"page-1"}
                    />
                )
            }}
            onPointerDown={() => setActive(true)}
            onPointerUp={() => setActive(false)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            animate={animate}
            transition={{ duration: 0.2, ease: "linear" }}
        >
            <div className="header">
                <span className="material-icon notranslate">add</span>
                <p>{t("playlists_page.create_new.title")}</p>
            </div>
            <AnimatePresence>{currentPage && currentPage}</AnimatePresence>
        </Container>
    )
}

export default CreatePlaylistBox
