import DropDown from "@/components/Widgets/DropDown"
import RoomCard from "@/pages/Home/RoomCard"
import { LoadRooms } from "@/pages/Home/styles"
import api from "@/services/api"
import { ApiRoom, ApiRoomScore, ApiRoomWithScore } from "@types"
import { AnimatePresence, motion, useInView } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

const RoomsSection = ({}: {}) => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: false, amount: "all", margin: "10%" })
    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation()
    const [orderType, setOrderingType] = useState<keyof ApiRoomScore>("recent_activity")
    const [rooms, setRoom] = useState<ApiRoomWithScore[] | false>(null)
    const [orderedRooms, setOrderedRooms] = useState<ApiRoomWithScore[] | false>(null)


    const orderOptions = {
        created_at: t("home.rooms.order_options.created_at"),
        recent_activity: t("home.rooms.order_options.recent_activity"),
        alphabetical_order: t("home.rooms.order_options.alphabetical_order"),
    }

    const fetchRooms = async () => {
        setIsLoading(true)
        const response = await api
            .fetchRooms()
            .then((response) => {
                setRoom(response.data)
            })
            .catch((err) => {
                console.log(err)
                setRoom(false)
            })
        setIsLoading(false)
        return response
    }
    useEffect(() => {
        fetchRooms()
    }, [])

    useEffect(() => {
        if(!rooms) return
        setOrderedRooms(() => {
            return Array.from(rooms).sort((a, b) => a.score[orderType] - b.score[orderType])
        })
    }, [orderType, rooms])

    return (
        <>
            <div className="title-wrapper">
                <h1 ref={ref}>{t("home.available_rooms")}</h1>
                <motion.p
                    className="order-by"
                    animate={{ opacity: +isInView }}
                    transition={{ duration: 0.3, delay: .75 }}
                >
                    {t("home.rooms.order_options.order_by")} <DropDown items={orderOptions} onChange={setOrderingType} value={orderType} />
                </motion.p>
            </div>
            <motion.div className="list">
                {orderedRooms &&
                    orderedRooms?.map((room, i) => (
                        <RoomCard room={room} key={i} index={i} animationDelay={1 / orderedRooms.length} />
                    ))}
            </motion.div>
            <AnimatePresence>
                {rooms === false && <LoadRooms isLoading={isLoading} onReload={fetchRooms} key={"load-rooms"} />}
            </AnimatePresence>
        </>
    )
}

export default RoomsSection
