import { motion } from "framer-motion"
import styled from "styled-components"

export const QuickActionsPageContainer = styled(motion.div)`
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    min-height: 25rem;
    background-color: var(--background);
    border-radius: var(--border-radius-m);
    justify-content: stretch;

    h2,
    h4 {
        text-align: center;
    }

    .droppable {
        > div {
            margin-bottom: 0.2rem;
        }
    }

    .droppable-enabled,
    .droppable-disabled {
        display: flex;
        flex-direction: column;
        background-color: var(--background-contrast);
        padding: 1rem 0.5rem;
        gap: 0.5rem;
        height: 100%;
        border-radius: var(--border-radius-s);
        outline: solid 1px #ffffff14;
    }

    .line {
        width: 100%;
        height: 1px;
        background: #ffffff14;
        margin-bottom: 0.4rem;
    }

    .columns-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        flex-wrap: wrap;
        width: 100%;
        flex-grow: 1;
    }

    .droppable {
        height: 100%;
    }

    .buttons-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 1rem;
        margin-top: auto;

        > button {
            border: 1px solid #ffffff18;
        }
    }
    .beta-tag {
        position: absolute;
        left: 0.5rem;
        top: 0.5rem;
    }

    .controls-preview {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        background-color: var(--background-contrast);
        padding: .5rem;
        outline: solid 1px #ffffff14;
        border-radius: var(--border-radius-s);
    }
`
