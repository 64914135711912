import { ApiChatter } from "@types"
import { toast } from "react-toastify"

export const getBadgesNames = (user: ApiChatter): string[] => {
    let result = []
    if (user.dev || user.admin) result.push(`staff.png`)
    if (user.vip) result.push(`vip.png`)
    else if (user.mod) result.push(`moderator.png`)
    else if (user.broadcaster) result.push(`broadcaster.png`)
    if (user.supporter) result.push("supporter.png")
    if (user.dj) result.push(`dj.png`)
    return result
}

export const getRoomName = () => window.location.pathname.split("/")[1]
export const getLoginUrl = () =>
    `${process.env.API_PATH}/auth/redirect?room=${getRoomName()}`

export const handleRejectedThunk = (_: any, action: any) => {
    const errorMessage = action.error.message
    toast.error(errorMessage)
}

export const sleep = async (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms))

export const roomSelect =
    <T extends IRootState, T2 extends keyof T["roomData"]>(d: T2) =>
    (state: T): T["roomData"][T2] =>
        // @ts-ignore
        state.roomData[d]

export const toTitle = (target: string) =>
    target[0].toUpperCase() + target.slice(1)
