import { ApiVideo } from "@types"
import { motion } from "framer-motion"
import { memo } from "react"
import styled from "styled-components"
import { QueueVideoDetails } from "./VideoDetails"

const Box = styled(motion.li)`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
    gap: 0.5rem;
    min-height: fit-content;
    
    .actions-wrapper {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: fit-content;

        display: flex;
        flex-direction: column;
        flex-wrap: wrap-reverse;

        justify-content: center;
        gap: 0.2rem;

        > div {
            width: fit-content;
            height: fit-content;
        }

        opacity: 0;
        filter: blur(15px);
        transition: .3s ease, filter .3s;

        button {
            font-size: 2rem;
        }
    }

    :hover {
        .actions-wrapper {
            opacity: 1;
            filter: blur(0px);
            transform: translateX(-5px);
            transition: .1s, filter 0s;
        }
    }

    .column-0 {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        width: fit-content;
        font-size: 0.9rem;
        opacity: 0.3;
        color: var(--foreground);
        span {
            font-size: 0.9rem;
            font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 100, "opsz" 12;
        }
    }

    .column-1 {
        /* position: relative; */
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        /* background-color: red; */
        height: 100%;
        width: fit-content;
        align-items: center;
        margin-right: 0.25rem;
        img {
            height: 3rem;
            border-radius: 0.125rem; //trollrun
            aspect-ratio: 16/9;
            user-select: none;
        }
    }

    .column-2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
        gap: 0.25rem;
        min-height: fit-content;
        width: fit-content;
        span.material-icon {
            color: var(--foreground);
            display: inline-flex;
            align-items: center;
            margin: 0 0.25rem 0 0;
            vertical-align: bottom;
            height: fit-content;
            font-size: 1.1rem;
            font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 0, "opsz" 20;
        }

        p.text {
            display: inline-block;
            color: var(--foreground);
            font-size: 0.8rem;
            overflow: hidden;
            word-break: break-word;
        }

        p.author,
        p.views,
        p.duration {
            font-size: 0.9rem;
        }
        p.author span,
        p.views span,
        p.duration span {
            font-size: 0.9rem;
            height: fit-content;
        }
        a {
            text-decoration: none;
            height: 21px;
        }
        a:hover {
            text-decoration: underline;
        }
        .details {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
        }
    }
    .tippy-wrapper {
        height: 21px;
    }
`

const QueueItem = memo(
    ({
        video,
        index,
        roomName,
        isDragging,
        isOwner,
        show_queue_thumbs,
        commandsAvailable,
        isPrimary,
    }: {
        index: number
        video: ApiVideo
        roomName: string
        isDragging?: boolean
        isOwner?: boolean
        show_queue_thumbs?: boolean
        commandsAvailable: string[]
        isPrimary?: boolean
    }) => {
        return (
            <Box
                layout={isDragging ? null : "position"}
                initial={
                    isDragging
                        ? null
                        : {
                              opacity: 0,
                              x: 250,
                          }
                }
                animate={{
                    opacity: 1,
                    x: 0,
                    // transition: { delay: index * 0.175 },
                }}
                exit={{
                    opacity: 0,
                    scale: 0,
                    x: -250,
                    transition: { delay: 0, duration: 0 },
                }}
            >
                <QueueVideoDetails
                    index={index}
                    video={video}
                    roomName={roomName}
                    isOwner={isOwner}
                    commandsAvailable={commandsAvailable}
                    show_queue_thumbs={show_queue_thumbs}
                />
            </Box>
        )
    }
)
export default QueueItem
