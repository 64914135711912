import { BaseTexture, Rectangle, Resource, SCALE_MODES, Texture } from "pixi.js"

type TexturesCache = { [key: string]: Texture<Resource>[] }

let baseTexturesCache: any = {}
let texturesCache: TexturesCache = {}
const STATIC_URL = "https://f.feridinha.com/sync"

const getBaseTexture = (id: string): BaseTexture => {
    if (baseTexturesCache[id]) return baseTexturesCache[id]
    console.log("[BaseTexture item] Criando cache de", id)
    baseTexturesCache[id] = BaseTexture.from(`${STATIC_URL}/${id}`)
    baseTexturesCache[id].scaleMode = SCALE_MODES.NEAREST
    return baseTexturesCache[id]
}

const getTextures = (
    filename: string,
    width: number,
    height: number,
    steps: number,
    baseTexture: BaseTexture
): Texture[] => {
    const hasCache = Boolean(texturesCache[filename])
    if (hasCache) return texturesCache[filename]
    texturesCache[filename] = []
    console.log("[Textures array] Criando cache de", filename)
    for (let i = 0; i < steps; i++) {
        const texture = new Texture(
            baseTexture,
            new Rectangle(i * (width / steps), 0, width / steps, height)
        )

        texturesCache[filename].push(texture)
    }
    return texturesCache[filename]
}

const pixiUtils = {
    getTextures,
    getBaseTexture,
}

export default pixiUtils
