import { ApiChatter } from "@types"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import styled from "styled-components"
import api from "@/services/api"
import { getPaintStyles } from "@/utils/7tv"
import SevenTvLogo from "@/assets/logos/seventv.svg"
import AsyncButton from "@/components/Widgets/AynscButton"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

const assetsPath = `https://f.feridinha.com/sync`

const Container = styled.div`
    display: flex;
    align-items: center;
    background-color: var(--background);
    width: 100%;

    .userdata-wrapper {
        display: flex;
        gap: 0.5rem;
        padding: 0.5rem 0.5rem;
        align-items: center;
        overflow: hidden;
    }

    p.name {
        font-size: 1.5rem;
        overflow: auto;
        text-shadow: 0px 0px 5px #ffffff49;
    }

    img.badge {
        min-height: 36px;
        border-radius: 0.25rem;
        min-width: 36px;
    }

    border-radius: var(--border-radius-s);
    justify-self: flex-start;
    /* margin-bottom: auto; */
    text-overflow: ellipsis;
    min-height: fit-content !important;
    /* border: 1px solid rgba(255, 255, 255, 0.09); */

    span.base {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0.3rem;
        height: 2rem;
        border-radius: 1rem;
        transition: 0.3s;
        background-repeat: no-repeat;
        background-size: cover;
    }

    span.base.show {
        cursor: pointer;
    }

    :hover span.base.show {
        width: 2rem;
        border-radius: 0.375rem;
        ::before {
            opacity: 1;
            transition: 0.3s;
            transition-delay: 0.1s;
        }
    }

    .badges-wrapper {
        display: flex;
        width: 36px;
        flex-shrink: 0;
        height: 36px;
        aspect-ratio: 1/1 !important;
        overflow: hidden;
        gap: 5px;
        border-radius: 0.25rem;

        cursor: pointer;

        user-select: none !important;
        > * {
            user-select: none !important;
        }
        /* transition: scale .3s; */
    }

    .refresh-area {
        margin-left: auto;
        width: fit-content;
        margin-right: 0.4rem;
        transition: ease-in-out 0.1s;
        display: flex;
        cursor: pointer;
        svg {
            height: 1.25rem;
            width: auto;
            /* z-index: 2; */
            &,
            > * {
                stroke: var(--foreground) !important;
                color: var(--foreground) !important;
                /* fill: var(--foreground) !important; */
            }
        }

        .box-shadow {
            position: absolute;
            height: 2rem;
            width: 2rem;
            z-index: 1;
            border-radius: 50%;
            box-shadow: 0px 0px 15px 1px transparent;
            transition: all 0.5s;
            scale: 0.7;
            background-color: var(--background-light);
            transition-delay: 0.3;
        }
        button {
            padding: 0.25rem !important;
            aspect-ratio: 1/1;
        }
        :active {
            scale: 0.9;
        }
    }

    .loading-async {
        width: 100%;
        height: 100%;
        background-color: var(--background-light);
        z-index: 99;
    }
`

const getBadges = (user: ApiChatter) => {
    let badges = []
    if (user.sevenTvCosmetics?.badge) {
        badges.push({
            file: `https://cdn.7tv.app/badge/${user.sevenTvCosmetics.badge.id}/3x`,
            tooltip: user.sevenTvCosmetics.badge.tooltip,
            isExtern: true,
        })
    }
    if (user.badges?.includes("formandos"))
        badges.push({ file: `formandos_l.png`, tooltip: "Badge exclusiva do formandos" })
    if (user.supporter) badges.push({ file: `supporter_l.gif`, tooltip: "Apoiador" })
    if (user.dev || user.admin) badges.push({ file: `staff_l.png`, tooltip: "Developer" })
    if (user.broadcaster) badges.push({ file: `broadcaster_l.png`, tooltip: "Broadcaster" })

    if (user.vip) badges.push({ file: `vip_l.png`, tooltip: "Vip" })
    if (user.mod) badges.push({ file: `moderator_l.png`, tooltip: "Moderator" })
    if (user.dj) badges.push({ file: `dj_l.png`, tooltip: "DJ Ilimitado" })
    if (user.betaTester) badges.push({ file: `beta_user.png`, tooltip: "beta_user" })

    return badges
}

let timeoutId: any = null

const UserHeader = ({
    chatter,
    className,
    isUserCard,
}: {
    chatter: ApiChatter
    className?: string
    isUserCard?: boolean
}) => {
    const badges = getBadges(chatter)
    const [index, setIndex] = useState(0)
    const [isHover, setIsHover] = useState(false)
    const hasBadge = badges.length > 0
    const { t } = useTranslation()

    const changeBadge = () => {
        setIndex((e) => {
            if (e + 2 > badges.length) return 0
            return e + 1
        })
        clearTimeout(timeoutId)
        if (!isHover) return
        timeoutId = setTimeout(changeBadge, 1500)
    }

    useEffect(() => {
        clearTimeout(timeoutId)
        if (!isHover) return
        timeoutId = setTimeout(changeBadge, 300)
        return () => clearTimeout(timeoutId)
    }, [isHover])

    const handleSpotifyRedirect = async (): Promise<boolean> => {
        const url = await api.getSpotifyRedirectUrl()
        if (!url) return false
        window.open(url.data, "_self")
        return true
    }

    const paintStyles = getPaintStyles(chatter)

    const handleUpdateUserCache = async () => {
        const response = await api.updateSevenTvCache()
        if (typeof response === "string") return toast.error(response)
        toast.success(response.message)
    }

    return (
        <Container className={className} style={isUserCard ? null : { marginBottom: "1rem" }}>
            <div
                className="userdata-wrapper"
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => {
                    setIsHover(false)
                }}
            >
                <span
                    className={`base material-icon`}
                    style={{
                        background: chatter.color,
                        ...paintStyles,
                        backgroundClip: "initial",
                        backgroundSize: "initial",
                        WebkitTextFillColor: "initial",
                        filter: "initial",
                    }}
                />
                {hasBadge && (
                    <motion.div
                        className="badges-wrapper no-select ns"
                        animate={{ scale: isHover ? 1.35 : 1 }}
                        whileTap={{ scale: 1 }}
                    >
                        {badges.map((badge) => (
                            <motion.img
                                className="badge no-select ns"
                                src={badge.isExtern ? badge.file : `${assetsPath}/badges/${badge.file}`}
                                animate={{ x: -(41 * index) }}
                                transition={{
                                    duration: 0.3,
                                    ease: "easeInOut",
                                }}
                                key={badge.file}
                            ></motion.img>
                        ))}
                    </motion.div>
                )}
                <p style={{ color: chatter.color, ...paintStyles }} className="name">
                    {chatter.name}
                </p>
            </div>
            {!isUserCard && (
                <div className="refresh-area">
                    <AsyncButton
                        loadingClassName={"loading-async"}
                        resolver={handleUpdateUserCache}
                        children={<SevenTvLogo />}
                        tooltip={t("dashboard.update_seventv")}
                        tooltipPlacement="bottom"
                    />
                </div>
            )}
        </Container>
    )
}

export default UserHeader
