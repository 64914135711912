import Cookies from "js-cookie"
import { useContext } from "react"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import TokenContext from "../hooks/TokenContext"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { updateKey } from "@/slices/config"
import { useTranslation } from "react-i18next"

const AuthCallback = () => {
    const [searchParams] = useSearchParams()
    const { setToken } = useContext(TokenContext)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    useEffect(() => {
        const cookie = Cookies.get("sync_token")
        if (!cookie) return alert(t("auth_callback.token_not_found"))
        setToken(cookie)

        Cookies.remove("sync_token")
        dispatch(updateKey({ key: "tab_active", newValue: "dashboard" }))

        const roomParam = searchParams.get("room") || ""
        window.location.href = `/${roomParam}`
        toast.success(t("auth_callback.login_success"))
    }, [])
    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <a style={{ margin: "auto" }} href="/">
                {t("auth_callback.redirecting")}
            </a>
        </div>
    )
}

export default AuthCallback
