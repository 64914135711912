import { motion, TargetAndTransition, VariantLabels } from "framer-motion"

const FadeInOut = ({
    children,
    duration,
    customExit,
}: {
    children?: JSX.Element
    duration?: number
    customExit?: VariantLabels | TargetAndTransition
}) => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={customExit || { opacity: 0 }}
            transition={{ duration: duration || 2 }}
        >
            {children}
        </motion.div>
    )
}

export default FadeInOut
