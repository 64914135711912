import { ApiChatter } from "@types"
import { useState } from "react"
import ModalContext from "."

type ModalContentType = JSX.Element | false
type ModalType = "main" | "minimum"
type PositionType = [number, number]
type ListenerCallback = (data: ModalContentType, type: ModalType) => boolean

type ListenerType = {
    [key: string]: ListenerCallback
}

const ModalProvider = ({ children }: { children: JSX.Element }) => {
    const [modal, setModalState] = useState<ModalContentType>(null)
    const [mainModal, setMainModalState] = useState<ModalContentType>(null)
    const [userCard, setUserCard_] = useState<ApiChatter>(null)
    const [wrapperProps, setWrapperProps] = useState<any>(null)
    const [userCardPosition, setUserCardPosition] = useState<PositionType>([
        0, 0,
    ])

    const [listeners, setListeners] = useState<ListenerType>({})

    const setModal = (data: ModalContentType, type: ModalType, props: any) => {
        let mustContinue = true
        console.log({ listeners })
        setWrapperProps(props)

        for (let listener of Object.values(listeners)) {
            if (!listener(data, type)) {
                mustContinue = false
                break
            }
        }

        if (!mustContinue) return

        if (type === "main") return setMainModalState(data)
        setModalState(data)
    }

    const setUserCard = (user: ApiChatter, position: PositionType) => {
        setUserCard_(user)
        setUserCardPosition(position)
    }

    /**
     * Função para interceptar setModal
     * @return {true} Caso o setModal continue sem alterações
     * @return {false} Caso o setModal deva abortar a operação
     *
     */
    function addListener(id: string, target: ListenerCallback) {
        setListeners((e) => {
            e[id] = target
            return e
        })
    }

    function removeListener(id: string) {
        setListeners((e) => {
            delete e[id]
            return e
        })
    }

    return (
        <ModalContext.Provider
            value={{
                modal,
                setModal,
                mainModal,
                userCard,
                setUserCard,
                userCardPosition,
                listeners,
                addListener,
                removeListener,
                wrapperProps
            }}
        >
            {children}
        </ModalContext.Provider>
    )
}

export default ModalProvider
