import { motion } from "framer-motion"
import styled from "styled-components"

export const PageContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    padding: 1rem;
    overflow: auto;
    /* gap: 2rem; */

    h1 {
        z-index: 2;
    }

    p.label {
        text-align: left;
        align-self: flex-start;
        margin-left: 2rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        /* background-color: red; */
        /* transform: translateY(20px); */
    }
`

export const PlaylistCardsContainer = styled(motion.div)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: clip;
    gap: 1rem;
    width: 100%;
    padding: 1.25rem;
    min-height: fit-content;
    flex-shrink: 0;
    /* z-index: 2; */
    /* background-color: blue; */

    > div {
        z-index: 0;
    }
`

export const Backdrop = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    backdrop-filter: blur(10px);
    background-color: #ffffff04;
`
