import DropDown from "@/components/Widgets/DropDown"
import { LanguageKeys } from "@/i18n"
import { IConfigState, updateKey } from "@/slices/config"
import store from "@/store"
import i18next from "i18next"
import { ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    padding: 0;
    flex-direction: column;
    gap: 1rem;
    color: var(--foreground);

    .inputs {
        display: flex;
        padding: 0.5rem;
        flex-direction: column;
        gap: 0.4rem;
        background-color: var(--background);
        border-radius: var(--border-radius-ss);
        margin: 0 0.25rem;
    }

    .inputs p {
        position: relative;
        display: flex;
        gap: 0.3rem;
        font-weight: 400;
        font-size: 0.9rem;
        align-items: center;
    }
    .inputs p::before {
        position: absolute;
        content: "";
        width: 100%;
        background-color: #ffffff21;
        height: 1px;
        bottom: -2px;
    }

    > p {
        margin: 0 0.5rem;
        font-size: 0.8rem;
        opacity: 0.9;
    }

    .news-tag {
        align-self: flex-start;
        padding: 0.1rem 0.2rem;
        margin-left: auto;
    }
`

type BooleanKeys<T> = {
    [K in keyof T]: T[K] extends boolean ? K : never
}[keyof T]

let alerts: { [key in BooleanKeys<IConfigState>]?: () => void } = {
    autoplay: () =>
        toast.info(i18next.t("dashboard.config.autoplay_tooltip"), {
            autoClose: 25_000,
        }),
    queue_in_sidebar: () => {
        const currentIndex = store.getState().config.tab_index
        store.dispatch(updateKey({ key: "tab_active", newValue: "queue" }))
        toast.info(i18next.t("dashboard.config.queue_in_sidebar_tooltip"))
    },
}

export const ConfigCheckbox = ({ id }: { id: BooleanKeys<IConfigState> }) => {
    const config = useSelector(({ config }: IRootState) => config)
    const dispatch = useDispatch()
    if (!config) return

    return (
        <input
            type="checkbox"
            defaultChecked={config[id]}
            onChange={() => {
                const newValue = !config[id]
                if (newValue && alerts[id]) alerts[id]()
                dispatch(updateKey({ key: id, newValue }))
            }}
        ></input>
    )
}

const ConfigPage = () => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const handleLanguageChange = (language: LanguageKeys) => {
        i18n.changeLanguage(language)
        dispatch(updateKey({ key: "language", newValue: language }))
    }

    return (
        <Container>
            <div className="inputs">
                <p>
                    <ConfigCheckbox id="render_avatars" />
                    {t("dashboard.config.render_avatars")}
                </p>
                <p>
                    <ConfigCheckbox id="render_animations" />
                    {t("dashboard.config.render_animations")}
                </p>
                <p>
                    <ConfigCheckbox id="render_twitch_chat" />
                    {t("dashboard.config.render_twitch_chat")}
                </p>
                <p>
                    <ConfigCheckbox id="queue_in_sidebar" />
                    {t("dashboard.config.queue_in_sidebar")}
                    {/* <NewTag className="news-tag" /> */}
                </p>
                <p>
                    <ConfigCheckbox id="show_queue_thumbs" />
                    {t("dashboard.config.show_queue_thumbs")}
                    {/* <NewTag className="news-tag" /> */}
                </p>
                <p>
                    <ConfigCheckbox id="autoplay" />
                    {t("dashboard.config.autoplay")}
                    {/* <NewTag className="news-tag" /> */}
                </p>
                <p>
                    <ConfigCheckbox id="hide_title" />
                    {t("dashboard.config.hide_title")}
                    {/* <NewTag className="news-tag" /> */}
                </p>
                <p>
                    {t("choose_your_language")}
                    <DropDown
                        onChange={handleLanguageChange}
                        items={{
                            en: t("widgets.config_queue.choose_language_options.en"),
                            pt: t("widgets.config_queue.choose_language_options.pt"),
                            es: t("widgets.config_queue.choose_language_options.es"),
                        }}
                        value={i18n.language}
                        style={{ marginLeft: "auto" }}
                    />

                    {/* <select
                        style={{ marginLeft: "auto" }}
                        id="language"
                        onChange={handleLanguageChange}
                        value={i18n.language}
                    >
                        <option value="en">
                            {t(
                                "widgets.config_queue.choose_language_options.en"
                            )}
                        </option>
                        <option value="pt">
                            {t(
                                "widgets.config_queue.choose_language_options.pt"
                            )}
                        </option>
                        <option value="es">
                            {t(
                                "widgets.config_queue.choose_language_options.es"
                            )}
                        </option>
                    </select> */}
                </p>
            </div>
            <p>{t("dashboard.config.tip")}</p>
        </Container>
    )
}

export default ConfigPage
