import { motion } from "framer-motion"
import styled from "styled-components"

export const Container = styled(motion.div)`
    position: relative;
    height: 100%;
    min-width: 15rem;
    border-radius: var(--border-radius-s);
    overflow: hidden;
    min-height: 18.5rem;
    min-width: 14.5rem;
    display: flex;
    justify-content: center;
    background: var(--background-contrast);
    cursor: pointer;
    border: none;


/* 
    &.enabled:hover {
        transform: scale(1.05);
    }

    &.enabled.active {
        transform: scale(0.9);
    } */

    &.disabled {
        cursor: auto;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        span {
            font-size: 3rem;
        }
        pointer-events: all;
    }

    /* button.import-button {
        position: absolute;
        bottom: 1rem;
    } */

    button.create-new-playlist span.material-icon {
        height: 4rem;
        font-size: 4rem;
    }
`

export const Form = styled(motion.div)`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background: var(--background);
    height: 100%;
    width: 100%;
    padding: 1rem 0.5rem;
    /* z-index: 2; */

    button.close-button {
        position: absolute;
        left: 0.5rem;
        top: 0.5rem;
        background-color: var(--background-light);
    }

    button.close-button:hover {
        background-color: var(--background-2);
    }

    .input-wrapper {
        background: var(--background-light) !important;
    }

    .input-wrapper button {
        background: var(--background-contrast);
    }

    .line-text {
        position: absolute;
        bottom: 3rem;

        margin-bottom: 1rem;
        width: calc(100% - 1.5rem);
        display: flex;
        justify-content: center;
        align-items: center;

        ::before {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
            /* background: var(--background-contrast); */
            background-color: #ffffff47;
        }

        p {
            position: absolute;
            background-color: var(--background);
            padding: 0 .75rem;
        }
    }

    .import-button {
        position: absolute;
        bottom: 1rem;
    }
`
