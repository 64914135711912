import { motion } from "framer-motion"
import styled from "styled-components"

export const PlaylistCardContainer = styled(motion.div)`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    min-height: 18.5rem;
    min-width: 14.5rem;
    max-width: 14.5rem;
    padding: 1rem;

    padding-bottom: 1.5rem;
    border-radius: 0.5rem;
    background-color: var(--background-contrast);
    overflow: hidden;
    cursor: pointer;
    transition: 0.3s box-shadow linear;
    /* z-index: 2; */
    border: 1px solid #ffffff11;

    &.focused {
        border: 1px solid #ffffff4c;
    }

    .thumbs {
        position: relative;
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        height: 12.5rem;
        width: 12.5rem;
        gap: .15rem;
        margin-bottom: 0.25rem;
        flex-wrap: wrap;
        overflow: hidden;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            flex-grow: 1;
            border-radius: 0.25rem;
        }
    }

    .thumbs-1 {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
    }

    .thumbs-2 {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }

    .thumbs-2 img:first-child {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
    }

    .thumbs-2 img:last-child {
        grid-column: 1 / 4;
        grid-row: 2 / 4;
    }

    .thumbs-3 img:last-child {
        grid-column: 1 / 3;
    }

    :hover .playlist-details-button {
        opacity: 1;
        transform: translateY(0px);
        filter: blur(0px);
    }

    :hover {
        box-shadow: 0px 0px 25px 1px #00000061;
        transform: rotateX(50px);
        /* background-color: #1E2331; */
    }
`

export const PlaylistBottomContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto;
    justify-content: space-between;
    width: 100%;

    .info {
        flex-direction: column;
        gap: 0.2rem;
        grid-row: 1/2;
        grid-column: 1/2;
        width: 100%;
        max-height: 5rem;
        overflow: hidden;
    }

    .info p {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
    }

    .extra-info {
        grid-column: 1/4;
        p.size {
            opacity: 0.25;
        }
    }

    .actions {
        display: flex;
        gap: 0.25rem;
        grid-column: 2/4;
        grid-row: 1/2;
    }

    .extern {
        display: inline-block;

        span.light {
            opacity: 0.25;
            margin-right: 0.25rem;
        }

        p.username {
            overflow-wrap: break-word;
            word-break: break-all;
        }

        span {
            display: inline;
        }
    }
`
