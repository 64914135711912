import { useTranslation } from "react-i18next"
import ButtonIcon, { ButtonIconProps } from "../Widgets/ButtonIcon"
import { motion } from "framer-motion"
import styled from "styled-components"

const ConfirmSaveContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    width: 100%;

    .content {
        padding-top: 0.2rem;
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 0.35rem;
    }
`

export const ConfirmSave = ({
    onSave,
    onUndo,
    buttonProps,
}: {
    onSave: () => void
    onUndo: () => void
    buttonProps?: Partial<ButtonIconProps>
}) => {
    const { t } = useTranslation()
    return (
        <ConfirmSaveContainer
            initial={{ height: 0, overflow: "hidden" }}
            animate={{ height: "fit-content" }}
            exit={{ height: 0 }}
            transition={{ ease: "easeInOut", duration: 0.5 }}
            className="confirm-save-container"
        >
            <div className="line"></div>
            <div className="content">
                <ButtonIcon
                    onSubmit={onUndo}
                    icon={"undo"}
                    type={3}
                    {...buttonProps}
                >
                    {t("undo")}
                </ButtonIcon>
                <ButtonIcon
                    icon="save"
                    onSubmit={onSave}
                    type={3}
                    {...buttonProps}
                >
                    {t("save")}
                </ButtonIcon>
            </div>
        </ConfirmSaveContainer>
    )
}

